export const USUARIO_COMPRAS_MENORES = "611bebffadd7fa00257b0b59"; //ID de Nicolas Perez por reemplazo de Aline Galimidi - 2024.03.13 
export const APROBADOR_GERENTE_GAF = "657288001299d8001250f6b6"; //ID Luis Recabarren - Reemplaza Marcelo Baladrón

export const PAIS_CHILE = '6645287f93aafbf0e42a0c11';
export const PAIS_PERU = '6645288793aafbf0e42a0c14';
export const GERENCIA_PERU = '655615b5b6eb2a0011438b4e';

//'5e0f291a70edaf00137fd2f5';   ID Luis Recabarren
//'657288001299d8001250f6b6';   ID Marcelo Baladrón
//"5e0f291a70edaf00137fd297"    ID JRAMOS
//"611bebffadd7fa00257b0b59"    ID Nicolás Peréz V.
//"5e0f291670edaf00137fcffd";   ID Aline Galimidi
