import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Grid, Button, Container, MenuItem } from '@material-ui/core';
import TextField from './TextField';
import SelectField from './SelectField';

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'nombre',
    'tipo_recurso',
    'descripcion',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }
  return errors
}

let FormClases = props => {
    const { handleSubmit, tiposRecursos, pristine, reset, submitting, editar } = props
      return(
            <Container>
                <form noValidate onSubmit={handleSubmit} >

                    <Grid container justify="center" alignItems="center"  >
                        <Grid item xs={12} lg={4}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                type="text"
                                name="nombre"
                                label="Nombre"
                                component={TextField}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                name="tipo_recurso"
                                label="Pertenece a Tipo Recurso:"
                                component={SelectField}
                                    
                            >
                                {[<MenuItem key="" value="" >Seleccionar</MenuItem>].concat(tiposRecursos ? tiposRecursos.map( (x,id) => <MenuItem key={id} value={x.id}>{x.nombre}</MenuItem>) : [])}
                            </Field>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                type="text"
                                name="descripcion"
                                label="Descripción"
                                component={TextField}
                            />
                        </Grid>                                                           
                    </Grid>

                    
                    <Grid container style={{ marginTop: '20px'}} justify="center" alignItems="center">
                        <Button type='submit' variant='outlined' color="primary">
                        {editar ? "Editar" : "Agregar"}
                        </Button>
                    </Grid>
                </form>
            </Container>
        )
    }


FormClases = reduxForm({
  form: 'clases',
})(FormClases);

export default connect( (state, ownProps) => {
    return{
    initialValues: {
      nombre: ownProps.claseSelected ? ownProps.claseSelected.nombre : "" ,
      tipo_recurso: ownProps.claseSelected ? ownProps.claseSelected.tipo_recurso : "" ,
      descripcion: ownProps.claseSelected ? ownProps.claseSelected.descripcion : "",
    },
    enableReinitialize : true,
    validate,
}})(FormClases)