import { makeTypes, mac, createReducer, reduceReducers } from 'ducks-maker'
import { addHOR, delHOR } from './hors.js'

const t = makeTypes('modales')
const ADD = t('add').single()
const DEL = t('del').single()
 
export const openModal = values => mac(ADD, 'payload')({ id: Math.random().toString(36), ...values })
export const closeModal = mac(DEL, 'payload')

const initialState = {
    data: [],
}

const addReducer =  createReducer(initialState, addHOR({ ADD }))
const delReducer =  createReducer(initialState, delHOR({ DEL }))

export default reduceReducers(addReducer, delReducer)