import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Grid, IconButton, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SelectSearch from './SelectSearch';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

const validate = (values = {}) => {
    const errors = {};
    const requiredFields = ['aprobador'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = true;
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
};

let FormAprobadoresProyecto = props => {
    const { handleSubmit, change, usuarios } = props;
    return (
        <Container>
            <form noValidate onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12} lg={9}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                style={{ width: '80%', padding: '10px ' }}
                                type="text"
                                change={change}
                                name="aprobador"
                                label="Aprobadores"
                                options={usuarios.map(x => ({
                                    ...x,
                                    apellidos: x.apellidos + (x.email ? ' (' + x.email + ')' : '')
                                }))}
                                component={SelectSearch}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={1}>
                        <Grid container justify="center" alignItems="center">
                            <IconButton type="submit">
                                <i className="material-icons">add</i>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

FormAprobadoresProyecto = reduxForm({
    form: 'aprobadoresProyecto'
})(FormAprobadoresProyecto);

export default connect(state => ({
    initialValues: {},
    enableReinitialize: true,
    validate
}))(FormAprobadoresProyecto);
