import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Grid, Container, Typography, Card, CardContent } from '@material-ui/core';
import SelectSearch from '../components/SelectSearch';
import KeyboardDatePicker from '../components/KeyboardDatePicker';
import DatePicker from '../components/DatePicker';
import { compareObjectString } from '../services/utils';
import moment from 'moment';

let FormFiltrosDashProv = props => {
    const {
        handleSubmit,
        pristine,
        reset,
        submitting,
        gerencias,
        proyectos,
        centros,
        codigosSol,
        estados,
        tiposRecursos,
        formSolicitudHeaders,
        change,
        touch
    } = props;
    return (
        <form noValidate style={{ marginTop: -20 }}>
            <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            multiple
                            limitTags={1}
                            maxTagWidth="60px"
                            size="small"
                            style={{ width: '100%' }}
                            type="text"
                            change={change}
                            touch={touch}
                            name="gerencia"
                            label="Gerencia"
                            options={
                                gerencias
                                    ? gerencias.data
                                          .sort(compareObjectString('codigo'))
                                          .map(x => ({ ...x, nombre: x.codigo }))
                                    : []
                            }
                            component={SelectSearch}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            multiple
                            limitTags={1}
                            maxTagWidth="105px"
                            size="small"
                            style={{ width: '100%' }}
                            type="text"
                            change={change}
                            touch={touch}
                            name="proyecto"
                            label="Proyecto"
                            charCountLimit={12}
                            options={proyectos ? proyectos.data.sort(compareObjectString('codigo')) : []}
                            component={SelectSearch}
                        />
                    </Grid>
                </Grid> */}
                <Grid item style={{ width: 120 }}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            size="small"
                            style={{ width: '100%', fontSize: 12 }}
                            name="desde"
                            label="Desde"
                            change={change}
                            openTo="month"
                            views={["year", "month"]}
                            component={DatePicker}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item style={{ width: 120 }}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            size="small"
                            style={{ width: '100%' }}
                            name="hasta"
                            label="Hasta"
                            change={change}
                            openTo="month"
                            views={["year", "month"]}
                            component={DatePicker}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

FormFiltrosDashProv = reduxForm({
    //   form: 'filtrosDashProv'
    initialValues: {
        desde: null,
        hasta: null
    }
})(FormFiltrosDashProv);

export default FormFiltrosDashProv;
