import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, addFetch, selectHOR, } from './hors.js'
import { leer, modificar, crear } from '../services/api'

const t = makeTypes('coloresProyecto')
const FETCH = t('fetch').async()

const fetchActions = asyncMac(FETCH)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))

export default reduceReducers(fetchReducer)

export const fetchColoresProyecto = id => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer(`/proyecto/${id}`)
        dispatch(fetchActions.success(data.configuracion_proyecto.colores))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const agregarColorProyecto = (id,value) => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await crear(`/agregarColor/${id}`, value)
        console.log(data)
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const quitarColorProyecto = (id,value) => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await crear(`/quitarColor/${id}`, value)
        console.log(data)
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

