import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListIcon from '@material-ui/icons/List';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';

const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: props => props.dotcolor,
      color: props => props.dotcolor,
      top: props => props.dottop,
      right: props => props.dotright
    }
  }))(Badge);

export default function BotonMenuLista(props) {
    const { listData, tooltip, tipo, permisoEvaluarCosto, centrosUsuario, handleVerDocumento, handleCotizacionModal } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ display: 'inline-block' }}>
            <Tooltip title={tooltip}>
                <div>
                    <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        variant="dot"
                        dotcolor="#f44336"
                        dotright={0}
                        dottop={8}
                        invisible={tipo != 'cotizacion' || !listData.some(x => x.evalCostoPendiente)}>
                        <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            style={{ padding: '0px', width: '38px', height: '38px' }}>
                            <div style={{ fontSize: 14, fontWeight: 'bold' }}>
                                {tipo === 'cotizacion' ? 'COT' : tipo === 'ordenCompra' ? 'OC' : 'FAC'}
                            </div>
                        </IconButton>
                    </StyledBadge>
                </div>
            </Tooltip>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {listData.map(x => (
                    <MenuItem
                        key={tipo === 'factura' ? x.id : x.codigo}
                        onClick={() => {
                            handleClose();
                            if (tipo === 'cotizacion') {
                                handleCotizacionModal(x.id, x.solicitud_recurso_id);
                            } else if (tipo === 'ordenCompra') {
                                handleVerDocumento(x.archivo_id, x.codigo);
                            } else {
                                handleVerDocumento(x.archivo_id, x.numero);
                            }
                        }}>
                        <StyledBadge
                            key={tipo === 'factura' ? x.id : x.codigo}
                            // overlap="circle"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            variant="dot"
                            dotcolor="#f44336"
                            dotright={-5}
                            dottop={0}
                            invisible={tipo != 'cotizacion' || !x.evalCostoPendiente}>
                            {tipo === 'factura' ? x.numero : x.codigo}
                        </StyledBadge>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
