import React from 'react';
import * as Sentry from "@sentry/react";
import './App.css';
import { Route } from 'react-router-dom';
import Navbar from './containers/Navbar';
import Drawer from './containers/Drawer';
import Solicitudes from './containers/Solicitudes';
import Dashboard from './containers/Dashboard';
import MarcasVehiculos from './containers/MarcasVehiculos';
import Proveedores from './containers/Proveedores';
import GestionProveedores from './containers/GestionProveedores';
import Proyectos from './containers/Proyectos';
import Colores from './containers/Colores';
import Clases from './containers/Clases';
import SubClases from './containers/SubClases';
import Procesadores from './containers/Procesadores';
import DiscosDuros from './containers/DiscosDuros';
import MemoriaRam from './containers/MemoriaRam';
import Traccion from './containers/Traccion';
import TipoMotor from './containers/TipoMotor';
import Empresas from './containers/Empresas';
import AccionDirecta from './containers/AccionDirecta';
import NuevaSolicitud from './containers/NuevaSolicitud';
import DetalleSolicitudes from './containers/DetalleSolicitudes';
import Cilindrada from './containers/Cilindrada';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { esES } from '@material-ui/core/locale';
import { auth } from './services/firebase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from './components/Spinner';
import Footer from './components/Footer';
import * as solicitudesDuck from './ducks/Solicitudes';
import * as solicitudesUsuarioDuck from './ducks/SolicitudesUsuario';
import * as solicitudesAprobarDuck from './ducks/SolicitudesAprobar';
import * as gerenciaDuck from './ducks/Gerencias';
import * as proyectoDuck from './ducks/Proyectos';
import * as usuarioDuck from './ducks/Usuario';
import * as permisosDuck from './ducks/PermisosUsuario';
import * as estadoDucks from './ducks/Estados';
import * as clasesDuck from './ducks/Clases';
import * as subClaseDuck from './ducks/Subclases';
import * as usuariosDuck from './ducks/Usuarios';
import * as centrosDuck from './ducks/Centros';
import * as empresasDuck from './ducks/Empresas';
import * as proveedoresDuck from './ducks/Proveedores';
import * as mensajesDuck from './ducks/Mensajes';
import * as perfilesDuck from './ducks/Perfiles';
import * as proyectosGeneralDuck from './ducks/ProyectosGeneral';
import * as gerenciasGeneralDuck from './ducks/GerenciasGeneral';
import * as centroLogisticoDuck from './ducks/CentroLogistico';
import * as marcasVehiculosDuck from './ducks/MarcasVehiculos';
import * as rangosPreciosDuck from './ducks/RangosPrecios';
import * as aprobadoresCotizacionDuck from './ducks/AprobadoresCotizacion';
import * as tiposRercursosDuck from './ducks/TiposRecursos';
import * as equipamientosDuck from './ducks/Equipamientos';
import * as coloresDuck from './ducks/Colores';
import * as ramDuck from './ducks/MemoriaRam';
import * as discosDurosDuck from './ducks/DiscosDuros';
import * as procesadoresDuck from './ducks/Procesadores';
import * as tipoMotorDuck from './ducks/TipoMotor';
import * as cilindradaDuck from './ducks/Cilindrada';
import * as traccionDuck from './ducks/Traccion';
import * as comunasDuck from './ducks/Comunas';
import { cerrarSesion } from './services/auth.js';
import Modal from './components/Modal';
import Ayuda from './containers/Ayuda';
import LoadingPage from './components/LoadingPage';
import { leer } from './services/api';

const theme = createMuiTheme(
    {
        spacing: 2,
        palette: {
            primary: {
                light: '#a64c46',
                main: '#731f1f',
                dark: '#430000',
                contrastText: '#fff'
            },
            secondary: {
                light: '#4f5b62',
                main: '#263238',
                dark: '#000a12',
                contrastText: '#fff'
            },
            blanco: {
                light: '#fff',
                main: '#fff',
                dark: '#fff',
                contrastText: '#fff'
            }
        },
        typography: {
            fontSize: 12
        }
    },
    esES
);

const menu = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '98vh'
};

class App extends React.Component {
    state = {
        loading: true,
        modal: false,
        confirmacion: false,
        usuario: {},
        accion: null,
        gerenciasUsuario: null
    };

    getQueryVariable(variable) {
        const query = window.location.search.substring(1);
        //console.log(query)//"app=article&act=news_content&aid=160990"
        const vars = query.split('&');
        //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return false;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    login = () => {
        var csrfToken = '';
        if (this.getQueryVariable('csrfToken')) {
            csrfToken = this.getQueryVariable('csrfToken');
        } else if (localStorage.getItem('csrfToken')) {
            csrfToken = localStorage.getItem('csrfToken');
        }
        localStorage.setItem('csrfToken', csrfToken);
        fetch(`https://api-usuarios.cydocs.cl/login/verifySessionCookie?_csrf=${csrfToken}`, {
            method: 'post',
            credentials: 'include'
        })
            .then(response => response.json())
            .then(jsonResponse => {
                auth.signInWithCustomToken(jsonResponse.token);
            })
            .catch(error => {
                console.log(error);
                window.location.href = `https://account.cydocs.cl/?origin=${window.location.href.split('?')[0]}`;
            });
    };

    handleClose = () => {
        this.setState({ ...this.state, confirmacion: false, modal: false, accion: false });
    };

    handleConfirmacion = e => {
        const { assignMensaje } = this.props;
        assignMensaje('¿Está seguro que desea cerrar sesión?');
        this.setState({ ...this.state, modal: true, confirmacion: true, accion: this.handleCerrarSesion() });
    };

    handlePerfil = e => {
        const { assignMensaje, perfiles } = this.props;
        assignMensaje(`Su perfil actual es: ${perfiles.data.map(x => x.descripcion)}`);
        this.setState({ ...this.state, modal: true, confirmacion: false, accion: false });
    };

    handleCerrarSesion = () => async e => {
        try {
            await cerrarSesion();
        } catch (error) {
            console.log(error);
        }
    };

    componentDidMount() {
        const {
            fetchSolicitudes,
            fetchSolicitudesUsuario,
            fetchSolicitudesAprobar,
            fetchGerencias,
            agregarUsuario,
            fetchRam,
            fetchCilindradas,
            fetchTipoMotor,
            fetchTracciones,
            fetchProcesadores,
            fetchDiscosDuros,
            fetchTiposRercursos,
            fetchColores,
            fetchEquipamientos,
            fetchCentroLogistico,
            fetchMarcasVehiculos,
            fetchRangosPrecios,
            fetchAprobadoresCotizacion,
            fetchGerenciasGeneral,
            fetchPerfiles,
            fetchEstados,
            fetchClases,
            fetchSubclases,
            fetchUsuarios,
            fetchEmpresas,
            fetchProveedores,
            fetchCentros,
            fetchPermisos,
            fetchProyectos,
            fetchProyectosGeneral,
            fetchUsuario,
            fetchComunas,
            saveGerenciasUsuario
        } = this.props;
        this._isMounted = true;
        const usuario = async () => {
            auth.onAuthStateChanged(async user => {
                try {
                    if (user) {
                        var responsePersona = await leer(`/usuario/get-persona/${user.uid}`);
                        // var customID = '611bebffadd7fa00257b0b59';
                        // var customNombre = 'nperezv';
                        var customID = responsePersona._id;
                        var customNombre = `${responsePersona.nombre} ${responsePersona.apellidos}`;
                        Sentry.setUser({ id: customID, username: customNombre, email: user.email });
                        // fetchColores();
                        // fetchProcesadores();
                        // fetchRam();
                        // fetchDiscosDuros();
                        // fetchCilindradas();
                        // fetchTracciones();
                        // fetchTipoMotor();
                        // fetchEstados();
                        // fetchClases();
                        // fetchSubclases();
                        // fetchUsuarios();
                        // fetchEmpresas();
                        // fetchProveedores();
                        // fetchMarcasVehiculos();
                        // fetchEquipamientos();
                        // fetchComunas();
                        // fetchSolicitudes(customID, 'abiertas');
                        // fetchSolicitudesUsuario(customID, '', '', '', '', '');
                        await Promise.all([
                            // fetchSolicitudesAprobar(customID, '', '', '', '', ''),
                            // fetchAprobadoresCotizacion(),
                            // fetchCentros(),
                            // fetchRangosPrecios(),
                            // fetchProyectosGeneral(),
                            // fetchGerenciasGeneral(),
                            // fetchTiposRercursos(),
                            // fetchCentroLogistico(customID),
                            fetchPermisos(customID),
                            fetchPerfiles(customID),
                            // fetchGerencias(customID),
                            // fetchProyectos(customID),
                            agregarUsuario({ ...user, nombres: customNombre, id: customID })
                        ]);
                        console.log("terminó fetch en app.js");
                        // saveGerenciasUsuario(this.props.gerenciasTodas, this.props.proyectos);
                        if (this._isMounted === true) {
                            this.setState({ ...this.state, usuario: user, loading: false });
                        }
                    } else {
                        this.login();
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        };
        usuario();
    }

    render() {
        const { usuarioAutenticado, history, mensaje, perfiles, permisos } = this.props;
        const { usuario } = this.state;
        return (
            <ThemeProvider theme={theme}>
                {this.state.loading ? (
                    <LoadingPage />
                ) : (
                    <div>
                        <Route path="/" component={Navbar} />
                        <Route path="/" component={Drawer} />
                        <div style={{ padding: '5rem 1rem' }}>
                            <Route
                                exact={true}
                                path="/"
                                render={() => <Solicitudes usuario={usuario} history={history} />}
                            />
                            <Route
                                exact={true}
                                path="/nuevasolicitud"
                                render={() => (
                                    <NuevaSolicitud history={history} usuarioAutenticado={usuarioAutenticado.data} />
                                )}
                            />
                            <Route exact={true} path="/dashboard" component={Dashboard} />
                            <Route exact={true} path="/detalle/:id" component={DetalleSolicitudes} />
                            {/* <Route exact={true} path="/configuracion/marcas-vehiculos" component={MarcasVehiculos} /> */}
                            <Route exact={true} path="/configuracion/proyectos" component={Proyectos} />
                            <Route exact={true} path="/configuracion/proveedores" component={GestionProveedores} />
                            {/* <Route exact={true} path="/configuracion/colores" component={Colores} /> */}
                            <Route exact={true} path="/configuracion/clases" component={Clases} />
                            <Route exact={true} path="/configuracion/subclases" component={SubClases} />
                            {/* <Route exact={true} path="/configuracion/procesadores" component={Procesadores} />
                            <Route exact={true} path="/configuracion/discosduros" component={DiscosDuros} />
                            <Route exact={true} path="/configuracion/ram" component={MemoriaRam} />
                            <Route exact={true} path="/configuracion/traccion" component={Traccion} />
                            <Route exact={true} path="/configuracion/tipo-motor" component={TipoMotor} />
                            <Route exact={true} path="/configuracion/cilindrada" component={Cilindrada} />
                            <Route exact={true} path="/ayuda" render={() => <Ayuda history={history} />} /> */}
                            <Route
                                exact={true}
                                path="/accion"
                                render={() => <AccionDirecta history={history} />}
                            />
                        </div>
                    </div>
                )}
                <Modal
                    open={this.state.modal}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}>
                    {mensaje.data}
                </Modal>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        usuarioAutenticado: state.Usuario,
        gerencias: state.Gerencias.data,
        gerenciasTodas: state.GerenciasGeneral.data,
        proyectos: state.Proyectos.data,
        mensaje: state.Mensajes,
        perfiles: state.Perfiles,
        permisos: state.PermisosUsuario
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...solicitudesDuck,
            ...solicitudesUsuarioDuck,
            ...solicitudesAprobarDuck,
            ...gerenciaDuck,
            ...proyectoDuck,
            ...usuarioDuck,
            ...permisosDuck,
            ...estadoDucks,
            ...clasesDuck,
            ...subClaseDuck,
            ...usuariosDuck,
            ...empresasDuck,
            ...centrosDuck,
            ...proveedoresDuck,
            ...mensajesDuck,
            ...perfilesDuck,
            ...proyectosGeneralDuck,
            ...gerenciasGeneralDuck,
            ...centroLogisticoDuck,
            ...rangosPreciosDuck,
            ...aprobadoresCotizacionDuck,
            ...marcasVehiculosDuck,
            ...tiposRercursosDuck,
            ...equipamientosDuck,
            ...coloresDuck,
            ...procesadoresDuck,
            ...discosDurosDuck,
            ...cilindradaDuck,
            ...tipoMotorDuck,
            ...traccionDuck,
            ...ramDuck,
            ...comunasDuck
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(App);
