import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, addFetch, selectHOR, removeErrorHOR } from './hors.js'
import { leer , crear, modificar} from '../services/api'

const t = makeTypes('empresas')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const SELECT = t('select').single()
const ADD = t('add').async()
const REMOVEERROR = t('removeError').single()

const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
const addActions = asyncMac(ADD)
export const removeError = mac(REMOVEERROR)
export const selectEmpresa = mac(SELECT,'payload')
 
const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const selectReducer= createReducer(initialState, selectHOR({ SELECT }))
const removeErrorReducer= createReducer(initialState, removeErrorHOR({ REMOVEERROR }))

export default reduceReducers(fetchReducer, editReducer, selectReducer, addReducer, removeErrorReducer)

export const fetchEmpresas = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer('/empresa-cyd')
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const editarProcesadores = (id, values) => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await modificar('/empresa-cyd', id, values)
        dispatch(editActions.success(data))
    } catch (error) {
        console.log(error)
        dispatch(editActions.error(error))
    }
}

export const agregarProcesador = (values) => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await crear('/empresa-cyd', values)
        dispatch(addActions.success(data))
    } catch (error) {
        dispatch(addActions.error(error))
    }
}