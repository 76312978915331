import { makeTypes, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch } from './hors.js'
import { leer, crearArchivo } from '../services/api'

const t = makeTypes('Facturas')
const FETCH = t('fetch').async()
 
const fetchActions = asyncMac(FETCH)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))

export default reduceReducers(fetchReducer)

export const subirFactura = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await crearArchivo('/guia-despacho', values)
        dispatch(fetchActions.success(data))
        return data
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const fetchFacturas = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data = await leer(`/factura`)
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}