  import React from 'react'
  import { Checkbox } from '@material-ui/core';
  import FormControlLabel from '@material-ui/core/FormControlLabel';

  const renderCheckbox = ({ input, label, disabled, style }) => {
    return(
      <FormControlLabel
      style={style ? style : {}} 
      control = {
        <Checkbox
          color="primary"
          disabled={disabled ? true : false}
          checked={input.value ? true : false}
          onChange={input.onChange}
        />}
      label={label}
      />
  )}

  export default renderCheckbox

  
