import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Grid, IconButton, Container, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SelectField from './SelectField';
import SelectSearch from './SelectSearch';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const validate = (values = {}) => {
  const errors = {};
  const requiredFields = ['color'];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true;
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

let FormAgregarColores = props => {
  const { handleSubmit, pristine, reset, submitting, change, colores } = props;
  return (
    <Container>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12} lg={9}>
            <Grid container justify="center" alignItems="center">
              <Field
                required
                style={{ width: '80%', padding: '10px ' }}
                type="text"
                change={change}
                name="color"
                label="Colores"
                options={colores}
                component={SelectSearch}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={1}>
            <Grid container justify="center" alignItems="center">
              <IconButton type="submit">
                <i className="material-icons">add</i>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

FormAgregarColores = reduxForm({
  form: 'agregarColores'
})(FormAgregarColores);

export default connect(state => ({
  initialValues: {},
  enableReinitialize: true,
  validate
}))(FormAgregarColores);
