import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Spinner from '../components/Spinner';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

Modal.defaultProps = {
    showTitle: true,
    showActions: true
};

export default function Modal(props) {
    const {
        open,
        loading,
        handleClose,
        confirmacion,
        accion,
        xl,
        title,
        fullWidth,
        maxWidth,
        disableBackClick,
        disableEscapeKey,
        botonSi,
        botonNo,
        showTitle,
        showActions,
        style
    } = props;

    return (
        <div>
            <Dialog
                disableBackdropClick={disableBackClick ? disableBackClick : false}
                disableEscapeKeyDown={disableEscapeKey ? disableEscapeKey : false}
                open={open}
                maxWidth={xl ? 'xl' : maxWidth ? maxWidth : 'sm'}
                fullWidth={fullWidth ? true : false}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{ style: style }}>
                {showTitle && (
                    <DialogTitle id="alert-dialog-slide-title">
                        {title ? title : accion ? 'Confirmación' : 'Notificación'}
                    </DialogTitle>
                )}
                <DialogContent>{loading ? <Spinner /> : props.children}</DialogContent>
                {showActions && (
                    <DialogActions>
                        <div>
                            <Button onClick={handleClose} color="secondary">
                                {botonNo ? botonNo : 'Cerrar'}
                            </Button>
                            {confirmacion && (
                                <Button onClick={accion} disabled={loading ? true : false} color="primary">
                                    {botonSi ? botonSi : 'Aceptar'}
                                </Button>
                            )}
                        </div>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
}
