import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, selectHOR, editHOR, addFetch, removeErrorHOR, editFetch } from './hors.js'
import { leer, crear, solicitud, modificar } from '../services/api'

const t = makeTypes('Solicitudes')
const FETCH = t('fetch').async()
const EDITAR = t('editar').async()
const EDIT = t('edit').single()
const ADD = t('add').async()
const REMOVEERROR = t('removeError').single()

const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDITAR)
const agregarActions = asyncMac(ADD)
export const removeErrorSolicitudes = mac(REMOVEERROR)
export const editSolicitudes = mac(EDIT, 'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editarReducer = createReducer(initialState, editFetch( EDITAR ))
const agregarReducer = createReducer(initialState, addFetch( ADD ))
const editReducer = createReducer(initialState, editHOR({ EDIT }))
const removeErrorReducer= createReducer(initialState, removeErrorHOR({ REMOVEERROR }))

export default reduceReducers(fetchReducer, editReducer, agregarReducer, removeErrorReducer, editarReducer)

export const fetchSolicitudes = (id, estado) => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer(`/solicitudesRecursosUsuario/${id}?estado=${estado}&fecha_inicio=&fecha_fin=&gerencia=&proyecto=`)
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const detallesSolicitud = (id) => async (dispatch, getState) => {
    // dispatch(fetchActions.start())
    try {
        const data = await leer(`/detallesSolicitudRecursos/${id}`);
        console.log(data.solicitud_recurso);
        return data.solicitud_recurso;
        // dispatch(fetchActions.success([data.solicitud_recurso]))
    } catch (error) {
        console.log(error);
        // dispatch(fetchActions.error(error))     
    }
}

export const aprobarSolicitud = (id, userId) => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await solicitud('/aprobarSolicitud', id, userId )
        console.log(data)
        dispatch(editActions.success(data))
        return data
    } catch (error) {
        console.log(error)
        dispatch(editActions.error(error))        
    }
}

export const rechazarSolicitud = value => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data = await crear('/rechazarSolicitudRecursos', value)
        dispatch(editSolicitudes(data))
        return data        
    } catch (error) {
        dispatch(fetchActions.error(error))        
    }
}

export const editarSolicitudes = (id, solicitud) => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data = await modificar('/solicitud-recurso', id, solicitud)
        dispatch(editSolicitudes(data))        
    } catch (error) {
        dispatch(fetchActions.error(error))        
    }
}

export const agregarSolicitudes = values => async (dispatch, getState) => {
    dispatch(agregarActions.start())
    try {
        let data = await crear('/solicitud-recurso', values)
        console.log(data)
        dispatch(agregarActions.success(data))        
    } catch (error) {
        dispatch(agregarActions.error(error))        
    }
}