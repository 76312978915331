import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

class ModRadioGroup extends React.Component {
    constructor(props) {
        super(props);
        const { change, claseEquipo } = props;
        // console.log("creando radiogroup!!");
        change('procesador', 1);
        change('ram', 4);
        if (claseEquipo == 'Computador Notebook') {
            change('hdd', 1);
        } else {
            change('hdd', 2);
        }
    }

    componentDidUpdate(prevProps) {
        const { input, change, claseEquipo } = this.props;
        if (prevProps.claseEquipo != claseEquipo) {
            // console.log("nueva clase: ", claseEquipo);
            if (claseEquipo == 'Computador Notebook') {
                change('hdd', 1);
            } else {
                change('hdd', 2);
                if (input.value == 'gerencia' || input.value == 'autocad') {
                    change(input.name, 'oficina');
                }
            }
        }
    }

    handleChange = (e, newValue) => {
        const { input, change, claseEquipo } = this.props;

        // this.setState({
        //     ...this.state,
        //     selectedOption: newValue,
        // });

        if (newValue) {
            change(input.name, newValue);
            switch (newValue) {
                case "oficina":
                    change("procesador", 1);
                    change("ram", 4);
                    if (claseEquipo == "Computador Notebook") {
                        change("hdd", 1);
                    } else {
                        change("hdd", 2);
                    }
                    break;
                case "gerencia":
                    change("procesador", 2);
                    change("ram", 4);
                    change("hdd", 1);
                    break;
                case "autocad":
                    change("procesador", 2);
                    change("ram", 4);
                    change("hdd", 1);
                    break;
                case "bim":
                    change("procesador", 2);
                    change("ram", 5);
                    change("hdd", 2);
                    break;
                default:
                    change("procesador", null);
                    change("ram", null);
                    change("hdd", null);
                    break;
            }
        } else {
            change(input.name, null);
        }
    };

    render() {
        const {
            input,
            label,
            options,
            meta: { touched, error },
            change,
            claseEquipo,
            ...custom
        } = this.props;
        // console.log(" el input:", input);
        return (
            <FormControl component="fieldset" style={{ width: '90%' }}>
                <FormLabel component="legend">{label}</FormLabel>
                <RadioGroup
                    row
                    aria-label="propositoEquipo"
                    name="propositoEquipo"
                    value={input.value}
                    onChange={this.handleChange}>
                    <FormControlLabel
                        value="oficina"
                        control={<Radio color="primary" />}
                        label="Administrativo"
                        labelPlacement="top"
                    />
                    {claseEquipo == 'Computador Notebook' && (
                        <FormControlLabel
                            value="gerencia"
                            control={<Radio color="primary" />}
                            label="Ejecutivo"
                            labelPlacement="top"
                        />
                    )}
                    {claseEquipo == 'Computador Notebook' && (
                        <FormControlLabel
                            value="autocad"
                            control={<Radio color="primary" />}
                            label="Especialista AutoCAD"
                            labelPlacement="top"
                        />
                    )}
                    <FormControlLabel
                        value="bim"
                        control={<Radio color="primary" />}
                        label="Especialista BIM"
                        labelPlacement="top"
                    />
                    <FormControlLabel
                        value="personalizado"
                        control={<Radio color="primary" />}
                        label="Personalizado"
                        labelPlacement="top"
                    />
                </RadioGroup>
            </FormControl>
        );
    }
}

export default ModRadioGroup;
