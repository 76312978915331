import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Container, Box, Typography, Button, IconButton, Card, CardContent } from '@material-ui/core';
import { saveAs } from 'file-saver';
import TablaDetalleItems from '../components/TablaDetalleItems';
import Modal from '../components/Modal';
import Spinner from '../components/Spinner';
import FormRechazoSolicitud from '../components/FormRechazoSolicitud';
import FormSolicitudItems from '../components/FormSolicitudItems';
import { downloadUrlAsPromise } from '../services/utils';
import { crear, leer } from '../services/api'
import * as solicitudesUsuarioDuck from '../ducks/SolicitudesUsuario';
import * as solicitudesAprobarDuck from '../ducks/SolicitudesAprobar';
import * as solicitudesDuck from '../ducks/Solicitudes';
import * as idsolicitudDuck from '../ducks/Idsolicitud';
import * as iditemDuck from '../ducks/Iditem';
import * as itemsDuck from '../ducks/Items';
import * as aprobador1Duck from '../ducks/Aprobador1';
import * as aprobador2Duck from '../ducks/Aprobador2';
import * as vehiculoDuck from '../ducks/Vehiculos';
import * as mensajesDuck from '../ducks/Mensajes';
import * as modoEdicionDuck from '../ducks/ModoEdicion';
import * as proyectosGeneralDuck from '../ducks/ProyectosGeneral';
import * as gerenciasGeneralDuck from '../ducks/GerenciasGeneral';
import * as comprobanteRecepcionDuck from '../ducks/ComprobanteRecepcion';
import * as trasladoItemRecursoDuck from '../ducks/TrasladoItemRecurso';
import * as entregaItemRecurso from '../ducks/EntregaItemRecurso';
import moment from 'moment';
import 'moment/locale/es';

class DetalleSolicitud extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        let anioMax = moment().year();
        let anios = [];
        for (let i = anioMax; i > (anioMax - 10); i--) anios.push(i.toString());
        this.state = {
            open: false,
            openModalRechazo: false,
            openModalSeparar: false,
            openModalEntregar: false,
            openCrearCotizacion: false,
            openVerCotizacion: false,
            openItemTraslado: false,
            openItemRecibido: false,
            openListoEntrega: false,
            confirmacion: false,
            accion: null,
            loading: false,
            mostrarFormulario: true,
            mostrarBusqueda: true,
            mostrarDetalle: false,
            loadingDetalle: true,
            comprobante: '',
            cantidadItemsEntregados: 0,
            textoItemsEntregados: '',
            maxItemsEntrega: 0,
            cantidadItemsRecibidos: 0,
            textoItemsRecibidos: '',
            maxItemsRecibidos: 0,
            textoItemsEnTraslado: '',
            maxItemsEnTraslado: 0,
            loadingSolicitud: props.idPanel ? true : false,
            itemEditado: null,
            nuevoItem: false,
            openModalEditarItem: false,
            guardandoItem: false,
            anios: anios,
            items: props.idPanel ? [] : props.solicitudActual.items,
            aprobador1: props.idPanel ? null : props.aprobador1,
            aprobador2: props.idPanel ? null : props.aprobador2,
            procesadores: props.idPanel ? [] : props.procesadores,
            discoDuro: props.idPanel ? [] : props.discoDuro,
            memoriaRam: props.idPanel ? [] : props.memoriaRam,

        };
        if (this.state.loadingSolicitud) props.handleLoadingSolicitud(true);
        // console.log("idSolicitud en constructor: ", props.idSolicitud);
    }
    handleClose = () => {
        const { handleLoadingSolicitud } = this.props;
        if (this._isMounted) {
            this.setState({
                ...this.state,
                accion: false,
                confirmacion: false,
                open: false,
                openModalRechazo: false,
                openModalSeparar: false,
                openModalEntregar: false,
                openListoEntrega: false,
                botonSi: false,
                botonNo: false
            });
        }
        console.log("estoy en handle close");
        if (handleLoadingSolicitud) handleLoadingSolicitud(false);
    };

    handleAprobarModal = e => {
        const { assignMensaje, handleLoadingSolicitud } = this.props;
        if (handleLoadingSolicitud) handleLoadingSolicitud(true);
        assignMensaje('¿Desea aprobar la solicitud?');
        if (this._isMounted) this.setState({ ...this.state, confirmacion: true, open: true, accion: this.handleAprobarSolicitud });
    };

    //funcion para aprobar la solicitud
    handleAprobarSolicitud = async () => {
        const {
            aprobarSolicitud,
            assignMensaje,
            idSolicitud,
            usuarioAutenticado,
            recargarDatos,
            refreshTable,
        } = this.props;
        const solicitudAprobada = await aprobarSolicitud(idSolicitud, usuarioAutenticado.data.id);
        if (solicitudAprobada) {
            const response = await leer(`/detallesSolicitudRecursos/${idSolicitud}?usuario=${usuarioAutenticado.data.id}`);
            this.setState({ items: response.items });
            assignMensaje('Solicitud Aprobada');
            if (recargarDatos) recargarDatos(false);
            if (refreshTable) refreshTable();
        }
        if (this._isMounted) this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
    };

    handleRechazarSolicitudModal = () => {
        const { handleLoadingSolicitud } = this.props;
        if (handleLoadingSolicitud) handleLoadingSolicitud(true);
        if (this._isMounted) this.setState({ ...this.state, confirmacion: false, openModalRechazo: true, accion: false, botonNo: 'Cancelar' });
    };

    //Funcion para rechazar la solicitud de recursos
    handleRechazarSolicitud = async values => {
        const {
            rechazarSolicitud,
            usuarioAutenticado,
            idSolicitud,
            handleLoadingSolicitud,
            recargarDatos,
            refreshTable,
        } = this.props;
        const form = {
            ...values,
            fecha: moment().toISOString(),
            solicitud_recurso_id: idSolicitud,
            usuario_id: usuarioAutenticado.data.id,
            estado_id: 4
        };
        const solicitudRechazada = await rechazarSolicitud(form);
        if (solicitudRechazada) {
            const response = await leer(`/detallesSolicitudRecursos/${idSolicitud}?usuario=${usuarioAutenticado.data.id}`);
            this.setState({ items: response.items });
            if (recargarDatos) recargarDatos(false);
            if (refreshTable) refreshTable();
        }
        if(this._isMounted) this.setState({ ...this.state, confirmacion: false, openModalRechazo: false, accion: false, botonNo: false });
        if (handleLoadingSolicitud) handleLoadingSolicitud(false);
    };

    handleEdit = id => {
        const { assignIditem, toggleModoEdicion, handleLoadingSolicitud } = this.props;
        if (handleLoadingSolicitud) handleLoadingSolicitud(true);
        assignIditem(id);
        toggleModoEdicion(true);
    };

    handleEditarItemModal = item => {
        // const { assignIditem, toggleModoEdicion, handleLoadingSolicitud } = this.props;
        // if (handleLoadingSolicitud) handleLoadingSolicitud(true);
        // assignIditem(id);
        // toggleModoEdicion(true);
        console.log("editando ítem", item);
        this.setState({ itemEditado: item, openModalEditarItem: true, nuevoItem: false, guardandoItem: false })
    };

    handleNuevoItemModal = () => {
        console.log("nuevo ítem");
        this.setState({ 
            itemEditado: this.props.idPanel ? this.state.items[0] : this.props.solicitudActual.items[0],
            openModalEditarItem: true,
            nuevoItem: true,
            guardandoItem: false
        });
    };

    handleSubmitItem = async values => {
        const { addItems, editarItems, solicitudActual, assignMensaje, usuarioAutenticado, recargarDatos } = this.props;
        console.log("en submit item: ", values);
        this.setState({ guardandoItem: true });
        let estandar = null;
        if (values.clase == 5 || values.clase == 6 || values.clase == 7) {
            estandar =
              values.clase == 5
                ? 'Administrativo (Procesador Intel i5, 16GB Ram, 500GB SSD, pantalla 14", Windows 10 Pro, 3 años de garantía)'
                : 'Administrativo (Procesador Intel i5, 16GB Ram, 500GB SSD, pantalla 14", Windows 10 Pro, 3 años de garantía)';
            switch (values.propositoEquipo) {
                case 'gerencia':
                    estandar = 'Ejecutivo (Procesador Intel i7, 16GB Ram, 500GB SSD, pantalla 14", Windows 10 Pro, 3 años de garantía)';
                    break;
                case 'autocad':
                    estandar = 'Especialista AutoCAD (Procesador Intel i7, 16GB Ram, 500GB SSD, tarjeta de video 4GB, pantalla 15", Windows 10 Pro, 3 años de garantía)';
                    break;
                case 'bim':
                    estandar =
                        values.clase == 5
                            ? 'Especialista BIM (Procesador Intel i9, 32GB Ram, 1TB SSD, tarjeta de video 4GB o más, pantalla 15", Windows 10 Pro, 3 años de garantía)'
                            : 'Especialista BIM (Procesador Intel i9, 32GB Ram, 1TB SSD, tarjeta de video 4GB o más, pantalla 15", Windows 10 Pro, 3 años de garantía)';
                    break;
                case 'personalizado':
                    estandar =
                        'Personalizado (' +
                        this.state.procesadores.filter(y => y.id == values.procesador)[0].nombre +
                        ', Disco ' +
                        this.state.discoDuro.filter(y => y.id == values.hdd)[0].nombre +
                        ', Ram ' +
                        this.state.memoriaRam.filter(y => y.id == values.ram)[0].nombre +
                        ')';
                    break;
            }
        }
        if (this.state.nuevoItem) {
            console.log("agregar nuevo ítem");
            
            let dataItem = {
                cantidad: values.cantidad,
                anio: values.anio ? values.anio : null,
                descripcion: values.descripcion,
                fecha_requerida: values.fecha_requerida,
                estado: values.estado_item,
                tipo_recurso: values.tipo_recurso,
                clase: values.clase,
                sub_clase: values.sub_clase ? values.sub_clase : null,
                marca_vehiculo: values.marca_vehiculo ? values.marca_vehiculo : null,
                solicitud_recurso: solicitudActual.id,
                color: values.color ? values.color : null,
                activo: 1,
                ubicacion_logistica: values.centro,
                // comprar: 0,
                // factura_id: null,
                // entregado_por_id: null,
                // fecha_entrega: null,
                precio_referencial: null,
                cantidad_listos_entrega: 0,
                cilindrada_id: values.cilindrada ? values.cilindrada : null,
                hdd_id: values.hdd ? values.hdd : null,
                procesador_id: values.procesador ? values.procesador : null,
                ram_id: values.ram ? values.ram : null,
                tipo_motor_id: values.tipo_motor ? values.tipo_motor : null,
                traccion_id: values.traccion ? values.traccion : null,
                adjunto: null,
                tipo_moneda: "CLP",
                equipo_estandar: estandar,
                es_gasto_reemb: values.es_gasto_reemb === true
            }
            console.log(dataItem);
            let itemCreado = await addItems(dataItem);
            const response = await leer(`/detallesSolicitudRecursos/${solicitudActual.id}?usuario=${usuarioAutenticado.data.id}`);
            this.setState({ items: response.items });
            await crear('/log-estado', { 
                solicitud_recurso_id : solicitudActual.id,
                item_recurso_id: itemCreado.id,
                estado_id: values.estado_item,
                usuario_id: usuarioAutenticado.data.id,
                fecha: moment().toISOString(),
                descripcion:"Ítem creado con perfil edición logístico",
                duracion: 0
             });
            assignMensaje('Item agregado');
            if (recargarDatos) await recargarDatos(false);
            await this.verificarEstadoSolicitud();
            this.setState({ guardandoItem: false, confirmacion: false, open: true, accion: false });
        } else {
            console.log("editar ítem");
            
            let dataItem = {
                cantidad: values.cantidad,
                anio: values.anio ? values.anio : null,
                descripcion: values.descripcion,
                fecha_requerida: values.fecha_requerida,
                estado: values.estado_item,
                tipo_recurso: values.tipo_recurso,
                clase: values.clase,
                sub_clase: values.sub_clase ? values.sub_clase : null,
                marca_vehiculo: values.marca_vehiculo ? values.marca_vehiculo : null,
                color: values.color ? values.color : null,
                ubicacion_logistica: values.centro,
                cilindrada_id: values.cilindrada ? values.cilindrada : null,
                hdd_id: values.hdd ? values.hdd : null,
                procesador_id: values.procesador ? values.procesador : null,
                ram_id: values.ram ? values.ram : null,
                tipo_motor_id: values.tipo_motor ? values.tipo_motor : null,
                traccion_id: values.traccion ? values.traccion : null,
                equipo_estandar: estandar,
                es_gasto_reemb: values.es_gasto_reemb === true
            }

            await editarItems(this.state.itemEditado.id, dataItem);
            const response = await leer(`/detallesSolicitudRecursos/${solicitudActual.id}`);
            this.setState({ items: response.items });
            await crear('/log-estado', { 
                solicitud_recurso_id : solicitudActual.id,
                item_recurso_id: this.state.itemEditado.id,
                estado_id: values.estado_item,
                usuario_id: usuarioAutenticado.data.id,
                fecha: moment().toISOString(),
                descripcion:"Ítem editado con perfil edición logístico",
                duracion: 0
             });
            assignMensaje('Item modificado');
            if (recargarDatos) await recargarDatos(false);
            await this.verificarEstadoSolicitud();
            this.setState({
                confirmacion: false,
                open: true,
                accion: false,
                openModalEditarItem: false,
                itemEditado: null,
                nuevoItem: false
              });
        }
        if (recargarDatos) recargarDatos(false);
    };
    //funcion para salir del modo de edicion
    handleNoEdit = e => {
        const { assignIditem, toggleModoEdicion } = this.props;
        assignIditem('');
        toggleModoEdicion(false);
    };

    //Modal de rechazo de item
    handleRechazarItemModal = id => e => {
        const { assignMensaje, assignIditem, handleLoadingSolicitud } = this.props;
        if (handleLoadingSolicitud) handleLoadingSolicitud(true);
        assignIditem(id);
        assignMensaje('¿Desea rechazar el item?');
        if (this._isMounted) this.setState({
            ...this.state,
            confirmacion: true,
            open: true,
            accion: this.handleRechazarItem,
            botonSi: 'Sí',
            botonNo: 'No'
        });
    };

    //Rechazar Item
    handleRechazarItem = async () => {
        const { assignIditem, delItem, assignMensaje, rechazarItem, idItem, usuarioAutenticado } = this.props;
        await rechazarItem(idItem, usuarioAutenticado.data.id);
        assignIditem('');
        assignMensaje('Item Rechazado');
        delItem(idItem);
        if (this._isMounted) this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
    };

    //Edicion de item mediante formulario
    handleSubmitEdicion = async values => {
        const { idItem, assignIditem, editarItems, toggleModoEdicion } = this.props;
        let itemEditado = this.props.idPanel
            ? this.state.items.filter(x => x.id === idItem)[0]
            : this.props.solicitudActual.items.filter(x => x.id === idItem)[0];
        itemEditado = { ...itemEditado, ...values };
        await editarItems(idItem, itemEditado);
        toggleModoEdicion(false);
        assignIditem('');
    };

    async componentDidMount() {
        this._isMounted = true;
        const {
            usuarioAutenticado,
            // assignIdsolicitud,
            idPanel,
            handleLoadingSolicitud
        } = this.props;

        if (this._isMounted && this.state.loadingSolicitud) {
            // console.log("montando: " + idPanel);
            // assignIdsolicitud(idPanel);
            const resultados = await Promise.all([
                leer(`/detallesSolicitudRecursos/${idPanel}?usuario=${usuarioAutenticado.data.id}`),
                leer(`/aprobador1SolicitudRecursos/${idPanel}/${usuarioAutenticado.data.id}`),
                leer(`/aprobador2SolicitudRecursos/${idPanel}/${usuarioAutenticado.data.id}`),
                leer(`/procesador`),
                leer(`/hdd`),
                leer(`/ram`)
            ]);
            const updatesState = {
                items: resultados[0].items,
                aprobador1: resultados[1].esAprobador ? true : false,
                aprobador2: resultados[2].esAprobador ? true : false,
                procesadores: resultados[3],
                discoDuro: resultados[4],
                memoriaRam: resultados[5],
            }
            
            if (this._isMounted) updatesState.loadingSolicitud = false;
            this.setState(updatesState);
            if (handleLoadingSolicitud) handleLoadingSolicitud(false);
        }
    }

    componentWillUnmount() {
        // console.log("desmontando");
        this._isMounted = false;
        if(this.props.handleLoadingSolicitud) this.props.handleLoadingSolicitud(false);
        // console.log("cerrando detalle", this.props.solicitudActual[0].codigo);
        if(this.props.handleCierreDetalle) this.props.handleCierreDetalle();
    }

    handleVerDocumento = (idArchivo, nombre) => {
        var request =
            'https://www.googleapis.com/drive/v3/files/' +
            idArchivo +
            '?alt=media';

        var newTab = window.open(window.location.href + '/waiting', '_blank');
        downloadUrlAsPromise(request, nombre)
            .then(function(response) {
                console.log('tipo de archivo: ', response.blob.type);
                var tipoGrupo = response.blob.type ? response.blob.type.split('/')[0] : '';
                if (tipoGrupo == 'image' || response.blob.type == 'application/pdf') {
                    var blobUrl = URL.createObjectURL(response.blob);

                    newTab.location = blobUrl;
                } else {
                    newTab.close();
                    saveAs(response.blob, nombre);
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    verificarEstadoSolicitud = async () => {
        const { idSolicitud, editarSolicitudes } = this.props;
        const itemsSol = this.props.idPanel ? this.state.items : this.props.solicitudActual.items;
        if (itemsSol.some(x => x.estado === 3)) {
            await editarSolicitudes(idSolicitud, { estado: 3, fecha_termino: null });
        } else if (itemsSol.some(x => x.estado === 7)) {
            await editarSolicitudes(idSolicitud, { estado: 7, fecha_termino: null });
        } else if (itemsSol.some(x => x.estado === 8 || x.estado === 22)) {
            await editarSolicitudes(idSolicitud, { estado: 8,fecha_termino: null });
        } else if (itemsSol.some(x => x.estado === 9)) {
            await editarSolicitudes(idSolicitud, { estado: 9, fecha_termino: null });
        } else if (itemsSol.some(x => x.estado === 12)) {
            await editarSolicitudes(idSolicitud, { estado: 12, fecha_termino: null });
        } else if (itemsSol.some(x => x.estado === 6)) {
            await editarSolicitudes(idSolicitud, { estado: 6, fecha_termino: null });
        } else if (itemsSol.some(x => x.estado === 17)) {
            await editarSolicitudes(idSolicitud, { estado: 17, fecha_termino: null });
        } else if (itemsSol.some(x => x.estado === 18)) {
            await editarSolicitudes(idSolicitud, { estado: 18, fecha_termino: null });
        } else if (itemsSol.some(x => x.estado === 20)) {
            await editarSolicitudes(idSolicitud, { estado: 20, fecha_termino: moment().toISOString() });
        }
    };

    render() {
        const {
            solicitudActual,
            mensaje,
            modoEdicion,
            idItem,
            permisosUsuario,
            formSolicitudItems,
            idPanel,
            style
        } = this.props;
        // console.log("cargo el detalle con id: ", this.props.idSolicitud);
        // console.log("cargo el detalle con idPanel: ", this.props.idPanel);
        return this.state.loadingSolicitud ? (
            <Spinner />
        ) : (
            <Grid item xs={12}>
                <Box style={style}>
                    <Typography variant="h5" gutterBottom>
                        Solicitud de Recurso N° {solicitudActual ? solicitudActual.codigo : ''}
                    </Typography>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <Card style={{ height: '100%' }}>
                                <CardContent style={{ paddingBottom: '16px' }}>
                                    <Typography variant="body2" component="p">
                                        <strong>Solicitante: </strong>
                                        {solicitudActual.solicitante.nombre_completo}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <strong>Fecha y Hora de Solicitud: </strong>
                                        {moment(solicitudActual.fecha).format('DD-MM-YYYY HH:mm')}
                                    </Typography>
                                    <br />
                                    <Typography variant="body2">
                                        <strong>Requerida en: </strong>
                                        {solicitudActual.requerido_en}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card style={{ height: '100%' }}>
                                <CardContent style={{ paddingBottom: '16px' }}>
                                    <Typography variant="body2" component="p">
                                        <strong>Aprobación Proyecto: </strong>
                                        {solicitudActual.aprobador1
                                            ? solicitudActual.aprobador1.nombre_completo
                                            : solicitudActual.estado === 4
                                            ? 'Rechazada'
                                            : 'Pendiente'}
                                    </Typography>
                                    {solicitudActual.aprobador1 ? (
                                        <Typography variant="body2" component="p">
                                            <strong>Fecha y Hora Aprobación: </strong>
                                            {moment(solicitudActual.fecha_aprobacion1).format('DD-MM-YYYY HH:mm')}
                                        </Typography>
                                    ) : (
                                        ''
                                    )}
                                    <br />
                                    <Typography variant="body2" component="p">
                                        <strong>Aprobación Gerencia: </strong>
                                        {solicitudActual.aprobador2
                                            ? solicitudActual.aprobador2.nombre_completo
                                            : solicitudActual.estado === 4
                                            ? 'Rechazada'
                                            : 'Pendiente'}
                                    </Typography>
                                    {solicitudActual.aprobador2 ? (
                                        <Typography variant="body2" component="p">
                                            <strong>Fecha y Hora Aprobación: </strong>
                                            {moment(solicitudActual.fecha_aprobacion2).format('DD-MM-YYYY HH:mm')}
                                        </Typography>
                                    ) : (
                                        ''
                                    )}
                                    {solicitudActual.estado === 4 && solicitudActual.rechazo ? (
                                        <div>
                                            <br />
                                            <Typography variant="body2" component="p">
                                                <strong>Rechazado por: </strong>
                                                {solicitudActual.rechazo.usuario
                                                    ? solicitudActual.rechazo.usuario.nombre_completo
                                                    : ''}
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                <strong>Fecha y Hora Rechazo: </strong>
                                                {moment(solicitudActual.rechazo.fecha).format('DD-MM-YYYY HH:mm')}
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                <strong>Motivo: </strong>
                                                {solicitudActual.rechazo.motivo}
                                            </Typography>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    
                    {/* <Grid>
                        <Typography variant="h4" gutterBottom>
                            Items
                        </Typography>
                    </Grid> */}
                    <br/>
                    <Grid>
                        <TablaDetalleItems
                            solicitudActual={solicitudActual}
                            items={idPanel ? this.state.items : solicitudActual.items}
                            handleEdit={this.handleEdit}
                            handleNoEdit={this.handleNoEdit}
                            handleRechazarItemModal={this.handleRechazarItemModal}
                            aprobador1={this.state.aprobador1}
                            aprobador2={this.state.aprobador2}
                            editar={modoEdicion}
                            idItem={idItem}
                            onSubmit={this.handleSubmitEdicion}
                            handleVerDocumento={this.handleVerDocumento}
                            permisosUsuario={permisosUsuario}
                            handleEditarItemModal={this.handleEditarItemModal}
                        />
                    </Grid>
                    <Grid container>
                        {((solicitudActual.estado === 1 && (this.state.aprobador1 || this.state.aprobador2)) ||
                            (solicitudActual.estado === 2 && this.state.aprobador2)) && (
                            <Grid item xs>
                                <Button onClick={this.handleAprobarModal} variant="outlined" color="primary" style={{ marginTop: 10 }}>
                                    Aprobar
                                </Button>
                                <Button
                                    onClick={this.handleRechazarSolicitudModal}
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginLeft: 10, marginTop: 10 }}>
                                    Rechazar
                                </Button>
                            </Grid>
                        )}
                        {permisosUsuario.some(x => x.nombre === 'modificarSolicitudLogistica') && 
                            <Grid item xs container direction="row" justify="flex-end">
                                {[4, 20].indexOf(solicitudActual.estado) < 0 && (
                                    <Grid item>
                                        <Button
                                        onClick={this.handleRechazarSolicitudModal}
                                        variant="outlined"
                                        color="secondary"
                                        style={{ marginTop: 10, marginRight: 20 }}>
                                        Rechazar Solicitud
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button onClick={this.handleNuevoItemModal} variant="outlined" color="primary" style={{ marginTop: 10 }}>
                                        Agregar Ítem
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Box>

                <Modal
                    botonSi={this.state.botonSi ? this.state.botonSi : false}
                    botonNo={this.state.botonNo ? this.state.botonNo : false}
                    open={this.state.open}
                    handleClose={this.state.loadingSolicitud ? () => {} : this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={this.state.loadingSolicitud}>
                    {mensaje.data}
                </Modal>

                <Modal
                    botonNo={this.state.botonNo ? this.state.botonNo : false}
                    open={this.state.openModalRechazo}
                    handleClose={this.state.loadingSolicitud ? () => {} : this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={this.state.loadingSolicitud}>
                    <FormRechazoSolicitud onSubmit={this.handleRechazarSolicitud} />
                </Modal>
                <Modal
                    // botonNo={this.state.botonNo ? this.state.botonNo : false}
                    open={this.state.openModalEditarItem}
                    handleClose={() => {this.setState({ 
                        openModalEditarItem: false,
                        itemEditado: null,
                        nuevoItem: false,
                        // guardandoItem: false
                     })}}
                    maxWidth='md'
                    fullWidth={true}
                    // confirmacion={this.state.confirmacion}
                    // accion={this.state.accion}
                    loading={this.state.guardandoItem}
                    title={this.state.nuevoItem ? "Nuevo Ítem" : "Modificar Ítem"}
                    >
                    {/* {this.state.guardandoItem ? (
                        <Spinner />
                    ) : ( */}
                        <FormSolicitudItems
                            onSubmit={this.handleSubmitItem}
                            // items={items}
                            formSolicitudItems={formSolicitudItems}
                            anios={this.state.anios}
                            itemEditado={this.state.itemEditado}
                            nuevoItem={this.state.nuevoItem}
                            // adjuntoName={this.state.adjuntoName}
                            // handleOnChangeFileAdjunto={this.handleOnChangeFileAdjunto}
                        />
                </Modal>
            </Grid>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // console.log("props propias: ", ownProps);
    return {
        permisosUsuario: state.PermisosUsuario.data,
        formSolicitudItems: state.form.solicitudItems?.values ? state.form.solicitudItems.values : {},
        usuarioAutenticado: state.Usuario,
        idItem: state.Iditem.data,
        mensaje: state.Mensajes,
        form: state.form,
        modoEdicion: state.ModoEdicion.data,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...solicitudesUsuarioDuck,
            ...solicitudesAprobarDuck,
            ...solicitudesDuck,
            ...idsolicitudDuck,
            ...iditemDuck,
            ...itemsDuck,
            ...aprobador1Duck,
            ...aprobador2Duck,
            ...vehiculoDuck,
            ...mensajesDuck,
            ...modoEdicionDuck,
            ...proyectosGeneralDuck,
            ...gerenciasGeneralDuck,
            ...comprobanteRecepcionDuck,
            ...entregaItemRecurso,
            ...trasladoItemRecursoDuck
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DetalleSolicitud);
