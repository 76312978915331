import { makeTypes, mac, createReducer, reduceReducers } from 'ducks-maker'
import { addHOR } from './hors.js'

const t = makeTypes('vehiculos')
const ADD_VEHICULO = t('add')
 
export const addVehiculo = id => mac(ADD_VEHICULO, 'payload')({

})

const initialState = {
    data: [],
}

const addReducer =  createReducer(initialState, addHOR({ ADD: ADD_VEHICULO }))

export default reduceReducers(addReducer)