import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker';
import { createFetch, editFetch, addFetch, selectHOR } from './hors.js';
import { leer, crear } from '../services/api';
import { openModal } from './Modales';

const t = makeTypes('aprobadoresProyecto');
const FETCH = t('fetch').async();

const fetchActions = asyncMac(FETCH);

const initialState = {
  data: [],
  fetched: false,
  fetching: false
};

const fetchReducer = createReducer(initialState, createFetch(FETCH));

export default reduceReducers(fetchReducer);

export const fetchAprobadoresProyecto = id => async (dispatch, getState) => {
  dispatch(fetchActions.start());
  try {
    const data = await leer(`/proyecto/${id}`);
    dispatch(fetchActions.success(data.configuracion_proyecto.aprobador1));
  } catch (error) {
    dispatch(fetchActions.error(error));
  }
};

export const quitarAprobadorProyecto = (id, value) => async (dispatch, getState) => {
  dispatch(fetchActions.start());
  try {
    const data = await crear(`/quitarAprobador/${id}`, value);
    dispatch(fetchActions.success(data.aprobador1));
  } catch (error) {
    dispatch(fetchActions.error(error));
  }
};

export const agregarAprobadorProyecto = (id, value) => async (dispatch, getState) => {
  dispatch(fetchActions.start());
  try {
    const data = await crear(`/agregarAprobador/${id}`, value);
    dispatch(fetchActions.success(data.aprobador1));
  } catch (error) {
    dispatch(fetchActions.error(error));
  }
};
