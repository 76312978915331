import { makeTypes,mac, createReducer, reduceReducers } from 'ducks-maker'
import { toggleHOR } from './hors.js'

const t = makeTypes('drawer-comentarios')
const TOGGLE = t('toggle').single()

export const toggleDrawerComentarios = mac(TOGGLE,'payload')
 
const initialState = {
    data: false,
    fetched: false,
    fetching: false,
}

const toggleReducer = createReducer(initialState, toggleHOR( { TOGGLE } ))

export default reduceReducers(toggleReducer)