import * as firebase from 'firebase'

const config = {
    apiKey: "AIzaSyDiRfYh144WXcsui3Ok_DfF-rnm84OgbTk",
    authDomain: "cydingenieria-proyectos.firebaseapp.com",
    databaseURL: "https://cydingenieria-proyectos.firebaseio.com",
    projectId: "cydingenieria-proyectos",
    storageBucket: "cydingenieria-proyectos.appspot.com",
    messagingSenderId: "908645375548",
    appId: "1:908645375548:web:181d8a499e800a95"
  };
  
  firebase.initializeApp(config);

  export const auth = firebase.auth()
  export const db = firebase.firestore()
  export const storage = firebase.storage()