import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, addFetch, selectHOR, } from './hors.js'
import { leer, modificar, crear } from '../services/api'

const t = makeTypes('equipamientos')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const SELECT = t('select').single()
const ADD = t('add').async()
 
const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
const addActions = asyncMac(ADD)
export const selectMarcaVehiculo = mac(SELECT,'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const selectReducer= createReducer(initialState, selectHOR({ SELECT }))

export default reduceReducers(fetchReducer, editReducer, selectReducer, addReducer)

export const fetchEquipamientos = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer('/equipamiento')
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const editarMarcasVehiculos = (id, values) => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await modificar('/marca-vehiculo', id, values)
        dispatch(editActions.success(data))
    } catch (error) {
        dispatch(editActions.error(error))
    }
}

export const agregarMarcasVehiculos = (values) => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await crear('/equipamiento', values)
        dispatch(addActions.success(data))
    } catch (error) {
        dispatch(addActions.error(error))
    }
}