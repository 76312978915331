import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from './TextField';
import { Grid, Button, Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles({
    areaPaper: {
        padding: '10px 0',
        marginBottom: '20px',
        borderColor: 'rgba(0, 0, 0, 0.3)'
    }
});

const validate = (values = {}) => {
    const errors = {};
    const requiredFields = [
        'patente'
    ];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });

    return errors;
};

let FormVehiculo = (props) => {
    const {
        handleSubmit,
        change,
        touch,
        pristine,
        reset,
        submitting,
        editar,
    } = props;
    const classes = useStyles();

    return (
        <Container>
            <form noValidate onSubmit={handleSubmit}>
                <Paper variant="outlined" className={classes.areaPaper}>
                    <Grid container>
                        <Grid item xs={12} lg={4}>
                            
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '80%' }}
                                    type="text"
                                    name="patente"
                                    label="Patente"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container style={{ marginTop: '15px' }} justify="center" alignItems="center">
                    <Button type="submit" variant="outlined" color="primary">
                        {editar ? 'Editar' : 'Agregar'}
                    </Button>
                </Grid>
            </form>
        </Container>
    )
}

FormVehiculo = reduxForm({
    form: 'vehiculoProveedor',
})(FormVehiculo);

export default connect((state, ownProps) => ({
    initialValues: {
        patente: ownProps.editar ? ownProps.dataVehiculo.patente : null
    },
    enableReinitialize: true,
    validate,
}))(FormVehiculo);