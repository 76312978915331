import React, { Component } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

export default class FieldKeyboardDatePicker extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      selectedDate: props.input.value ? moment(props.input.value, 'DD/MM/YYYY') : moment()
    };
  }

  onChange(e) {
    const { input, change } = this.props;
    this.setState({ ...this.state, selectedDate: e });
    if (change) {
      if (e) {
        change(input.name, e.format('DD/MM/YYYY'));
      } else {
        change(input.name, null);
      }
    }
  }

  render() {
    const {
      input: { value },
      meta: { touched, error },
      ...custom
    } = this.props;
    const { input, label } = this.props;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          {...input}
          error={touched && (error ? true : false)}
          style={custom.style ? custom.style : {}}
          label={label}
          size={custom.size ? custom.size : 'medium'}
          // value={this.state.selectedDate}
          value={value ? moment(value, 'DD/MM/YYYY') : null}
          format="DD/MM/YYYY"
          onChange={this.onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      </MuiPickersUtilsProvider>
    );
  }
}
