import { makeTypes, mac, createReducer, reduceReducers } from 'ducks-maker'
import { assignHOR } from './hors.js'

const t = makeTypes('mensajes')
const ASSIGN_MENSAJE = t('assign').single()

export const assignMensaje = mac(ASSIGN_MENSAJE, 'payload')

const initialState = {
    data: "",
}

const assignReducer = createReducer(initialState, assignHOR({ ASSIGN: ASSIGN_MENSAJE }))

export default reduceReducers(assignReducer)