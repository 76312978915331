const CHANGE_TAB = 'CHANGE_TAB';

const initialState = {
    selectedTab: 0,
    columnasMisPendientes: [],
    columnasEnCurso: [],
    columnasMisSolicitudes: [],
    columnasVerTodas: [],
    searchTextMisPendientes: '',
    searchTextEnCurso: '',
    searchTextMisSolicitudes: '',
    searchTextVerTodas: ''
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_TAB:
            return { ...state, selectedTab: action.payload };
        case 'SET_COLUMN_CONFIG_MIS_PENDIENTES':
            return { ...state, columnasMisPendientes: [...action.payload] };
        case 'SET_COLUMN_CONFIG_EN_CURSO':
            return { ...state, columnasEnCurso: [...action.payload] };
        case 'SET_COLUMN_CONFIG_MIS_SOLICITUDES':
            return { ...state, columnasMisSolicitudes: [...action.payload] };
        case 'SET_COLUMN_CONFIG_VER_TODAS':
            return { ...state, columnasVerTodas: [...action.payload] };
        case 'SET_SEARCH_TEXT':
            return {
                ...state,
                searchTextMisPendientes: action.payload[0],
                searchTextEnCurso: action.payload[1],
                searchTextMisSolicitudes: action.payload[2] ,
                searchTextVerTodas: action.payload[3]
            };
    }
    return state;
}

export function changeTab(payload) {
    return { type: 'CHANGE_TAB', payload };
}

export function setColumnConfig(payload, tipo) {
    return { type: 'SET_COLUMN_CONFIG_' + tipo, payload };
}

export function setSearchText(payload) {
    return { type: 'SET_SEARCH_TEXT', payload };
}
