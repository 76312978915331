import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Box, Typography } from '@material-ui/core';
import Spinner from '../components/Spinner';
import Lista from '../components/Lista';
import FormAprobadores from '../components/FormAprobadores';
import FormAgregarColores from '../components/FormAgregarColores';
import * as aprobadoresProyectoDuck from '../ducks/AprobadoresProyecto';
import * as aprobadoresGerenciaDuck from '../ducks/AprobadoresGerencia';
import * as coloresProyectoDuck from '../ducks/ColoresProyecto';

class ConfiguracionProyecto extends React.Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    const { fetchAprobadoresProyecto, fetchAprobadoresGerencia, fetchColoresProyecto, id } = this.props;
    await Promise.all[(fetchAprobadoresProyecto(id), fetchAprobadoresGerencia(id), fetchColoresProyecto(id))];
    this.setState({ loading: false });
  }

  handleAgregarAprobadorGerencia = async values => {
    const { agregarAprobadorGerencia, id } = this.props;
    const aprobador = { aprobador1: [], aprobador2: [{ id: values.aprobador }] };
    await agregarAprobadorGerencia(id, aprobador);
  };

  handleBorrarAprobadorGerencia = idUsuario => async e => {
    const { quitarAprobadorGerencia, id } = this.props;
    const aprobador = { aprobador1: [], aprobador2: [{ id: idUsuario }] };
    await quitarAprobadorGerencia(id, aprobador);
  };

  handleAgregarAprobadorProyecto = async values => {
    const { agregarAprobadorProyecto, id } = this.props;
    const aprobador = { aprobador1: [{ id: values.aprobador }], aprobador2: [] };
    await agregarAprobadorProyecto(id, aprobador);
  };

  handleBorrarAprobadorProyecto = idUsuario => async e => {
    const { quitarAprobadorProyecto, id } = this.props;
    const aprobador = { aprobador1: [{ id: idUsuario }], aprobador2: [] };
    await quitarAprobadorProyecto(id, aprobador);
  };

  handleAgregarColores = async values => {
    const { agregarColorProyecto, id } = this.props;
    const color = { colores: [{ id: values.color }] };
    await agregarColorProyecto(id, color);
  };

  handleBorrarColorProyecto = idColor => async e => {
    const { quitarColorProyecto, id } = this.props;
    const color = { colores: [{ id: idColor }] };
    await quitarColorProyecto(id, color);
  };

  render() {
    const {
      aprobadoresGerencia,
      aprobadoresProyecto,
      coloresProyecto,
      colores,
      usuarios
    } = this.props;
    return this.state.loading || aprobadoresProyecto.fetching || aprobadoresGerencia.fetching ? (
      <Spinner />
    ) : (
      <Box>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container justify="center" alignItems="center">
              <Typography variant="h6">Aprobadores Proyecto</Typography>
              <FormAprobadores
                onSubmit={this.handleAgregarAprobadorProyecto}
                usuarios={usuarios.filter(x => !aprobadoresProyecto.data.some(y => y.id === x.id))}
              />
              <Lista lista={aprobadoresProyecto} handleClick={this.handleBorrarAprobadorProyecto} />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container justify="center" alignItems="center">
              <Typography variant="h6">Aprobadores Gerencia</Typography>
              <FormAprobadores
                onSubmit={this.handleAgregarAprobadorGerencia}
                usuarios={usuarios.filter(x => !aprobadoresGerencia.data.some(y => y.id === x.id))}
              />
              <Lista lista={aprobadoresGerencia} handleClick={this.handleBorrarAprobadorGerencia} />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container justify="center" alignItems="center">
              <Typography variant="h6">Colores</Typography>
              <FormAgregarColores
                onSubmit={this.handleAgregarColores}
                colores={colores.data.filter(z => z.activo).filter(x => !coloresProyecto.data.some(y => x.id === y.id))}
              />
              <Lista lista={coloresProyecto} handleClick={this.handleBorrarColorProyecto} />
            </Grid>
          </Grid>
        </Grid> */}
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    // usuarios: state.Usuarios,
    aprobadoresProyecto: state.AprobadoresProyecto,
    aprobadoresGerencia: state.AprobadoresGerencia,
    coloresProyecto: state.ColoresProyecto,
    colores: state.Colores
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...aprobadoresProyectoDuck,
      ...aprobadoresGerenciaDuck,
      ...coloresProyectoDuck
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguracionProyecto);
