import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from './TextField';
import { Grid, Button, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

const validate = (values = {}) => {
    const errors = {};
    const requiredFields = ['comentario'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = true;
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
};

let FormRechazoCotizacion = props => {
    const { handleSubmit, pristine, reset, submitting, gerencias, proyectos, estados, form } = props;
    return (
        <Container>
            <form noValidate onSubmit={handleSubmit}>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} lg={12}>
                        <Field
                            required
                            multiline
                            style={{ width: '100%' }}
                            type="text"
                            name="comentario"
                            label="Comentarios"
                            component={TextField}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: '20px' }} justify="center" alignItems="center">
                    <Button type="submit" variant="outlined" color="primary">
                        Rechazar
                    </Button>
                </Grid>
            </form>
        </Container>
    );
};

FormRechazoCotizacion = reduxForm({
    form: 'rechazoCotizacion'
})(FormRechazoCotizacion);

export default connect(state => ({
    initialValues: {
        comentario: ''
    },
    enableReinitialize: true,
    validate
}))(FormRechazoCotizacion);
