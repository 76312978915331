import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as drawerDuck from '../ducks/Drawer';
import * as mensajesDuck from '../ducks/Mensajes';
import { Menu, HeadsetMic } from '@material-ui/icons';
import { AppBar, Box, CssBaseline, Grid, IconButton, Toolbar, Typography, Tooltip } from '@material-ui/core';
import Avatar from '../components/Avatar';
import Modal from '../components/Modal';
import { cerrarSesion } from '../services/auth.js';

const logo2 = "https://storage.googleapis.com/mi-cyd/PRUEBA%20BPM%20GMI/logo-cydocs-blanco.png";

const styles = theme => ({
    root: {
        display: 'flex'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    hide: {
        display: 'none'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    },
    logo: {
        width: '70px',
        height: '50px'
    },
    title: {
        flexGrow: 10,
        textAlign: 'left'
    },
    name: {
        // flexGrow: 10,
        textAlign: 'right'
    },
    homeLink: {
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
            color: 'white',
            textDecoration: 'none'
        }
    },
    img: {
        height: "65px",
        marginTop: "0px",
        marginBottom: "5px",
        marginRight: theme.spacing(2),
    },
});

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            modal: false,
            confirmacion: false,
            usuario: {},
            accion: null,
            botonSi: 'Aceptar',
            botonNo: 'Cerrar'
        };
    }

    handleConfirmacion = e => {
        const { assignMensaje } = this.props;
        assignMensaje('¿Está seguro que desea cerrar sesión?');
        this.setState({
            ...this.state,
            modal: true,
            confirmacion: true,
            botonSi: 'Sí',
            botonNo: 'No',
            accion: this.handleCerrarSesion()
        });
    };

    handlePerfil = e => {
        const { assignMensaje, perfiles } = this.props;
        assignMensaje(`Su perfil actual es: ${perfiles.data.map(x => x.descripcion)}`);
        this.setState({ ...this.state, modal: true, confirmacion: false, botonNo: 'Cerrar', accion: false });
    };

    handleClose = () => {
        this.setState({ ...this.state, confirmacion: false, modal: false, accion: false });
    };

    handleCerrarSesion = () => async e => {
        try {
            await cerrarSesion();
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        const { usuarioAutenticado, perfiles, permisos, classes, toggleDrawer, mensaje } = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar color="primary" position="fixed">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            edge="start"
                            onClick={() => toggleDrawer()}>
                            <Menu />
                        </IconButton>
                        <img className={classes.img} src={logo2} alt="CyDocs" />
                        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                            <Link className={classes.homeLink} to="/">
                                Sistema de Adquisiciones
                            </Link>
                        </Typography>
                        <Tooltip title="Mesa de Ayuda CyD">
                            <IconButton
                                color="inherit"
                                aria-label="soporte"
                                onClick={() =>
                                    window.open('https://soportecyd.sd.cloud.invgate.net/', '_blank').focus()
                                }>
                                <HeadsetMic />
                            </IconButton>
                        </Tooltip>
                        <Grid className={classes.name}>
                            <Typography variant="body1" color="inherit">
                                <strong>Bienvenido</strong>
                            </Typography>
                            <div style={{ whiteSpace: 'nowrap' }}>
                                <Box component="div" textOverflow="ellipsis" overflow="hidden">
                                    <Typography variant="body2" color="inherit">
                                        {usuarioAutenticado ? usuarioAutenticado.data.nombres : ''}
                                    </Typography>
                                </Box>
                            </div>
                        </Grid>
                        <Avatar
                            handlePerfil={this.handlePerfil}
                            handleConfirmacion={this.handleConfirmacion}
                            foto={usuarioAutenticado.data.photoURL}
                        />
                    </Toolbar>
                </AppBar>
                <Modal
                    open={this.state.modal}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    botonSi={this.state.botonSi}
                    botonNo={this.state.botonNo}
                    accion={this.state.accion}
                    loading={false}>
                    {mensaje.data}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        usuarioAutenticado: state.Usuario,
        gerencias: state.Gerencias.data,
        proyectos: state.Proyectos.data,
        mensaje: state.Mensajes,
        perfiles: state.Perfiles,
        permisos: state.PermisosUsuario
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...drawerDuck,
            ...mensajesDuck
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Navbar));
