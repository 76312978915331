import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import './index.css';
import App from './App';
import { Router } from "react-router-dom";
import { Provider } from 'react-redux'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import createHistory from 'history/createBrowserHistory'
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk'
import ducks from './ducks'

Sentry.init({
    dsn: "https://4877044a4d3c61dc1927157ae20ce619@o4504243009093632.ingest.sentry.io/4505785046859776",
    integrations: [
      new Sentry.BrowserTracing({}),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: true,
});

const history = createHistory()

const store = createStore(combineReducers({
    ...ducks
}), applyMiddleware(thunk))


ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App history={history} />
        </Router>
    </Provider>
    ,document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
