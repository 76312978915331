import React from 'react'
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const renderSelect = ({
    nombre,
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
  }) => 
  {
    return(
      <FormControl {...custom}>
        <InputLabel>{label}</InputLabel>
        <Select
          {...input}
          children={children}
          error={touched && error}
          inputProps={{
            name: input.name
          }}
        />
        {touched && error && <FormHelperText style={{ color: 'red'}}>Requerido</FormHelperText>}

  
      </FormControl>
    )
  }

  export default renderSelect