import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Container, Typography, Button, Box, Paper, Tabs, Tab, Tooltip, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExcelJS from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Table from '../components/Table';
import Modal from '../components/Modal';
import BotonEditar from '../components/BotonEditar';
import FormProveedores from '../components/FormProveedores';
import Spinner from '../components/Spinner';
import SelectSearch from '../components/SelectSearch';
import * as proveedoresDuck from '../ducks/Proveedores';
import * as mensajesDuck from '../ducks/Mensajes';
import * as ciudadesDuck from '../ducks/Ciudades';
import * as comunasDuck from '../ducks/Comunas';
import * as tipoDocumentoProveedorDuck from '../ducks/TipoDocumentoProveedor';
import DetalleProveedor from './DetalleProveedor';
import { addMillsSep } from '../services/utils';
import { crear, leer } from '../services/api';
import { PAIS_PERU } from '../utils/constants';

const StyledLinearProgress = withStyles(() => ({
    root: {
        height: 8,
        borderRadius: 4
    },
    colorPrimary: {
        backgroundColor: props => (props.value < 50 ? '#e6cbc9' : props.value < 100 ? '#dad2ba' : '#d5e8ca')
    },
    barColorPrimary: {
        backgroundColor: props => (props.value < 50 ? '#f44336' : props.value < 100 ? '#ffc107' : '#44b700')
    }
}))(LinearProgress);

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <StyledLinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired
};

class Proveedores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openForm: false,
            confirmacion: false,
            accion: false,
            loadingForm: false,
            idSucEdit: false,
            exportandoExcel: false,
            critOrder: 'asc',
            loading: true,
            proveedorSelected: null,
            refreshTable: null,
            tipoDocumentoProveedor: [],
            configColumnas: []
        };
        this.tableRef = React.createRef();
    }

    async componentDidMount() {
        leer(`/proveedor/datos-campos`).then(response => {
            // console.log("response", response);
            this.setState({
                loading: false,
                tipoDocumentoProveedor: response.proveedorTiposDocumento,
                configColumnas: [
                    {
                        title: 'Razón Social',
                        field: 'nombre',
                        cellStyle: {
                            minWidth: '150px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            padding: '0 0 0 9px',
                            textAlign: 'center'
                        },
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={response.nombresProveedores.map(x => ({ id: x.nombre, nombre: x.nombre }))}
                                input={{ name: 'filtroNombre' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={5}
                                maxTagWidth="62px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    },
                    {
                        title: 'RUT/ID',
                        field: 'rut',
                        cellStyle: {
                            minWidth: '100px',
                            padding: '0px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            padding: '0 0 0 9px',
                            textAlign: 'center'
                        },
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={response.rutsProveedores.map(x => ({ id: x.rut, nombre: x.rut }))}
                                input={{ name: 'filtroRut' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={5}
                                maxTagWidth="35px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    },
                    {
                        title: 'Tipo de Recurso',
                        field: 'tipoRecurso',
                        cellStyle: {
                            minWidth: '150px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            padding: '0 0 0 9px',
                            textAlign: 'center'
                        },
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={response.tiposRecurso
                                    .map(x => ({ id: x.nombre, nombre: x.nombre }))}
                                input={{ name: 'filtroTipo' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={8}
                                maxTagWidth="62px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    },
                    {
                        title: 'Clase',
                        field: 'clase',
                        cellStyle: {
                            minWidth: '250px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            textAlign: 'center'
                        },
                        render: rowData => this.renderClase(rowData),
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={response.clases.filter(x => x.activo).map(x => ({ id: x.nombre, nombre: x.nombre }))}
                                input={{ name: 'filtroClase' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={12}
                                maxTagWidth="105px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    },
                    {
                        title: 'Criticidad',
                        field: 'criticidad',
                        cellStyle: {
                            minWidth: '100px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            padding: '0 0 0 9px',
                            textAlign: 'center'
                        },
                        render: rowData => this.renderCriticidad(rowData),
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={[
                                    { id: 'Alta', nombre: 'Alta' },
                                    { id: 'Media', nombre: 'Media' },
                                    { id: 'Baja', nombre: 'Baja' }
                                ]}
                                input={{ name: 'filtroCriticidad' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={2}
                                maxTagWidth="50px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    },
                    {
                        title: 'Local',
                        cellStyle: {
                            minWidth: '100px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            padding: '0 0 0 9px',
                            textAlign: 'center'
                        },
                        render: rowData => this.renderLocal(rowData),
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={[{ id: 'Sí', nombre: 'Sí' }, { id: 'No', nombre: 'No' }]}
                                input={{ name: 'filtroLocal' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={5}
                                maxTagWidth="62px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    },
                    {
                        title: 'Evaluación Categorías',
                        field: 'evaluacion',
                        cellStyle: {
                            minWidth: '120px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            textAlign: 'center'
                        },
                        render: rowData => this.renderEvaluacion(rowData),
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={response.optionsEvalCategorias.map(x => ({ id: x, nombre: x + '%' }))}
                                input={{ name: 'filtroEval' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={2}
                                maxTagWidth="60px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    },
                    {
                        title: 'Evaluación Final',
                        field: 'evaluacionFinal',
                        sorting: true,
                        cellStyle: {
                            minWidth: '120px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            padding: '0 0 0 9px',
                            textAlign: 'center'
                        },
                        render: rowData => this.renderEvaluacionFinal(rowData),
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={response.optionsEvalFinal.map(x => ({ id: x, nombre: x + '%' }))}
                                input={{ name: 'filtroEvalFinal' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={2}
                                maxTagWidth="60px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    },
                    {
                        title: 'Cantidad OC Emitidas',
                        field: 'cantOC',
                        cellStyle: {
                            minWidth: '112px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            padding: '0 0 0 9px',
                            textAlign: 'center'
                        },
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={response.optionsCantOC.map(x => ({ id: x, nombre: x.toString() }))}
                                input={{ name: 'filtroCantOC' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={2}
                                maxTagWidth="60px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    },
                    {
                        title: 'Total Compras',
                        field: 'montoTotalCLP',
                        cellStyle: {
                            minWidth: '120px',
                            padding: 0,
                            textAlign: 'center'
                        },
                        headerStyle: {
                            padding: '0 0 0 9px',
                            textAlign: 'center'
                        },
                        render: rowData => '$' + addMillsSep(rowData.montoTotalCLP),
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={response.optionsMontoTotalCLP.map(x => ({ id: x, nombre: '$' + addMillsSep(x) }))}
                                input={{ name: 'filtroMontoTotalProv' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={2}
                                maxTagWidth="60px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    },
                    {
                        title: 'Estado Documental',
                        field: 'estado_documental',
                        cellStyle: {
                            minWidth: '130px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            padding: '0 0 0 9px',
                            textAlign: 'center'
                        },
                        render: rowData => this.renderEstado(rowData),
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={[
                                    { id: 'No Aplica', nombre: 'No Aplica' },
                                    { id: 'Pendiente', nombre: 'Pendiente' },
                                    { id: 'En Progreso', nombre: 'En Progreso' },
                                    { id: 'En Revisión', nombre: 'En Revisión' },
                                    { id: 'Listo', nombre: 'Listo' }
                                ]}
                                input={{ name: 'filtroEstadoDocumental' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={2}
                                maxTagWidth="60px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    },
                    {
                        title: 'Progreso Carga\nde Documentos',
                        field: 'progreso',
                        filtering: false,
                        sorting: false,
                        cellStyle: {
                            minWidth: '200px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            textAlign: 'center'
                        }
                    },
                    {
                        title: 'Habilitado',
                        field: 'activoOriginal',
                        lookup: {
                            Activo: 'Activo',
                            Inactivo: 'Inactivo'
                        },
                        cellStyle: {
                            minWidth: '120px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            padding: '0 0 0 9px',
                            textAlign: 'center'
                        },
                        render: rowData => this.renderHabilitado(rowData),
                        filterComponent: propsFilter => (
                            <SelectSearch
                                options={[
                                    { id: 'Activo', nombre: 'Activo' },
                                    { id: 'Inactivo', nombre: 'Inactivo' }
                                ]}
                                input={{ name: 'filtroHabilitado' }}
                                meta={{}}
                                multiple
                                limitTags={1}
                                charCountLimit={2}
                                maxTagWidth="60px"
                                size="small"
                                type="text"
                                change={(name, value) => {
                                    propsFilter.onFilterChanged(propsFilter.columnDef.tableData.id, value);
                                }}
                            />
                        )
                    }
                ]
            })
        });
    }

    checkRut = rut => {
        var valor = rut.value.replace('.', '');
        /* Despejar Guión
      valor = valor.replace('-','');
      
      // Aislar Cuerpo y Dígito Verificador
      cuerpo = valor.slice(0,-1);
      dv = valor.slice(-1).toUpperCase();
      
      // Formatear RUN
      rut.value = cuerpo + '-'+ dv*/
    };

    handleClose = () => {
        const { removeError } = this.props;
        removeError();
        this.setState({ ...this.state, open: false, confirmacion: false, accion: false });
    };

    handleCloseForm = () => {
        const { selectProveedor } = this.props;
        selectProveedor(null);
        this.setState({
            ...this.state,
            openForm: false,
            loadingForm: false,
            idSucEdit: false,
            confirmacion: false,
            accion: false,
            proveedorSelected: null,
        });
    };

    handleEditar = async values => {
        const { editarProveedores, editarSucursal, assignMensaje } = this.props;
        let proveedorEditado = this.state.proveedorSelected;
        this.setState({ loadingForm: true });

        if (this.state.idSucEdit) {
            let updates = {
                pais: values.pais,
                region: values.region,
                ciudad_id: values.ciudad_id,
                comuna_id: values.comuna_id,
                direccion: values.direccion,
                telefono: values.telefono,
                pagina_web: values.pagina_web,
                email_envio_oc: values.email_envio_oc,
                contacto_nombre: values.contacto_nombre,
                contacto_email: values.contacto_email,
                contacto_telefono: values.contacto_telefono
            };
            // console.log('estoy editando sucursal', updates);

            await editarSucursal(this.state.idSucEdit, updates);

            assignMensaje('Sucursal editada');
            this.setState({
                openForm: false,
                idSucEdit: false,
                loadingForm: false,
                open: true,
                confirmacion: false,
                accion: false,
                proveedorSelected: null,
            });
            this.state.refreshTable();
        } else {
            values.clase = values.clase.join(',');
            let updates = {
                nombre: values.nombre,
                giro: values.giro,
                representante_legal: values.representante_legal,
                representante_legal_rut: values.representante_legal_rut,
                tipo_recurso: values.tipo_recurso,
                clase: values.clase,
                criticidad: values.criticidad ? values.criticidad : null,
                es_local: values.es_local,
                banco_nombre: values.banco_nombre,
                banco_numero_cuenta: values.banco_numero_cuenta,
                banco_tipo_cuenta: values.banco_tipo_cuenta,
                banco_titular_cuenta: values.banco_titular_cuenta,
                banco_rut_cuenta: values.banco_rut_cuenta,
                banco_email: values.banco_email
            };
            // console.log('editando proveedor', updates);
            // let proveedoresRestantes = proveedores.data.filter((x) => !x.selected);
            // if (
            //     proveedoresRestantes.some(
            //         (x) => x.ciudad_id === values.ciudad_id && x.comuna_id === values.comuna_id && x.rut === values.rut
            //     )
            // ) {
            //     assignMensaje('Este proveedor se encuentra en la lista');
            //     this.setState({ ...this.state, open: true, confirmacion: false, accion: false });
            // } else {

            await editarProveedores(proveedorEditado.id, updates);

            assignMensaje('Proveedor editado');
            this.setState({
                openForm: false,
                idSucEdit: false,
                loadingForm: false,
                open: true,
                confirmacion: false,
                accion: false,
                proveedorSelected: null,
            });
            this.state.refreshTable();
            // }
        }
    };

    handleAgregarRegistroModal = e => {
        this.setState({ ...this.state, openForm: true, confirmacion: false, accion: false, proveedorSelected: null });
    };

    handleEditarProveedorModal = (dataProveedor, refreshTable) => {
        // this.props.selectProveedor(id);
        this.setState({
            openForm: true,
            confirmacion: false,
            accion: false,
            proveedorSelected: dataProveedor,
            refreshTable: this.state.refreshTable ? this.state.refreshTable : refreshTable
        });
    };

    handleEditarSucursalModal = (dataProveedor, idSuc, refreshTable) => {
        // this.props.selectProveedor(idProv);
        this.setState({
            openForm: true,
            idSucEdit: idSuc,
            confirmacion: false,
            accion: false,
            proveedorSelected: dataProveedor,
            refreshTable: this.state.refreshTable ? this.state.refreshTable : refreshTable
        });
    };

    handleAgregarRegistro = async values => {
        const { agregarProveedores, agregarSucursal, assignMensaje, usuarioAutenticado } = this.props;
        this.setState({ loadingForm: true });

        values.email_creador = usuarioAutenticado.data.email;
        if (values.telefono == '') values.telefono = null;
        if (values.contacto_telefono == '') values.contacto_telefono = null;
        const proveedorExiste = await leer(`/proveedor/encontrar-por-rut?rut=${values.rut}`);
        if (proveedorExiste) {
            values.proveedor_id = proveedorExiste.id;
            console.log('proveedor existe, se crea la sucursal', values);
            await agregarSucursal(values);

            assignMensaje('Sucursal agregada');
            this.setState({ openForm: false, loadingForm: false, open: true, confirmacion: false, accion: false, proveedorSelected: null });
        } else {
            values.clase = values.clase.join(',');
            values.criticidad = values.criticidad ? values.criticidad : null;
            if (values.paisProveedor !== PAIS_PERU) values.cuentaInterbancaria = null;
            console.log('proveedor no existe, se crea proveedor', values);

            // if (
            //     proveedores.data.some(
            //         (x) => x.ciudad_id === values.ciudad_id && x.comuna_id === values.comuna_id && x.rut === values.rut
            //     )
            // ) {
            //     assignMensaje('Este proveedor ya existe');
            //     this.setState({ ...this.state, open: true, confirmacion: false, accion: false });
            // } else {
            await agregarProveedores(values);

            assignMensaje('Proveedor agregado plop');
            this.setState({ openForm: false, loadingForm: false, open: true, confirmacion: false, accion: false, proveedorSelected: null });
            // }
        }
    };

    handleOrderChange = (columnId, direction) => {
        // console.log('cambió el orden', columnId, direction);
        if (columnId == 4) this.setState({ critOrder: direction });
    };

    renderClase = rowData => {
        if (rowData.clase) {
            let textoClase = rowData.clase.replace(/,/g, ', ');
            return <div>{textoClase}</div>;
        } else {
            return '';
        }
    };

    renderEvaluacion = rowData => {
        if (rowData) {
            return (
                <div style={{ whiteSpace: 'pre' }}>
                {'Calidad: ' +
                  rowData.totalEvalCalidad +
                  '%\nCosto: ' +
                  rowData.totalEvalCosto +
                  '%\nEntrega: ' +
                  rowData.totalEvalEntrega +
                  '%'}
              </div>
            );
        } else {
            return '';
        }
    };

    renderEvaluacionFinal = rowData => {
        let color = '#4baf4f';
        if (rowData.evaluacionFinal < 40) color = '#bb0f27';
        else if (rowData.evaluacionFinal < 80) color = '#ffc106';
        return rowData.evaluacionFinal ? (
            <Paper style={{ backgroundColor: color, color: 'white' }}>
                <Grid container justify="center" alignItems="center" style={{ height: 50 }}>
                    <Grid item>
                        {rowData.evaluacionFinal + '%'}
                    </Grid>
                </Grid>  
             </Paper>
        ) : (
            '0%'
        );
    }

    renderEstado = rowData => {
        return <div style={{ whiteSpace: 'pre' }}>{rowData.estadoDocumental.replace(/-/g, '\n')}</div>;
    };

    renderHabilitado = rowData => {
        if (rowData.activoOriginal) {
            return 'Activo';
        } else {
            return (
                <Tooltip
                    title={
                        <div style={{ whiteSpace: 'pre', textAlign: 'left' }}>
                            {rowData.motivoBloqueo ? rowData.motivoBloqueo : ''}
                        </div>
                    }>
                    <div>{'Inactivo'}</div>
                </Tooltip>
            );
        }
    };

    renderCriticidad = rowData => {
        let color = '#4baf4f';
        if (rowData.criticidad == 'Alta') color = '#bb0f27';
        else if (rowData.criticidad == 'Media') color = '#ffc106';
        return rowData.criticidad ? (
            <Paper variant='outlined'>
                <Grid container justify="center" alignItems="center" style={{ height: 50 }}>
                    <Grid item>
                        {rowData.criticidad}
                    </Grid>
                </Grid>  
             </Paper>
        ) : (
            ''
        );
    };

    renderLocal = rowData => {
        return rowData.esLocal ? 'Sí' : 'No';
    }

    exportarTablaExcel = () => {
        this.setState({ exportandoExcel: true });
        const filters = this.tableRef.current.state.query.filters.map(x => ({ field: x.column.field, value: x.value }));
        crear(`/proveedor/data-excel`, {
          filters: filters,
          search: this.tableRef.current.state.query.search.trim()
        }).then(response => {
            // console.log("response excel", response);
            const wb = new ExcelJS.Workbook();
            const ws = wb.addWorksheet('Proveedores');
            const wsSucursales = wb.addWorksheet('Sucursales');
            const wsTrabajadores = wb.addWorksheet('Trabajadores');
            const wsVehiculos = wb.addWorksheet('Vehículos');
            ws.columns = [
                { header: 'País', width: 15 },
                { header: 'Razón Social', width: 30 },
                { header: 'RUT/ID', width: 15 },
                { header: 'Giro', width: 30 },
                { header: 'Representante Legal', width: 30 },
                { header: 'RUT/ID Representante Legal', width: 15 },
                { header: 'Tipo Recurso', width: 15 },
                { header: 'Clase', width: 20 },
                { header: 'Criticidad', width: 10 },
                { header: 'Local', width: 7 },
                { header: 'Eval. Calidad', width: 12 },
                { header: 'Eval. Costo', width: 12 },
                { header: 'Eval. Entrega', width: 12 },
                { header: 'Eval. Final', width: 12 },
                { header: 'Cant. OC Emitidas', width: 15 },
                { header: 'Total Compras', width: 15 },
                { header: 'Estado Documental', width: 20 },
                { header: 'Habilitado', width: 10 },
                { header: 'Nombre Banco', width: 15 },
                { header: 'Nº Cuenta', width: 15 },
                { header: 'Cuenta Interbancaria', width: 15 },
                { header: 'Tipo Cuenta', width: 15 },
                { header: 'Titular Cuenta', width: 20 },
                { header: 'RUT/ID Cuenta Bancaria', width: 15 },
                { header: 'Correo electrónico', width: 20 }
            ];
            wsSucursales.columns = [
                { header: 'Proveedor', width: 15 },
                { header: 'País', width: 15 },
                { header: 'Región', width: 30 },
                { header: 'Ciudad', width: 20 },
                { header: 'Comuna', width: 20 },
                { header: 'Dirección', width: 30 },
                { header: 'Teléfono', width: 15 },
                { header: 'Página Web', width: 20 },
                { header: 'Correo envío OC', width: 20 },
                { header: 'Nombre Ejecutivo', width: 30 },
                { header: 'Correo Ejecutivo', width: 20 },
                { header: 'Teléfono Ejecutivo', width: 15 },
                { header: 'Creado por', width: 30 }
            ];
            wsTrabajadores.columns = [
                { header: 'Proveedor', width: 15 },
                { header: 'RUT/ID', width: 15 },
                { header: 'Nombres', width: 20 },
                { header: 'Apellidos', width: 20 },
                { header: 'Creado por', width: 30 }
            ];
            wsVehiculos.columns = [
                { header: 'Proveedor', width: 15 },
                { header: 'Patente', width: 10 },
                { header: 'Creado por', width: 30 }
            ];

            ws.getRow(1).font = { bold: true };
            wsSucursales.getRow(1).font = { bold: true };
            wsTrabajadores.getRow(1).font = { bold: true };
            wsVehiculos.getRow(1).font = { bold: true };

            ws.addRows(response.dataProv);
            wsSucursales.addRows(response.dataSuc);
            wsTrabajadores.addRows(response.dataTrab);
            wsVehiculos.addRows(response.dataVeh);

            let that = this;
            wb.xlsx
                .writeBuffer()
                .then(function (bufferData) {
                    // console.log("exito en crear buffer");
                    var blob = new Blob([bufferData], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });
                    saveAs(blob, 'Proveedores ' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xlsx');
                    that.setState({ exportandoExcel: false });
                })
                .catch(function (error) {
                    console.log('error en excel:', error);
                    that.setState({ exportandoExcel: false });
                });
        });
    };

    render() {
        const {
            mensaje,
            nuevoDocumentoAplica,
            editarDocumentoAplica,
            agregarDocumentoProveedor,
            formProveedores,
            permisosUsuario,
            usuarioAutenticado,
            agregarTrabajador,
            editarTrabajador,
            agregarVehiculo,
            editarVehiculo,
        } = this.props;

        return this.state.loading ? (
            <Spinner />
        ) : (
            <Box>
                {/* <Grid container justify="center" alignItems="center">
                    <Typography style={{ marginBottom: '30px' }} variant="h4">
                        Proveedores
                    </Typography>
                </Grid> */}
                {permisosUsuario.some(x => x.nombre === 'crearProveedor') && (
                    <Grid container justify="center" alignItems="center" style={{ margin: '10px 0' }}>
                        <Button color="primary" variant="outlined" onClick={this.handleAgregarRegistroModal}>
                            Agregar Proveedor
                        </Button>
                    </Grid>
                )}
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} lg={12}>
                        <Table
                            tableRef={this.tableRef}
                            searchID="mainSearch"
                            agregar={true}
                            agregarRegistro={this.handleAgregarRegistroModal}
                            filtering={true}
                            sorting={false}
                            detailMultiple={false}
                            handleOrderChange={this.handleOrderChange}
                            detalleF={(rowData, refreshTable) => (
                                <DetalleProveedor
                                    dataProveedor={rowData}
                                    tipoDocumentoProveedor={this.state.tipoDocumentoProveedor}
                                    nuevoDocumentoAplica={nuevoDocumentoAplica}
                                    editarDocumentoAplica={editarDocumentoAplica}
                                    agregarDocumentoProveedor={agregarDocumentoProveedor}
                                    handleEditarProveedorModal={this.handleEditarProveedorModal}
                                    handleEditarSucursalModal={this.handleEditarSucursalModal}
                                    permisosUsuario={permisosUsuario}
                                    usuarioAutenticado={usuarioAutenticado}
                                    agregarTrabajador={agregarTrabajador}
                                    editarTrabajador={editarTrabajador}
                                    agregarVehiculo={agregarVehiculo}
                                    editarVehiculo={editarVehiculo}
                                    refreshTable={!refreshTable ? this.state.refreshTable : refreshTable}
                                />
                            )}
                            actions={[
                                {
                                    icon: 'save_alt',
                                    disabled: this.state.exportandoExcel,
                                    tooltip: 'Exportar a Excel',
                                    isFreeAction: true,
                                    onClick: this.exportarTablaExcel
                                }
                            ]}
                            columnas={this.state.configColumnas}
                            remoteData={true}
                            data={
                                query => new Promise((resolve, reject) => {
                                    // console.log('query en proveedores', query);
                                    const filters = query.filters.map(x => ({ field: x.column.field, value: x.value }));
                                    const options = {
                                        page: query.page,
                                        pageSize: query.pageSize,
                                        filters: filters,
                                        search: query.search.trim(),
                                    }
                                    crear(`/proveedor/datos-paginados`, options).then(resultado => {
                                        // console.log("proveedores paginados", resultado[0]);
                                        resolve({
                                            data: resultado[0].data.map(x => ({
                                                ...x,
                                                activoOriginal: x.activo,
                                                activo: x.activo ? 'Activo' : 'Inactivo',
                                                progreso:
                                                    x.estadoDocumental == 'No Aplica' ? (
                                                        'No Aplica'
                                                    ) : x.estadoDocumental == 'Pendiente' ? (
                                                        'Pendiente'
                                                    ) : (
                                                        <LinearProgressWithLabel
                                                            value={x.progresoDocumentos}
                                                        />
                                                    )
                                                })),
                                            page: query.page,
                                            totalCount: resultado[0].pagination[0]?.total || 0
                                        })
                                    });
                                })
                            }
                        />
                    </Grid>
                </Grid>

                <Modal
                    open={this.state.open}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}>
                    {mensaje.data}
                </Modal>

                <Modal
                    open={this.state.openForm}
                    handleClose={this.handleCloseForm}
                    confirmacion={this.state.confirmacion}
                    accion={false}
                    loading={false}
                    title="Formulario Proveedores"
                    fullWidth={true}
                    maxWidth="md"
                    showTitle={false}>
                    <Container>
                        {this.state.loadingForm ? (
                            <Spinner />
                        ) : (
                            <FormProveedores
                                formProveedores={formProveedores}
                                editar={this.state.proveedorSelected !== null}
                                proveedorSelected={this.state.proveedorSelected}
                                idSucEdit={this.state.idSucEdit}
                                onSubmit={this.state.proveedorSelected !== null ? this.handleEditar : this.handleAgregarRegistro}
                            />
                        )}
                    </Container>
                </Modal>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mensaje: state.Mensajes,
        formProveedores: state.form.proveedores ? state.form.proveedores.values : {},
        usuarioAutenticado: state.Usuario,
        permisosUsuario: state.PermisosUsuario.data,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            ...proveedoresDuck,
            ...mensajesDuck,
            ...ciudadesDuck,
            ...comunasDuck,
            ...tipoDocumentoProveedorDuck,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Proveedores);
