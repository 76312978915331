import { makeTypes, mac, createReducer, reduceReducers } from 'ducks-maker'
import { assignHOR } from './hors.js'

const t = makeTypes('idgrupocotizacion')
const ASSIGN = t('assign').single()

export const assignIdGrupoCotizacion = mac(ASSIGN, 'payload')

const initialState = {
    data: "",
}

const assignReducer =  createReducer(initialState, assignHOR({ ASSIGN }))

export default reduceReducers(assignReducer)