import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 450,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CheckboxListSecondary(props) {
  const classes = useStyles();
  const { lista, handleClick } = props

  return (
    <List dense className={classes.root}>
      {lista.data.map(value => {
        return (
          <ListItem key={value.id} button>
            <ListItemText
              primary={
                value.nombres
                  ? value.nombres + ' ' + value.apellidos + (value.email ? ' (' + value.email + ')' : '')
                  : value.nombre
              }
            />
            <ListItemSecondaryAction>
              <IconButton onClick={handleClick(value.id)} edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}
