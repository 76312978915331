import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FooterImagen from '../img/footer.png'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    width: '100%',
    bottom: 0,
    zIndex: 1150,
  },
  paper: {
    textAlign: 'center',
    backgroundColor: '#430000', 
    color: 'white',
    marginBottom: '0px',
    paddingBotton: '0px',
    borderRadius: '0px',
  },
  parrafo: {
      margin: '0px',
      padding: '0px'
  }
}));

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
              <div className={classes.parrafo}><img style={{ width: '120px', height: '42px'}} src={FooterImagen} /></div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
