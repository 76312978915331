import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, addFetch, selectHOR, } from './hors.js'
import { leer, modificar, crear } from '../services/api'

const t = makeTypes('configuraciones')
const FETCH = t('fetch').async()
const ADD = t('add').async()

const fetchActions = asyncMac(FETCH)
const addActions = asyncMac(ADD)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const addReducer = createReducer(initialState, addFetch( ADD ))


export default reduceReducers(fetchReducer, addReducer)

export const fetchConfiguracionProyecto = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer('/configuracion-proyecto')
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const agregarAprobadores2 = (values) => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await crear('/aprobador2_solicitud_recurso', values)
        dispatch(addActions.success(data))
    } catch (error) {
        dispatch(addActions.error(error))
    }
}
