import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Grid, Container, Box, Typography, Button} from '@material-ui/core'
import Table from '../components/Table'
import Modal from '../components/Modal'
import BotonEditar from '../components/BotonEditar'
import Spinner from '../components/Spinner'
import * as marcasVehiculosDuck from '../ducks/MarcasVehiculos'
import * as mensajesDuck from '../ducks/Mensajes'
import FormMarcasVehiculos from '../components/FormMarcasVehiculos';

class MarcasVehiculos extends React.Component{

    state= {
        open: false,
        openForm: false,
        confirmacion: false,
        accion: false,
    }

    handleClose = () => {
        const { removeError } = this.props
        removeError()
        this.setState({ ...this.state, open: false, confirmacion: false, accion: false})
    }

    handleCloseForm = () => {
        const { selectMarcaVehiculo } = this.props
        selectMarcaVehiculo(null)
        this.setState({ ...this.state, openForm: false, confirmacion: false, accion: false})
    }

    handleEditarModal = (id, accion) => async e => {
        const { assignMensaje, selectMarcaVehiculo } = this.props
        switch (accion) {
            case "activar":
                assignMensaje(`¿Está seguro desea activar el registro?`)
                this.setState({ ...this.state, open: true, confirmacion: true, accion: this.handleActivar(id)})
                break;
            case "desactivar":
                assignMensaje(`¿Está seguro desea desactivar el registro?`)
                this.setState({ ...this.state, open: true, confirmacion: true, accion: this.handleActivar(id)})
                break;
            case "editar":
                selectMarcaVehiculo(id)
                this.setState({ ...this.state, openForm: true, confirmacion: false, accion: false})
                break;
            default:
                break;
        }
    }

    handleActivar = id => async e => {
        const { editarMarcasVehiculos, marcasVehiculos, assignMensaje } = this.props
        let marcaVehiculo = marcasVehiculos.data.filter( x => x.id === id)[0]
        await editarMarcasVehiculos(id, {...marcaVehiculo, activo: !marcaVehiculo.activo})
        assignMensaje("Registro editado")
        this.setState({ ...this.state, open: true, confirmacion: false, accion: false})            
    }

    handleEditar = async values => {
        const { marcasVehiculos, editarMarcasVehiculos, assignMensaje, selectMarcaVehiculo } = this.props
        let marcaEditada = marcasVehiculos.data.filter(x => x.selected )
        await editarMarcasVehiculos(marcaEditada[0].id, {...marcaEditada, ...values})
        selectMarcaVehiculo(null)
        assignMensaje("Registro editado")
        this.setState({ ...this.state, openForm: false, open: true, confirmacion: false, accion: false})            
    }

    handleAgregarRegistroModal = e => {
        this.setState({ ...this.state, openForm: true, confirmacion: false, accion: false})
    }

    handleAgregarRegistro = async values => {
        const { agregarMarcasVehiculos, assignMensaje, marcasVehiculos } = this.props
        if(marcasVehiculos.data.some( x => x.nombre === values.nombre)){
            assignMensaje("Un Registro con este nombre ya existe")
            this.setState({ ...this.state, open: true, confirmacion: false, accion: false})            
        }else{
            await agregarMarcasVehiculos(values)
            assignMensaje("Registro agregado")
            this.setState({ ...this.state, openForm: false, open: true, confirmacion: false, accion: false})            
        }
    }

    render(){
        const { marcasVehiculos, mensaje, editar } = this.props
        return(
            <Box>
                <Grid container justify="center" alignItems="center">
                    <Typography style={{ marginBottom: '30px' }} variant="h4">Marcas Vehículos</Typography>
                </Grid>
                <Grid container justify="center" alignItems="center">
                    <Button color="primary" variant="outlined" onClick={this.handleAgregarRegistroModal}>Agregar Marca</Button>
                </Grid>
                <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} lg={12}>
                            <Table
                                searchID="mainSearch"
                                agregar={true}
                                agregarRegistro={this.handleAgregarRegistroModal}
                                columnas={[
                                    { title: 'Nombre', field: 'nombre', 
                                      cellStyle: {
                                        textAlign:"center"
                                     },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                    { title: 'Descripción', field: 'descripcion',cellStyle: {
                                        textAlign:"center"
                                      },headerStyle: {
                                        
                                        textAlign: "center",
                                      }},
                                    { title: 'Estado', field: 'activo', 
                                      cellStyle: {
                                        textAlign:"center"
                                     },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                    { title: 'Acción', field: 'accion', sorting: false, 
                                    cellStyle: {
                                        textAlign:"center",
                                    },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                  ]}
                                  data={marcasVehiculos.data.map(x => ({
                                    ...x,
                                    activo: x.activo ? "Activo" : "Inactivo",
                                    accion: <BotonEditar  handleEditar={this.handleEditarModal} handleActivar={this.handleEditarModal} id={x.id} activo={x.activo ? true: false} />                                    
                              }))} 
                            
                            />
                        </Grid>
                </Grid>

                <Modal 
                    open={this.state.open || (marcasVehiculos.error ? true : false)}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={marcasVehiculos.fetching}
                >
                    {marcasVehiculos.error ? "Ocurrió un error, contacte al administrador del sitio" : mensaje.data}
                </Modal>
                
                <Modal 
                    open={this.state.openForm}
                    handleClose={this.handleCloseForm}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={marcasVehiculos.fetching}
                    title="Formulario Marcas Vehiculos"
                >
                    <Container>
                        { marcasVehiculos.fetching ? <Spinner /> : <FormMarcasVehiculos editar={editar} onSubmit={editar ? this.handleEditar : this.handleAgregarRegistro} />}
                    </Container>
                </Modal>

            </Box>
        )
    }
}

const mapStateToProps = state => {
    return {
        marcasVehiculos: state.MarcasVehiculos,
        mensaje: state.Mensajes,
        editar: state.MarcasVehiculos.data.some(x => x.selected === true)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
      ...marcasVehiculosDuck,
      ...mensajesDuck,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MarcasVehiculos)