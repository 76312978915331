import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, resetHOR } from './hors.js'
import { crear, leer, modificar } from '../services/api'

const t = makeTypes('itemsCotizados')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const RESET = t('reset').single()

const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
export const resetItemsCotizados = mac(RESET)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const resetReducer = createReducer(initialState, resetHOR( {RESET} ))

export default reduceReducers(fetchReducer, editReducer, resetReducer)

export const agregarItemsCotizados = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data = await crear('/arrayItemCotizacion', values )
        data = data.items ? data.items : []
        dispatch(fetchActions.success(data))
        return data
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const editarItemsCotizados = (id,values) => async (dispatch, getState) => {
    dispatch(editActions.start())
    console.log("editar",id,values)
    try {
        let data = await modificar('/item-cotizacion', id, values )
        dispatch(editActions.success(data))
        return data
    } catch (error) {
        dispatch(editActions.error(error))
    }
}

export const fetchItemsCotizados = id => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data = await leer(`/detalleCotizacion/${id}`)
        data = data.item_cotizacion ? data.item_cotizacion : []
        dispatch(fetchActions.success(data))
        return data
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}


export const agregarRecepcionItemRecurso = values => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        let data = await crear('/recepcion-item-cotizacion', values)
        console.log("data", data)
        console.log("data", data)
        dispatch(editActions.success(data))
    } catch (error) {
        dispatch(editActions.error(error))
    }

}

