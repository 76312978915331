import { auth } from './firebase.js'

export const loginUsuario = async (email, password) => {
    console.log("intentando logearme")
    await auth.signInWithEmailAndPassword(email, password)
    //console.log(await auth.currentUser.getIdToken());
}

export const getCurrentUser =  async () =>{
    return  auth.currentUser.getIdTokenResult();
}

export const cerrarSesion = async () => {
    //console.log("cerrando sesion")
    const csrfToken = localStorage.getItem("csrfToken") || "";
    localStorage.setItem("csrfToken", "")
    //console.log(csrfToken)
    await fetch(`https://api-usuarios.cydocs.cl/login/sessionLogout?_csrf=${csrfToken}`,{
        method: 'post',
        credentials: 'include',
        headers: { 
            'Authorization': `Bearer ${csrfToken}`
            },
        }
    )
    await auth.signOut()
}