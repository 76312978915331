import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { TableBody, TableHead, TableCell, Paper, TableRow} from '@material-ui/core';

import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

const useStyles2 = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function CustomPaginationActionsTable(props) {
  const { columns } = props
  const classes = useStyles2();

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
          {<Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map( (x,id) => <TableCell style={{textAlign: 'center'}} key={id}>{x}</TableCell> )}
              </TableRow>
            </TableHead>
            <TableBody>
                {props.children}       
            </TableBody>
          </Table>}
      </div>
    </Paper>
  );
}