import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import ClearIcon from '@material-ui/icons/Clear';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import BlockIcon from '@material-ui/icons/Block';
import Spinner from '../components/Spinner';
import EditIcon from '@material-ui/icons/Edit';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Modal from '../components/Modal';
import Badge from '../components/Badge';
import SelectSearch from '../components/SelectSearch';
import FormTrabajador from '../components/FormTrabajador';
import FormVehiculo from '../components/FormVehiculo';
import FormFiltrosDashProv from '../containers/FormFiltrosDashProv';
import InformeEvalProveedor from '../containers/InformeEvalProveedor';
import ImagenCarrito from '../img/carrito.png'
import ImagenDinero from '../img/money.png'
import ImagenEval from '../img/eval-general.png'
import { useDispatch } from 'react-redux';
import { uploadFileAsPromise, downloadUrlAsPromise, addMillsSep } from '../services/utils';
import { leer, modificar, crear } from '../services/api'
import { saveAs } from 'file-saver';
import * as proveedoresDuck from '../ducks/Proveedores';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { isNumber } from 'highcharts';
import seriesLabel from 'highcharts/modules/series-label';
import moment from 'moment';
import 'moment/locale/es';
import { PAIS_CHILE, PAIS_PERU } from '../utils/constants';
moment.locale('es');
seriesLabel(Highcharts);

const useStyles = makeStyles((theme) => ({
    selectorTabs: {
      minWidth: 150,
      marginBottom: 15
    },
    textoInterior: {
        fontSize: 18,
        marginBottom: '0.20em'
    },
    tabContent: {
        padding: 10,
        height: 347,
        overflow: 'auto'
    },
    docName: {
        paddingLeft: 15,
        width: 300
    },
    sucursalDocActions: {
        width: 150,
        maxWidth: 150
    },
    tablaDocs: {
        width: 'fit-content',
        marginBottom: 15
    },
    input: {
        display: 'none'
    },
    kpiValue: {
        textAlign: 'center',
        marginBottom: '0',
        marginTop: 20
    },
    kpiTitle: {
        textAlign: 'center',
        marginBottom: 0,
        fontSize: 12
    },
    celdaInformes: {
        textAlign: 'center',
        padding: 5
    },
    cabeceraInformes: {
        textAlign: 'center',
        padding: 5,
        backgroundColor: '#731f1f',
        color: 'white',
        fontWeight: 'bold',
        position: 'relative'
    }
  }));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
}

export default function DetalleProveedor(props) {
    const {
        dataProveedor,
        tipoDocumentoProveedor,
        nuevoDocumentoAplica,
        editarDocumentoAplica,
        agregarDocumentoProveedor,
        handleEditarProveedorModal,
        handleEditarSucursalModal,
        permisosUsuario,
        usuarioAutenticado,
        agregarTrabajador,
        editarTrabajador,
        agregarVehiculo,
        editarVehiculo,
        refreshTable,
    } = props;
    const { formFiltros } = useSelector(state => ({ formFiltros: state.form['formProv' + dataProveedor.id] }));
    const classes = useStyles();
    const dispatch = useDispatch();
    const [tabValue, setTabValue] = React.useState(0);
    const [modalConfig, setModalConfig] = React.useState({
        open: false,
        confirmacion: false,
        accion: false,
        mensaje: '',
        loading: false,
        title: false,
        esDesactivar: false
    });
    const [modalTrabConfig, setModalTrabConfig] = React.useState({
        open: false,
        loading: false,
        editar: false,
        idTrab: null
    });
    const [modalVehConfig, setModalVehConfig] = React.useState({
        open: false,
        loading: false,
        editar: false,
        idVeh: null
    });
    const [modalInfEvalProv, setModalInfEvalProv] = React.useState({
        open: false
    });
    const [loading, setLoading] = React.useState(true);
    const [dataProveedorFull, setDataProveedorFull] = React.useState(null);
    const [esChile, setEsChile] = React.useState(null);
    const [esPeru, setEsPeru] = React.useState(null);
    const [selectorSucursal, setSelectorSucursal] = React.useState(0);
    const [selectorTrabajador, setSelectorTrabajador] = React.useState('');
    const [selectorVehiculo, setSelectorVehiculo] = React.useState('');
    const [modoAplica, setModoAplica] = React.useState(false);
    const [modoUpload, setModoUpload] = React.useState(false);
    const [modoRevision, setModoRevision] = React.useState(false);
    const [checkAplica, setCheckAplica] = React.useState([]);
    const [archivosDoc, setArchivosDoc] = React.useState([]);
    const [revisionDoc, setRevisionDoc] = React.useState([]);
    const [progressValue, setProgressValue] = React.useState(0);
    const [docsCategoria, setDocsCategoria] = React.useState([]);
    const [motivoBloqueo, setMotivoBloqueo] = React.useState('');
    const [motivoBloqueoError, setMotivoBloqueoError] = React.useState(false);

    const [cantidadOC, setCantidadOC] = React.useState('0');
    const [totalCLP, setTotalCLP] = React.useState('0');
    const [evalGeneral, setEvalGeneral] = React.useState('0');
    const [configChart1, setConfigChart1] = React.useState(null);

    const [informesEvalProveedor, setInformesEvalProveedor] = React.useState({ annios: [], data: [] });
    const [annioInformeSelected, setAnnioInformeSelected] = React.useState('');

    useEffect(() => {
        // console.log('filtros: ', formFiltros?.values);

        if (formFiltros?.values && dataProveedorFull) {
            let filtros = formFiltros.values;
            let cotiFiltradas = dataProveedorFull.cotizaciones.filter(x => x.estado_id === 23);
            // console.log(moment(filtros.desde, 'MMMM YYYY'));
            // console.log(moment(filtros.hasta, 'MMMM YYYY').endOf('month'));
            if (filtros.desde)
                cotiFiltradas = cotiFiltradas.filter(x =>
                    moment(x.fecha_orden).isSameOrAfter(moment(filtros.desde, 'MMMM YYYY'))
                );
            if (filtros.hasta)
                cotiFiltradas = cotiFiltradas.filter(x =>
                    moment(x.fecha_orden).isSameOrBefore(moment(filtros.hasta, 'MMMM YYYY').endOf('month'))
                );
            
            let cantOC = cotiFiltradas.length;
            let montoTotal = 0;
            let periodosTabla = [];
            let evaluacionesCalidadTabla = [];
            let evaluacionesCostoTabla = [];
            let evaluacionesEntregaTabla = [];

            let sumaValorCalidad = 0;
            let sumaValorCosto = 0;
            let sumaValorEntrega = 0;
            let sumaTotalCalidad = 0;
            let sumaTotalCosto = 0;
            let sumaTotalEntrega = 0;

            cotiFiltradas.forEach(x => {
                if (x.monto_total_pesos) montoTotal += Math.round(x.monto_total_pesos);

                if (moment(x.fecha_orden).isSameOrAfter('2021-09-01')) {
                    let posPeriodo = 0;
                    for (let y = 0; y < periodosTabla.length; y++) {
                        if (periodosTabla[y].isSame(moment(x.fecha_orden), 'month')) {
                            posPeriodo = y;
                            break;
                        } else {
                            if (periodosTabla[y].isAfter(moment(x.fecha_orden), 'month')) {
                                periodosTabla.splice(y, 0, moment(x.fecha_orden));
                                evaluacionesCalidadTabla.splice(y, 0, { valor: 0, total: 0 });
                                evaluacionesCostoTabla.splice(y, 0, { valor: 0, total: 0 });
                                evaluacionesEntregaTabla.splice(y, 0, { valor: 0, total: 0 });
                                posPeriodo = y;
                                break;
                            } else {
                                if (y === periodosTabla.length - 1) {
                                    periodosTabla.push(moment(x.fecha_orden));
                                    evaluacionesCalidadTabla.push({ valor: 0, total: 0 });
                                    evaluacionesCostoTabla.push({ valor: 0, total: 0 });
                                    evaluacionesEntregaTabla.push({ valor: 0, total: 0 });
                                    posPeriodo = y + 1;
                                    break;
                                } else {
                                    // periodosTabla.splice(y + 1, 0, moment(x.fecha_orden));
                                    // evaluacionesCalidadTabla.splice(y + 1, 0, { valor: 0, total: 0 });
                                    // evaluacionesCostoTabla.splice(y + 1, 0, { valor: 0, total: 0 });
                                    // evaluacionesEntregaTabla.splice(y + 1, 0, { valor: 0, total: 0 });
                                    // posPeriodo = y + 1;
                                    // break;
                                }
                            }
                        }
                    }
                    if (periodosTabla.length === 0) {
                        periodosTabla.push(moment(x.fecha_orden));
                        evaluacionesCalidadTabla.push({ valor: 0, total: 0 });
                        evaluacionesCostoTabla.push({ valor: 0, total: 0 });
                        evaluacionesEntregaTabla.push({ valor: 0, total: 0 });
                    }

                    let calidad = evaluacionesCalidadTabla[posPeriodo].valor;
                    let totalCalidad = evaluacionesCalidadTabla[posPeriodo].total;
                    let evaluacionesCalidad = dataProveedorFull.evaluaciones_calidad.filter(y => y.cotizacion_id === x.oldId);
                    if (evaluacionesCalidad.length > 0) {
                        for (let i = 0; i < evaluacionesCalidad.length; i++) {
                            let evalData = evaluacionesCalidad[i];
                            let sumaPuntos = 0;
                            let total = 0;
                            if (evalData.calidad1) sumaPuntos++;
                            if (evalData.calidad2) sumaPuntos++;
                            if (evalData.calidad3) sumaPuntos++;
                            if (evalData.calidad4) sumaPuntos++;
                            if (evalData.calidad5) sumaPuntos++;
                            if (evalData.calidad6) sumaPuntos++;
                            if (evalData.calidad7) sumaPuntos++;

                            if (evalData.calidad1 != null) total++;
                            if (evalData.calidad2 != null) total++;
                            if (evalData.calidad3 != null) total++;
                            if (evalData.calidad4 != null) total++;
                            if (evalData.calidad5 != null) total++;
                            if (evalData.calidad6 != null) total++;
                            if (evalData.calidad7 != null) total++;

                            calidad += total > 0 ? sumaPuntos / total : 0;
                            sumaValorCalidad += total > 0 ? sumaPuntos / total : 0;
                        }

                        sumaTotalCalidad += evaluacionesCalidad.length;
                        evaluacionesCalidadTabla[posPeriodo].valor = calidad;
                        evaluacionesCalidadTabla[posPeriodo].total = totalCalidad + evaluacionesCalidad.length;
                    }

                    let costo = evaluacionesCostoTabla[posPeriodo].valor;
                    let totalCosto = evaluacionesCostoTabla[posPeriodo].total;
                    let evaluacionesCosto = dataProveedorFull.evaluaciones_costo.filter(y => y.cotizacion_id === x.oldId);
                    if (evaluacionesCosto.length > 0) {
                        for (let i = 0; i < evaluacionesCosto.length; i++) {
                            let evalData = evaluacionesCosto[i];

                            costo += evalData.costo / 5;
                            sumaValorCosto += evalData.costo / 5;
                        }

                        sumaTotalCosto += evaluacionesCosto.length;
                        evaluacionesCostoTabla[posPeriodo].valor = costo;
                        evaluacionesCostoTabla[posPeriodo].total = totalCosto + evaluacionesCosto.length;
                    }

                    let entrega = evaluacionesEntregaTabla[posPeriodo].valor;
                    let totalEntrega = evaluacionesEntregaTabla[posPeriodo].total;
                    let evaluacionesEntrega = dataProveedorFull.evaluaciones_entrega.filter(y => y.cotizacion_id === x.oldId);
                    if (evaluacionesEntrega.length > 0) {
                        for (let i = 0; i < evaluacionesEntrega.length; i++) {
                            let evalData = evaluacionesEntrega[i];

                            entrega += evalData.entrega ? parseFloat(evalData.entrega) : 0;
                            sumaValorEntrega += evalData.entrega ? parseFloat(evalData.entrega) : 0;
                        }

                        sumaTotalEntrega += evaluacionesEntrega.length;
                        evaluacionesEntregaTabla[posPeriodo].valor = entrega;
                        evaluacionesEntregaTabla[posPeriodo].total = totalEntrega + evaluacionesEntrega.length;
                    }
                }
            });

            let evalFinalCalidad = sumaTotalCalidad > 0 ? (sumaValorCalidad * 100) / sumaTotalCalidad : 0;
            let evalFinalCosto = sumaTotalCosto > 0 ? (sumaValorCosto * 100) / sumaTotalCosto : 0;
            let evalFinalEntrega = sumaTotalEntrega > 0 ? (sumaValorEntrega * 100) / sumaTotalEntrega : 0;
            let evalFinalFinal = Math.round((evalFinalCalidad + evalFinalCosto + evalFinalEntrega) / 3);

            let meses = [];
            let evalCalidadMeses = [];
            let evalCostoMeses = [];
            let evalEntregaMeses = [];
            for (let i = 0; i < periodosTabla.length; i++) {
                meses.push(periodosTabla[i].format('MMM YYYY'));
                evalCalidadMeses.push(
                    evaluacionesCalidadTabla[i].total > 0
                        ? Math.round((evaluacionesCalidadTabla[i].valor * 100) / evaluacionesCalidadTabla[i].total)
                        : 0
                );
                evalCostoMeses.push(
                    evaluacionesCostoTabla[i].total > 0
                        ? Math.round((evaluacionesCostoTabla[i].valor * 100) / evaluacionesCostoTabla[i].total)
                        : 0
                );
                evalEntregaMeses.push(
                    evaluacionesEntregaTabla[i].total > 0
                        ? Math.round((evaluacionesEntregaTabla[i].valor * 100) / evaluacionesEntregaTabla[i].total)
                        : 0
                );
            }

            // console.log(evalCalidadMeses);
            // console.log(evalCostoMeses);
            // console.log(evalEntregaMeses);
            let nuevaConfig1 = {
                chart: {
                    type: 'line',
                    height: 200
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: meses
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                series: [
                    {
                        data: evalCalidadMeses,
                        name: 'Calidad',
                        color: '#1f69b1'
                        // marker: {
                        //     symbol: 'circle'
                        // }
                    },
                    {
                        data: evalCostoMeses,
                        name: 'Costo',
                        color: '#d57f1f'
                        // marker: {
                        //     symbol: 'circle'
                        // }
                    },
                    {
                        data: evalEntregaMeses,
                        name: 'Entrega',
                        color: '#d13636'
                        // marker: {
                        //     symbol: 'circle'
                        // }
                    }
                ],
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{point.y}%',
                    split: true
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        marker: {
                            symbol: 'circle'
                        }
                    }
                }
            };

            setCantidadOC(addMillsSep(cantOC));
            setTotalCLP(addMillsSep(montoTotal));
            setEvalGeneral(evalFinalFinal);
            setConfigChart1(nuevaConfig1);
        }
    }, [dataProveedorFull, formFiltros?.values]);

    useEffect(() => {
        if (checkAplica.length > 0) refreshDocsCategoria();
    }, [checkAplica])

    useEffect(() => {
        if (tipoDocumentoProveedor.length && dataProveedorFull) {
            refreshDocumentos();
            // refreshDocsCategoria();
        }
    }, [dataProveedorFull]);
    // console.log("docs: ", archivosDoc);

    useEffect(() => {
        leer('/proveedor/' + dataProveedor.id).then(response => {
            console.log('data full', response);
            setDataProveedorFull({
                ...response,
                activoOriginal: response.activo,
                activo: response.activo ? 'Activo' : 'Inactivo'
            });
            
            setEsChile(response.pais?._id === PAIS_CHILE);
            setEsPeru(response.pais?._id === PAIS_PERU);
            setLoading(false);
        });
        getInformesEvalProveedor();
    }, [dataProveedor]);

    const getInformesEvalProveedor = async () => {
        let resultado = await leer('/informesProveedor/' + dataProveedor.id);
        // console.log("informes prov", resultado);
        let informesActivos = resultado.filter(x => x.activo);
        let anniosInforme = [];
        informesActivos.forEach(x => {
            if (anniosInforme.indexOf(x.annio) === -1) anniosInforme.push(x.annio);
        });
        anniosInforme.sort((a, b) => (b - a));
        if (anniosInforme.length && annioInformeSelected == '') setAnnioInformeSelected(anniosInforme[0].toString());
        setInformesEvalProveedor({ annios: anniosInforme, data: informesActivos });
    }

    const refreshDocsCategoria = () => {
        // console.log("cambio dataProveedor", checkAplica);
        let cat = 1;
        let sumaCat = 0;
        let nuevoDocsCategoria = [0];
        tipoDocumentoProveedor.forEach((x, indexDoc) => {
            // if (x.categoria != cat) {
            //     cat = x.categoria;
            //     nuevoDocsCategoria.push(sumaCat);
            //     sumaCat = 0;
            // }
            if (!isNumber(nuevoDocsCategoria[x.categoria])) nuevoDocsCategoria[x.categoria] = 0;
            if (checkAplica[indexDoc]) nuevoDocsCategoria[x.categoria] += checkAplica[indexDoc].filter(y => y === true).length;
        });
        // nuevoDocsCategoria.push(sumaCat);
        // console.log("suma categorias: ", nuevoDocsCategoria);
        setDocsCategoria(nuevoDocsCategoria);
    }
    
    const refreshDocumentos = () => {
        console.log("actualizando ch3ckAplica");
        let initialDocs = [];
        setCheckAplica(tipoDocumentoProveedor.map(doc => {
            let arrayDoc = [];
            if (doc.categoria == 1 || doc.categoria == 2 || doc.categoria == 5) {
                let docProv = dataProveedorFull.sucursales.map((x, index) => {
                    arrayDoc.push(null);
                    let checkDocs = x.documentos.filter(y => y.tipo_documento_proveedor_id === doc.id && y.revisado != "No aplica")[0] ? true : false;
                    return checkDocs;
                });
                initialDocs.push(arrayDoc);
                return docProv;
            }
            if (doc.categoria == 3) {
                let docProv = dataProveedorFull.trabajadores.map((x, index) => {
                    arrayDoc.push(null);
                    let checkDocs = x.documentos.filter(y => y.tipo_documento_proveedor_id === doc.id && y.revisado != "No aplica")[0] ? true : false;
                    return checkDocs;
                });
                initialDocs.push(arrayDoc);
                return docProv;
            }
            if (doc.categoria == 4) {
                let docProv = dataProveedorFull.vehiculos.map((x, index) => {
                    arrayDoc.push(null);
                    let checkDocs = x.documentos.filter(y => y.tipo_documento_proveedor_id === doc.id && y.revisado != "No aplica")[0] ? true : false;
                    return checkDocs;
                });
                initialDocs.push(arrayDoc);
                return docProv;
            }
        }));
        setArchivosDoc(initialDocs);
        setRevisionDoc(initialDocs);
    }

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleActivar = (esDesactivar, bloqueo) => {
        // console.log(dataProveedor.id, { activo: dataProveedor.activo != 'Activo' });
        // console.log(modalConfig);
        // console.log("es desactivar: ", esDesactivar);
        // console.log("motivo: ", bloqueo);
        if (esDesactivar && bloqueo == '') {
            setMotivoBloqueoError(true);
        } else {
            setMotivoBloqueoError(false);
            setModalConfig({
                open: true,
                confirmacion: false,
                accion: false,
                mensaje: '',
                loading: true,
                title: false,
                esDesactivar: esDesactivar
            });
            
            let update = { activo: dataProveedorFull.activo != 'Activo' };
            // console.log("es desactivar: ", esDesactivar);
            if (esDesactivar) {
                let motivoGuardado = dataProveedorFull.motivo_bloqueo ? dataProveedorFull.motivo_bloqueo + '\n' : '';
                update['motivo_bloqueo'] = motivoGuardado + moment().format('DD/MM/YYYY') + ' - ' + bloqueo;
            }
            console.log("actualizando", update);
            dispatch(
                proveedoresDuck.editarProveedores(dataProveedor.id, update)
            )
                .then(() => {
                    setModalConfig({
                        open: true,
                        confirmacion: false,
                        accion: false,
                        mensaje: 'Proveedor editado correctamente',
                        loading: false,
                        title: false,
                        esDesactivar: false
                    });
                    refreshTable();
                })
                .catch(error => {
                    console.log('Error: ', error);
                });
        }
    };

    const handleModoAplica = () => {
        refreshDocumentos();
        setModoAplica(!modoAplica);
        setModoUpload(false);
        setModoRevision(false);
    };

    const handleModoUpload = () => {
        refreshDocumentos();
        setModoUpload(!modoUpload);
        setModoAplica(false);
        setModoRevision(false);
    };

    const handleModoRevision = () => {
        refreshDocumentos();
        setModoRevision(!modoRevision);
        setModoAplica(false);
        setModoUpload(false)
    }

    const contenidoCeldaDocs = (doc, x, indexDoc, index) => {
        let docProv = x.documentos.find(sucDoc => sucDoc.tipo_documento_proveedor_id === doc.id);
        if (modoAplica) {
            return (
                <TableCell align="center" key={index} className={classes.sucursalDocActions}>
                    <Checkbox
                        checked={checkAplica[indexDoc] ? checkAplica[indexDoc][index] : false}
                        color="primary"
                        onChange={event => handleChangeCheck(event, indexDoc, index)}
                    />
                </TableCell>
            );
        } else if (modoUpload) {
            if (docProv && docProv?.revisado !== 'No aplica') {
                return (
                    <TableCell align="center" key={index} className={classes.sucursalDocActions}>
                        {docProv?.archivo_id && (
                            <Tooltip title="Ver Archivo">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    style={{ marginRight: 10 }}
                                    onClick={() => verArchivoDocumento(docProv?.archivo_id, docProv?.archivo?.nombre)}>
                                    <Badge
                                        overlap="circle"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        variant="dot"
                                        color={
                                            docProv.revisado == 'Listo'
                                                ? '#44b700'
                                                : docProv.revisado == 'Por revisar'
                                                ? '#ffc107'
                                                : '#f44336'
                                        }>
                                        <InsertDriveFileOutlinedIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        )}
                        {archivosDoc[indexDoc] && archivosDoc[indexDoc][index] ? (
                            <Tooltip title="Limpiar">
                                <IconButton color="primary" size="small" onClick={() => clearDocFile(indexDoc, index)}>
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <div style={{ display: 'inline-block' }}>
                                <input
                                    className={classes.input}
                                    id={'doc-file-' + indexDoc + '-' + index}
                                    type="file"
                                    onChange={event => handleChangeDocFile(event, indexDoc, index)}
                                />
                                <label htmlFor={'doc-file-' + indexDoc + '-' + index} style={{ marginBottom: 0 }}>
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                        size="small">
                                        <AddCircleOutlineRoundedIcon />
                                    </IconButton>
                                </label>
                            </div>
                        )}
                    </TableCell>
                );
            } else {
                return (
                    <TableCell align="center" key={index} className={classes.sucursalDocActions}>
                        {'-'}
                    </TableCell>
                );
            }
        } else if (modoRevision) {
            if (docProv && docProv?.revisado !== 'No aplica' && docProv?.archivo_id) {
                return (
                    <TableCell align="center" key={index} className={classes.sucursalDocActions}>
                        {docProv?.archivo_id && (
                            <Tooltip title="Ver Archivo">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    style={{ marginRight: 10 }}
                                    onClick={() => verArchivoDocumento(docProv?.archivo_id, docProv?.archivo?.nombre)}>
                                    <Badge
                                        overlap="circle"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        variant="dot"
                                        color={
                                            docProv.revisado == 'Listo'
                                                ? '#44b700'
                                                : docProv.revisado == 'Por revisar'
                                                ? '#ffc107'
                                                : '#f44336'
                                        }>
                                        <InsertDriveFileOutlinedIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        )}
                        {revisionDoc[indexDoc] &&
                            (revisionDoc[indexDoc][index] === null || revisionDoc[indexDoc][index] === true) && (
                                <Tooltip title="OK">
                                    <IconButton
                                        size="small"
                                        style={{ color: '#44b700' }}
                                        onClick={() => handleChangeRevisionDoc(true, indexDoc, index)}>
                                        <ThumbUpIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        {revisionDoc[indexDoc] &&
                            (revisionDoc[indexDoc][index] === null || revisionDoc[indexDoc][index] === false) && (
                                <Tooltip title="Volver a subir">
                                    <IconButton
                                        size="small"
                                        style={{ color: '#f44336' }}
                                        onClick={() => handleChangeRevisionDoc(false, indexDoc, index)}>
                                        <ThumbDownIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                    </TableCell>
                );
            } else if (docProv && docProv?.revisado !== 'No aplica' && docProv?.archivo_id == null){
                return (
                    <TableCell align="center" key={index} className={classes.sucursalDocActions}>
                        {'Sin Archivo'}
                    </TableCell>
                );
            } else {
                return (
                    <TableCell align="center" key={index} className={classes.sucursalDocActions}>
                        {'-'}
                    </TableCell>
                );
            }
        } else if (docProv && docProv?.revisado !== 'No aplica') {
            if (docProv?.archivo_id) {
                return (
                    <TableCell align="center" key={index} className={classes.sucursalDocActions}>
                        <Tooltip title="Ver Archivo">
                            <IconButton
                                color="primary"
                                size="small"
                                onClick={() => verArchivoDocumento(docProv?.archivo_id, docProv?.archivo?.nombre)}>
                                <Badge
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    variant="dot"
                                    color={
                                        docProv.revisado == 'Listo'
                                            ? '#44b700'
                                            : docProv.revisado == 'Por revisar'
                                            ? '#ffc107'
                                            : '#f44336'
                                    }>
                                    <InsertDriveFileOutlinedIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                );
            } else {
                return (
                    <TableCell align="center" key={index} className={classes.sucursalDocActions}>
                        {'Sin Archivo'}
                    </TableCell>
                );
            }
        } else {
            return (
                <TableCell align="center" key={index} className={classes.sucursalDocActions}>
                    {'-'}
                </TableCell>
            );
        }
    };

    const handleChangeCheck = (event, findIndexDoc, findIndex) => {
        // console.log(event.target.checked, findIndexDoc, findIndex);
        // setChecked(event.target.checked);
        let tempCheck = [];
        checkAplica.forEach((x, indexDoc) => {
            tempCheck.push(x.map((y, index) => {
                if (indexDoc === findIndexDoc && index === findIndex) {
                    return event.target.checked;
                } else {
                    return y;
                }
            }));
        });
        // console.log(tempCheck);
        // tempCheck[indexDoc, index] = event.target.checked;
        setCheckAplica(tempCheck);
    };

    const handleChangeDocFile = (event, findIndexDoc, findIndex) => {
        let tempDocs = [];
        archivosDoc.forEach((x, indexDoc) => {
            tempDocs.push(x.map((y, index) => {
                if (indexDoc === findIndexDoc && index === findIndex) {
                    return event.target.files[0];
                } else {
                    return y;
                }
            }));
        });
        setArchivosDoc(tempDocs);
        // console.log("run handleChangeDocFile");
    }

    const clearDocFile = (findIndexDoc, findIndex) => {
        let tempDocs = [];
        archivosDoc.forEach((x, indexDoc) => {
            tempDocs.push(x.map((y, index) => {
                if (indexDoc === findIndexDoc && index === findIndex) {
                    return null;
                } else {
                    return y;
                }
            }));
        });
        setArchivosDoc(tempDocs);
        // console.log("run clearDocFile");
    }

    const handleChangeRevisionDoc = (revision, findIndexDoc, findIndex) => {
        let tempRevision = [];
        revisionDoc.forEach((x, indexDoc) => {
            tempRevision.push(x.map((y, index) => {
                if (indexDoc === findIndexDoc && index === findIndex) {
                    return (revision === y) ? null : revision;
                } else {
                    return y;
                }
            }));
        });
        setRevisionDoc(tempRevision);
        // console.log("run handleChangeRevisionDoc");
    }

    const handleGuardarCambiosAplica = () => {
        // console.log("run handleGuardarCambiosAplica");
        setModalConfig({
            open: true,
            confirmacion: false,
            accion: false,
            mensaje: '',
            loading: true,
            title: false,
            esDesactivar: false
        });
        let promises = [];
        let docsCrear = [];
        let docsEditar = [];
        let noAplicaAll = true;
        let hayEnProgreso = false;
        let hayPorRevisar = false;
        let docsNotificar = {};
        let docsNotificarTrab = {};
        let docsNotificarVeh = {};
        checkAplica.forEach((filaDoc, indexDoc) => {
            let tipoDoc = tipoDocumentoProveedor[indexDoc];
            if (tipoDoc.categoria == 1 || tipoDoc.categoria == 2 || tipoDoc.categoria == 5) {
                dataProveedorFull.sucursales.forEach((x, index) => {
                    if (noAplicaAll && filaDoc[index]) noAplicaAll = false;
                    if (
                        !hayPorRevisar &&
                        filaDoc[index] &&
                        x.documentos.some(
                            y => y.tipo_documento_proveedor_id === tipoDoc.id && y.revisado == 'Por revisar'
                        )
                    )
                        hayPorRevisar = true;
                    let tieneDoc = x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] ? true : false;
                    if (tieneDoc) {
                        if ((x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado == "No aplica" && filaDoc[index])
                            || (x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado != "No aplica" && !filaDoc[index])) {
                            let updateDoc = {
                                documento_id: x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].id,
                                revisado: filaDoc[index] ? "Aplica" : "No aplica"
                            }
                            docsEditar.push(updateDoc);
                            if (filaDoc[index]) {
                                let arrSuc = docsNotificar[x.id] ? docsNotificar[x.id] : [];
                                arrSuc.push(tipoDoc.nombre);
                                docsNotificar = {
                                    ...docsNotificar,
                                    [x.id]: arrSuc
                                }
                                if (!hayEnProgreso) hayEnProgreso = true;
                            }
                        }
                        if (filaDoc[index] && !hayEnProgreso && x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado == "Aplica") hayEnProgreso = true;
                    } else if(filaDoc[index]) {
                        let nuevoDoc = {
                            tipo_documento_proveedor_id: tipoDoc.id,
                            sucursal_id: x.id,
                            revisado: "Aplica",
                            activo: true
                        }
                        docsCrear.push(nuevoDoc);
                        if (!hayEnProgreso) hayEnProgreso = true;
                        let arrSuc = docsNotificar[x.id] ? docsNotificar[x.id] : [];
                        arrSuc.push(tipoDoc.nombre);
                        docsNotificar = {
                            ...docsNotificar,
                            [x.id]: arrSuc
                        }
                    }
                });
            } else if (tipoDoc.categoria === 3) {
                dataProveedorFull.trabajadores.forEach((x, index) => {
                    if (noAplicaAll && filaDoc[index]) noAplicaAll = false;
                    if (
                        !hayPorRevisar &&
                        filaDoc[index] &&
                        x.documentos.some(
                            y => y.tipo_documento_proveedor_id === tipoDoc.id && y.revisado == 'Por revisar'
                        )
                    )
                        hayPorRevisar = true;
                    let tieneDoc = x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] ? true : false;
                    if (tieneDoc) {
                        if ((x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado == "No aplica" && filaDoc[index])
                            || (x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado != "No aplica" && !filaDoc[index])) {
                            let updateDoc = {
                                documento_id: x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].id,
                                revisado: filaDoc[index] ? "Aplica" : "No aplica"
                            }
                            docsEditar.push(updateDoc);
                            if (filaDoc[index]) {
                                let arr = docsNotificarTrab[x.id] ? docsNotificarTrab[x.id] : [];
                                arr.push(tipoDoc.nombre);
                                docsNotificarTrab = {
                                    ...docsNotificarTrab,
                                    [x.id]: arr
                                }
                                if (!hayEnProgreso) hayEnProgreso = true;
                            }
                        }
                        if (filaDoc[index] && !hayEnProgreso && x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado == "Aplica") hayEnProgreso = true;
                    } else if(filaDoc[index]) {
                        let nuevoDoc = {
                            tipo_documento_proveedor_id: tipoDoc.id,
                            sucursal_id: x.id,
                            revisado: "Aplica",
                            activo: true
                        }
                        docsCrear.push(nuevoDoc);
                        if (!hayEnProgreso) hayEnProgreso = true;
                        let arr = docsNotificarTrab[x.id] ? docsNotificarTrab[x.id] : [];
                        arr.push(tipoDoc.nombre);
                        docsNotificarTrab = {
                            ...docsNotificarTrab,
                            [x.id]: arr
                        }
                    }
                });
            } else if (tipoDoc.categoria === 4) {
                dataProveedorFull.vehiculos.forEach((x, index) => {
                    if (noAplicaAll && filaDoc[index]) noAplicaAll = false;
                    if (
                        !hayPorRevisar &&
                        filaDoc[index] &&
                        x.documentos.some(
                            y => y.tipo_documento_proveedor_id === tipoDoc.id && y.revisado == 'Por revisar'
                        )
                    )
                        hayPorRevisar = true;
                    let tieneDoc = x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] ? true : false;
                    if (tieneDoc) {
                        if ((x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado == "No aplica" && filaDoc[index])
                            || (x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado != "No aplica" && !filaDoc[index])) {
                            let updateDoc = {
                                documento_id: x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].id,
                                revisado: filaDoc[index] ? "Aplica" : "No aplica"
                            }
                            docsEditar.push(updateDoc);
                            if (filaDoc[index]) {
                                let arr = docsNotificarVeh[x.id] ? docsNotificarVeh[x.id] : [];
                                arr.push(tipoDoc.nombre);
                                docsNotificarVeh = {
                                    ...docsNotificarVeh,
                                    [x.id]: arr
                                }
                                if (!hayEnProgreso) hayEnProgreso = true;
                            }
                        }
                        if (filaDoc[index] && !hayEnProgreso && x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado == "Aplica") hayEnProgreso = true;
                    } else if(filaDoc[index]) {
                        let nuevoDoc = {
                            tipo_documento_proveedor_id: tipoDoc.id,
                            sucursal_id: x.id,
                            revisado: "Aplica",
                            activo: true
                        }
                        docsCrear.push(nuevoDoc);
                        if (!hayEnProgreso) hayEnProgreso = true;
                        let arr = docsNotificarVeh[x.id] ? docsNotificarVeh[x.id] : [];
                        arr.push(tipoDoc.nombre);
                        docsNotificarVeh = {
                            ...docsNotificarVeh,
                            [x.id]: arr
                        }
                    }
                });
            }
        });
        // console.log("crear: ", docsCrear.length);
        // console.log("editar: ", docsEditar.length);
        console.log("docs notificar: ", docsNotificar);
        if (
            Object.keys(docsNotificar).length +
                Object.keys(docsNotificarTrab).length +
                Object.keys(docsNotificarVeh).length >
            0
        ) {
            crear('/notificarDocumentosAplica', { suc: docsNotificar, trab: docsNotificarTrab, veh: docsNotificarVeh });
        }
        let updateProv = { estado_documental: 'En Progreso' };
        if (noAplicaAll) updateProv.estado_documental = 'No Aplica';
        else if (hayEnProgreso && hayPorRevisar) updateProv.estado_documental = 'En Progreso-En Revisión';
        else if (hayEnProgreso) updateProv.estado_documental = 'En Progreso';
        else if (hayPorRevisar) updateProv.estado_documental = 'En Revisión';
        else updateProv.estado_documental = 'Listo';
        if (dataProveedorFull.estado_documental != updateProv.estado_documental)
            dispatch(proveedoresDuck.editarProveedores(dataProveedorFull.id, updateProv));
        if (docsCrear.length > 0)
            promises.push(nuevoDocumentoAplica({ proveedor_id: dataProveedorFull.id, listaDocs: docsCrear }));
        if (docsEditar.length > 0)
            promises.push(editarDocumentoAplica({ proveedor_id: dataProveedorFull.id, listaDocs: docsEditar }));
        Promise.all(promises).then(() => {
            setModalConfig({
                open: true,
                confirmacion: false,
                accion: false,
                mensaje: 'Cambios guardados correctamente',
                loading: false,
                title: false,
                esDesactivar: false
            });
            refreshTable();
            handleModoAplica();
            refreshDocsCategoria();
        }).catch(error => {
            console.log(error);
        });
    };

    const handleSubirArchivos = () => {
        // console.log("run handleSubirArchivos", archivosDoc);
        setProgressValue(0);
        setModalConfig({
            open: true,
            confirmacion: false,
            accion: false,
            mensaje: '',
            loading: false,
            title: 'Subiendo Archivos',
            esDesactivar: false
        });

        let archivosASubir = [];
        let ningunoAplica = true;
        let docsNotificar = [];
        archivosDoc.forEach((filaDoc, indexDoc) => {
            let tipoDoc = tipoDocumentoProveedor[indexDoc];
            if (tipoDoc.categoria == 1 || tipoDoc.categoria == 2 || tipoDoc.categoria == 5) {
                dataProveedorFull.sucursales.forEach((x, index) => {
                    if (filaDoc[index]) {
                        archivosASubir.push({
                            file: filaDoc[index],
                            id: x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].id
                        });
                        docsNotificar.push(tipoDoc.nombre);
                    } else {
                        if (
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado ==
                                'Aplica'
                        )
                            ningunoAplica = false;
                    }
                });
            } else if (tipoDoc.categoria == 3) {
                dataProveedorFull.trabajadores.forEach((x, index) => {
                    if (filaDoc[index]) {
                        archivosASubir.push({
                            file: filaDoc[index],
                            id: x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].id
                        });
                        docsNotificar.push(tipoDoc.nombre);
                    } else {
                        if (
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado ==
                                'Aplica'
                        )
                            ningunoAplica = false;
                    }
                });
            } else if (tipoDoc.categoria == 4) {
                dataProveedorFull.vehiculos.forEach((x, index) => {
                    if (filaDoc[index]) {
                        archivosASubir.push({
                            file: filaDoc[index],
                            id: x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].id
                        });
                        docsNotificar.push(tipoDoc.nombre);
                    } else {
                        if (
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado ==
                                'Aplica'
                        )
                            ningunoAplica = false;
                    }
                });
            }
        });
        if (archivosASubir.length > 0) {
            let updateProv = { estado_documental: 'En Progreso-En Revisión' };
            if (ningunoAplica) updateProv.estado_documental = 'En Revisión';
            if (dataProveedorFull.estado_documental != updateProv.estado_documental)
                dispatch(proveedoresDuck.editarProveedores(dataProveedorFull.id, updateProv));
            console.log("docs notificar: ", docsNotificar);
            crear('/notificarDocumentosCargados', {
                proveedor_id: dataProveedorFull.id,
                docsCargados: docsNotificar,
                usuario_accion: usuarioAutenticado.data.email
            });
        }
        // console.log("archivos a subir: ", archivosASubir);
        Promise.all(archivosASubir.map(async archivo => {
            let respuesta = await uploadFileAsPromise(archivo.file, "19u3V78nLco4o0lO1iDsht4vF6RkqTk9W");
            await agregarDocumentoProveedor({
                proveedor_id: dataProveedorFull.id,
                documento_id: archivo.id,
                archivo_id: respuesta.id,
                archivo_url: respuesta.url,
                archivo_nombre: respuesta.nombre
            });
            // console.log("uno listo: ", archivo.file.name);
            setProgressValue(oldProgress => {
                let avance = 100 / archivosASubir.length;
                return Math.min(oldProgress + avance, 100);
            })
        })).then(() => {
            setModalConfig({
                open: true,
                confirmacion: false,
                accion: false,
                mensaje: 'Archivos subidos correctamente',
                loading: false,
                title: 'Subiendo Archivos',
                esDesactivar: false
            });
            refreshTable();
            handleModoUpload();
        }).catch(error => {
            console.log(error);
        });
    }

    const handleGuardarRevisiones = () => {
        console.log("run handleGuardarRevisiones", revisionDoc);
        setModalConfig({
            open: true,
            confirmacion: false,
            accion: false,
            mensaje: '',
            loading: true,
            title: false,
            esDesactivar: false
        });

        let docsEditar = [];
        let hayEnProgreso = false;
        let hayPorRevisar = false;
        let docsNotificar = {};
        let docsNotificarTrab = {};
        let docsNotificarVeh = {};
        revisionDoc.forEach((filaDoc, indexDoc) => {
            let tipoDoc = tipoDocumentoProveedor[indexDoc];
            if (tipoDoc.categoria == 1 || tipoDoc.categoria == 2 || tipoDoc.categoria == 5) {
                dataProveedorFull.sucursales.forEach((x, index) => {
                    if (filaDoc[index] !== null) {
                        docsEditar.push({
                            documento_id: x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].id,
                            revisado: filaDoc[index] === true ? 'Listo' : 'Aplica'
                        });
                        if (!hayEnProgreso && !filaDoc[index]) hayEnProgreso = true;
                        if (!filaDoc[index]) {
                            let arr = docsNotificar[x.id] ? docsNotificar[x.id] : [];
                            arr.push(tipoDoc.nombre);
                            docsNotificar = {
                                ...docsNotificar,
                                [x.id]: arr
                            }
                        }
                    } else {
                        if (
                            !hayEnProgreso &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado ==
                                'Aplica'
                        )
                            hayEnProgreso = true;
                        if (
                            !hayPorRevisar &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado ==
                                'Por revisar'
                        )
                            hayPorRevisar = true;
                    }
                });
            } else if (tipoDoc.categoria == 3) {
                dataProveedorFull.trabajadores.forEach((x, index) => {
                    if (filaDoc[index] !== null) {
                        docsEditar.push({
                            documento_id: x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].id,
                            revisado: filaDoc[index] === true ? 'Listo' : 'Aplica'
                        });
                        if (!hayEnProgreso && !filaDoc[index]) hayEnProgreso = true;
                        if (!filaDoc[index]) {
                            let arr = docsNotificarTrab[x.id] ? docsNotificarTrab[x.id] : [];
                            arr.push(tipoDoc.nombre);
                            docsNotificarTrab = {
                                ...docsNotificarTrab,
                                [x.id]: arr
                            }
                        }
                    } else {
                        if (
                            !hayEnProgreso &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado ==
                                'Aplica'
                        )
                            hayEnProgreso = true;
                        if (
                            !hayPorRevisar &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado ==
                                'Por revisar'
                        )
                            hayPorRevisar = true;
                    }
                });
            } else if (tipoDoc.categoria == 4) {
                dataProveedorFull.vehiculos.forEach((x, index) => {
                    if (filaDoc[index] !== null) {
                        docsEditar.push({
                            documento_id: x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].id,
                            revisado: filaDoc[index] === true ? 'Listo' : 'Aplica'
                        });
                        if (!hayEnProgreso && !filaDoc[index]) hayEnProgreso = true;
                        if (!filaDoc[index]) {
                            let arr = docsNotificarVeh[x.id] ? docsNotificarVeh[x.id] : [];
                            arr.push(tipoDoc.nombre);
                            docsNotificarVeh = {
                                ...docsNotificarVeh,
                                [x.id]: arr
                            }
                        }
                    } else {
                        if (
                            !hayEnProgreso &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado ==
                                'Aplica'
                        )
                            hayEnProgreso = true;
                        if (
                            !hayPorRevisar &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0] &&
                            x.documentos.filter(y => y.tipo_documento_proveedor_id === tipoDoc.id)[0].revisado ==
                                'Por revisar'
                        )
                            hayPorRevisar = true;
                    }
                });
            }
        });
        let updateProv = { estado_documental: 'En Progreso' };
        if (hayEnProgreso && hayPorRevisar) updateProv.estado_documental = 'En Progreso-En Revisión';
        else if (hayEnProgreso) updateProv.estado_documental = 'En Progreso';
        else if (hayPorRevisar) updateProv.estado_documental = 'En Revisión';
        else updateProv.estado_documental = 'Listo';
        if (dataProveedorFull.estado_documental != updateProv.estado_documental)
            dispatch(proveedoresDuck.editarProveedores(dataProveedorFull.id, updateProv));
        if (
            Object.keys(docsNotificar).length +
                Object.keys(docsNotificarTrab).length +
                Object.keys(docsNotificarVeh).length >
            0
        ) {
            crear('/notificarDocumentosVolverCargar', {
                suc: docsNotificar,
                trab: docsNotificarTrab,
                veh: docsNotificarVeh,
                usuario_accion: usuarioAutenticado.data.email
            });
        }
        
        console.log(" a actualizar: ", docsEditar);
        editarDocumentoAplica({ proveedor_id: dataProveedorFull.id, listaDocs: docsEditar }).then(() => {
            setModalConfig({
                open: true,
                confirmacion: false,
                accion: false,
                mensaje: 'Revisiones guardadas correctamente',
                loading: false,
                title: false,
                esDesactivar: false
            });
            refreshTable();
            handleModoRevision();
        }).catch(error => {
            console.log(error);
        });
    }

    const verArchivoDocumento = (archivo_id, nombre) => {
        var request =
            'https://www.googleapis.com/drive/v3/files/' +
            archivo_id +
            '?alt=media';

        var newTab = window.open(window.location.href + '/waiting', '_blank');
        downloadUrlAsPromise(request, nombre)
            .then(function(response) {
                // console.log('tipo de archivo: ', response.blob.type);
                var tipoGrupo = response.blob.type ? response.blob.type.split('/')[0] : '';
                if (tipoGrupo == 'image' || response.blob.type == 'application/pdf') {
                    var blobUrl = URL.createObjectURL(response.blob);
                    newTab.location = blobUrl;
                } else {
                    newTab.close();
                    saveAs(response.blob, nombre);
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    const handleCrearTrabajador = async (values) => {
        setModalTrabConfig({
            open: true,
            loading: true,
            editar: false,
            idTrab: null
        });
        
        values.email_creador = usuarioAutenticado.data.email;
        values.proveedor_id = dataProveedorFull.id;
        console.log("crear trabajador", values);
        await agregarTrabajador(values);
        setModalTrabConfig({
            open: false,
            loading: false,
            editar: false,
            idTrab: null
        });
        setModalConfig({
            open: true,
            confirmacion: false,
            accion: false,
            mensaje: 'Trabajador agregado',
            loading: false,
            title: false,
            esDesactivar: false
        });
    }

    const handleEditarTrabajador = async (values) => {
        setModalTrabConfig({
            open: true,
            loading: true,
            editar: false,
            idTrab: null
        });
        console.log("editar trabajador", modalTrabConfig.idTrab, values);
        await editarTrabajador(modalTrabConfig.idTrab, values);
        setModalTrabConfig({
            open: false,
            loading: false,
            editar: false,
            idTrab: null
        });
        setModalConfig({
            open: true,
            confirmacion: false,
            accion: false,
            mensaje: 'Trabajador editado',
            loading: false,
            title: false,
            esDesactivar: false
        });
    }

    const handleCrearVehiculo = async (values) => {
        setModalVehConfig({
            open: true,
            loading: true,
            editar: false,
            idVeh: null
        });
        
        values.email_creador = usuarioAutenticado.data.email;
        values.proveedor_id = dataProveedorFull.id;
        console.log("crear vehiculo", values);
        await agregarVehiculo(values);
        setModalVehConfig({
            open: false,
            loading: false,
            editar: false,
            idVeh: null
        });
        setModalConfig({
            open: true,
            confirmacion: false,
            accion: false,
            mensaje: 'Vehículo agregado',
            loading: false,
            title: false,
            esDesactivar: false
        });
    }

    const handleEditarVehiculo = async (values) => {
        setModalVehConfig({
            open: true,
            loading: true,
            editar: false,
            idVeh: null
        });
        console.log("editar vehiculo", modalVehConfig.idVeh, values);
        await editarVehiculo(modalVehConfig.idVeh, values);
        setModalVehConfig({
            open: false,
            loading: false,
            editar: false,
            idVeh: null
        });
        setModalConfig({
            open: true,
            confirmacion: false,
            accion: false,
            mensaje: 'Vehículo editado',
            loading: false,
            title: false,
            esDesactivar: false
        });
    }
    
    // console.log(dataProveedor);
    return (loading
        ? <Spinner />
        : <div style={{ padding: '8px', backgroundColor: '#f5f5f5' }}>
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <Card style={{ height: '100%' }}>
                        <CardContent style={{ padding: '0 5px' }}>
                            <Grid container justify="center" alignItems="center" spacing={4} style={{ marginTop: 20 }}>
                                <Grid item style={{ width: 100 }}>
                                    <Paper variant='outlined' style={{ height: 70 }}>
                                        <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                            <div style={{ position: 'absolute' }}>
                                                <img src={ImagenCarrito} style={{ width: 40, margin: 'auto', display: 'block' }} />
                                            </div>
                                        </Grid>
                                        <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                                            {cantidadOC}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p" className={classes.kpiTitle}>
                                            Cantidad OC
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs>
                                    <Paper variant='outlined' style={{ height: 70 }}>
                                        <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                            <div style={{ width: 45, position: 'absolute', backgroundColor: 'white' }}>
                                                <img src={ImagenDinero} style={{ width: 40, margin: 'auto', display: 'block' }} />
                                            </div>
                                        </Grid>
                                        <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                                            {'$ ' + totalCLP}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p" className={classes.kpiTitle}>
                                            Total $CLP
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item style={{ width: 100 }}>
                                    <Paper variant='outlined' style={{ height: 70 }}>
                                        <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                            <div style={{ width: 70, position: 'absolute', backgroundColor: 'white' }}>
                                                <img src={ImagenEval} style={{ width: 60, margin: 'auto', display: 'block' }} />
                                            </div>
                                        </Grid>
                                        <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                                            {evalGeneral + '%'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            className={classes.kpiTitle}>
                                            Evaluación
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item style={{ width: 110 }}>
                                    <FormFiltrosDashProv form={'formProv' + dataProveedorFull.id} />
                                </Grid>
                            </Grid>
                            <HighchartsReact highcharts={Highcharts} options={configChart1} />
                            <Grid container justify="center" alignItems="center" style={{ margin: '5px 0' }}>
                                <Grid item xs>
                                    <TableContainer component={Paper} variant="outlined">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={`${classes.cabeceraInformes} sepColumna`} style={{ width: 90 }}>Año</TableCell>
                                                    <TableCell className={`${classes.cabeceraInformes} sepColumna`}>
                                                        Informe <br />1<sup>er</sup> Semestre
                                                    </TableCell>
                                                    <TableCell className={`${classes.cabeceraInformes} sepColumna`}>
                                                        Informe <br />2<sup>do</sup> Semestre
                                                    </TableCell>
                                                    <TableCell className={`${classes.cabeceraInformes} sepColumna`}>
                                                        Informe Anual
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classes.celdaInformes}>
                                                        <SelectSearch
                                                            options={informesEvalProveedor.annios.map(x => ({
                                                                id: x.toString(),
                                                                nombre: x.toString()
                                                            }))}
                                                            input={{ name: 'filtroAnio', value: annioInformeSelected }}
                                                            meta={{}}
                                                            // multiple
                                                            // limitTags={1}
                                                            // charCountLimit={5}
                                                            // maxTagWidth="62px"
                                                            size="small"
                                                            type="text"
                                                            change={(name, value) => {
                                                                console.log(name, value);
                                                                setAnnioInformeSelected(value);
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className={classes.celdaInformes}>
                                                        {annioInformeSelected &&
                                                            informesEvalProveedor.data.filter(
                                                                x =>
                                                                    x.annio == annioInformeSelected &&
                                                                    x.tipo_informe == 1
                                                            )[0] && (
                                                                <Tooltip title="Ver Informe">
                                                                    <IconButton
                                                                        color="primary"
                                                                        size="small"
                                                                        // style={{ marginRight: 10 }}
                                                                        onClick={() =>
                                                                            verArchivoDocumento(
                                                                                informesEvalProveedor.data.filter(
                                                                                    x =>
                                                                                        x.annio ==
                                                                                            annioInformeSelected &&
                                                                                        x.tipo_informe == 1
                                                                                )[0].archivo_id,
                                                                                informesEvalProveedor.data.filter(
                                                                                    x =>
                                                                                        x.annio ==
                                                                                            annioInformeSelected &&
                                                                                        x.tipo_informe == 1
                                                                                )[0].codigo
                                                                            )
                                                                        }>
                                                                        <InsertDriveFileOutlinedIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                    </TableCell>
                                                    <TableCell className={classes.celdaInformes}>
                                                        {annioInformeSelected &&
                                                            informesEvalProveedor.data.filter(
                                                                x =>
                                                                    x.annio == annioInformeSelected &&
                                                                    x.tipo_informe == 2
                                                            )[0] && (
                                                                <Tooltip title="Ver Informe">
                                                                    <IconButton
                                                                        color="primary"
                                                                        size="small"
                                                                        // style={{ marginRight: 10 }}
                                                                        onClick={() =>
                                                                            verArchivoDocumento(
                                                                                informesEvalProveedor.data.filter(
                                                                                    x =>
                                                                                        x.annio ==
                                                                                            annioInformeSelected &&
                                                                                        x.tipo_informe == 2
                                                                                )[0].archivo_id,
                                                                                informesEvalProveedor.data.filter(
                                                                                    x =>
                                                                                        x.annio ==
                                                                                            annioInformeSelected &&
                                                                                        x.tipo_informe == 2
                                                                                )[0].codigo
                                                                            )
                                                                        }>
                                                                        <InsertDriveFileOutlinedIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                    </TableCell>
                                                    <TableCell className={classes.celdaInformes}>
                                                        {annioInformeSelected &&
                                                            informesEvalProveedor.data.filter(
                                                                x =>
                                                                    x.annio == annioInformeSelected &&
                                                                    x.tipo_informe == 3
                                                            )[0] && (
                                                                <Tooltip title="Ver Informe">
                                                                    <IconButton
                                                                        color="primary"
                                                                        size="small"
                                                                        // style={{ marginRight: 10 }}
                                                                        onClick={() =>
                                                                            verArchivoDocumento(
                                                                                informesEvalProveedor.data.filter(
                                                                                    x =>
                                                                                        x.annio ==
                                                                                            annioInformeSelected &&
                                                                                        x.tipo_informe == 3
                                                                                )[0].archivo_id,
                                                                                informesEvalProveedor.data.filter(
                                                                                    x =>
                                                                                        x.annio ==
                                                                                            annioInformeSelected &&
                                                                                        x.tipo_informe == 3
                                                                                )[0].codigo
                                                                            )
                                                                        }>
                                                                        <InsertDriveFileOutlinedIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item style={{ width: 120 }}>
                                    {/* <Button
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        style={{ display: 'block', width: 80 }}
                                        onClick={() =>
                                            setModalInfEvalProv({
                                                open: true
                                            })
                                        }>
                                        Nuevo Informe
                                    </Button> */}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={8}>
                    <Paper style={{ height: '100%' }}>
                        <Tabs
                            style={{ margin: 'auto' }}
                            value={tabValue}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            centered={true}
                            aria-label="scrollable auto tabs example">
                            <Tab style={{ outline: 'none' }} label="Antecedentes" {...a11yProps(0)} />
                            <Tab style={{ outline: 'none' }} label="Sucursales" {...a11yProps(1)} />
                            <Tab style={{ outline: 'none' }} label="Documentos Empresa" {...a11yProps(2)} />
                            <Tab style={{ outline: 'none' }} label="Trabajadores y Vehículos" {...a11yProps(3)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0} className={classes.tabContent}>
                            {permisosUsuario.some(x => x.nombre === 'bloquearProveedor') &&
                                dataProveedorFull.activo == 'Activo' && (
                                    <Tooltip title="Desactivar">
                                        <IconButton
                                            color="primary"
                                            style={{ float: 'right' }}
                                            onClick={() => {
                                                setModalConfig({
                                                    open: true,
                                                    confirmacion: true,
                                                    accion: motivo => handleActivar(true, motivo),
                                                    mensaje: '¿Está seguro de desactivar el proveedor?',
                                                    loading: false,
                                                    title: false,
                                                    esDesactivar: true
                                                });
                                            }}>
                                            <BlockIcon style={{ fontSize: '1.5rem' }} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            {permisosUsuario.some(x => x.nombre === 'bloquearProveedor') &&
                                dataProveedorFull.activo != 'Activo' && (
                                    <Tooltip title="Activar">
                                        <IconButton
                                            color="primary"
                                            style={{ float: 'right' }}
                                            onClick={() => {
                                                setModalConfig({
                                                    open: true,
                                                    confirmacion: true,
                                                    accion: motivo => handleActivar(false, motivo),
                                                    mensaje: '¿Está seguro de activar el proveedor?',
                                                    loading: false,
                                                    title: false,
                                                    esDesactivar: false
                                                });
                                            }}>
                                            <CheckCircleOutlineRoundedIcon style={{ fontSize: '1.5rem' }} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            {permisosUsuario.some(x => x.nombre === 'crearProveedor') && (
                                <Tooltip title="Editar">
                                    <IconButton
                                        color="primary"
                                        style={{ float: 'right' }}
                                        onClick={() => handleEditarProveedorModal(dataProveedorFull, refreshTable)}>
                                        <EditIcon style={{ fontSize: '1.5rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Grid container spacing={3} style={{ width: 700 }}>
                                <Grid item xs>
                                    <Typography variant="h5" gutterBottom>
                                        Antecedentes Generales
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <strong>País: </strong>
                                        {dataProveedorFull.pais?.nombre}
                                        <br />
                                        <strong>{esChile ? 'RUT: ' : 'ID: '}</strong>
                                        {dataProveedorFull.rut}
                                        <br />
                                        <strong>Razón Social: </strong>
                                        {dataProveedorFull.nombre}
                                        <br />
                                        <strong>Giro de la Empresa: </strong>
                                        {dataProveedorFull.giro}
                                        <br />
                                        <strong>Representante Legal: </strong>
                                        {dataProveedorFull.representante_legal}
                                        <br />
                                        <strong>{esChile ? 'RUT Representante Legal: ' : 'ID Representante Legal: '}</strong>
                                        {dataProveedorFull.representante_legal_rut}
                                        <br />
                                        <strong>Tipo de Recurso: </strong>
                                        {dataProveedorFull.tipo_recurso}
                                        <br />
                                        <strong>Clase: </strong>
                                        {dataProveedorFull.clase.replace(/,/g, ', ')}
                                        <br />
                                        <strong>Criticidad: </strong>
                                        {dataProveedorFull.criticidad}
                                        <br />
                                        <strong>Es Local: </strong>
                                        {dataProveedorFull.es_local ? 'Sí' : 'No'}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="h5" gutterBottom>
                                        Antecedentes Bancarios
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        <strong>Nombre Banco: </strong>
                                        {dataProveedorFull.banco_nombre}
                                        <br />
                                        <strong>Nº de Cuenta: </strong>
                                        {dataProveedorFull.banco_numero_cuenta}
                                        {esPeru &&
                                            <span>
                                                <br />
                                                <strong>Cuenta Interbancaria: </strong>
                                                {dataProveedorFull.datosBanco?.cuentaInterbancaria}
                                            </span>
                                        }
                                        <br />
                                        <strong>Tipo de Cuenta: </strong>
                                        {dataProveedorFull.banco_tipo_cuenta}
                                        <br />
                                        <strong>Titular Cuenta: </strong>
                                        {dataProveedorFull.banco_titular_cuenta}
                                        <br />
                                        <strong>{esChile ? 'RUT Cuenta Bancaria: ' : 'ID Cuenta Bancaria: '}</strong>
                                        {dataProveedorFull.banco_rut_cuenta}
                                        <br />
                                        <strong>Correo electrónico: </strong>
                                        {dataProveedorFull.banco_email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} className={classes.tabContent}>
                            {permisosUsuario.some(x => x.nombre === 'crearProveedor') && (
                                <Tooltip title="Editar">
                                    <IconButton
                                        color="primary"
                                        style={{ float: 'right' }}
                                        onClick={() =>
                                            handleEditarSucursalModal(
                                                dataProveedorFull,
                                                dataProveedorFull.sucursales[selectorSucursal].id,
                                                refreshTable
                                            )
                                        }>
                                        <EditIcon style={{ fontSize: '1.5rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <FormControl className={classes.selectorTabs}>
                                <InputLabel id="labelSucursal">Sucursal</InputLabel>
                                <Select
                                    labelId="labelSucursal"
                                    id="selectSucursal"
                                    value={selectorSucursal}
                                    onChange={event => setSelectorSucursal(event.target.value)}>
                                    {dataProveedorFull.sucursales.map((x, index) => (
                                        <MenuItem value={index} key={index}>
                                            {'Sucursal ' + (index + 1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        País
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].pais}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Región
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].region}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Ciudad
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].ciudad.nombre}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {esChile ? 'Comuna' : 'Distrito'}
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].comuna.nombre}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Dirección
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].direccion}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Teléfono
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].telefono}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Página Web
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].pagina_web}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Correo electrónico envío OC
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].email_envio_oc}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Nombre de Ejecutivo
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].contacto_nombre}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Correo electrónico de Ejecutivo
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].contacto_email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Teléfono de Ejecutivo
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].contacto_telefono}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Creado por
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {dataProveedorFull.sucursales[selectorSucursal].email_creador}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2} className={classes.tabContent}>
                            {permisosUsuario.some(x => x.nombre === 'revisarDocsProveedor') && (
                                <Tooltip title="Revisión">
                                    <IconButton color="primary" style={{ float: 'right' }} onClick={handleModoRevision}>
                                        <DoneAllIcon style={{ fontSize: '1.5rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {permisosUsuario.some(x => x.nombre === 'subirDocsProveedor') && (
                                <Tooltip title="Subir Archivos">
                                    <IconButton color="primary" style={{ float: 'right' }} onClick={handleModoUpload}>
                                        <PublishRoundedIcon style={{ fontSize: '1.5rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {permisosUsuario.some(x => x.nombre === 'definirDocsProveedor') && (
                                <Tooltip title="Aplica / No Aplica">
                                    <IconButton color="primary" style={{ float: 'right' }} onClick={handleModoAplica}>
                                        <CheckCircleIcon style={{ fontSize: '1.5rem' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {/* <div style={{ float: 'right' }}> */}
                            <Badge
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}
                                variant="dot"
                                color="#f44336"
                                indicador="indicador"
                                style={{ float: 'right', margin: '15px 12px 0' }}>
                                <Typography variant="body2" component="p">
                                    Volver a subir
                                </Typography>
                            </Badge>
                            <Badge
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}
                                variant="dot"
                                color="#ffc107"
                                indicador="indicador"
                                style={{ float: 'right', margin: '15px 12px 0' }}>
                                <Typography variant="body2" component="p">
                                    Por Revisar
                                </Typography>
                            </Badge>
                            <Badge
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}
                                variant="dot"
                                color="#44b700"
                                indicador="indicador"
                                style={{ float: 'right', margin: '15px 12px 0' }}>
                                <Typography variant="body2" component="p">
                                    Listo
                                </Typography>
                            </Badge>
                            {/* </div> */}
                            {(docsCategoria[1] > 0 || modoAplica) && (
                                <Typography variant="h5" gutterBottom>
                                    Antecedentes Generales
                                </Typography>
                            )}
                            {(docsCategoria[1] > 0 || modoAplica) && (
                                <TableContainer component={Paper} className={classes.tablaDocs}>
                                    <Table size="small" style={{ width: 'auto' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.docName}></TableCell>
                                                {dataProveedorFull.sucursales.map((x, index) => (
                                                    <TableCell
                                                        align="center"
                                                        key={index}
                                                        className={classes.sucursalDocActions}>
                                                        {'Sucursal ' + (index + 1)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tipoDocumentoProveedor.map((doc, indexDoc) => {
                                                let cant = 0;
                                                dataProveedorFull.sucursales.forEach(x => {
                                                    cant += x.documentos.filter(
                                                        y =>
                                                            y.tipo_documento_proveedor_id === doc.id &&
                                                            y.revisado != 'No aplica'
                                                    ).length;
                                                });
                                                if (doc.categoria === 1 && (modoAplica || cant > 0)) {
                                                    return (
                                                        <TableRow key={indexDoc}>
                                                            <TableCell className={classes.docName}>
                                                                {doc.nombre}
                                                            </TableCell>
                                                            {dataProveedorFull.sucursales.map((x, index) =>
                                                                contenidoCeldaDocs(doc, x, indexDoc, index)
                                                            )}
                                                        </TableRow>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                            {(docsCategoria[2] > 0 || modoAplica) && (
                                <Typography variant="h5" gutterBottom>
                                    Antecedentes Empresa
                                </Typography>
                            )}
                            {(docsCategoria[2] > 0 || modoAplica) && (
                                <TableContainer component={Paper} className={classes.tablaDocs}>
                                    <Table size="small" style={{ width: 'auto' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                {dataProveedorFull.sucursales.map((x, index) => (
                                                    <TableCell
                                                        align="center"
                                                        key={index}
                                                        className={classes.sucursalDocActions}>
                                                        {'Sucursal ' + (index + 1)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tipoDocumentoProveedor.map((doc, indexDoc) => {
                                                let cant = 0;
                                                dataProveedorFull.sucursales.forEach(x => {
                                                    cant += x.documentos.filter(
                                                        y =>
                                                            y.tipo_documento_proveedor_id === doc.id &&
                                                            y.revisado != 'No aplica'
                                                    ).length;
                                                });
                                                if (doc.categoria === 2 && (modoAplica || cant > 0)) {
                                                    return (
                                                        <TableRow key={indexDoc}>
                                                            <TableCell className={classes.docName}>
                                                                {doc.nombre}
                                                            </TableCell>
                                                            {dataProveedorFull.sucursales.map((x, index) =>
                                                                contenidoCeldaDocs(doc, x, indexDoc, index)
                                                            )}
                                                        </TableRow>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                            {(docsCategoria[3] > 0 || modoAplica) && (
                                <Typography variant="h5" gutterBottom>
                                    Antecedentes Trabajador
                                </Typography>
                            )}
                            {(docsCategoria[3] > 0 || modoAplica) && (
                                <TableContainer component={Paper} className={classes.tablaDocs}>
                                    <Table size="small" style={{ width: 'auto' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                {dataProveedorFull.trabajadores.map((x, index) => (
                                                    <TableCell
                                                        align="center"
                                                        key={index}
                                                        className={classes.sucursalDocActions}>
                                                        {'Trabajador ' + (index + 1)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tipoDocumentoProveedor.map((doc, indexDoc) => {
                                                let cant = 0;
                                                dataProveedorFull.trabajadores.forEach(x => {
                                                    cant += x.documentos.filter(
                                                        y =>
                                                            y.tipo_documento_proveedor_id === doc.id &&
                                                            y.revisado != 'No aplica'
                                                    ).length;
                                                });
                                                if (doc.categoria === 3 && (modoAplica || cant > 0)) {
                                                    return (
                                                        <TableRow key={indexDoc}>
                                                            <TableCell className={classes.docName}>
                                                                {doc.nombre}
                                                            </TableCell>
                                                            {dataProveedorFull.trabajadores.map((x, index) =>
                                                                contenidoCeldaDocs(doc, x, indexDoc, index)
                                                            )}
                                                        </TableRow>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                            {(docsCategoria[4] > 0 || modoAplica) && (
                                <Typography variant="h5" gutterBottom>
                                    Antecedentes Vehículos de Proveedor
                                </Typography>
                            )}
                            {(docsCategoria[4] > 0 || modoAplica) && (
                                <TableContainer component={Paper} className={classes.tablaDocs}>
                                    <Table size="small" style={{ width: 'auto' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                {dataProveedorFull.vehiculos.map((x, index) => (
                                                    <TableCell
                                                        align="center"
                                                        key={index}
                                                        className={classes.sucursalDocActions}>
                                                        {'Vehículo ' + (index + 1)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tipoDocumentoProveedor.map((doc, indexDoc) => {
                                                let cant = 0;
                                                dataProveedorFull.vehiculos.forEach(x => {
                                                    cant += x.documentos.filter(
                                                        y =>
                                                            y.tipo_documento_proveedor_id === doc.id &&
                                                            y.revisado != 'No aplica'
                                                    ).length;
                                                });
                                                if (doc.categoria === 4 && (modoAplica || cant > 0)) {
                                                    return (
                                                        <TableRow key={indexDoc}>
                                                            <TableCell className={classes.docName}>
                                                                {doc.nombre}
                                                            </TableCell>
                                                            {dataProveedorFull.vehiculos.map((x, index) =>
                                                                contenidoCeldaDocs(doc, x, indexDoc, index)
                                                            )}
                                                        </TableRow>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                            {(docsCategoria[5] > 0 || modoAplica) && (
                                <Typography variant="h5" gutterBottom>
                                    Antecedentes Legales y Financieros
                                </Typography>
                            )}
                            {(docsCategoria[5] > 0 || modoAplica) && (
                                <TableContainer component={Paper} className={classes.tablaDocs}>
                                    <Table size="small" style={{ width: 'auto' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                {dataProveedorFull.sucursales.map((x, index) => (
                                                    <TableCell
                                                        align="center"
                                                        key={index}
                                                        className={classes.sucursalDocActions}>
                                                        {'Sucursal ' + (index + 1)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tipoDocumentoProveedor.map((doc, indexDoc) => {
                                                let cant = 0;
                                                dataProveedorFull.sucursales.forEach(x => {
                                                    cant += x.documentos.filter(
                                                        y =>
                                                            y.tipo_documento_proveedor_id === doc.id &&
                                                            y.revisado != 'No aplica'
                                                    ).length;
                                                });
                                                if (doc.categoria === 5 && (modoAplica || cant > 0)) {
                                                    return (
                                                        <TableRow key={indexDoc}>
                                                            <TableCell className={classes.docName}>
                                                                {doc.nombre}
                                                            </TableCell>
                                                            {dataProveedorFull.sucursales.map((x, index) =>
                                                                contenidoCeldaDocs(doc, x, indexDoc, index)
                                                            )}
                                                        </TableRow>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                            {modoAplica && (
                                <Grid container style={{ marginTop: '15px' }} justify="center" alignItems="center">
                                    <Button variant="outlined" color="primary" onClick={handleGuardarCambiosAplica}>
                                        {'Guardar Cambios'}
                                    </Button>
                                </Grid>
                            )}
                            {modoUpload && docsCategoria.reduce((a, b) => a + b, 0) > 0 && (
                                <Grid container style={{ marginTop: '15px' }} justify="center" alignItems="center">
                                    <Button variant="outlined" color="primary" onClick={handleSubirArchivos}>
                                        {'Subir Archivos'}
                                    </Button>
                                </Grid>
                            )}
                            {modoRevision && docsCategoria.reduce((a, b) => a + b, 0) > 0 && (
                                <Grid container style={{ marginTop: '15px' }} justify="center" alignItems="center">
                                    <Button variant="outlined" color="primary" onClick={handleGuardarRevisiones}>
                                        {'Guardar Revisiones'}
                                    </Button>
                                </Grid>
                            )}
                        </TabPanel>
                        <TabPanel value={tabValue} index={3} className={classes.tabContent}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid container spacing={3}>
                                        <Grid item style={{ width: 170 }}>
                                            <FormControl className={classes.selectorTabs}>
                                                <InputLabel id="labelTrabajador">Trabajador</InputLabel>
                                                <Select
                                                    labelId="labelTrabajador"
                                                    id="selectTrabajador"
                                                    value={selectorTrabajador}
                                                    onChange={event => setSelectorTrabajador(event.target.value)}>
                                                    {dataProveedorFull.trabajadores.map((x, index) => (
                                                        <MenuItem value={index} key={index}>
                                                            {'Trabajador ' + (index + 1)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            {permisosUsuario.some(x => x.nombre === 'crearProveedor') && (
                                                <Tooltip title="Agregar">
                                                    <IconButton
                                                        color="primary"
                                                        // style={{ float: 'right' }}
                                                        onClick={() =>
                                                            setModalTrabConfig({
                                                                open: true,
                                                                loading: false,
                                                                editar: false,
                                                                idTrab: null
                                                            })
                                                        }>
                                                        <AddRoundedIcon style={{ fontSize: '1.5rem' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {permisosUsuario.some(x => x.nombre === 'crearProveedor') &&
                                                dataProveedorFull.trabajadores[selectorTrabajador] && (
                                                    <Tooltip title="Editar">
                                                        <IconButton
                                                            color="primary"
                                                            // style={{ float: 'right' }}
                                                            onClick={() =>
                                                                setModalTrabConfig({
                                                                    open: true,
                                                                    loading: false,
                                                                    editar: true,
                                                                    idTrab: dataProveedorFull.trabajadores[
                                                                        selectorTrabajador
                                                                    ].id
                                                                })
                                                            }>
                                                            <EditIcon style={{ fontSize: '1.5rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                        </Grid>
                                    </Grid>
                                    {dataProveedorFull.trabajadores[selectorTrabajador] && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    {esChile ? 'RUT' : 'ID'}
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    component="h2"
                                                    className={classes.textoInterior}>
                                                    {dataProveedorFull.trabajadores[selectorTrabajador].rut}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {dataProveedorFull.trabajadores[selectorTrabajador] && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    Nombres
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    component="h2"
                                                    className={classes.textoInterior}>
                                                    {dataProveedorFull.trabajadores[selectorTrabajador].nombres}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    Apellidos
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    component="h2"
                                                    className={classes.textoInterior}>
                                                    {dataProveedorFull.trabajadores[selectorTrabajador].apellidos}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={3}>
                                        <Grid item style={{ width: 170 }}>
                                            <FormControl className={classes.selectorTabs}>
                                                <InputLabel id="labelVehiculo">Vehículo</InputLabel>
                                                <Select
                                                    labelId="labelVehiculo"
                                                    id="selectVehiculo"
                                                    value={selectorVehiculo}
                                                    onChange={event => setSelectorVehiculo(event.target.value)}>
                                                    {dataProveedorFull.vehiculos.map((x, index) => (
                                                        <MenuItem value={index} key={index}>
                                                            {'Vehículo ' + (index + 1)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            {permisosUsuario.some(x => x.nombre === 'crearProveedor') && (
                                                <Tooltip title="Agregar">
                                                    <IconButton
                                                        color="primary"
                                                        // style={{ float: 'right' }}
                                                        onClick={() =>
                                                            setModalVehConfig({
                                                                open: true,
                                                                loading: false,
                                                                editar: false,
                                                                idVeh: null
                                                            })
                                                        }>
                                                        <AddRoundedIcon style={{ fontSize: '1.5rem' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {permisosUsuario.some(x => x.nombre === 'crearProveedor') &&
                                                dataProveedorFull.vehiculos[selectorVehiculo] && (
                                                    <Tooltip title="Editar">
                                                        <IconButton
                                                            color="primary"
                                                            // style={{ float: 'right' }}
                                                            onClick={() =>
                                                                setModalVehConfig({
                                                                    open: true,
                                                                    loading: false,
                                                                    editar: true,
                                                                    idVeh: dataProveedorFull.vehiculos[
                                                                        selectorVehiculo
                                                                    ].id
                                                                })
                                                            }>
                                                            <EditIcon style={{ fontSize: '1.5rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                        </Grid>
                                    </Grid>
                                    {dataProveedorFull.vehiculos[selectorVehiculo] && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    Patente
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    component="h2"
                                                    className={classes.textoInterior}>
                                                    {dataProveedorFull.vehiculos[selectorVehiculo].patente}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </Paper>
                </Grid>
            </Grid>
            <Modal
                open={modalConfig.open}
                handleClose={() => {
                    setMotivoBloqueoError(false);
                    setMotivoBloqueo('');
                    setModalConfig({
                        open: false,
                        confirmacion: false,
                        accion: false,
                        mensaje: '',
                        loading: false,
                        title: false,
                        esDesactivar: false
                    });
                }}
                confirmacion={modalConfig.confirmacion}
                accion={modalConfig.accion !== false ? () => modalConfig.accion(motivoBloqueo) : false}
                loading={modalConfig.loading}
                showTitle={modalConfig.title ? true : false}
                title={modalConfig.title}
                maxWidth="xs"
                fullWidth={true}
                disableBackClick={true}
                disableEscapeKey={true}>
                {!modalConfig.loading && modalConfig.mensaje == '' ? (
                    <div style={{ width: '100%' }}>
                        <LinearProgress variant="determinate" value={progressValue} />
                    </div>
                ) : modalConfig.esDesactivar ? (
                    <form>
                        <p style={{ marginBottom: 0 }}>{modalConfig.mensaje + ' Ingrese el motivo.'}</p>
                        <TextField
                            style={{ width: '100%' }}
                            error={motivoBloqueoError}
                            label="Motivo *"
                            onChange={e => setMotivoBloqueo(e.target.value)}
                        />
                    </form>
                ) : (
                    modalConfig.mensaje
                )}
            </Modal>
            <Modal
                open={modalTrabConfig.open}
                handleClose={() => {
                    setModalTrabConfig({
                        open: false,
                        loading: false,
                        editar: false,
                        idTrab: null
                    });
                }}
                loading={modalTrabConfig.loading}
                fullWidth={true}
                maxWidth="md"
                showTitle={false}>
                <FormTrabajador
                    editar={modalTrabConfig.editar}
                    dataTrabajador={
                        modalTrabConfig.editar
                            ? dataProveedorFull.trabajadores.find(x => x.id === modalTrabConfig.idTrab)
                            : null
                    }
                    onSubmit={modalTrabConfig.editar ? handleEditarTrabajador : handleCrearTrabajador}
                />
            </Modal>
            <Modal
                open={modalVehConfig.open}
                handleClose={() => {
                    setModalVehConfig({
                        open: false,
                        loading: false,
                        editar: false,
                        idVeh: null
                    });
                }}
                loading={modalVehConfig.loading}
                fullWidth={true}
                maxWidth="md"
                showTitle={false}>
                <FormVehiculo
                    editar={modalVehConfig.editar}
                    dataVehiculo={
                        modalVehConfig.editar
                            ? dataProveedorFull.vehiculos.find(x => x.id === modalVehConfig.idVeh)
                            : null
                    }
                    onSubmit={modalVehConfig.editar ? handleEditarVehiculo : handleCrearVehiculo}
                />
            </Modal>
            <Modal
                open={modalInfEvalProv.open}
                handleClose={() => {
                    setModalInfEvalProv({ open: false });
                }}
                fullWidth={true}
                style={{ minWidth: 1100, maxWidth: 1100 }}
                showTitle={false}
                showActions={false}>
                <InformeEvalProveedor
                    dataProveedor={dataProveedorFull}
                    defDesde={formFiltros?.values?.desde}
                    defHasta={formFiltros?.values?.hasta}
                    defCantidadOC={cantidadOC}
                    defTotalCLP={totalCLP}
                    defEvalGeneral={evalGeneral}
                    cotizacionesProv={dataProveedorFull.cotizaciones}
                    informesEvalProveedor={informesEvalProveedor}
                    getInformesEvalProveedor={getInformesEvalProveedor}
                />
            </Modal>
        </div>
    );
}
