import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, addFetch } from './hors.js'
import { leer, crear } from '../services/api'
import { closeModal } from './Modales'
import { reset } from 'redux-form'

const t = makeTypes('comentarios')
const FETCH = t('fetch').async()
const ADD = t('add').async()

const fetchActions = asyncMac(FETCH)
const addActions = asyncMac(ADD)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const addReducer = createReducer(initialState, addFetch( ADD ))

export default reduceReducers(fetchReducer, addReducer)

export const fetchComentarios = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer('/comentario')
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const fetchComentariosSolicitud = id => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer(`/solicitud-recurso/${id}/comentario`)
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const agregarComentarios = value => async (dispatch, getState) => {
    dispatch(addActions.start())
    const estado = getState()
    try {
        const data = await crear(`/comentario`, value)
        estado.Modales.data.map( x => dispatch(closeModal(x.id)))
        dispatch(addActions.success(data))
        dispatch(reset('comentario'))
    } catch (error) {
        dispatch(addActions.error(error))

    }
}