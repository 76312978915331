import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Grid, Box, Typography, Container, Button} from '@material-ui/core'
import Table from '../components/Table'
import Modal from '../components/Modal'
import BotonEditar from '../components/BotonEditar'
import FormSubClases from '../components/FormSubClases'
import * as sublclasesDuck from '../ducks/Subclases'
import * as mensajesDuck from '../ducks/Mensajes'
import { leer } from '../services/api';

class Proveedores extends React.Component{

    state= {
        open: false,
        openForm: false,
        confirmacion: false,
        accion: false,
        clases: [],
        subClases: [],
        subClaseSelectedId: null,
    }

    componentDidMount() {
        this.recargarDatos();
    }

    recargarDatos = () => {
        Promise.all([leer('/sub-clase'), leer('/clase')]).then(responses => {
            this.setState({ subClases: responses[0], clases: responses[1] });
        });
    }

    handleClose = () => {
        const { removeError } = this.props
        removeError()
        this.setState({ ...this.state, open: false, confirmacion: false, accion: false})
    }

    handleCloseForm = () => {
        this.setState({ ...this.state, openForm: false, confirmacion: false, accion: false, subClaseSelectedId: null})
    }

    handleEditarModal = (id, accion) => async e => {
        const { assignMensaje } = this.props
        switch (accion) {
            case "activar":
                assignMensaje(`¿Está seguro desea activar el registro?`)
                this.setState({ ...this.state, open: true, confirmacion: true, accion: this.handleActivar(id)})
                break;
            case "desactivar":
                assignMensaje(`¿Está seguro desea desactivar el registro?`)
                this.setState({ ...this.state, open: true, confirmacion: true, accion: this.handleActivar(id)})
                break;
            case "editar":
                this.setState({ ...this.state, openForm: true, confirmacion: false, accion: false, subClaseSelectedId: id})
                break;
            default:
                break;
        }
    }

    handleActivar = id => async e => {
        const { editarSubClases, assignMensaje } = this.props
        let subClase = this.state.subClases.filter( x => x.id === id)[0]
        await editarSubClases(id, {...subClase, activo: !subClase.activo})
        assignMensaje("Registro editado")
        this.recargarDatos();
        this.setState({ ...this.state, open: true, confirmacion: false, accion: false})            
    }

    handleEditar = async values => {
        const { editarSubClases, assignMensaje } = this.props
        let subClaseEditado = this.state.subClases.filter(x => x.id === this.state.subClaseSelectedId )
        let subClasesRestantes = this.state.subClases.filter( x => x.id !== this.state.subClaseSelectedId )
        if(subClasesRestantes.some(x => (x.nombre === values.nombre ))){
          assignMensaje("Esta sub-clase se encuentra en la lista")
          this.setState({ ...this.state, open: true, confirmacion: false, accion: false})            
        }else{
          await editarSubClases(subClaseEditado[0].id, {...subClaseEditado, ...values})
          assignMensaje("Registro editado")
          this.recargarDatos();
          this.setState({ ...this.state, openForm: false, open: true, confirmacion: false, accion: false, subClaseSelectedId: null})            
        }
    }

    handleAgregarRegistroModal = e => {
        this.setState({ ...this.state, openForm: true, confirmacion: false, accion: false})
    }

    handleAgregarRegistro = async values => {
        const { agregarSubClase, assignMensaje } = this.props
        if(this.state.subClases.some(x => ( x.nombre === values.nombre ))){
            assignMensaje("Esta sub-clase ya existe")
            this.setState({ ...this.state, open: true, confirmacion: false, accion: false})            
        }else{
            await agregarSubClase(values)
            assignMensaje("Registro agregado")
            this.recargarDatos();
            this.setState({ ...this.state, openForm: false, open: true, confirmacion: false, accion: false})            
        }
    }

    render(){
        const { mensaje } = this.props
        return(
            <Box>
                <Grid container justify="center" alignItems="center">
                    <Typography style={{ marginBottom: '30px' }} variant="h4">Sub-Clases</Typography>
                </Grid>
                <Grid container justify="center" alignItems="center">
                    <Button color="primary" variant="outlined" onClick={this.handleAgregarRegistroModal}>Agregar Sub-Clase</Button>
                </Grid>
                <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} lg={12}>
                            <Table
                                searchID="mainSearch"
                                agregar={true}
                                agregarRegistro={this.handleAgregarRegistroModal}
                                columnas={[
                                    { title: 'Nombre', field: 'nombre', 
                                      cellStyle: {
                                        textAlign:"center"
                                     },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                    { title: 'Pertenece a Clase', field: 'clase',cellStyle: {
                                        textAlign:"center"
                                      },headerStyle: {
                                        
                                        textAlign: "center",
                                      }},
                                    { title: 'Descripción', field: 'descripcion',cellStyle: {
                                        textAlign:"center"
                                      },headerStyle: {
                                        
                                        textAlign: "center",
                                      }},
                                    { title: 'Estado', field: 'activo', 
                                      cellStyle: {
                                        textAlign:"center"
                                     },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                    { title: 'Acción', field: 'accion', sorting: false, 
                                    cellStyle: {
                                        textAlign:"center",
                                    },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                ]}
                                data={this.state.subClases.map(x => ({
                                    ...x,
                                    clase: this.state.clases.filter(y => y.id === x.clase)[0] ? this.state.clases.filter(y => y.id === x.clase)[0].nombre : "",
                                    activo: x.activo ? "Activo" : "Inactivo",
                                    accion: <BotonEditar  handleEditar={this.handleEditarModal} handleActivar={this.handleEditarModal} id={x.id} activo={x.activo ? true: false} />                                    
                                }))} 
                            />
                        </Grid>
                </Grid>

                <Modal 
                    open={this.state.open}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}
                >
                    {mensaje.data}
                </Modal>
                
                <Modal 
                    open={this.state.openForm}
                    handleClose={this.handleCloseForm}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}
                    fullWidth={true}
                    maxWidth="md"
                    title="Formulario Sub-Clases"
                >
                    <Container>
                        <FormSubClases
                            editar={this.state.subClaseSelectedId ? true : false}
                            subClaseSelected={this.state.subClaseSelectedId ? this.state.subClases.find(x => x.id === this.state.subClaseSelectedId) : null}
                            clases={this.state.clases}
                            onSubmit={this.state.subClaseSelectedId ? this.handleEditar : this.handleAgregarRegistro}
                        />
                    </Container>
                </Modal>

            </Box>
        )
    }
}

const mapStateToProps = state => {
    return {
        mensaje: state.Mensajes,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
      ...sublclasesDuck,
      ...mensajesDuck,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Proveedores)