import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Grid, Container, Typography, Card, CardContent } from '@material-ui/core';
import SelectSearch from '../components/SelectSearch';
import KeyboardDatePicker from '../components/KeyboardDatePicker';
import { compareObjectString } from '../services/utils';

let FormFiltrosDashboard = props => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    gerencias,
    proyectos,
    centros,
    codigosSol,
    estados,
    tiposRecursos,
    formSolicitudHeaders,
    change,
    touch
  } = props;
  return (
    <form id="formFiltrosDashboard" noValidate>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={5} style={{ margin: '0 5px' }}>
            {/* <Grid item xs={1}>
          <Typography variant="body2" color="textSecondary" component="p">
            Días de Aprobación
          </Typography>
          <Typography gutterBottom variant="h6" component="h2">

          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body2" color="textSecondary" component="p">
            Días de atraso
          </Typography>
          <Typography gutterBottom variant="h6" component="h2">
            gg
          </Typography>
        </Grid> */}
            <Grid item>
              <Grid container justify="center" alignItems="center">
                <Field
                  size="small"
                  style={{ width: '157px' }}
                  type="text"
                  change={change}
                  touch={touch}
                  name="solicitud"
                  label="Solicitud"
                  options={codigosSol ? codigosSol.sort(compareObjectString('codigo')) : []}
                  component={SelectSearch}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justify="center" alignItems="center">
                <Field
                  multiple
                  limitTags={1}
                  // maxTagWidth="70px"
                  size="small"
                  style={{ width: '190px' }}
                  type="text"
                  change={change}
                  touch={touch}
                  name="centro"
                  label="Centro"
                  // charCountLimit={5}
                  options={centros ? centros.filter(x => x.id != 4).sort(compareObjectString('nombre')) : []}
                  component={SelectSearch}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justify="center" alignItems="center">
                <Field
                  multiple
                  limitTags={1}
                  maxTagWidth="60px"
                  size="small"
                  style={{ width: '149px' }}
                  type="text"
                  change={change}
                  touch={touch}
                  name="gerencia"
                  label="Gerencia"
                  options={
                    gerencias
                      ? gerencias.sort(compareObjectString('codigo')).map(x => ({ ...x, nombre: x.codigo }))
                      : []
                  }
                  component={SelectSearch}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justify="center" alignItems="center">
                <Field
                  multiple
                  limitTags={1}
                  maxTagWidth="215px"
                  size="small"
                  style={{ width: '310px' }}
                  type="text"
                  change={change}
                  touch={touch}
                  name="proyecto"
                  label="Proyecto"
                  charCountLimit={20}
                  options={proyectos ? proyectos.sort(compareObjectString('codigo')) : []}
                  component={SelectSearch}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={5} style={{ margin: '0 5px' }}>
            <Grid item>
              <Grid container justify="center" alignItems="center">
                <Field
                  multiple
                  limitTags={1}
                  size="small"
                  style={{ width: '253px' }}
                  type="text"
                  change={change}
                  touch={touch}
                  name="tipoRecurso"
                  label="Tipo Recurso"
                  // charCountLimit={11}
                  options={tiposRecursos ? tiposRecursos : []}
                  component={SelectSearch}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justify="center" alignItems="center">
                <Field
                  multiple
                  limitTags={1}
                  size="small"
                  style={{ width: '307px' }}
                  type="text"
                  change={change}
                  touch={touch}
                  name="estado"
                  label="Estado"
                  // charCountLimit={9}
                  options={estados ? estados : []}
                  component={SelectSearch}
                />
              </Grid>
            </Grid>

            <Grid item>
              <Grid container justify="center" alignItems="center">
                <Field
                  size="small"
                  style={{ width: '123px' }}
                  name="desde"
                  label="Desde"
                  change={change}
                  component={KeyboardDatePicker}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justify="center" alignItems="center">
                <Field
                  size="small"
                  style={{ width: '123px' }}
                  name="hasta"
                  label="Hasta"
                  change={change}
                  component={KeyboardDatePicker}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

FormFiltrosDashboard = reduxForm({
  form: 'filtrosDashboard'
})(FormFiltrosDashboard);

export default FormFiltrosDashboard;
