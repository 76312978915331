import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from './TextField';
import { Grid, Dialog, Button, DialogTitle, DialogContent, DialogActions, Slide } from '@material-ui/core';
import FieldTextField from './FieldTextField';
import Spinner from './Spinner';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

const validate = (values = {}) => {
    const errors = {};
    const requiredFields = ['cantidad'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    if (values.cantidad > values.max) {
        errors.cantidad = `No puede ser mayor que ${values.max}`;
    }
    if (values.cantidad < 1) {
        errors.cantidad = `No puede ser menor que 0`;
    }
    return errors;
};

const numero = value => (isNaN(value) === false ? (isNaN(parseInt(value)) === true ? '' : parseInt(value)) : value);

let FormListoParaEntrega = props => {
    const { handleSubmit, handleClose, open, listoEntrega, max, loading } = props;
    return (
        <Dialog
            open={open}
            maxWidth="xl"
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="alert-dialog-slide-title">Listos para entregar</DialogTitle>
            <DialogContent>
                {loading ? (
                    <div style={{ marginBottom: '20px' }}><Spinner /></div>
                ) : (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container justify="center" alignItems="center">
                            Se marcarán los recursos seleccionados como "Listos para entregar".
                            {/* <Grid item xs={12} lg={6}>
                                <Field
                                    label="Items Listos"
                                    name="listosEntrega"
                                    component={FieldTextField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        disabled: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Field
                                    required
                                    type="number"
                                    name="cantidad"
                                    label="Cantidad"
                                    component={FieldTextField}
                                    normalize={numero}
                                />
                            </Grid> */}
                        </Grid>
                        <br />
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Cancelar
                            </Button>
                            <Button type="submit" color="primary">
                                Aceptar
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
};

FormListoParaEntrega = reduxForm({
    form: 'listoEntrega'
})(FormListoParaEntrega);

export default connect((state, props) => ({
    // initialValues: {
    //     cantidad: 1,
    //     listosEntrega: props.listoEntrega,
    //     max: props.max
    // },
    enableReinitialize: true,
    validate
}))(FormListoParaEntrega);
