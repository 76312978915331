import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Spinner from '../components/Spinner'
import * as modalesDuck from '../ducks/Modales'

class Modal extends Component {



    render() {
        const { closeModal, modales, loading } = this.props
        return (
            modales.data.map(x => 
                <div key={x.id}>
                    <Dialog
                        fullScreen={x.fullScreen}
                        fullWidth={x.fullWidth}
                        maxWidth='md'
                        open={true} 
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{x.title}</DialogTitle>
                        <DialogContent>
                            {x.body}
                        </DialogContent>
                        <DialogActions>
                            {x.close &&
                            <Button onClick={ loading ? () => {} : () => closeModal(x.id)} color="primary">
                                Cerrar
                            </Button>
                            }
                            {x.action && (loading ? <Spinner /> :
                            <Button onClick={x.action} color="primary">
                                Aceptar
                            </Button>)
                            }
                        </DialogActions>
                    </Dialog>
                </div>
            )
        );
    }
}

const mapStateToProps = state => {
    return {
        modales: state.Modales
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    ...modalesDuck,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
