import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Grid, Box, Container, Typography, Button} from '@material-ui/core'
import Table from '../components/Table'
import Modal from '../components/Modal'
import BotonEditar from '../components/BotonEditar'
import FormColores from '../components/FormColores'
import Spinner from '../components/Spinner'
import * as coloresDuck from '../ducks/Colores'
import * as mensajesDuck from '../ducks/Mensajes'
import * as ciudadesDuck from '../ducks/Ciudades'
import * as comunasDuck from '../ducks/Comunas'

class Proveedores extends React.Component{

    state= {
        open: false,
        openForm: false,
        confirmacion: false,
        accion: false,
    }

    handleClose = () => {
        const { removeError } = this.props
        removeError()
        this.setState({ ...this.state, open: false, confirmacion: false, accion: false})
    }

    handleCloseForm = () => {
        const { selectColor } = this.props
        selectColor(null)
        this.setState({ ...this.state, openForm: false, confirmacion: false, accion: false})
    }

    handleEditarModal = (id, accion) => async e => {
        const { assignMensaje, selectColor } = this.props
        switch (accion) {
            case "activar":
                assignMensaje(`¿Está seguro desea activar el registro?`)
                this.setState({ ...this.state, open: true, confirmacion: true, accion: this.handleActivar(id)})
                break;
            case "desactivar":
                assignMensaje(`¿Está seguro desea desactivar el registro?`)
                this.setState({ ...this.state, open: true, confirmacion: true, accion: this.handleActivar(id)})
                break;
            case "editar":
                selectColor(id)
                this.setState({ ...this.state, openForm: true, confirmacion: false, accion: false})
                break;
            default:
                break;
        }
    }

    handleActivar = id => async e => {
        const { editarColores, colores, assignMensaje } = this.props
        let color = colores.data.filter( x => x.id === id)[0]
        await editarColores(id, {...color, activo: !color.activo})
        assignMensaje("Registro editado")
        this.setState({ ...this.state, open: true, confirmacion: false, accion: false})            
    }

    handleEditar = async values => {
        const { colores, editarColores, assignMensaje, selectColor } = this.props
        let colorEditado = colores.data.filter(x => x.selected )
        let coloresRestantes = colores.data.filter( x => !x.selected )
        if(coloresRestantes.some(x => (x.nombre === values.nombre ))){
          assignMensaje("Este color se encuentra en la lista")
          this.setState({ ...this.state, open: true, confirmacion: false, accion: false})            
        }else{
          await editarColores(colorEditado[0].id, {...colorEditado, ...values})
          selectColor(null)
          assignMensaje("Registro editado")
          this.setState({ ...this.state, openForm: false, open: true, confirmacion: false, accion: false})            
        }
    }

    handleAgregarRegistroModal = e => {
        this.setState({ ...this.state, openForm: true, confirmacion: false, accion: false})
    }

    handleAgregarRegistro = async values => {
        const { agregarColor, assignMensaje, colores } = this.props
        if(colores.data.some(x => ( x.nombre === values.nombre ))){
            assignMensaje("Este proveedor ya existe")
            this.setState({ ...this.state, open: true, confirmacion: false, accion: false})            
        }else{
            await agregarColor(values)
            assignMensaje("Registro agregado")
            this.setState({ ...this.state, openForm: false, open: true, confirmacion: false, accion: false})            
        }
    }

    render(){
        const { colores, mensaje, editar } = this.props
        return(
            <Box>
                <Grid container justify="center" alignItems="center">
                    <Typography style={{ marginBottom: '30px' }} variant="h4">Colores</Typography>
                </Grid>
                <Grid container justify="center" alignItems="center">
                    <Button color="primary" variant="outlined" onClick={this.handleAgregarRegistroModal}>Agregar Color</Button>
                </Grid>
                <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} lg={12}>
                            <Table
                                searchID="mainSearch"
                                agregar={true}
                                agregarRegistro={this.handleAgregarRegistroModal}
                                columnas={[
                                    { title: 'Nombre', field: 'nombre', 
                                      cellStyle: {
                                        textAlign:"center"
                                     },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                    { title: 'Descripción', field: 'descripcion',cellStyle: {
                                        textAlign:"center"
                                      },headerStyle: {
                                        
                                        textAlign: "center",
                                      }},
                                    { title: 'Estado', field: 'activo', 
                                      cellStyle: {
                                        textAlign:"center"
                                     },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                    { title: 'Acción', field: 'accion', sorting: false, 
                                    cellStyle: {
                                        textAlign:"center",
                                    },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                  ]}
                                  data={colores.data.map(x => ({
                                    ...x,
                                    activo: x.activo ? "Activo" : "Inactivo",
                                    accion: <BotonEditar  handleEditar={this.handleEditarModal} handleActivar={this.handleEditarModal} id={x.id} activo={x.activo ? true: false} />                                    
                              }))} 
                            
                            />
                        </Grid>
                </Grid>

                <Modal 
                    open={this.state.open || colores.error ? true : false}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={colores.fetching}
                >
                    {colores.error ? "Ocurrió un error, contacte al administrador del sitio" : mensaje.data}
                </Modal>
                
                <Modal 
                    open={this.state.openForm}
                    handleClose={this.handleCloseForm}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={colores.fetching}
                    title="Formulario Colores"
                >
                    <Container>
                        { colores.fetching ? <Spinner /> : <FormColores editar={editar} onSubmit={editar ? this.handleEditar : this.handleAgregarRegistro} /> }
                    </Container>
                </Modal>

            </Box>
        )
    }
}

const mapStateToProps = state => {
    return {
        colores: state.Colores,
        mensaje: state.Mensajes,
        editar: state.Colores.data.some(x => x.selected === true)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
      ...coloresDuck,
      ...mensajesDuck,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Proveedores)