import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Button, Container, Box, Typography, Paper, Tooltip, IconButton } from '@material-ui/core';
import FormSolicitudHeaders from '../components/FormSolicitudHeaders';
import FormSolicitudItems from '../components/FormSolicitudItems';
import Table from '../components/Table';
import Modal from '../components/Modal';
import ModalSolicitud from '../components/ModalSolicitud';
import Snackbar from '../components/Snackbar';
import * as itemsDuck from '../ducks/Items';
import * as mensajesDuck from '../ducks/Mensajes';
import * as solicitudesDuck from '../ducks/Solicitudes';
import * as archivoDuck from '../ducks/Archivo';
import * as reset from 'redux-form';
import moment from 'moment';
import 'moment/locale/es';
import { addMillsSep, uploadFileAsPromise } from '../services/utils';
import { leer } from '../services/api';

class NuevaSolicitud extends React.Component {
    constructor(props) {
        super(props);
        let anioMax = moment().year();
        let anios = [];
        for (let i = anioMax; i > (anioMax - 10); i--) anios.push(i.toString());
        this.state = {
            id: 0,
            procesadores: ['Intel I3', 'Intel I5', 'Intel I7'],
            discoDuro: ['500GB', '1TB'],
            memoriaRam: ['2GB', '4GB', '8GB', '16GB'],
            tracciones: ['4x2', '4x4'],
            cilindros: ['1.500', '2.000', '2.500', '3.000 o superior'],
            motores: ['Diesel', 'Bencina'],
            anios: anios,
            openVistaPrevia: false,
            openSnackBar: false,
            confirmacion: false,
            accion: false,
            open: false,
            openModalSolicitud: false,
            adjuntoName: '',
            loading: false,
            showActions: true,
            centros: [],
            proyectos: [],
            gerencias: [],
            tiposRecursos: [],
            clases: [],
            subClases: [],
            equipamientos: [],
            marcas: [],
            colores: [],
        };
    }

    componentDidMount() {
        const { resetItems, usuarioAutenticado } = this.props;
        resetItems();
        Promise.all([
            leer(`/getListasFormHeader?usuario=${usuarioAutenticado.data.id}`),
            leer(`/getListasFormItem`),
        ]).then(responses => {
            this.setState({ 
                centros: responses[0].centros,
                proyectos: responses[0].proyectos,
                gerencias: responses[0].gerencias,
                tiposRecursos: responses[1].tiposRecursos,
                clases: responses[1].clases,
                subClases: responses[1].subClases,
                equipamientos: responses[1].equipamientos,
                marcas: responses[1].marcas,
                colores: responses[1].colores,
                procesadores: responses[1].procesadores,
                discoDuro: responses[1].discoDuro,
                memoriaRam: responses[1].memoriaRam,
                tracciones: responses[1].tracciones,
                cilindros: responses[1].cilindros,
                motores: responses[1].motores,
            });
        });
    }

    handleClose = e => {
        this.setState({ ...this.state, openVistaPrevia: false, confirmacion: false, accion: false });
    };
    handleCloseModal = e => {
        this.setState({
            ...this.state,
            openModalSolicitud: false,
            openSnackBar: false,
            open: false,
            confirmacion: false,
            accion: false
        });
    };

    handleVistaPrevia = e => {
        this.setState({ ...this.state, openVistaPrevia: true });
    };

    handleSubmitSolicitud = values => {
        const { assignMensaje } = this.props;
        assignMensaje('¿Está seguro que desea crear la solicitud?');
        this.setState({ ...this.state, open: true, confirmacion: true, accion: this.handleSolicitud(values) });
    };

    handleSolicitud = values => async e => {
        const {
            items,
            usuarioAutenticado,
            assignMensaje,
            agregarSolicitudes,
            resetItems,
        } = this.props;
        const { tiposRecursos, proyectos } = this.state;
        const solicitud = {
            ...values,
            //fecha: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            contratoModel: proyectos.find(x => x.id === values.proyecto)?.contratoModel,
            estado: 1,
            solicitante: usuarioAutenticado.data.id
        };

        this.setState({ ...this.state, loading: true, showActions: false });
        let errorAdjunto =  null;
        try {
            for (var i = 0; i < items.data.length; i++) {
                let x = items.data[i];
                if (x.adjunto) {
                    // console.log("sí hay adjunto: ", x)
                    let archivoAdjunto = await uploadFileAsPromise(x.adjunto, "1MZilDkQ8E5eqeohq1lFcbBuaL-aG9V2Q");
                    const formAdjunto = {
                        // file: x.adjunto,
                        id: archivoAdjunto.id,
                        nombre: archivoAdjunto.nombre,
                        url: archivoAdjunto.url,
                    };
                    x.adjunto = archivoAdjunto.id;
                } else {
                    delete x.adjunto;
                    // console.log("data sin file", x);
                }
            }
        } catch(e) {
            // console.log("error en algún adjunto", e);
            errorAdjunto  = e.message;
        }

        // console.log("items después", items.data);

        if (!errorAdjunto) {
            if (
                items.data.filter(x => tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Computación')
                    .length > 0
            ) {
                const solicitudComputacionales = {
                    ...solicitud,
                    items: items.data.filter(
                        x => tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Computación'
                    )
                };
                await agregarSolicitudes(solicitudComputacionales);
            }

            if (
                items.data.filter(x => tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Misceláneos')
                    .length > 0
            ) {
                const solicitudMiscelaneos = {
                    ...solicitud,
                    items: items.data.filter(
                        x => tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Misceláneos'
                    )
                };
                await agregarSolicitudes(solicitudMiscelaneos);
            }

            if (
                items.data.filter(
                    x => tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Topografía y Laboratorio'
                ).length > 0
            ) {
                const solicitudTopografia = {
                    ...solicitud,
                    items: items.data.filter(
                        x =>
                            tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Topografía y Laboratorio'
                    )
                };
                await agregarSolicitudes(solicitudTopografia);
            }

            if (
                items.data.filter(x => tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Vehículos')
                    .length > 0
            ) {
                const solicitudVehiculos = {
                    ...solicitud,
                    items: items.data.filter(
                        x => tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Vehículos'
                    )
                };
                await agregarSolicitudes(solicitudVehiculos);
            }

            if (items.data.length === 1) {
                this.props.reset('solicitudItems');
                this.props.reset('solicitudHeaders');
                resetItems();
                assignMensaje('Su solicitud fue creada con éxito');
                this.setState({
                    ...this.state,
                    open: false,
                    openModalSolicitud: true,
                    openSnackBar: false,
                    openVistaPrevia: false,
                    confirmacion: true,
                    accion: this.handleRedireccion,
                    loading: false,
                    showActions: true
                });
            } else {
                this.props.reset('solicitudItems');
                this.props.reset('solicitudHeaders');
                resetItems();
                assignMensaje(`Se han creando
                ${(items.data.filter(
                    x => tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Vehículos'
                ).length > 0
                    ? 1
                    : 0) +
                    (items.data.filter(
                        x =>
                            tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre ===
                            'Topografía y Laboratorio'
                    ).length > 0
                        ? 1
                        : 0) +
                    (items.data.filter(
                        x => tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Misceláneos'
                    ).length > 0
                        ? 1
                        : 0) +
                    (items.data.filter(
                        x => tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Computación'
                    ).length > 0
                        ? 1
                        : 0)} 
                    solicitudes con éxito`);
                this.setState({
                    ...this.state,
                    open: false,
                    openModalSolicitud: true,
                    openSnackBar: false,
                    openVistaPrevia: false,
                    confirmacion: true,
                    accion: this.handleRedireccion,
                    loading: false,
                    showActions: true
                });
            }
        } else {
            assignMensaje('Ha ocurrido un problema al subir los adjuntos: ' + errorAdjunto);
            this.setState({
                ...this.state,
                open: true,
                openModalSolicitud: false,
                openSnackBar: false,
                openVistaPrevia: false,
                confirmacion: false,
                loading: false,
                showActions: true
            });
        }
    };

    handleRedireccion = e => {
        const { history } = this.props;
        history.push('/');
    };

    handleSubmitItem = values => {
        const { addItem, assignMensaje } = this.props;
        const { tiposRecursos, clases, subClases, procesadores, discoDuro, memoriaRam } = this.state;
        let nuevoID = this.state.id + 1;
        // console.log("nuevo item: ", values);
        // let mensajeItemAgregado = 'Se ha agregado el item con éxito';
        // let openSnack = true;
        const nombreTipoRecurso = tiposRecursos.filter(x => x.id === values.tipo_recurso)[0]
            ? tiposRecursos.filter(x => x.id === values.tipo_recurso)[0].nombre
            : '';
        const nombreClase = clases.filter(x => x.id === values.clase)[0]
            ? clases.filter(x => x.id === values.clase)[0].nombre
            : '';
        const nombreSubClase = subClases.filter(x => x.id === values.sub_clase)[0]
            ? subClases.filter(x => x.id === values.sub_clase)[0].nombre
            : '';
        if (nombreTipoRecurso === 'Misceláneos') {
            addItem({
                id: this.state.id,
                cantidad: values.cantidad,
                tipo_moneda: values.tipo_moneda,
                adjunto: values.adjunto,
                tipo_recurso: values.tipo_recurso,
                clase: values.clase,
                descripcion: values.descripcion,
                es_gasto_reemb: values.es_gasto_reemb === true,
                precio_referencial: values.precio_referencial ? values.precio_referencial.replace(/\./g, '') : null,
                fecha_requerida: values.fecha_requerida
            });
        } else if (nombreTipoRecurso === 'Topografía y Laboratorio') {
            addItem({
                id: this.state.id,
                cantidad: values.cantidad,
                tipo_moneda: values.tipo_moneda,
                adjunto: values.adjunto,
                tipo_recurso: values.tipo_recurso,
                clase: values.clase,
                sub_clase: values.sub_clase,
                descripcion: values.descripcion,
                es_gasto_reemb: values.es_gasto_reemb === true,
                precio_referencial: values.precio_referencial ? values.precio_referencial.replace(/\./g, '') : null,
                fecha_requerida: values.fecha_requerida
            });
            if (['Estaciones totales', 'Niveles'].indexOf(nombreSubClase) !== -1) {
                addItem({
                    id: this.state.id + 1,
                    cantidad: values.cantidad,
                    tipo_moneda: values.tipo_moneda,
                    adjunto: null,
                    tipo_recurso: values.tipo_recurso,
                    clase: 3,
                    sub_clase: 26,
                    descripcion: "Calibración de equipos solicitados",
                    es_gasto_reemb: values.es_gasto_reemb === true,
                    precio_referencial: null,
                    fecha_requerida: values.fecha_requerida
                });
                nuevoID++;
            }
        } else if (nombreTipoRecurso === 'Vehículos') {
            if (nombreClase != 'Vehículos') {
                addItem({
                    id: this.state.id,
                    cantidad: values.cantidad,
                    tipo_moneda: values.tipo_moneda,
                    adjunto: values.adjunto,
                    tipo_recurso: values.tipo_recurso,
                    clase: values.clase,
                    sub_clase: values.sub_clase,
                    descripcion: values.descripcion,
                    es_gasto_reemb: values.es_gasto_reemb === true,
                    precio_referencial: values.precio_referencial ? values.precio_referencial.replace(/\./g, '') : null,
                    fecha_requerida: values.fecha_requerida
                });
            } else {
                addItem({
                    id: this.state.id,
                    cantidad: values.cantidad,
                    tipo_moneda: values.tipo_moneda,
                    adjunto: values.adjunto,
                    tipo_recurso: values.tipo_recurso,
                    clase: values.clase,
                    sub_clase: values.sub_clase,
                    marca_vehiculo: values.marca_vehiculo,
                    traccion_id: values.traccion,
                    tipo_motor_id: values.tipo_motor,
                    color: values.color,
                    cilindrada_id: values.cilindrada,
                    anio: values.anio,
                    equipamiento: values.equipamiento ? values.equipamiento.map((x, id) => {
                        if (x === true) {
                            return id;
                        }
                    }) : null,
                    descripcion: values.descripcion,
                    es_gasto_reemb: values.es_gasto_reemb === true,
                    precio_referencial: values.precio_referencial ? values.precio_referencial.replace(/\./g, '') : null,
                    fecha_requerida: values.fecha_requerida
                });
            }
        } else if (nombreTipoRecurso === 'Computación') {
            if (['Computador Notebook', 'Computador Escritorio', 'Servidor'].indexOf(nombreClase) !== -1) {
                let estandar =
                  nombreClase == "Computador Notebook"
                    ? 'Administrativo (Procesador Intel i5, 16GB Ram, 500GB SSD, pantalla 14", Windows 10 Pro, 3 años de garantía)'
                    : 'Administrativo (Procesador Intel i5, 16GB Ram, 500GB SSD, pantalla 14", Windows 10 Pro, 3 años de garantía)';
                switch (values.propositoEquipo) {
                    case 'gerencia':
                        estandar =
                          'Ejecutivo (Procesador Intel i7, 16GB Ram, 500GB SSD, pantalla 14", Windows 10 Pro, 3 años de garantía)';
                        break;
                    case 'autocad':
                        estandar =
                          'Especialista AutoCAD (Procesador Intel i7, 16GB Ram, 500GB SSD, tarjeta de video 4GB, pantalla 15", Windows 10 Pro, 3 años de garantía)';
                        break;
                    case 'bim':
                        estandar =
                          nombreClase == "Computador Notebook"
                            ? 'Especialista BIM (Procesador Intel i9, 32GB Ram, 1TB SSD, tarjeta de video 4GB o más, pantalla 15", Windows 10 Pro, 3 años de garantía)'
                            : 'Especialista BIM (Procesador Intel i9, 32GB Ram, 1TB SSD, tarjeta de video 4GB o más, pantalla 15", Windows 10 Pro, 3 años de garantía)';
                        break;
                    case 'personalizado':
                        estandar =
                            'Personalizado (' +
                            procesadores.filter(y => y.id == values.procesador)[0].nombre +
                            ', Disco ' +
                            discoDuro.filter(y => y.id == values.hdd)[0].nombre +
                            ', Ram ' +
                            memoriaRam.filter(y => y.id == values.ram)[0].nombre +
                            ')';
                        break;
                }
                addItem({
                    id: this.state.id,
                    cantidad: values.cantidad,
                    tipo_moneda: values.tipo_moneda,
                    adjunto: values.adjunto,
                    tipo_recurso: values.tipo_recurso,
                    clase: values.clase,
                    procesador_id: values.procesador,
                    hdd_id: values.hdd,
                    ram_id: values.ram,
                    equipo_estandar: estandar,
                    descripcion: values.descripcion,
                    es_gasto_reemb: values.es_gasto_reemb === true,
                    precio_referencial: values.precio_referencial ? values.precio_referencial.replace(/\./g, '') : null,
                    fecha_requerida: values.fecha_requerida
                });
                // openSnack = false;
                // mensajeItemAgregado += '\n\nLos ítems Computador de Escritorio o Notebook no incluyen Office. ';
                // mensajeItemAgregado += 'Debe agregar otro ítem clase Software ofimática indicando la cantidad y en descripción el contenido de Office que se ajuste a lo necesitado. Lo mismo para otros software especializados como AutoCAD.';
                // addItem({
                //     id: this.state.id + 1,
                //     cantidad: values.cantidad,
                //     tipo_moneda: values.tipo_moneda,
                //     adjunto: null,
                //     tipo_recurso: values.tipo_recurso,
                //     clase: 15,
                //     descripcion: "Licencia Office para equipos solicitados",
                //     precio_referencial: null,
                //     fecha_requerida: values.fecha_requerida
                // });
                // nuevoID++;
            } else if (['Telefonía y/o BAM'].indexOf(nombreClase) !== -1) {
                let opChip = values.requiereChip === "1" ? "Sí" : "No";
                addItem({
                    id: this.state.id,
                    cantidad: values.cantidad,
                    tipo_moneda: values.tipo_moneda,
                    adjunto: values.adjunto,
                    tipo_recurso: values.tipo_recurso,
                    clase: values.clase,
                    descripcion: values.descripcion + ' (' + opChip + ' requiere chip)',
                    es_gasto_reemb: values.es_gasto_reemb === true,
                    precio_referencial: values.precio_referencial ? values.precio_referencial.replace(/\./g, '') : null,
                    fecha_requerida: values.fecha_requerida
                });
            } else {
                addItem({
                    id: this.state.id,
                    cantidad: values.cantidad,
                    tipo_moneda: values.tipo_moneda,
                    adjunto: values.adjunto,
                    tipo_recurso: values.tipo_recurso,
                    clase: values.clase,
                    descripcion: values.descripcion,
                    es_gasto_reemb: values.es_gasto_reemb === true,
                    precio_referencial: values.precio_referencial ? values.precio_referencial.replace(/\./g, '') : null,
                    fecha_requerida: values.fecha_requerida
                });
            }
        }
        assignMensaje('Se ha agregado el item con éxito');
        this.setState({ ...this.state, id: nuevoID, openSnackBar: true });
    };

    handleBorrar = id => e => {
        const { delItem } = this.props;
        delItem(id);
    };

    handleOnChangeFileAdjunto = e => {
        this.setState({ ...this.state, adjuntoName: e.target.files[0] ? e.target.files[0].name : '' });
    };

    render() {
        const { mensaje, formSolicitudHeaders, formSolicitudItems, items, permisoPruebas } = this.props;
        const {
            anios,
            tiposRecursos,
            clases,
            subClases,
            procesadores,
            discoDuro,
            memoriaRam,
            gerencias,
            centros,
            proyectos,
            tracciones,
            cilindros,
            motores,
            equipamientos,
            marcas,
            colores,
          } = this.state;
        return (
            <Box>
                <Grid container justify="center" alignItems="center">
                    <Typography style={{ marginBottom: '10px' }} variant="h4">
                        Nueva Solicitud de Recursos
                    </Typography>
                </Grid>
                <Grid>
                    <Paper style={{ padding: '10px' }} square={true}>
                        <FormSolicitudHeaders
                            onSubmit={this.handleSubmitSolicitud}
                            formSolicitudHeaders={formSolicitudHeaders}
                            gerencias={gerencias}
                            centros={centros}
                            proyectos={proyectos}
                            permisoPruebas={permisoPruebas}
                        />
                        <FormSolicitudItems
                            onSubmit={this.handleSubmitItem}
                            formSolicitudItems={formSolicitudItems}
                            anios={anios}
                            adjuntoName={this.state.adjuntoName}
                            handleOnChangeFileAdjunto={this.handleOnChangeFileAdjunto}
                        />
                        {items.data.length > 0 && (
                            <Grid container style={{ marginTop: '20px' }} justify="center" alignItems="center">
                                {/* <Button onClick={this.handleVistaPrevia} variant="outlined" color="primary">
                                    Vista Previa
                                </Button> */}
                                <Button form="formSolicitudHeaders" type="submit" variant="outlined" color="primary">
                                    Crear Solicitud
                                </Button>
                            </Grid>
                        )}
                    </Paper>
                </Grid>
                {items.data.length > 0 && (
                    <Grid>
                        <Grid container justify="center" alignItems="center">
                            <Typography style={{ margintop: '20px' }} variant="h4">
                                Items Agregados: {items.data.length}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Table
                                search={false}
                                toolbar={false}
                                paging={false}
                                columnas={[
                                    {
                                        title: 'Adjunto',
                                        field: 'adjunto',
                                        sorting: false,
                                        cellStyle: {
                                            width: '50px',
                                            textAlign: 'center',
                                            padding: '0px'
                                        },
                                        headerStyle: {
                                            textAlign: 'center',
                                            padding: '0px'
                                        }
                                    },
                                    {
                                        title: 'Cantidad',
                                        field: 'cantidad',
                                        sorting: false,
                                        cellStyle: {
                                            width: '100px',
                                            textAlign: 'center',
                                            padding: '0px'
                                        },
                                        headerStyle: {
                                            textAlign: 'center',
                                            padding: '0px'
                                        }
                                    },
                                    {
                                        title: 'Tipo de Recurso',
                                        field: 'tipo_recurso',
                                        sorting: false,
                                        cellStyle: {
                                            width: '150px',
                                            padding: '0px',
                                            textAlign: 'center'
                                        },
                                        headerStyle: {
                                            textAlign: 'center',
                                            padding: '0px'
                                        }
                                    },
                                    {
                                        title: 'Clase',
                                        field: 'clase',
                                        sorting: false,
                                        cellStyle: {
                                            width: '150px',
                                            padding: '0px',
                                            textAlign: 'center'
                                        },
                                        headerStyle: {
                                            textAlign: 'center',
                                            padding: '0px'
                                        }
                                    },
                                    {
                                        title: 'Descripción',
                                        field: 'descripcion',
                                        sorting: false,
                                        cellStyle: {
                                            padding: '0px',
                                            textAlign: 'center'
                                        },
                                        headerStyle: {
                                            textAlign: 'center',
                                            padding: '0px'
                                        }
                                    },
                                    {
                                        title: 'Gasto Reembolsable',
                                        field: 'es_gasto_reemb',
                                        sorting: false,
                                        cellStyle: {
                                            width: '150px',
                                            padding: '0px',
                                            textAlign: 'center'
                                        },
                                        headerStyle: {
                                            padding: '0px',
                                            textAlign: 'center'
                                        }
                                    },
                                    {
                                        title: 'Eliminar',
                                        field: 'eliminar',
                                        sorting: false,
                                        cellStyle: {
                                            width: '100px',
                                            textAlign: 'center',
                                            padding: '0px'
                                        },
                                        headerStyle: {
                                            textAlign: 'center',
                                            padding: '0px'
                                        }
                                    }
                                ]}
                                data={items.data
                                    .filter(
                                        x =>
                                            tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                            tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre ===
                                                'Computación'
                                    )
                                    .concat(
                                        items.data.filter(
                                            x =>
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre ===
                                                    'Misceláneos'
                                        )
                                    )
                                    .concat(
                                        items.data.filter(
                                            x =>
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre ===
                                                    'Topografía y Laboratorio'
                                        )
                                    )
                                    .concat(
                                        items.data.filter(
                                            x =>
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre ===
                                                    'Vehículos'
                                        )
                                    )
                                    .map(x => ({
                                        ...x,
                                        adjunto: x.adjunto ? (
                                            <Button color="primary" onClick={() => {
                                                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                                    window.navigator.msSaveOrOpenBlob(x.adjunto);
                                                } else {
                                                    var blobUrl = URL.createObjectURL(x.adjunto);
                                                    window.open(blobUrl);
                                                }
                                            }}>
                                                Ver
                                            </Button>
                                        ) : "",
                                        tipo_recurso: tiposRecursos.filter(y => y.id === x.tipo_recurso)[0]
                                            ? tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre
                                            : '',
                                        clase: clases.filter(y => y.id === x.clase)[0]
                                            ? clases.filter(y => y.id === x.clase)[0].nombre
                                            : '',
                                        // fecha_requerida: moment(x.fecha_requerida).format('DD-MM-YYYY'),
                                        es_gasto_reemb: x.es_gasto_reemb === true ? 'Sí' : 'No',
                                        descripcion:
                                            x.descripcion +
                                            (subClases.filter(y => y.id === x.sub_clase)[0]
                                                ? '/' + subClases.filter(y => y.id === x.sub_clase)[0].nombre
                                                : '') +
                                            (marcas.filter(y => y.id === x.marca)[0]
                                                ? '/' + marcas.filter(y => y.id === x.marca)[0].nombre
                                                : '') +
                                            (tracciones.filter(y => y.id === x.traccion_id)[0]
                                                ? '/' + tracciones.filter(y => y.id === x.traccion_id)[0].nombre
                                                : '') +
                                            (motores.filter(y => y.id === x.tipo_motor_id)[0]
                                                ? '/' + motores.filter(y => y.id === x.tipo_motor_id)[0].nombre
                                                : '') +
                                            (colores.filter(y => y.id === x.color)[0]
                                                ? '/' + colores.filter(y => y.id === x.color)[0].nombre
                                                : '') +
                                            (cilindros.filter(y => y.id === x.cilindrada_id)[0]
                                                ? '/' + cilindros.filter(y => y.id === x.cilindrada_id)[0].nombre
                                                : '') +
                                            (x.anio ? '/' + x.anio : '') +
                                            (x.equipamiento
                                                ? '/' + x.equipamiento
                                                      .map(y => {
                                                          if (equipamientos.filter(z => z.id === y)[0]) {
                                                              return (
                                                                  equipamientos.filter(z => z.id === y)[0].nombre
                                                              );
                                                          } else {
                                                              return '';
                                                          }
                                                      })
                                                      .filter(x => x !== '')
                                                : '') +
                                            (x.equipo_estandar ? '/Tipo: ' + x.equipo_estandar : '') +
                                            // ((x.equipo_estandar && x.equipo_estandar.includes('Personalizado')) ?
                                            //     ((procesadores.data.filter(y => y.id === x.procesador_id)[0]
                                            //         ? '(' +
                                            //         procesadores.data.filter(y => y.id === x.procesador_id)[0].nombre
                                            //         : '') +
                                            //     (discoDuro.data.filter(y => y.id === x.hdd_id)[0]
                                            //         ? '/' + discoDuro.data.filter(y => y.id === x.hdd_id)[0].nombre
                                            //         : '') +
                                            //     (memoriaRam.data.filter(y => y.id === x.ram_id)[0]
                                            //         ? '/' + memoriaRam.data.filter(y => y.id === x.ram_id)[0].nombre + ')'
                                            //         : '')) : '') +
                                            (x.precio_referencial
                                                ? '/' +
                                                  'Precio Referencial: ' +
                                                  x.tipo_moneda + '$ ' +
                                                  addMillsSep(x.precio_referencial)
                                                : ''),
                                        eliminar: (
                                            <Tooltip title="Eliminar">
                                                <IconButton onClick={this.handleBorrar(x.id)}>
                                                    <i className="material-icons">delete</i>
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }))}
                            />
                        </Grid>
                    </Grid>
                )}

                <Modal
                    open={this.state.openVistaPrevia}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    title="Resumen de items por solicitar"
                    fullWidth={true}>
                    <Container>
                        <Grid container justify="center" alignItems="center">
                            <Typography align="center" variant="h4" gutterBottom>
                                Vista Previa
                            </Typography>
                        </Grid>

                        <Grid container justify="center" alignItems="center">
                            <Button form="formSolicitudHeaders" type="submit" variant="outlined" color="primary">
                                Realizar Solicitud
                            </Button>
                        </Grid>

                        {items.data.filter(
                            x =>
                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Computación'
                        ).length > 0 && (
                            <Grid>
                                <Typography variant="h6" gutterBottom>
                                    Computacionales
                                </Typography>
                                <ul>
                                    {items.data
                                        .filter(
                                            x =>
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre ===
                                                    'Computación'
                                        )
                                        .map(x => (
                                            <ol key={x.id}>
                                                <strong>Cantidad: </strong> {x.cantidad} -<strong>Clase: </strong>{' '}
                                                {clases.filter(y => y.id === x.clase)[0].nombre} -
                                                <strong>Descripción: </strong>{' '}
                                                {x.descripcion +
                                                    (procesadores.filter(y => y.id === x.procesador_id)[0]
                                                        ? '/' +
                                                          procesadores.filter(y => y.id === x.procesador_id)[0]
                                                              .nombre
                                                        : '') +
                                                    (discoDuro.filter(y => y.id === x.hdd_id)[0]
                                                        ? '/' + discoDuro.filter(y => y.id === x.hdd_id)[0].nombre
                                                        : '') +
                                                    (memoriaRam.filter(y => y.id === x.ram_id)[0]
                                                        ? '/' + memoriaRam.filter(y => y.id === x.ram_id)[0].nombre
                                                        : '') +
                                                    (x.precio_referencial
                                                        ? '/' +
                                                          'Precio Referencial:' +
                                                          '$ ' +
                                                          x.precio_referencial.toLocaleString()
                                                        : '')}
                                                <strong>Fecha Requerida:</strong>{' '}
                                                {moment(x.fecha_requerida).format('DD-MM-YYYY')}
                                                <strong>Requerido en:</strong>
                                                {x.requerido_en}
                                            </ol>
                                        ))}
                                </ul>
                            </Grid>
                        )}

                        {items.data.filter(
                            x =>
                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Misceláneos'
                        ).length > 0 && (
                            <Grid>
                                <Typography variant="h6" gutterBottom>
                                    Misceláneos
                                </Typography>
                                <ul>
                                    {items.data
                                        .filter(
                                            x =>
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre ===
                                                    'Misceláneos'
                                        )
                                        .map(x => (
                                            <ol key={x.id}>
                                                <strong>Cantidad: </strong> {x.cantidad} -<strong>Clase: </strong>{' '}
                                                {clases.filter(y => y.id === x.clase)[0].nombre} -
                                                <strong>Descripción: </strong>{' '}
                                                {x.descripcion +
                                                    (x.precio_referencial
                                                        ? '/' +
                                                          'Precio Referencial:' +
                                                          '$ ' +
                                                          x.precio_referencial.toLocaleString()
                                                        : '')}{' '}
                                                -<strong>Fecha Requerida:</strong>{' '}
                                                {moment(x.fecha_requerida).format('DD-MM-YYYY')}
                                                <strong>Requerido en:</strong>
                                                {x.requerido_en}
                                            </ol>
                                        ))}
                                </ul>
                            </Grid>
                        )}

                        {items.data.filter(
                            x =>
                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre ===
                                    'Topografía y Laboratorio'
                        ).length > 0 && (
                            <Grid>
                                <Typography variant="h6" gutterBottom>
                                    Topografía y Laboratorio
                                </Typography>
                                <ul>
                                    {items.data
                                        .filter(
                                            x =>
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre ===
                                                    'Topografía y Laboratorio'
                                        )
                                        .map(x => (
                                            <ol key={x.id}>
                                                <strong>Cantidad: </strong> {x.cantidad} -<strong>Clase: </strong>{' '}
                                                {clases.filter(y => y.id === x.clase)[0].nombre} -
                                                <strong>Descripción: </strong>{' '}
                                                {x.descripcion +
                                                    (subClases.filter(y => y.id === x.sub_clase)[0]
                                                        ? '/' +
                                                          subClases.filter(y => y.id === x.sub_clase)[0].nombre
                                                        : '') +
                                                    (x.precio_referencial
                                                        ? '/' +
                                                          'Precio Referencial:' +
                                                          '$ ' +
                                                          x.precio_referencial.toLocaleString()
                                                        : '')}{' '}
                                                -<strong>Fecha Requerida:</strong>{' '}
                                                {moment(x.fecha_requerida).format('DD-MM-YYYY')}
                                                <strong>Requerido en:</strong>
                                                {x.requerido_en}
                                            </ol>
                                        ))}
                                </ul>
                            </Grid>
                        )}

                        {items.data.filter(
                            x =>
                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre === 'Vehículos'
                        ).length > 0 && (
                            <Grid>
                                <Typography variant="h6" gutterBottom>
                                    Vehículos
                                </Typography>
                                <ul>
                                    {items.data
                                        .filter(
                                            x =>
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] &&
                                                tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre ===
                                                    'Vehículos'
                                        )
                                        .map(x => (
                                            <ol key={x.id}>
                                                <strong>Cantidad: </strong> {x.cantidad} -<strong>Clase: </strong>{' '}
                                                {clases.filter(y => y.id === x.clase)[0].nombre} -
                                                <strong>Descripción: </strong>{' '}
                                                {x.descripcion +
                                                    (subClases.filter(y => y.id === x.sub_clase)[0]
                                                        ? '/' +
                                                          subClases.filter(y => y.id === x.sub_clase)[0].nombre
                                                        : '') +
                                                    (marcas.filter(y => y.id === x.marca)[0]
                                                        ? '/' + marcas.filter(y => y.id === x.marca)[0].nombre
                                                        : '') +
                                                    (tracciones.filter(y => y.id === x.traccion_id)[0]
                                                        ? '/' +
                                                          tracciones.filter(y => y.id === x.traccion_id)[0].nombre
                                                        : '') +
                                                    (motores.filter(y => y.id === x.tipo_motor_id)[0]
                                                        ? '/' +
                                                          motores.filter(y => y.id === x.tipo_motor_id)[0].nombre
                                                        : '') +
                                                    (colores.filter(y => y.id === x.color)[0]
                                                        ? '/' + colores.filter(y => y.id === x.color)[0].nombre
                                                        : '') +
                                                    (cilindros.filter(y => y.id === x.cilindrada_id)[0]
                                                        ? '/' +
                                                          cilindros.filter(y => y.id === x.cilindrada_id)[0].nombre
                                                        : '') +
                                                    (x.anio ? '/' + x.anio : '') +
                                                    (x.equipamiento
                                                        ? x.equipamiento
                                                              .map(y => {
                                                                  if (equipamientos.filter(z => z.id === y)[0]) {
                                                                      return (
                                                                          '/' +
                                                                          equipamientos.filter(z => z.id === y)[0]
                                                                              .nombre
                                                                      );
                                                                  } else {
                                                                      return '';
                                                                  }
                                                              })
                                                              .filter(x => x !== '')
                                                        : '') +
                                                    (x.precio_referencial
                                                        ? '/' +
                                                          'Precio Referencial:' +
                                                          '$ ' +
                                                          x.precio_referencial.toLocaleString()
                                                        : '')}{' '}
                                                -<strong>Fecha Requerida:</strong>{' '}
                                                {moment(x.fecha_requerida).format('DD-MM-YYYY')}
                                                <strong>Requerido en:</strong>
                                                {x.requerido_en}
                                            </ol>
                                        ))}
                                </ul>
                            </Grid>
                        )}
                    </Container>
                </Modal>

                <Modal
                    open={this.state.open}
                    handleClose={this.handleCloseModal}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    showActions={this.state.showActions}
                    loading={this.state.loading}>
                        <p style={{ whiteSpace: 'break-spaces' }}>{mensaje}</p>
                </Modal>

                <ModalSolicitud
                    open={this.state.openModalSolicitud}
                    handleClose={this.handleCloseModal}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={this.state.loading}>
                    {mensaje}
                </ModalSolicitud>

                <Snackbar handleClose={this.handleCloseModal} open={this.state.openSnackBar} texto={mensaje} />
            </Box>
        );
    }
}

const mapStateToProps = state => {
    // console.log(state);
    return {
        usuarioAutenticado: state.Usuario,
        formSolicitudHeaders: state.form.solicitudHeaders?.values ? state.form.solicitudHeaders.values : {},
        formSolicitudItems: state.form.solicitudItems?.values ? state.form.solicitudItems.values : {},
        permisoPruebas: state.PermisosUsuario.data.some(x => x.nombre === 'usuarioPruebas'),
        items: state.Items,
        perfiles: state.Perfiles,
        mensaje: state.Mensajes.data,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...itemsDuck,
            ...archivoDuck,
            ...mensajesDuck,
            ...solicitudesDuck,
            ...reset
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(NuevaSolicitud);
