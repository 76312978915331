import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch, addFetch, selectHOR, removeErrorHOR } from './hors.js'
import { leer, modificar, crear } from '../services/api'

const t = makeTypes('proveedores')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const SELECT = t('select').single()
const ADD = t('add').async()
const REMOVEERROR = t('removeError').single()

const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
const addActions = asyncMac(ADD)
export const removeError = mac(REMOVEERROR)
export const selectProveedor = mac(SELECT,'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const removeErrorReducer= createReducer(initialState, removeErrorHOR({ REMOVEERROR }))
const selectReducer= createReducer(initialState, selectHOR({ SELECT }))

export default reduceReducers(fetchReducer, editReducer, selectReducer, addReducer, removeErrorReducer)

export const fetchProveedores = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer('/proveedor')
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const editarProveedores = (id, values) => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await modificar('/proveedor', id, values)
        dispatch(editActions.success(data))
    } catch (error) {
        console.log(error)
        dispatch(editActions.error(error))
    }
}

export const agregarProveedores = (values) => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await crear('/proveedor', values)
        dispatch(addActions.success(data))
    } catch (error) {
        dispatch(addActions.error(error))
    }
}

export const agregarSucursal = (values) => async (dispatch, getState) => {
    dispatch(editActions.start());
    try {
        const data = await crear('/sucursal', values);
        dispatch(editActions.success(data));
    } catch (error) {
        dispatch(editActions.error(error));
    }
}

export const editarSucursal = (id, values) => async (dispatch, getState) => {
    dispatch(editActions.start());
    try {
        const data = await modificar('/sucursal', id, values);
        dispatch(editActions.success(data));
    } catch (error) {
        dispatch(editActions.error(error));
    }
}

export const nuevoDocumentoAplica = (values) => async (dispatch, getState) => {
    dispatch(editActions.start());
    try {
        const data = await crear('/nuevoDocumentoAplica', values);
        dispatch(editActions.success(data));
    } catch (error) {
        dispatch(editActions.error(error));
    }
}

export const editarDocumentoAplica = (values) => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await crear('/editarDocumentoAplica', values);
        dispatch(editActions.success(data));
    } catch (error) {
        dispatch(editActions.error(error));
    }
}

export const agregarDocumentoProveedor = (values) => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await crear('/agregarDocumentoProveedor', values);
        dispatch(editActions.success(data));
    } catch (error) {
        dispatch(editActions.error(error));
    }
}

export const agregarTrabajador = (values) => async (dispatch, getState) => {
    dispatch(editActions.start());
    try {
        const data = await crear('/trabajadorProveedor', values);
        dispatch(editActions.success(data));
    } catch (error) {
        dispatch(editActions.error(error));
    }
}

export const editarTrabajador = (id, values) => async (dispatch, getState) => {
    dispatch(editActions.start());
    try {
        const data = await modificar('/trabajadorProveedor', id, values);
        dispatch(editActions.success(data));
    } catch (error) {
        dispatch(editActions.error(error));
    }
}

export const agregarVehiculo = (values) => async (dispatch, getState) => {
    dispatch(editActions.start());
    try {
        const data = await crear('/vehiculoProveedor', values);
        dispatch(editActions.success(data));
    } catch (error) {
        dispatch(editActions.error(error));
    }
}

export const editarVehiculo = (id, values) => async (dispatch, getState) => {
    dispatch(editActions.start());
    try {
        const data = await modificar('/vehiculoProveedor', id, values);
        dispatch(editActions.success(data));
    } catch (error) {
        dispatch(editActions.error(error));
    }
}