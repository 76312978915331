import { makeTypes, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, addFetch, editFetch } from './hors.js'
import { leer, crear, modificar } from '../services/api'

const t = makeTypes('aprobadoresCotizacion')
const FETCH = t('fetch').async()
const ADD = t('add').async()
const EDIT = t('edit').async()
 
const fetchActions = asyncMac(FETCH)
const addActions = asyncMac(ADD)
const editActions = asyncMac(EDIT)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const addReducer = createReducer(initialState, addFetch( ADD ))
const editReducer = createReducer(initialState, editFetch( EDIT ))

export default reduceReducers(fetchReducer, addReducer, editReducer)

export const fetchAprobadoresCotizacion = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer('/aprobador-cotizacion')
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const agregarAprobadorCotizacion = (values) => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        const data = await crear('/aprobador-cotizacion', values)
        dispatch(addActions.success(data))
    } catch (error) {
        dispatch(addActions.error(error))
    }
}

export const editarAprobadorCotizacion = (id, values) => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await modificar('/aprobador-cotizacion', id, values)
        dispatch(editActions.success(data))
    } catch (error) {
        console.log(error)
        dispatch(editActions.error(error))
    }
}