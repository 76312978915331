import React, { Component } from 'react';
import {
  List,
  ListItem,
  Typography
} from '@material-ui/core';
import TableOfContentsItem from './TableOfContentsItem';


export default class TableOfContents extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      selected: this.props.selected
    };
  }

  handleClick(index) {
    const { handleClick } = this.props;
    handleClick(index);
    this.setState({ ...this.state, selected: index });
  }

  render() {
    const { title, items, style, custom } = this.props;
    const { selected } = this.state;

    return (

      <List
        {...custom}
        style={{
          ...style
        }}
        >
        <ListItem>
          <Typography gutterBottom>
            {title}
          </Typography>
        </ListItem>

        {items.map((value, index) => (
          <TableOfContentsItem
            key={index}
            link={value.link}
            selected={index === selected}
            text={value.text}
            handleClick={() => this.handleClick(index)}
          />
        ))}
      </List>
    )
  }
}