import React from 'react';
import TextField from '@material-ui/core/TextField';

const renderTextField = ({ input, label, characterLimit, meta: { touched, error }, ...custom }) => {
    return (
        <TextField
            label={label}
            {...input}
            {...custom}
            error={touched && (error ? true : false)}
            helperText={
                characterLimit ? (
                    touched && error ? (
                        custom.type == 'date' || typeof error === 'string' ? (
                            <span>
                                <span>{error}</span>
                                <span style={{ float: 'right' }}>
                                    {input.value.length}/{characterLimit}
                                </span>
                            </span>
                        ) : (
                            <span>
                                <span>Requerido</span>
                                <span style={{ float: 'right' }}>
                                    {input.value.length}/{characterLimit}
                                </span>
                            </span>
                        )
                    ) : (
                        <span
                            style={
                                input.value.length == characterLimit
                                    ? { float: 'right', color: 'red' }
                                    : { float: 'right' }
                            }>
                            {input.value.length}/{characterLimit}
                        </span>
                    )
                ) : touched && error ? (
                    custom.type == 'date' || typeof error === 'string' ? (
                        <span>{error}</span>
                    ) : (
                        <span>Requerido</span>
                    )
                ) : (
                    <span></span>
                )
            }
        />
    );
};

export default renderTextField;
