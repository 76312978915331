import { makeTypes, mac, createReducer, reduceReducers } from 'ducks-maker'
import { addHOR } from './hors.js'

const t = makeTypes('topografiaLaboratorio')
const ADD = t('add')
 
export const addGerencia = mac(ADD, 'payload')

const initialState = {
    data: [],
}

const selectReducer =  createReducer(initialState, addHOR({ ADD }))

export default reduceReducers(selectReducer)