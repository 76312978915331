import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Typography, Popper, Tooltip, Chip, Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { withStyles } from '@material-ui/core/styles';

const StyledTooltip = withStyles({
    tooltipPlacementTop: {
        margin: '0'
    },
    tooltipPlacementBottom: {
        margin: '0'
    }
})(Tooltip);

class RenderSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: props.input.value
                ? props.options.filter(x => x.id === props.input.value)[0]
                    ? props.options.filter(x => x.id === props.input.value)[0]
                    : props.multiple
                    ? props.options.filter(x => props.input.value.some(y => y == x.id))
                    : null
                : props.multiple
                ? []
                : null
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.meta.pristine != this.props.meta.pristine && this.props.meta.pristine) {
            if (this.props.input.value && this.props.options.filter(x => x.id === this.props.input.value)[0]) {
                this.handleChange(null, this.props.options.filter(x => x.id === this.props.input.value)[0]);
            } else {
                this.handleChange(null, this.props.multiple ? [] : null);
            }
        } else {
            if (prevProps.options && this.props.options) {
                if (prevProps.options.length == this.props.options.length) {
                    for (var i = 0; i < prevProps.options.length; i++) {
                        if (prevProps.options[i].nombre != this.props.options[i].nombre) {
                            if (
                                this.props.input.value &&
                                this.props.options.filter(x => x.id === this.props.input.value)[0]
                            ) {
                                this.handleChange(
                                    null,
                                    this.props.options.filter(x => x.id === this.props.input.value)[0]
                                );
                            } else {
                                this.handleChange(null, this.props.multiple ? [] : null);
                            }
                            break;
                        }
                    }
                } else {
                    if (this.props.input.value && this.props.options.filter(x => x.id === this.props.input.value)[0]) {
                        this.handleChange(null, this.props.options.filter(x => x.id === this.props.input.value)[0]);
                    } else {
                        this.handleChange(null, this.props.multiple ? [] : null);
                    }
                }
            }
        }
    };

    handleChange = (e, newValue) => {
        const { input, change, multiple } = this.props;

        this.setState({
            ...this.state,
            selectedOption: newValue
        });

        if (newValue) {
            if (multiple) {
                change(
                    input.name,
                    newValue.map(x => x.id)
                );
            } else {
                if (newValue.id) {
                    change(input.name, newValue.id);
                } else {
                    change(input.name, newValue);
                }
            }
        } else {
            if (multiple) {
                change(input.name, []);
            } else {
                change(input.name, null);
            }
        }
    };

    handleBlur = () => {
        const { input, touch } = this.props;
        if (touch) touch(input.name);
    };

    optionLabel = options => {
        const { input } = this.props;
        if (options == null) return '';
        let value = options.nombres
            ? options.nombres + ' ' + options.apellidos
            : options.nombre
            ? options.nombre
            : options.codigo
            ? options.codigo
            : options;

        return input.name == 'proyecto' ? options.codigo + ', ' + value : value;
    };

    filterOptions = createFilterOptions({
        stringify: option => this.optionLabel(option)
    });

    custPopper = props => {
        return <Popper {...props} style={{ width: 'fit-content', maxWidth: 700 }} placement="bottom-start" />;
    };

    render() {
        const {
            input,
            label,
            options,
            meta: { touched, error },
            change,
            charCountLimit,
            multiple,
            limitTags,
            maxTagWidth,
            ...custom
        } = this.props;

        return (
            <Autocomplete
                clearOnEscape
                disabled={custom.disabled ? true: false}
                multiple={multiple}
                limitTags={limitTags}
                disableCloseOnSelect={multiple}
                disableClearable={multiple}
                size={custom.size ? custom.size : 'medium'}
                options={options}
                style={custom.style ? custom.style : {}}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={this.state.selectedOption}
                filterOptions={this.filterOptions}
                getOptionLabel={options => {
                    const value = this.optionLabel(options);

                    return value ? value : '';
                }}
                PopperComponent={this.custPopper}
                renderOption={(option, { selected }) => {
                    if (multiple) {
                        return (
                            <React.Fragment>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8, padding: 0 }}
                                    checked={selected}
                                />
                                {this.optionLabel(option)}
                            </React.Fragment>
                        );
                    } else {
                        return <Typography>{this.optionLabel(option)}</Typography>;
                    }
                }}
                renderInput={params => {
                    return input.value &&
                        charCountLimit &&
                        !multiple &&
                        this.optionLabel(this.state.selectedOption).length > charCountLimit ? (
                        <StyledTooltip title={this.optionLabel(this.state.selectedOption)} placement={'top'}>
                            <TextField
                                {...params}
                                autoComplete="apagado"
                                required={custom.required}
                                label={label}
                                error={touched && error ? true : false}
                                helperText={
                                    touched && error ? (
                                        <span>Requerido</span>
                                    ) : custom.extraInfo ? (
                                        <span>{custom.extraInfo}</span>
                                    ) : (
                                        <span></span>
                                    )
                                }
                                fullWidth
                            />
                        </StyledTooltip>
                    ) : (
                        <TextField
                            {...params}
                            autoComplete="apagado"
                            required={custom.required}
                            label={label}
                            error={touched && error ? true : false}
                            helperText={
                                touched && error ? (
                                    <span>Requerido</span>
                                ) : custom.extraInfo ? (
                                    <span>{custom.extraInfo}</span>
                                ) : (
                                    <span></span>
                                )
                            }
                            fullWidth
                        />
                    );
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        if (charCountLimit && this.optionLabel(option).length > charCountLimit) {
                            return (
                                <StyledTooltip
                                    title={this.optionLabel(option)}
                                    placement={'top'}
                                    key={{ ...getTagProps({ index }) }.key}>
                                    <Chip
                                        variant="outlined"
                                        label={this.optionLabel(option)}
                                        size="small"
                                        classes={{ sizeSmall: 'chipSizeSmall' }}
                                        style={{ maxWidth: maxTagWidth }}
                                        {...getTagProps({ index })}
                                    />
                                </StyledTooltip>
                            );
                        } else {
                            return (
                                <Chip
                                    variant="outlined"
                                    label={this.optionLabel(option)}
                                    size="small"
                                    classes={{ sizeSmall: 'chipSizeSmall' }}
                                    {...getTagProps({ index })}
                                />
                            );
                        }
                    })
                }
                getOptionSelected={(option, value) => {
                    // console.log("option: ", option);
                    // console.log("value: ", value);
                    if (value.id) {
                        return value.id == option.id;
                    } else {
                        return option == value;
                    }
                }}
            />
        );
    }
}

export default RenderSelect;
