import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import FormComentario from '../components/FormComentario'
import Modal from './Modal'
import * as comentariosDuck from '../ducks/Comentarios'
import moment from 'moment'
import 'moment/locale/es'
import * as modalesDuck from '../ducks/Modales'

class FormularioComentario extends React.Component{
    render(){
        const { solicitudActual, usuarioAutenticado, agregarComentarios, openModal, comentarios, etapas } = this.props
        return(
            <>
                <FormComentario etapas={etapas} onSubmit={ values => openModal({ title: "Confirmación" , action: () => {console.log(values); agregarComentarios({ ...values, estado_id: solicitudActual.estado, fecha: moment().toISOString(), solicitud_recurso_id: solicitudActual.id, usuario_id: usuarioAutenticado.data.id }) } , body: "Al crear este comentario se notificará por correo a los responsables de la etapa seleccionada", close: true })} />
                <Modal loading={comentarios.fetching} />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        usuarioAutenticado: state.Usuario,
        solicitudActual: state.Solicitudes.data.find(x => x.id === state.Idsolicitud.data)[0]
            ? state.Solicitudes.data.find(x => x.id === state.Idsolicitud.data)
            : state.SolicitudesUsuario.data.find(x => x.id === state.Idsolicitud.data),
        comentarios: state.Comentarios,
        etapas: state.Etapas
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    ...comentariosDuck,
    ...modalesDuck
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FormularioComentario)