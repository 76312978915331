import { makeTypes, mac, createReducer, reduceReducers } from 'ducks-maker'
import { assignHOR } from './hors.js'

const t = makeTypes('modoEdicion')
const ASSIGN = t('assign').single()

export const toggleModoEdicion = mac(ASSIGN, 'payload')

const initialState = {
    data: false,
}

const toggleReducer =  createReducer(initialState, assignHOR({ ASSIGN }))

export default reduceReducers(toggleReducer)