import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Grid, withStyles, Typography, IconButton, Tooltip } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Detalle from '../components/Detalle';
import Spinner from '../components/Spinner';
import Comentarios from '../containers/Comentarios';
import * as solicitudesUsuarioDuck from '../ducks/SolicitudesUsuario';
import * as solicitudesAprobarDuck from '../ducks/SolicitudesAprobar';
import * as solicitudesDuck from '../ducks/Solicitudes';
import * as idsolicitudDuck from '../ducks/Idsolicitud';
import * as itemsDuck from '../ducks/Items';
import * as itemsAllDuck from '../ducks/ItemsAll';
import * as aprobador1Duck from '../ducks/Aprobador1';
import * as aprobador2Duck from '../ducks/Aprobador2';
import * as gruposCotizacionDuck from '../ducks/GruposCotizacion';
import * as cotizacionesDuck from '../ducks/Cotizaciones';
import * as ordenesCompraDuck from '../ducks/OrdenesCompra';
import * as drawerComentariosDuck from '../ducks/DrawerComentarios';
import * as comentariosDuck from '../ducks/Comentarios';
import * as proveedoresDuck from '../ducks/Proveedores';
import { crear, leer } from '../services/api';

const styles = theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(25),
        right: theme.spacing(1)
    },
    fab2: {
        position: 'fixed',
        bottom: theme.spacing(45),
        right: theme.spacing(1)
    }
});

class DetalleSolicitudes extends React.Component {
    state = {
        loading: true,
        solicitudActual: null,
        aprobador1: null,
        aprobador2: null,
        procesadores: [],
        discoDuro: [],
        memoriaRam: [],
        proveedores: [],
        estados: []
    };

    async componentDidMount() {
        // if (!this.props.solicitudes.fetched) {
        //     console.log("cargando solicitudes");
        //     await Promise.all([
        //         this.props.fetchSolicitudes(this.props.usuarioAutenticado.data.id, ''),
        //         this.props.fetchSolicitudesUsuario(this.props.usuarioAutenticado.data.id,'','','','',''),
        //         this.props.fetchSolicitudesAprobar(this.props.usuarioAutenticado.data.id,'','','','','')
        //     ]);
        // }
        const {
            match,
            history,
            assignIdsolicitud,
            solicitudesAprobar,
            solicitudesUsuario,
            solicitudes,
            fetchSolicitudes,
            fetchGruposCotizacion,
            fetchCotizaciones,
            fetchItems,
            fetchItemsTodos,
            fetchAprobador1,
            fetchAprobador2,
            fetchProveedores,
            usuarioAutenticado
        } = this.props;
        const {
            params: { id }
        } = match;
        let idParam = parseInt(id);
        // console.log("proooops: ", this.props);
        const dataSolicitud = await leer(`/detallesSolicitudRecursos/${id}?usuario=${usuarioAutenticado.data.id}`);
        console.log("dataSolicitud:", dataSolicitud);
        const responseTest = await crear(`/solicitudesRecursosUsuario/${usuarioAutenticado.data.id}`, {
            estado: '',
            page: 0,
            pageSize: 50,
            filters: [{ field: 'codigo', value: dataSolicitud.solicitud_recurso.codigo }],
            search: '',
        });
        console.log("responseTest", responseTest);
        // const cond1 = solicitudes.data.find(x => x.id === idParam); //solicitudes abiertas
        // const cond2 = solicitudesAprobar.data.find(x => x.id === idParam);//solicitudes pendientes
        // const cond3 = solicitudesUsuario.data.find(x => x.id === idParam);//todas solicitudes
        if (responseTest[0].data.length === 1) {
            // await Promise.all([
            //     // fetchItemsTodos(),
            //     fetchItems(idParam),
            //     fetchAprobador1(idParam, usuarioAutenticado.data.id),
            //     fetchAprobador2(idParam, usuarioAutenticado.data.id),
            //     fetchGruposCotizacion(),
            //     // fetchCotizaciones()
            // ]);
            // fetchProveedores();
            // assignIdsolicitud(idParam);
            const resultados = await Promise.all([
                leer(`/aprobador1SolicitudRecursos/${id}/${usuarioAutenticado.data.id}`),
                leer(`/aprobador2SolicitudRecursos/${id}/${usuarioAutenticado.data.id}`),
                leer(`/procesador`),
                leer(`/hdd`),
                leer(`/ram`),
                leer(`/estado`),
                leer(`/proveedor?formato=simple`),
            ]);
            this.setState({
                loading: false,
                solicitudActual: dataSolicitud.solicitud_recurso,
                aprobador1: resultados[0].esAprobador ? true : false,
                aprobador2: resultados[1].esAprobador ? true : false,
                procesadores: resultados[2],
                discoDuro: resultados[3],
                memoriaRam: resultados[4],
                estados: resultados[5],
                proveedores: resultados[6],
            });
        } else {
            history.push('/');
        }
    }

    //Funcion para refrescar datos de solicitud
    recargarDatos = async (loadingMode, soloSolicitud) => {
        console.log("parametro", loadingMode);
        this.setState({ loading: loadingMode });
        
            const {
                assignIdsolicitud,
                fetchSolicitudes,
                fetchGruposCotizacion,
                fetchCotizaciones,
                fetchOrdenesCompra,
                fetchItems,
                fetchAprobador1,
                fetchAprobador2,
                fetchComentariosSolicitud,
                fetchProveedores,
                usuarioAutenticado
            } = this.props;
            // fetchSolicitudes(usuarioAutenticado.data.id, '');
            // fetchProveedores();
            // await Promise.all([
            //     // fetchSolicitudes(),
            //     fetchItems(idParam),
            //     fetchAprobador1(idParam, usuarioAutenticado.data.id),
            //     fetchAprobador2(idParam, usuarioAutenticado.data.id),
            //     fetchGruposCotizacion(),
            //     fetchCotizaciones(),
            //     fetchOrdenesCompra(),
            //     fetchComentariosSolicitud(idParam)
            // ]);
            // assignIdsolicitud(idParam);
            if (soloSolicitud) {
                const resultados = await Promise.all([
                    leer(`/detallesSolicitudRecursos/${this.state.solicitudActual.id}?usuario=${usuarioAutenticado.data.id}`),
                ]);
                this.setState({
                    loading: false,
                    solicitudActual: resultados[0].solicitud_recurso,
                });
            } else {
                const resultados = await Promise.all([
                    leer(`/detallesSolicitudRecursos/${this.state.solicitudActual.id}?usuario=${usuarioAutenticado.data.id}`),
                    leer(`/aprobador1SolicitudRecursos/${this.state.solicitudActual.id}/${usuarioAutenticado.data.id}`),
                    leer(`/aprobador2SolicitudRecursos/${this.state.solicitudActual.id}/${usuarioAutenticado.data.id}`),
                    leer(`/proveedor?formato=simple`),
                ]);
                this.setState({
                    loading: false,
                    solicitudActual: resultados[0].solicitud_recurso,
                    aprobador1: resultados[1].esAprobador ? true : false,
                    aprobador2: resultados[2].esAprobador ? true : false,
                    proveedores: resultados[3],
                });
            }
    };

    render() {
        const { loading } = this.state;
        const { estados, items, solicitudActual, classes, toggleDrawerComentarios, proyectos, history, comentarios } = this.props;
        // console.log("items de sol: ", items);
        return loading ? (
            <Spinner />
        ) : (
            <Grid item xs={12}>
                {/* <Comentarios /> */}
                <Grid>
                    <Typography align="center" variant="h4" gutterBottom>
                        Detalle del Proceso
                    </Typography>
                </Grid>
                <Grid>
                    <Typography align="center" variant="body1" gutterBottom>
                        <Tooltip title="Regresar a solicitudes">
                            <IconButton onClick={history.goBack}>
                                <i className="material-icons">keyboard_arrow_left</i>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Actualizar">
                            <IconButton onClick={() => this.recargarDatos(true)}>
                                <i className="material-icons">autorenew</i>
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </Grid>
                <Grid>
                    <Detalle
                        estados={this.state.estados}
                        // items={items}
                        solicitudActual={this.state.solicitudActual}
                        aprobador1={this.state.aprobador1}
                        aprobador2={this.state.aprobador2}
                        procesadores={this.state.procesadores}
                        discoDuro={this.state.discoDuro}
                        memoriaRam={this.state.memoriaRam}
                        proveedores={this.state.proveedores}
                        recargarDatos={this.recargarDatos}
                        // proyectos={proyectos}
                        // comentarios={comentarios.data}
                    />
                </Grid>
                {/* <Tooltip title="Comentarios">
                    <Fab
                        size="small"
                        variant="extended"
                        color="primary"
                        className={classes.fab}
                        onClick={() => {
                            toggleDrawerComentarios();
                        }}>
                        <ChevronLeftIcon />
                    </Fab>
                </Tooltip>
                {false && (
                    <Tooltip title="Log Estado">
                        <Fab
                            size="small"
                            variant="extended"
                            color="secondary"
                            className={classes.fab2}
                            onClick={() => {
                                toggleDrawerComentarios();
                            }}>
                            <ChevronLeftIcon />
                        </Fab>
                    </Tooltip>
                )} */}
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        estados: state.Estados,
        items: state.Items,
        usuarioAutenticado: state.Usuario,
        solicitudes: state.Solicitudes,
        solicitudesUsuario: state.SolicitudesUsuario,
        solicitudesAprobar: {
            data: state.Solicitudes.data.filter(x => state.SolicitudesAprobar.data.some(y => x.id === y.id)),
            fetched: state.SolicitudesAprobar.fetched,
            fetching: state.SolicitudesAprobar.fetching
        },
        // solicitudesAprobar: state.SolicitudesAprobar,
        solicitudActual: state.Solicitudes.data.filter(x => x.id === state.Idsolicitud.data)[0]
            ? state.Solicitudes.data.filter(x => x.id === state.Idsolicitud.data)
            : state.SolicitudesUsuario.data.filter(x => x.id === state.Idsolicitud.data),
        idSolicitud: state.Idsolicitud.data,
        proyectos: state.Proyectos.data,
        comentarios: state.Comentarios
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...solicitudesUsuarioDuck,
            ...solicitudesAprobarDuck,
            ...solicitudesDuck,
            ...idsolicitudDuck,
            ...itemsDuck,
            ...itemsAllDuck,
            ...aprobador1Duck,
            ...aprobador2Duck,
            ...gruposCotizacionDuck,
            ...cotizacionesDuck,
            ...ordenesCompraDuck,
            ...drawerComentariosDuck,
            ...comentariosDuck,
            ...proveedoresDuck
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DetalleSolicitudes));
