import React, { useState, useLayoutEffect, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import BotonMenuItems from '../components/BotonMenuItems';
import Table from '../components/Table';
import Modal from '../components/Modal';
import Spinner from '../components/Spinner';
import { addMillsSep } from '../services/utils';
import moment from 'moment';
import { leer, crear } from '../services/api';

const useStyles = makeStyles(theme => ({
    totales: {
        width: 350
    },
    textoInterior: {
        fontSize: 18,
        marginBottom: '0.20em'
    }
}));

export default function VerCotizacion(props) {
    const {
        idSolicitud,
        idCotizacion,
        handleVerDocumento,
        cargarIndicadores,
        handleRecepcionarTodoModal,
        revisarCotizacion,
        usuarioAutenticado,
        permisoEvaluarCosto,
        recepcionRealizada,
    } = props;
    const classes = useStyles();
    // let items = [];
    
    const [cotizacionSelected, setCotizacionSelected] = useState(null);
    const [evalCostoGuardada, setEvalCostoGuardada] = useState(false);
    const [itemsARecepcionar, setitemsARecepcionar] = useState([]);
    const [items, setItems] = useState([]);
    const [itemsPendientesRecepcion, setItemsPendientesRecepcion] = useState([]);
    const [evalCosto, setEvalCosto] = useState(0);
    const [porcentEvalCalidad, setPorcentEvalCalidad] = useState(0);
    const [porcentEvalCosto, setPorcentEvalCosto] = useState(0);
    const [porcentEvalEntrega, setPorcentEvalEntrega] = useState(0);
    const [errorEvalCosto, setErrorEvalCosto] = useState(false);
    const [modalEvalCostoCongif, setModalEvalCostoCongif] = useState({
        open: false,
        loading: false,
        mensaje: false
    });
    // let itemsARecepcionar = [];
    // let itemsPendientesRecepcion = [];
    useEffect(() => {
        if (idSolicitud && idCotizacion) {
            Promise.all([
                leer(`/detallesSolicitudRecursos/${idSolicitud}?usuario=${usuarioAutenticado.data.id}`),
                leer(`/detalleCotizacion/${idCotizacion}?usuario=${usuarioAutenticado.data.id}`),
            ]).then(responses => {
                const tempItems = responses[0].items;
                const tempCotizacion = responses[1];
                console.log("tempItems", tempItems);
                console.log("tempCotizacion", tempCotizacion);
            
                if (handleRecepcionarTodoModal && revisarCotizacion && tempCotizacion && tempCotizacion.estado_id === 23) {
                    let tempPendientes = [];
                    tempCotizacion.item_cotizacion.forEach(x => {
                        if (tempItems.find(y => y.id === x.item_recurso_id).estado === 12) {
                            tempPendientes.push({ ...tempItems.find(y => y.id === x.item_recurso_id) });
                        }
                    });
                    setItemsPendientesRecepcion(tempPendientes);
                    console.log("items para recepcion", tempItems, tempPendientes);
                }

                let calidad = 0;
                if (tempCotizacion?.evaluaciones_calidad && tempCotizacion?.evaluaciones_calidad[0]) {
                    let evalData = tempCotizacion.evaluaciones_calidad[0];
                    let sumaPuntos = 0;
                    let total = 0;
                    if (evalData.calidad1) sumaPuntos++;
                    if (evalData.calidad2) sumaPuntos++;
                    if (evalData.calidad3) sumaPuntos++;
                    if (evalData.calidad4) sumaPuntos++;
                    if (evalData.calidad5) sumaPuntos++;
                    if (evalData.calidad6) sumaPuntos++;
                    if (evalData.calidad7) sumaPuntos++;

                    if (evalData.calidad1 != null) total++;
                    if (evalData.calidad2 != null) total++;
                    if (evalData.calidad3 != null) total++;
                    if (evalData.calidad4 != null) total++;
                    if (evalData.calidad5 != null) total++;
                    if (evalData.calidad6 != null) total++;
                    if (evalData.calidad7 != null) total++;

                    calidad = total > 0 ? Math.round((sumaPuntos * 100) / total) : 0;
                }
                let costo = 0;
                if (tempCotizacion?.evaluaciones_costo && tempCotizacion?.evaluaciones_costo[0]) {
                    let evalData = tempCotizacion.evaluaciones_costo[0];

                    costo = Math.round((evalData.costo * 100) / 5);
                }

                let entrega = 0;
                if (tempCotizacion?.evaluaciones_entrega && tempCotizacion?.evaluaciones_entrega[0]) {
                    let evalData = tempCotizacion.evaluaciones_entrega[0];

                    entrega = evalData.entrega ? evalData.entrega * 100 : 0;
                }
                setPorcentEvalCalidad(calidad);
                setPorcentEvalCosto(costo);
                setPorcentEvalEntrega(entrega);
                setItems(tempItems);
                setCotizacionSelected(tempCotizacion);
            });
        }
    }, [evalCostoGuardada, recepcionRealizada]);
    // console.log("items soli: ", items);
    const handleItemSelectionChange = (rows) => {
        // console.log("items para recepcion", itemsPendientesRecepcion);
        // console.log("cambio en selection: ", rows);
        // console.log("cambio en selection selected: ", rows.filter(x => x.seleccionable));
        let arr = [];
        rows.filter(x => x.seleccionable).forEach((dataFila) => {
            // arr.push(itemsPendientesRecepcion.find(x => x.id === dataFila.item_recurso_id));
            arr.push(dataFila);
        });
        setitemsARecepcionar(arr);
    }

    const handleClickRecibirItems = () => {
        if (cotizacionSelected) {
            // console.log('hizo click recepcionar ítems marcados: ', itemsARecepcionar);
            handleRecepcionarTodoModal(cotizacionSelected.id, itemsARecepcionar);
        }
    }

    const handleChangeEvalCosto = (event) => {
        console.log("cambio eval costo: ", event.target.value);
        setEvalCosto(event.target.value);
        if (errorEvalCosto) setErrorEvalCosto(false);
    }

    const handleGuardarEvalCosto = async () => {
        if (evalCosto === 0) {
            setErrorEvalCosto(true);
        } else {
            console.log("guardando eval costoo", evalCosto);
            setModalEvalCostoCongif({
                open: true,
                loading: true,
                mensaje: false
            });
            await crear('/evaluacion-costo-proveedor', {
                cotizacion_id: cotizacionSelected.id,
                proveedor_id: cotizacionSelected.proveedor_id,
                costo: evalCosto,
                usuario_id: usuarioAutenticado.data.id,
                activo: true
            });
            setModalEvalCostoCongif({
                open: true,
                loading: false,
                mensaje: 'Evaluación guardada.'
            });
            setEvalCostoGuardada(true);
            if (cargarIndicadores) cargarIndicadores();
        }
    }
    // console.log("items a recepcionar", itemsARecepcionar);
    return (!cotizacionSelected
        ? <Spinner />
        : <div>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={5}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Empresa CyD
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.textoInterior}>
                                {`${cotizacionSelected?.sociedad.nombre} (${cotizacionSelected?.sociedad.rutFormateado})`}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Código
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.textoInterior}>
                                {cotizacionSelected?.codigo}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Fecha de Orden
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.textoInterior}>
                                {moment(cotizacionSelected ? cotizacionSelected.fecha_orden : '').format(
                                    'DD-MM-YYYY'
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={5}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Proveedor
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.textoInterior}>
                                {cotizacionSelected?.proveedorSucursal.proveedor.nombre}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Centro Logístico
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.textoInterior}>
                                {cotizacionSelected?.ubicacionLogistica.nombre}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Tipo de Moneda
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.textoInterior}>
                                {cotizacionSelected?.tipoMoneda}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            {cotizacionSelected && cotizacionSelected.tipoMoneda != 'CLP' && cotizacionSelected.tipoMoneda != 'PEN' && (
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Cambio de Moneda
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {cotizacionSelected?.cambioMoneda}
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={5}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Nombre Contacto
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.textoInterior}>
                                {cotizacionSelected?.atendidoPor}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Condiciones de Pago
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.textoInterior}>
                                {cotizacionSelected?.condicionPago}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Tipo de Compra
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.textoInterior}>
                                {cotizacionSelected?.esGastoReemb
                                    ? 'Gasto Reembolsable'
                                    : cotizacionSelected?.esTarifaGestion
                                    ? 'Tarifa de Gestión'
                                    : 'Varios'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={5}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Creada Por
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.textoInterior}>
                                {`${cotizacionSelected?.creador.nombre} ${cotizacionSelected?.creador.apellidos}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Estado
                            </Typography>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.textoInterior}>
                                {cotizacionSelected?.estado.nombre}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            {cotizacionSelected?.aprobadorCompras && (
                                    <div>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Aprobación Compras
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            className={classes.textoInterior}>
                                            {`${cotizacionSelected.aprobadorCompras.nombre} ${cotizacionSelected.aprobadorCompras.apellidos}`}
                                            {` (${moment(cotizacionSelected.fechaAprobacionCompras).format('DD-MM-YYYY')})`}
                                        </Typography>
                                    </div>
                                )}
                        </Grid>
                    </Grid>
                    {(cotizacionSelected?.aprobador || cotizacionSelected?.aprobadorGaf) && (
                        <Grid container spacing={3}>
                            {cotizacionSelected?.aprobador && (
                                <Grid item xs={5}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Aprobación Contrato
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {`${cotizacionSelected.aprobador.nombre} ${cotizacionSelected.aprobador.apellidos}`}
                                        {` (${moment(cotizacionSelected.fechaAprobacion).format('DD-MM-YYYY')})`}
                                    </Typography>
                                </Grid>
                            )}
                            {cotizacionSelected?.aprobadorGaf && (
                                <Grid item xs={7}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Aprobación GAF
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {`${cotizacionSelected.aprobadorGaf.nombre} ${cotizacionSelected.aprobadorGaf.apellidos}`}
                                        {` (${moment(cotizacionSelected.fechaAprobacionGaf).format('DD-MM-YYYY')})`}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {cotizacionSelected?.estado_id === 24 && (
                        <Grid container spacing={3}>
                            <Grid item xs={5}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Rechazada Por
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="h2"
                                    className={classes.textoInterior}>
                                    {`${cotizacionSelected.rechazador.nombre} ${cotizacionSelected.rechazador.apellidos}`}
                                    {` (${moment(cotizacionSelected.fechaRechazo).format('DD-MM-YYYY')})`}
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Motivo Rechazo
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="h2"
                                    className={classes.textoInterior}>
                                    {`${cotizacionSelected.motivoRechazo}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={3}>
                        <Grid item xs={5}>
                            {cotizacionSelected?.plazoEntrega && (
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Plazo Entrega Proveedor
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        className={classes.textoInterior}>
                                        {cotizacionSelected.tipoPlazo === 'habil'
                                            ? cotizacionSelected.plazoEntrega + ' días hábiles'
                                            : cotizacionSelected.plazoEntrega + ' días corridos'}
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={4}>
                            {cotizacionSelected && cotizacionSelected.evaluaciones_calidad?.length > 0 && (
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Evaluación de Compra
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                        className={classes.textoInterior}
                                        style={{ display: 'inline-block' }}>
                                        {'Calidad: ' + porcentEvalCalidad + '%'}
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                        className={classes.textoInterior}
                                        style={{ display: 'inline-block', marginLeft: 30 }}>
                                        {'Costo: ' + porcentEvalCosto + '%'}
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                        className={classes.textoInterior}
                                        style={{ display: 'inline-block', marginLeft: 30 }}>
                                        {'Entrega: ' + porcentEvalEntrega + '%'}
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container justify="space-between" alignItems="center" spacing={3}>
                        <Grid item xs>
                            {itemsPendientesRecepcion.length > 0 && (
                                <Button variant="outlined" color="primary" onClick={handleClickRecibirItems}>
                                    Recepcionar ítems
                                </Button>
                            )}
                            {permisoEvaluarCosto &&
                                cotizacionSelected?.deMisCentros &&
                                cotizacionSelected?.plazoEntrega &&
                                cotizacionSelected?.todoRecibido &&
                                !(cotizacionSelected?.evaluaciones_costo?.length > 0) && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            setModalEvalCostoCongif({
                                                open: true,
                                                loading: false,
                                                mensaje: false
                                            });
                                        }}>
                                        Evaluación de Costos
                                    </Button>
                                )}
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    if (cotizacionSelected) {
                                        // console.log('hizo click ver respaldo: ', cotizacionSelected.archivo_id);
                                        handleVerDocumento(cotizacionSelected.archivo_id, 'Respaldo');
                                    }
                                }}>
                                Ver Respaldo
                            </Button>
                        </Grid>
                        <Grid item xs></Grid>
                    </Grid>
                </CardContent>
            </Card>
            <br />
            <Table
                search={false}
                paging={false}
                toolbar={false}
                selection={itemsPendientesRecepcion.length > 0}
                handleSelectionChange={itemsPendientesRecepcion.length > 0 ? handleItemSelectionChange : null}
                columnas={[
                    {
                        title: 'Cantidad',
                        field: 'cantidad',
                        sorting: false,
                        cellStyle: {
                            width: '80px',
                            textAlign: 'center',
                            padding: '8px'
                        },
                        headerStyle: {
                            textAlign: 'center',
                            padding: '0px'
                        }
                    },
                    {
                        title: 'Estado',
                        field: 'estado',
                        sorting: false,
                        cellStyle: {
                            width: '116px',
                            padding: '8px',
                            textAlign: 'center'
                        },
                        headerStyle: {
                            textAlign: 'center',
                            padding: '0px'
                        }
                    },
                    // {
                    //     title: 'Detalles',
                    //     field: 'detalle',
                    //     cellStyle: {
                    //         padding: '0px',
                    //         textAlign: 'center'
                    //     },
                    //     headerStyle: {
                    //         textAlign: 'center',
                    //         padding: '0px'
                    //     }
                    // },
                    {
                        title: 'Descripción',
                        field: 'descripcion',
                        sorting: false,
                        cellStyle: {
                            padding: '8px',
                            textAlign: 'left'
                        },
                        headerStyle: {
                            padding: '0px',
                            textAlign: 'center'
                        }
                    },
                    {
                        title: 'Precio Unitario',
                        field: 'precioMostrar',
                        sorting: false,
                        cellStyle: {
                            width: '150px',
                            padding: '8px 10px 8px 8px',
                            textAlign: 'right'
                        },
                        headerStyle: {
                            padding: '0px',
                            textAlign: 'center'
                        }
                    },
                    {
                        title: 'Total Ítem',
                        field: 'totalItem',
                        sorting: false,
                        cellStyle: {
                            width: '150px',
                            padding: '8px 10px 8px 8px',
                            textAlign: 'right'
                        },
                        headerStyle: {
                            padding: '0px',
                            textAlign: 'center'
                        }
                    }
                ]}
                data={cotizacionSelected.item_cotizacion.map((x, id) => ({
                    ...x,
                    seleccionable: items.find(z => z.id === x.item_recurso_id)?.estado === 12,
                    tableData: { checked: itemsARecepcionar.find(z => z.id === x.id) ? true : false },
                    estado: items.find(z => z.id === x.item_recurso_id)?.estadoRef.nombre,
                    precioMostrar: cotizacionSelected
                        ? cotizacionSelected.tipoMoneda +
                          '$ ' +
                          addMillsSep(x.precio, cotizacionSelected.tipoMoneda)
                        : '',
                    totalItem: cotizacionSelected
                        ? cotizacionSelected.tipoMoneda +
                          '$ ' +
                          addMillsSep(parseFloat(x.precio * x.cantidad).toFixed(2), cotizacionSelected.tipoMoneda)
                        : ''
                }))}
            />
            <br />
            <Grid container justify="flex-end">
                <Grid item>
                    <Card className={classes.totales}>
                        <CardContent>
                            <Grid container alignItems="center" spacing={3}>
                                <Grid item xs>
                                    <Typography variant="h6">Subtotal</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>
                                        {cotizacionSelected ? cotizacionSelected.tipoMoneda + '$ ' : ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align="right">
                                        {cotizacionSelected
                                            ? addMillsSep(
                                                  (
                                                      parseFloat(cotizacionSelected.montoTotal) -
                                                      parseFloat(cotizacionSelected.iva)
                                                  ).toFixed(2),
                                                  cotizacionSelected.tipoMoneda
                                              )
                                            : ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" spacing={3}>
                                <Grid item xs>
                                    <Typography variant="h6">{cotizacionSelected?.pais?.impuesto?.nombre ? cotizacionSelected.pais.impuesto.nombre : 'IVA'}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>
                                        {cotizacionSelected ? cotizacionSelected.tipoMoneda + '$ ' : ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align="right">
                                        {cotizacionSelected
                                            ? addMillsSep(cotizacionSelected.iva, cotizacionSelected.tipoMoneda)
                                            : ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" spacing={3}>
                                <Grid item xs>
                                    <Typography variant="h6">Total</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>
                                        {cotizacionSelected ? cotizacionSelected.tipoMoneda + '$ ' : ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align="right">
                                        {cotizacionSelected
                                            ? addMillsSep(
                                                  cotizacionSelected.montoTotal,
                                                  cotizacionSelected.tipoMoneda
                                              )
                                            : ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Modal
                open={modalEvalCostoCongif.open}
                handleClose={() => {
                    setModalEvalCostoCongif({
                        open: false,
                        loading: false,
                        mensaje: false
                    });
                    setEvalCosto(0);
                    setErrorEvalCosto(false);
                }}
                // confirmacion={false}
                // accion={false}
                loading={modalEvalCostoCongif.loading}
                title="Evaluación de Costo"
                // fullWidth={true}
                maxWidth="sm"
                showTitle={true}>
                {modalEvalCostoCongif.mensaje ? (
                    modalEvalCostoCongif.mensaje
                ) : (
                    <div>
                        <RadioGroup
                            value={evalCosto}
                            onChange={handleChangeEvalCosto}
                            style={{ color: errorEvalCosto ? '#ff0000' : '#000000de' }}>
                            <FormControlLabel
                                style={{ marginBottom: 0 }}
                                value="5"
                                control={<Radio color="primary" />}
                                label="El precio es menor en más del 10% respecto al mercado."
                            />
                            <FormControlLabel
                                style={{ marginBottom: 0 }}
                                value="4"
                                control={<Radio color="primary" />}
                                label="El precio es menor en más del 5% respecto al mercado."
                            />
                            <FormControlLabel
                                style={{ marginBottom: 0 }}
                                value="3"
                                control={<Radio color="primary" />}
                                label="El precio se encuentra en el promedio del mercado."
                            />
                            <FormControlLabel
                                style={{ marginBottom: 0 }}
                                value="2"
                                control={<Radio color="primary" />}
                                label="El precio es mayor en más del 5% sobre el mercado."
                            />
                            <FormControlLabel
                                style={{ marginBottom: 0 }}
                                value="1"
                                control={<Radio color="primary" />}
                                label="El precio es mayor en más del 10% del valor en el mercado."
                            />
                        </RadioGroup>
                        <Grid container justify="center" alignItems="center" style={{ marginTop: 30 }}>
                            <Button variant="outlined" color="primary" onClick={handleGuardarEvalCosto}>
                                Guardar
                            </Button>
                        </Grid>
                    </div>
                )}
            </Modal>
        </div>
    );
}
