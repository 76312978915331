import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import DrawerComentarios from '../containers/DrawerComentarios';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Card from '../components/Card'
import Spinner from '../components/Spinner'
import * as drawerComentariosDuck from '../ducks/DrawerComentarios'
import * as comentariosDuck from '../ducks/Comentarios'
import * as etapasDuck from '../ducks/Etapas'
import FormularioComentario from './FormularioComentario'
import Divider from '@material-ui/core/Divider';
import moment from 'moment'
import 'moment/locale/es'

const useStyles = makeStyles( theme => ({
    list: {
      width: 400,
    },
    fullList: {
      width: 'auto',
    },
    fab:{
      position: 'fixed',
      bottom: theme.spacing(25),
      right: theme.spacing(1),
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
}));

export default function Comentarios() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const usuarios = useSelector(state => state.Usuarios)
  const comentarios = useSelector(state => state.Comentarios)
  const idSolicitud = useSelector(state => state.Idsolicitud.data)
  const [loading, setLoading ] = React.useState(true)
  React.useEffect( () => {
    const cargar = async () => {
      await dispatch( comentariosDuck.fetchComentariosSolicitud(idSolicitud) )
      await dispatch( etapasDuck.fetchEtapas() )
      setLoading(false)
    }
    cargar()
  },[])

    return(
        <DrawerComentarios>
            <div
                className={classes.list}
                role="presentation"
                onClick={() => {}}
                onKeyDown={() => {}}
                >
                
                {loading ? <Spinner /> :
                    <div>
                    <Grid container style={{ paddingBottom:"20px"}} >
                        <Grid item xs={12} lg={12} xl={12}>
                        <Card>
                            <FormularioComentario />  
                        </Card>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginBottom: '10px'}}/>
                    {comentarios.data.map(x =>
                    <Grid key={x.id} container style={{ paddingBottom: '5px'}}>
                        <Grid item xs={11}>
                        <Card info={ usuarios.data.find(y => y.id === x.usuario_id).nombres+ " " + usuarios.data.find(y => y.id === x.usuario_id).apellidos + "  -  "+ moment(x.fecha).format('DD-MM-YYYY HH:mm')}>
                            {x.comentario}
                        </Card>
                        </Grid>
                    </Grid>
                    )}          
                        <Tooltip title="Cerrar" >
                            <Fab size="small" variant="extended" color="primary" className={classes.fab} onClick={() => dispatch(drawerComentariosDuck.toggleDrawerComentarios())} >
                                <ChevronRightIcon />
                            </Fab>
                        </Tooltip>
                    </div>
                }
            </div>
        </DrawerComentarios>

    )        
}