import { makeTypes, asyncMac, createReducer, reduceReducers, mac } from 'ducks-maker'
import { createFetch, assignHOR } from './hors.js'
import { leer } from '../services/api'

const t = makeTypes('Usuario')
const FETCH = t('fetch').async()
const ASSIGN = t('assign').single()

export const agregarUsuario = mac(ASSIGN,'payload') 
const fetchActions = asyncMac(FETCH)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const addReducer = createReducer(initialState, assignHOR({ ASSIGN }))

export default reduceReducers(fetchReducer, addReducer)

export const fetchUsuario = userId => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer('/usuario', userId )
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}