import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Grid, Typography, Tooltip, IconButton, Card, CardContent, Paper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import HighchartsReact from 'highcharts-react-official';
import Spinner from '../components/Spinner';
import DetalleSolicitud from './DetalleSolicitud';
import FormFiltrosDashboard from './FormFiltrosDashboard';
import Table from '../components/Table';
import ExcelJS from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';
import { crear, leer } from '../services/api';
import moment from 'moment';

highchartsMore(Highcharts);
solidGauge(Highcharts);

const KpiTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: 10,
    textAlign: 'center'
  }
}))(Tooltip);

const useStyles = makeStyles({
  root: {
    typography: {
      fontSize: '12px'
    }
  },
  kpiLogo: {
    width: '50px',
    position: 'absolute',
    marginTop: '-30px'
  },
  kpiLogoColorOrange: {
    background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
    boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 152 0 / 40%)'
  },
  kpiLogoColorGreen: {
    background: 'linear-gradient(60deg, #66bb6a, #43a047)',
    boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)'
  },
  kpiLogoColorRed: {
    background: 'linear-gradient(60deg, #ef5350, #e53935)',
    boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(244 67 54 / 40%)'
  },
  kpiLogoGrid: {
    height: '50px'
  },
  kpiLogoIcon: {
    color: 'white',
    fontSize: '22px'
  },
  kpiCard: {
    width: '140px'
  },
  kpiCardContent: {
    paddingBottom: '10px !important'
  },
  kpiValue: {
    textAlign: 'right',
    marginBottom: '0'
  },
  kpiTitle: {
    textAlign: 'center'
  }
});

const Dashboard = props => {
  const {
    formFiltros,
    usuarioAutenticado,
    permisosUsuario,
  } = useSelector(state => ({
    formFiltros: state.form.filtrosDashboard,
    usuarioAutenticado: state.Usuario,
    permisosUsuario: state.PermisosUsuario,
  }));
  const refTabla = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [configChart1, setConfigChart1] = useState(null);
  const [configChart2, setConfigChart2] = useState(null);
  const [configChart3, setConfigChart3] = useState(null);
  const [configChart4, setConfigChart4] = useState(null);
  const [configChart5, setConfigChart5] = useState(null);
  const [totalSolicitudes, setTotalSolicitudes] = useState('-');
  const [diasAprob, setDiasAprob] = useState('-');
  const [diasAtraso, setDiasAtraso] = useState('-');
  const [loading, setLoading] = useState(false);
  const [exportandoExcel, setExportandoExcel] = useState(false);
  const [proyectos, setProyectos] = useState([]);
  const [gerencias, setGerencias] = useState([]);
  const [centros, setCentros] = useState([]);
  const [estados, setEstados] = useState([]);
  const [tiposRecursos, setTiposRecursos] = useState([]);

  useLayoutEffect(() => {
    if (permisosUsuario.data.some(x => x.nombre === 'verDashboard')) {
      let fechaDesde = moment().subtract(3, 'months');
      dispatch(change('filtrosDashboard', 'desde', fechaDesde.format('DD/MM/YYYY')));
      dispatch(change('filtrosDashboard', 'hasta', moment().format('DD/MM/YYYY')));
      // console.log("se obtienen datos form inicio");
      leer(`/getListasFormDashboard?usuario=${usuarioAutenticado.data.id}`).then(response => {
        setProyectos(response.contratos);
        setGerencias(response.gerencias);
        setCentros(response.centros);
        setEstados(response.estados);
        setTiposRecursos(response.tiposRecurso);
      });
    } else {
      props.history.replace('/');
    }
  }, []);

  useEffect(() => {
    // console.log("algo cambio", formFiltros);
    if (formFiltros && formFiltros.values && !loading) {
      // console.log('obteniendo datos form');
      setLoading(true);
      let filtros = formFiltros.values;
      let fechaDesde = moment(filtros.desde, 'DD/MM/YYYY');
      let fechaHasta = moment(filtros.hasta, 'DD/MM/YYYY').endOf('day');
      crear('/getIndicadoresDashboard', {
        ...filtros,
        desde: fechaDesde.toDate(),
        hasta: fechaHasta.toDate(),
        usuario: usuarioAutenticado.data.id
      }).then(response => {
        cargarDatos(response);
      });
    }
  }, [formFiltros?.values]);

  function contenidoDetalle(rowData, refreshTable) {
    return (
      <div style={{ padding: '8px', backgroundColor: '#f5f5f5' }}>
        <Paper>
          <DetalleSolicitud
            style={{ padding: '8px' }}
            idPanel={rowData.idSolicitud}
            idSolicitud={rowData.idSolicitud}
            solicitudActual={rowData.dataOriginal}
            handleLoadingSolicitud={handleLoadingSolicitud}
            handleCierreDetalle={handleCierreDetalle}
            refreshTable={refreshTable}
        />
        </Paper>
      </div>
    );
  }

  function handleLoadingSolicitud(isLoading) {
    // this.setState({ ...this.state, loadingSolicitud: isLoading, detalleVisible: true });
    // console.log("handle loading solicitud", isLoading);
  }

  function handleCierreDetalle() {
    // this.setState({ ...this.state, detalleVisible: false });
    // console.log("cierre detalle");
  }

  function exportarTablaExcel() {
    setExportandoExcel(true);
    let filtros = formFiltros.values;
    let fechaDesde = moment(filtros.desde, 'DD/MM/YYYY');
    let fechaHasta = moment(filtros.hasta, 'DD/MM/YYYY').endOf('day');

    crear(`/getDataExcel`, {
      ...filtros,
      desde: fechaDesde.toDate(),
      hasta: fechaHasta.toDate(),
      usuario: usuarioAutenticado.data.id
    }).then(response => {
      // console.log('datos para excel', response);
      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet('Solicitudes');
      const wsItems = wb.addWorksheet('Ítems');
      ws.columns = [
        { header: 'Código', width: 15 },
        { header: 'Tipo', width: 22 },
        { header: 'Clase', width: 30 },
        { header: 'Centro', width: 12 },
        { header: 'Estado', width: 29 },
        { header: 'Proyecto', width: 13 },
        { header: 'Nombre Proyecto', width: 52 },
        { header: 'Solicitante', width: 34 },
        { header: 'Fecha Solicitud', width: 21 },
        { header: 'Fecha Aprobación', width: 21 },
        { header: 'Fecha Término', width: 21 }
      ];
      wsItems.columns = [
        { header: 'ID Interno', width: 15, hidden: true },
        { header: 'Solicitud', width: 15 },
        { header: 'Cantidad', width: 9 },
        { header: 'Estado', width: 29 },
        { header: 'Centro', width: 12 },
        { header: 'Tipo', width: 22 },
        { header: 'Clase', width: 30 },
        { header: 'Detalle', width: 50 },
        { header: 'Descripción', width: 70 },
        { header: 'Fecha Requerida', width: 15 },
        { header: 'Reasignado', width: 10 },
        { header: 'Ahorro por Reasignación', width: 15 },
        { header: 'Fecha Creación Cotización', width: 21 },
        { header: 'Fecha Creación OC', width: 21 },
        { header: 'Rut Proveedor', width: 13 },
        { header: 'Nombre Proveedor', width: 30 },
        { header: 'Monto Compra', width: 15 },
        { header: 'Ahorro por Negociación', width: 15 },
        { header: 'Comentario Compra', width: 70 },
        { header: 'Fecha Sistema Recepción', width: 21 },
        { header: 'Fecha Ingresada Recepción', width: 21 },
        { header: 'Usuario Recepción', width: 30 },
        { header: 'Fecha Sistema Traslado', width: 21 },
        { header: 'Fecha Ingresada Traslado', width: 21 },
        { header: 'Usuario Traslado', width: 30 },
        { header: 'N Seguimiento', width: 30 },
        { header: 'Info Traslado', width: 30 },
        { header: 'Fecha Sistema Entrega', width: 21 },
        { header: 'Fecha Ingresada Entrega', width: 21 },
        { header: 'Usuario Entrega', width: 30 },
        { header: 'Lugar Entrega', width: 30 },
        { header: 'Nombre Entrega', width: 30 },
        { header: 'Nombre Recibe', width: 30 }
      ];

      ws.getRow(1).font = { bold: true };
      wsItems.getRow(1).font = { bold: true };

      ws.addRows(response.dataSR);
      wsItems.addRows(response.dataItems);

      wb.xlsx
        .writeBuffer()
        .then(function (bufferData) {
          // console.log("exito en crear buffer");
          var blob = new Blob([bufferData], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          saveAs(blob, 'Solicitudes ' + moment().format('YYYY-MM-DD HH:mm:ss') + '.xlsx');
          setExportandoExcel(false);
        })
        .catch(function (error) {
          console.log('error en excel:', error);
          setExportandoExcel(false);
        });
    });
  }

  function renderProyecto(rowData) {
    return (
      <Tooltip
        enterTouchDelay={0}
        title={
          <div style={{ whiteSpace: 'pre', textAlign: 'center' }}>
            {
              rowData.proyecto.nombre
              + "\nSociedad: " + rowData.proyecto.sociedad
            }
          </div>
        }>
        <div>
          {rowData.proyecto.codigo}
        </div>
      </Tooltip>
  );
  }

  function renderClase(rowData) {
    return (
      <div style={{ whiteSpace: 'pre' }}>
        {rowData.clase}
      </div>
    );
  }

  function actualizarDatos() {
    if (formFiltros?.values) {
      // console.log("obteniendo datos reload");
      setLoading(true);
      let filtros = formFiltros.values;
      let fechaDesde = moment(filtros.desde, 'DD/MM/YYYY');
      let fechaHasta = moment(filtros.hasta, 'DD/MM/YYYY').endOf('day');
      crear('/getIndicadoresDashboard', {
        ...filtros,
        desde: fechaDesde.toDate(),
        hasta: fechaHasta.toDate(),
        usuario: usuarioAutenticado.data.id
      }).then(response => {
        cargarDatos(response);
      });
    }
  }

  function cargarDatos(datos) {
    let nuevaConfig1 = {
      chart: {
        type: 'column',
        height: 200
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Solicitudes Mensuales'
      },
      xAxis: {
        categories: datos.meses
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      series: [
        {
          data: datos.cantSolPorMes,
          name: 'Solicitudes'
        },
        {
          data: datos.cantItemsPorMes,
          type: 'line',
          name: 'Items'
        }
      ],
      tooltip: {
        headerFormat: '',
        pointFormat: '{point.y}'
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
      },
      plotOptions: {
        series:{
          label:{
            enabled: false
          }
        }
      }
    };

    let nuevaConfig2 = {
      chart: {
        type: 'column',
        height: 200
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Solicitudes por Gerencia'
      },
      xAxis: {
        categories: datos.nomGerencias
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      series: [
        {
          data: datos.cantSolPorGerencia
        }
      ],
      tooltip: {
        headerFormat: '',
        pointFormat: '{point.y}'
      },
      legend: {
        enabled: false
      }
    };

    let nuevaConfig3 = {
      chart: {
        type: 'pie',
        height: 200
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Solicitudes por Tipo'
      },
      xAxis: {
        categories: datos.nomGerencias
      },
      // yAxis: {
      //   title: {
      //     text: 'Solicitudes'
      //   }
      // },
      series: [
        {
          data: [
            {
              name: 'Vehículos',
              y: Math.round((datos.cantSolicitudesVehi / datos.totalSolicitudes) * 10000) / 100,
              cant: datos.cantSolicitudesVehi
              // sliced: true,
              // selected: true
            },
            {
              name: 'Topografía y Laboratorio',
              y: Math.round((datos.cantSolicitudesTopo / datos.totalSolicitudes) * 10000) / 100,
              cant: datos.cantSolicitudesTopo
            },
            {
              name: 'Computación',
              y: Math.round((datos.cantSolicitudesComp / datos.totalSolicitudes) * 10000) / 100,
              cant: datos.cantSolicitudesComp
            },
            {
              name: 'Misceláneos',
              y: Math.round((datos.cantSolicitudesMisc / datos.totalSolicitudes) * 10000) / 100,
              cant: datos.cantSolicitudesMisc
            }
          ]
        }
      ],
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          size: '100%',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.cant}',
            style: {
              fontSize: '10px',
              width: '100px'
            }
          }
        }
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '{point.y}%'
      },
      legend: {
        enabled: false
      }
    };

    let nuevaConfig4 = {
      chart: {
        type: 'solidgauge',
        height: 78
      },
      credits: {
        enabled: false
      },
      title: null,
      pane: {
        center: ['50%', '85%'],
        size: '170%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },
      tooltip: {
        enabled: false
      },
      yAxis: {
        stops: [
          [0.3, '#DF5353'], // red
          [0.5, '#DDDF0D'], // yellow
          [0.7, '#55BF3B'] // green
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -23,
          text: ''
        },
        labels: {
          distance: -8,
          y: 12,
          style: {
            fontSize: '10px'
          }
        },
        min: 0,
        max: 100
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 1,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      series: [
        {
          name: 'Terminadas',
          data: [datos.porcentTerminadas],
          dataLabels: {
            format:
              '<div style="text-align:center; margin-bottom: 5px">' +
              '<span style="font-size:14px">{y}%</span><br/>' +
              '</div>'
          }
        }
      ]
    };

    let nuevaConfig5 = {
      chart: {
        type: 'solidgauge',
        height: 78
      },
      credits: {
        enabled: false
      },
      title: null,
      pane: {
        center: ['50%', '85%'],
        size: '170%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },
      tooltip: {
        enabled: false
      },
      yAxis: {
        stops: [
          [0.3, '#55BF3B'], // green
          [0.5, '#DDDF0D'], // yellow
          [0.7, '#DF5353'] // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -23,
          text: ''
        },
        labels: {
          distance: -8,
          y: 12,
          style: {
            fontSize: '10px'
          }
        },
        min: 0,
        max: 100
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 1,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      series: [
        {
          name: 'Atrasadas',
          data: [datos.porcentAtraso],
          dataLabels: {
            format:
              '<div style="text-align:center; margin-bottom: 5px">' +
              '<span style="font-size:14px">{y}%</span><br/>' +
              '</div>'
          }
        }
      ]
    };
    setTotalSolicitudes(datos.totalSolicitudes);
    setDiasAprob(datos.promDiasAprob);
    setDiasAtraso(datos.promDiasAtraso);
    setConfigChart1(nuevaConfig1);
    setConfigChart2(nuevaConfig2);
    setConfigChart3(nuevaConfig3);
    setConfigChart4(nuevaConfig4);
    setConfigChart5(nuevaConfig5);
    setLoading(false);
  }

  return (
    <div>
      <Grid container spacing={3} justify="center">
        <Grid item>
          <Grid container justify="center" alignItems="center">
            <Typography align="center" variant="h4" style={{ display: 'inline-block' }}>
              Resumen Sistema de Adquisiciones
            </Typography>
            <Tooltip title="Actualizar">
              <span>
                <IconButton
                  color="primary"
                  style={{ padding: '6px', marginLeft: '10px' }}
                  disabled={loading}
                  onClick={actualizarDatos}>
                  <AutorenewIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ display: loading ? 'none' : 'flex' }}>
        <Grid item xs>
          <Grid container spacing={3} justify="space-evenly">
            <Grid item>
              <Card variant="outlined" className={classes.kpiCard}>
                <KpiTooltip
                  title="Cantidad total de solicitudes analizadas de acuerdo a los filtros."
                  placement="top"
                  arrow>
                  <CardContent className={classes.kpiCardContent}>
                    <Paper className={`${classes.kpiLogo} ${classes.kpiLogoColorOrange}`}>
                      <Grid container justify="center" alignItems="center" className={classes.kpiLogoGrid}>
                        <FileCopyOutlinedIcon className={classes.kpiLogoIcon} />
                      </Grid>
                    </Paper>
                    <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                      {totalSolicitudes}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.kpiTitle}>
                      Total Solicitudes
                    </Typography>
                  </CardContent>
                </KpiTooltip>
              </Card>
            </Grid>
            <Grid item>
              <Card variant="outlined" className={classes.kpiCard}>
                <KpiTooltip title="Promedio de días en aprobación de solicitudes terminadas." placement="top" arrow>
                  <CardContent className={classes.kpiCardContent}>
                    <Paper className={`${classes.kpiLogo} ${classes.kpiLogoColorGreen}`}>
                      <Grid container justify="center" alignItems="center" className={classes.kpiLogoGrid}>
                        <ThumbUpOutlinedIcon className={classes.kpiLogoIcon} />
                      </Grid>
                    </Paper>
                    <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                      {diasAprob.toString().replace('.', ',')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.kpiTitle}>
                      Días Aprobación
                    </Typography>
                  </CardContent>
                </KpiTooltip>
              </Card>
            </Grid>
            <Grid item>
              <Card variant="outlined" className={classes.kpiCard}>
                <KpiTooltip
                  title={
                    'Promedio de días de atraso, considerando Fecha Requerida y Fecha Entrega de solicitudes terminadas.'
                  }
                  placement="top"
                  arrow>
                  <CardContent className={classes.kpiCardContent}>
                    <Paper className={`${classes.kpiLogo} ${classes.kpiLogoColorRed}`}>
                      <Grid container justify="center" alignItems="center" className={classes.kpiLogoGrid}>
                        <ErrorOutlineIcon className={classes.kpiLogoIcon} />
                      </Grid>
                    </Paper>
                    <Typography gutterBottom variant="h5" component="p" className={classes.kpiValue}>
                      {diasAtraso.toString().replace('.', ',')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.kpiTitle}>
                      Días Atraso
                    </Typography>
                  </CardContent>
                </KpiTooltip>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="space-evenly">
            <Grid item xs={4}>
              <KpiTooltip title="Porcentaje de solicitudes terminadas y entregadas." placement="top" arrow>
                <Paper
                  variant="outlined"
                  style={{ padding: '1px', height: '82px', overflow: 'hidden', paddingTop: '9px' }}>
                  <div style={{ position: 'relative' }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={classes.kpiTitle}
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        width: '100%',
                        top: '-6px',
                        left: '50%',
                        transform: 'translateX(-50%)'
                      }}>
                      Solicitudes Terminadas
                    </Typography>
                  </div>
                  <HighchartsReact highcharts={Highcharts} options={configChart4} />
                </Paper>
              </KpiTooltip>
            </Grid>
            <Grid item xs={4}>
              <KpiTooltip
                title="Porcentaje de solicitudes entregadas con atraso respecto a la Fecha Requerida."
                placement="top"
                arrow>
                <Paper
                  variant="outlined"
                  style={{ padding: '1px', height: '82px', overflow: 'hidden', paddingTop: '9px' }}>
                  <div style={{ position: 'relative' }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={classes.kpiTitle}
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        width: '100%',
                        top: '-6px',
                        left: '50%',
                        transform: 'translateX(-50%)'
                      }}>
                      Solicitudes Atrasadas
                    </Typography>
                  </div>
                  <HighchartsReact highcharts={Highcharts} options={configChart5} />
                </Paper>
              </KpiTooltip>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <FormFiltrosDashboard
            gerencias={gerencias}
            proyectos={proyectos}
            centros={centros}
            codigosSol={[]}
            estados={estados.filter(x => x.id <= 8 || x.id == 12 || x.id == 17 || x.id == 18 || x.id == 20)}
            tiposRecursos={tiposRecursos}
          />
        </Grid>
      </Grid>
      {loading && <Spinner />}
      {!loading && (
        <Grid container spacing={3} style={{ height: '210px' }}>
          <Grid item xs={4}>
            <Paper variant="outlined" style={{ padding: '1px' }}>
              <HighchartsReact highcharts={Highcharts} options={configChart1} />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper variant="outlined" style={{ padding: '1px' }}>
              <HighchartsReact highcharts={Highcharts} options={configChart2} />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper variant="outlined" style={{ padding: '1px' }}>
              <HighchartsReact highcharts={Highcharts} options={configChart3} />
            </Paper>
          </Grid>
        </Grid>
      )}
      {!loading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Table
                tableRef={refTabla}
                searchID="mainSearchDashboard"
                filtering={false}
                grouping={false}
                columnVis={false}
                detalleF={contenidoDetalle}
                actions={[
                  {
                    icon: 'save_alt',
                    disabled: exportandoExcel,
                    tooltip: 'Exportar a Excel',
                    isFreeAction: true,
                    onClick: exportarTablaExcel
                  }
                ]}
                columnas={[
                  {
                    title: 'Código',
                    field: 'codigo',
                    sorting: false,
                    cellStyle: {
                      width: '130px',
                      textAlign: 'center'
                    },
                    headerStyle: {
                      textAlign: 'center'
                    }
                  },
                  {
                    title: 'Tipo',
                    field: 'tipoRecurso',
                    sorting: false,
                    cellStyle: {
                      width: '110px',
                      textAlign: 'center'
                    },
                    headerStyle: {
                      textAlign: 'center'
                    }
                  },
                  {
                    title: 'Clase',
                    sorting: false,
                    cellStyle: {
                        width: '100px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                    },
                    render: rowData => renderClase(rowData)
                },
                  {
                    title: 'Centro Solicitud',
                    field: 'centro',
                    sorting: false,
                    cellStyle: {
                      width: '110px',
                      textAlign: 'center'
                    },
                    headerStyle: {
                      textAlign: 'center'
                    }
                  },
                  {
                    title: 'Estado',
                    field: 'estadoMostrar',
                    sorting: false,
                    grouping: false,
                    cellStyle: {
                      width: '100px',
                      textAlign: 'center'
                    },
                    headerStyle: {
                      textAlign: 'center'
                    }
                  },
                  {
                    title: 'Proyecto',
                    field: 'proyecto',
                    sorting: false,
                    cellStyle: {
                      width: '120px',
                      textAlign: 'center'
                    },
                    headerStyle: {
                      textAlign: 'center'
                    },
                    render: rowData => renderProyecto(rowData)
                  },
                  {
                    title: 'Solicitante',
                    field: 'solicitante',
                    sorting: false,
                    cellStyle: {
                      width: '120px',
                      textAlign: 'center'
                    },
                    headerStyle: {
                      textAlign: 'center'
                    }
                  },
                  {
                    title: 'Tiempo Transcurrido',
                    field: 'fecha',
                    sorting: false,
                    filtering: false,
                    grouping: false,
                    cellStyle: {
                      width: '130px',
                      textAlign: 'center'
                    },
                    headerStyle: {
                      textAlign: 'center'
                    }
                  }
                ]}
                remoteData={true}
                data={
                  query => new Promise((resolve, reject) => {
                    // console.log('query en dashboard', formFiltros.values, centros);
                    const filters = [];
                    if (formFiltros?.values?.desde)
                      filters.push({ field: 'desde', value: moment(formFiltros.values.desde, 'DD/MM/YYYY').toDate() });
                    if (formFiltros?.values?.hasta)
                      filters.push({
                        field: 'hasta',
                        value: moment(formFiltros.values.hasta, 'DD/MM/YYYY').endOf('day').toDate()
                      });
                      if (formFiltros?.values?.centro && formFiltros?.values?.centro.length)
                      filters.push({ field: 'centroDashboard', value: formFiltros.values.centro });
                    if (formFiltros?.values?.proyecto && formFiltros?.values?.proyecto.length)
                      filters.push({ field: 'proyectoDashboard', value: formFiltros.values.proyecto });
                    if (
                      formFiltros?.values?.gerencia &&
                      formFiltros?.values?.gerencia.length &&
                      (!formFiltros?.values?.proyecto || formFiltros?.values?.proyecto.length === 0)
                    )
                      filters.push({ field: 'gerenciaDashboard', value: formFiltros.values.gerencia });
                    if (formFiltros?.values?.tipoRecurso && formFiltros?.values?.tipoRecurso.length)
                      filters.push({ field: 'tipoRecursoDashboard', value: formFiltros.values.tipoRecurso });
                    if (formFiltros?.values?.estado && formFiltros?.values?.estado.length)
                      filters.push({ field: 'estadoDashboard', value: formFiltros.values.estado });
                    const options = {
                        estado: '',
                        page: query.page,
                        pageSize: query.pageSize,
                        filters: filters,
                        search: query.search.trim(),
                    }
                    crear(`/solicitudesRecursosUsuario/${usuarioAutenticado.data.id}`, options).then(resultado => {
                      // console.log("solicitudes en dashboard", resultado[0]);
                      resolve({
                        data: resultado[0].data.map(x => ({
                          ...x,
                          dataOriginal: x,
                          id: x.id,
                          idSolicitud: x.id,
                          idProyecto: x.proyecto.id,
                          tipoRecurso: x.tipo_recurso.nombre,
                          codigo: x.codigo,
                          proyecto: x.proyecto,
                          codProyecto: x.proyecto.codigo,
                          nomProyecto: x.proyecto.nombre,
                          centro: x.centro_logistico.nombre,
                          estado: x.estado,
                          estadoMostrar: x.estadoPrincipal,
                          solicitante: x.solicitante.nombre_completo,
                          fecha: (
                            <Tooltip
                              title={
                                <div style={{ whiteSpace: 'pre', textAlign: 'center' }}>
                                  {'Creada: ' +
                                    moment(x.fecha).format('DD-MM-YYYY HH:mm:ss') +
                                    (x.fecha_termino
                                      ? '\nTerminada: ' + moment(x.fecha_termino).format('DD-MM-YYYY HH:mm:ss')
                                      : '')}
                                </div>
                              }>
                              <div>
                                {x.fecha_termino
                                  ? moment(x.fecha_termino).diff(moment(x.fecha), 'days') + ' días'
                                  : moment(x.fecha).fromNow()}
                              </div>
                            </Tooltip>
                          ),
                          fechaCreacion: x.fecha ? moment(x.fecha).format('DD-MM-YYYY HH:mm:ss') : '',
                          fechaAprobacion: x.fecha_aprobacion2 ? moment(x.fecha_aprobacion2).format('DD-MM-YYYY HH:mm:ss') : '',
                          fechaTermino: x.fecha_termino ? moment(x.fecha_termino).format('DD-MM-YYYY HH:mm:ss') : '',
                          items: x.items
                        })),
                        page: query.page,
                        totalCount: resultado[0].pagination[0]?.total || 0
                      });
                    });
                  })
                }
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
