import React, { Component } from 'react';
import { TextField, Button, IconButton, Tooltip, Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

export default class FieldFileInput extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        if (!props.input.value && props.fileName) {
            props.handleOnChange({ target: { files: [] } });
        }
    }

    onChange = e => {
        const {
            input: { onChange }
        } = this.props;
        const { handleOnChange } = this.props;
        onChange(e.target.files[0]);
        handleOnChange(e);
    };

    clearInput = () => {
        const {
            input: { onChange },
            handleOnChange
        } = this.props;
        onChange(null);
        handleOnChange({ target: { files: [] } });
    };

    render() {
        const {
            input: { value }
        } = this.props;
        const { input, label, accept, handleOnChange, fileName, style, required, meta: { touched, error } } = this.props;

        return (
            <Grid container style={style}>
                <Grid item xs={12} lg={4}>
                    <Button variant="outlined" component="label" color="primary">
                        Seleccionar
                        <input onChange={this.onChange} style={{ display: 'none' }} type="file" accept={accept} />
                    </Button>
                </Grid>
                <Grid item xs={11} lg={7}>
                    <TextField
                        required={required}
                        error={touched && (error ? true : false)}
                        // label={label}
                        style={{ width: '100%' }}
                        placeholder={label ? label : 'No se ha seleccionado archivo'}
                        value={fileName}
                        readOnly
                        fullWidth={false}
                    />
                </Grid>
                <Grid item xs={1} lg={1}>
                    {value && fileName && (
                        <Tooltip title="Limpiar">
                            <IconButton
                                color="primary"
                                style={{ padding: '6px', float: 'right' }}
                                onClick={this.clearInput}>
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        );
    }
}
