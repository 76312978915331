import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'
import {IconButton} from '@material-ui/core'


export default function SimpleMenu(props) {
  const { activo, handleEditar, handleActivar, id, style } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div style={{ ...style }}>
      <IconButton color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <i className="material-icons">
        edit
        </i>
      </IconButton> 
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {handleClose();handleEditar(id,"editar")()}}>Editar</MenuItem>
        <MenuItem onClick={() => {handleClose();handleActivar(id, activo ? "desactivar" : "activar")()}} >{activo ? "Desactivar" : "Activar"}</MenuItem>
      </Menu>
    </div>
  );
}
