import { makeTypes, asyncMac, createReducer, reduceReducers } from 'ducks-maker';
import { createFetch } from './hors.js';
import { crear } from '../services/api';

const t = makeTypes('traslado-item-recurso')
const FETCH = t('fetch').async()
 
const fetchActions = asyncMac(FETCH)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))

export default reduceReducers(fetchReducer);
