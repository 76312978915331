import * as Sentry from "@sentry/react";
import { getCurrentUser } from './auth';

const baseUrl = 'https://api-sda.cydocs.cl/v1'

// const baseUrl = 'http://localhost:8000/v1'

async function request(url, method, data, retry) {
    var token = await getCurrentUser();
    // console.log(token.token);

    const response = await fetch(`${baseUrl}${url}`, {
        method: method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.token
        },
        body: data ? JSON.stringify(data) : undefined
    });
    if (method !== 'DELETE') {
        if (response.status === 503) {
            console.log(`Error 503, reintento ${retry}, url: ${response.url}`);
            if (retry < 3) {
                return request(url, method, data, retry + 1);
            } else {
                Sentry.captureMessage(`Error 503, reintento ${retry}, url: ${response.url}`);
                throw new Error(`Error 503, reintentos superados, url: ${response.url}`);
            }
        } else {
            const jsonResponse = await response.json();
            if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
                let error;
                if (jsonResponse && jsonResponse.errors) {
                    error = jsonResponse.errors;
                }
                throw error;
            }
            return jsonResponse;
        }
    }
}

async function requestArchivo(url, method, data) {
    var token = await getCurrentUser();
    var formData = new FormData();
    for (var name in data) {
        formData.append(name, data[name]);
    }

    const response = await fetch(`${baseUrl}${url}`, {
        method: method,
        headers: {
            Authorization: 'Bearer ' + token.token
        },
        body: formData ? formData : undefined
    });
    if (method !== 'DELETE') {
        const multipartResponse = await response.json();
        /*if(response.status !== 200 && response.status !== 201 && response.status !== 204){
            let error;
            if(jsonResponse && jsonResponse.errors){
                error = jsonResponse.errors[0].message
            }
            throw Error(error || "hubo un error")
        }*/
        return multipartResponse;
    }
}

export function crear(url, data) {
    return request(url, 'POST', data, 0);
}

export function modificar(url, id, data) {
    return request(`${url}/${id}`, 'PUT', data, 0);
}

export function leer(url, id) {
    if (id) return request(`${url}/${id}`, 'GET', null, 0);
    else return request(`${url}`, 'GET', null, 0);
}

export function borrar(url, id) {
    return request(`${url}/${id}`, 'DELETE', null, 0);
}

export function perfil(url, id, uid) {
    return request(`${url}/${id}/${uid}`, 'GET', null, 0);
}

export function solicitud(url, id, userId) {
    return request(`${url}/${id}?usuario=${userId}`, 'PUT', null, 0);
}

export function solicitarAprobacion(url, id) {
    return request(`${url}/${id}`, 'POST', null, 0);
}

export function crearArchivo(url, data) {
    return requestArchivo(url, 'POST', data);
}
