import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: props => props.dotcolor,
      color: props => props.dotcolor,
      top: props => props.indicador ? 8 : 'unset',
      left: props => props.indicador ? -8: 'unset',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

export default function SimpleBadge(props) {
    return (
        <StyledBadge
            style = {props.style}
            overlap={props.overlap ? props.overlap : 'rectangle'}
            anchorOrigin={props.anchorOrigin}
            variant={props.variant}
            dotcolor={props.color}
            indicador={props.indicador}
            >
            {props.children}
        </StyledBadge>
    );
}
