import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Spinner from '../components/Spinner'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const { open, loading, handleClose, confirmacion, accion, xl, title, fullWidth } = props

  return (
    <div>
      <Dialog
        open={open}
        maxWidth={xl ? 'xl' : false}
        fullWidth={fullWidth ? true : false}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{ title ? title : accion ? "Confirmación" : "Notificación"}</DialogTitle>
        <DialogContent>
          {props.children}
        </DialogContent>
        <DialogActions>
          {loading ? <Spinner /> :
          <div>
            <Button onClick={handleClose} color="secondary">
              Cerrar
            </Button>
            {confirmacion && 
              <Button onClick={accion} color="primary">
                Ver Solicitudes
              </Button>}
          </div>}
        </DialogActions>
      </Dialog>
    </div>
  );
}