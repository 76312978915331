import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Box, Typography } from '@material-ui/core';
import Table from '../components/Table';
import ConfiguracionProyecto from '../containers/ConfiguracionProyecto';
import { leer } from '../services/api';

const Proyectos = props => {
  const { permisos } = useSelector(state => ({ permisos: state.PermisosUsuario }));

  const [proyectos, setProyectos] = useState([]);
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    Promise.all([leer('/proyecto'), leer('/usuario')]).then(responses => {
      setProyectos(responses[0]);
      setUsuarios(responses[1]);
    });
  }, []);

  useEffect(() => {
    if (!permisos.data.some(x => x.nombre === 'verConfigProyectos')) props.history.push('/');
  }, [permisos]);

  return (
    <Box>
      <Grid container justify="center" alignItems="center">
        <Typography style={{ marginBottom: '30px' }} variant="h4">
          Proyectos
        </Typography>
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} lg={12}>
          <Table
            searchID="mainSearch"
            columnas={[
              {
                title: 'Id',
                field: 'id',
                hidden: true,
                sorting: false,
                cellStyle: {
                  textAlign: 'center',
                  width: '100px',
                  padding: '18px 0'
                },
                headerStyle: {
                  textAlign: 'center'
                }
              },
              {
                title: 'Código',
                field: 'codigo',
                cellStyle: {
                  textAlign: 'center',
                  width: '120px',
                  padding: '18px 0'
                },
                headerStyle: {
                  textAlign: 'center',
                  paddingLeft: '28px'
                }
              },
              {
                title: 'Nombre',
                field: 'nombre',
                cellStyle: {
                  textAlign: 'center',
                  padding: '0'
                },
                headerStyle: {
                  textAlign: 'center',
                  padding: '0 0 0 28px'
                }
              },
              {
                title: 'Gerencia',
                field: 'gerencia',
                cellStyle: {
                  textAlign: 'center',
                  padding: '0'
                },
                headerStyle: {
                  textAlign: 'center',
                  padding: '0 0 0 28px'
                }
              },
              {
                title: 'Jefe Proyecto',
                field: 'jefe_proyecto',
                cellStyle: {
                  textAlign: 'center',
                  width: '350px',
                  padding: '0'
                },
                headerStyle: {
                  textAlign: 'center',
                  padding: '0 0 0 28px'
                }
              }
            ]}
            data={proyectos
              .filter(x => x.activo)
              .map(x => ({
                ...x,
                gerencia: x.gerencia.nombre,
                jefe_proyecto: x.jefe_proyecto.nombre
              }))}
            detalle={[
              {
                tooltip: 'Configuraciones',
                render: rowData => {
                  return <ConfiguracionProyecto id={rowData.id} usuarios={usuarios} />;
                }
              }
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Proyectos;
