import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch,  editHOR, addHOR, delHOR, searchHOR, resetHOR, editFetch, removeErrorHOR, addFetch } from './hors.js'
import { leer, modificar, solicitud, crear } from '../services/api'

const t = makeTypes('Items')
const FETCH = t('fetch').async()
const EDITAR = t('editar').async()
const EDIT = t('select').single()
const AGREGAR = t('agregar').async()
const ADD = t('add').single()
const DEL = t('del').single()
const SEARCH = t('search').single()
const RESET = t('reset').single()
const REMOVEERROR = t('removeError').single()


const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDITAR)
const addActions = asyncMac(AGREGAR)
export const removeErrorItems = mac(REMOVEERROR)
export const editarItem = mac(EDIT, 'payload')
export const addItem = mac(ADD, 'payload')
export const delItem = mac(DEL, 'payload')
export const searchItem = mac(SEARCH,'payload')
export const resetItems = mac(RESET)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editarReducer = createReducer(initialState, editFetch( EDITAR ))
const agregarReducer = createReducer(initialState, addFetch( AGREGAR ))
const editReducer = createReducer(initialState, editHOR({ EDIT }))
const addReducer = createReducer(initialState, addHOR({ ADD }))
const delReducer = createReducer(initialState, delHOR({ DEL }))
const searchReducer = createReducer(initialState, searchHOR({ SEARCH }))
const resetReducer = createReducer(initialState, resetHOR({ RESET }))
const removeErrorReducer= createReducer(initialState, removeErrorHOR({ REMOVEERROR }))

export default reduceReducers(fetchReducer, editReducer, addReducer, delReducer, searchReducer, resetReducer, editarReducer, removeErrorReducer, agregarReducer )

export const fetchItems = id => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data = await leer(`/detallesSolicitudRecursos/${id}`)
        // data = data.items.filter( x => x.estado !== 4 && x.estado !== 5)
        dispatch(fetchActions.success(data.items))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const editarItems = (id, item) => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        let data = await modificar('/item-recurso', id, item)
        dispatch(editActions.success(data))
        return data
    } catch (error) {
        dispatch(editActions.error(error))        
    }
}

export const verificarStockItems = (id, item) => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        let data = await modificar('/verificarStock', id, item)
        dispatch(editActions.success(data))
        return data
    } catch (error) {
        dispatch(editActions.error(error))        
    }
}

export const rechazarItem = (id, userId) => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        let data = await solicitud('/rechazarItemRecurso', id , userId )
        dispatch(editActions.success(data))
    } catch (error) {
        dispatch(editActions.error(error))        
    }
} 

export const addItems = item => async (dispatch, getState) => {
    dispatch(addActions.start())
    try {
        let data = await crear('/item-recurso', item )
        dispatch(addActions.success(data))
        return data
    } catch (error) {
        dispatch(addActions.error(error))
    }
}

export const consultarItem = id => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data = await leer(`/item-recurso/${id}?plain=true`)
        dispatch(searchItem())
        return data
    } catch (error) {
        dispatch(fetchActions.error(error))
    }

}

export const consultarItemCompleto = id => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data = await leer(`/item-recurso/${id}`)
        dispatch(searchItem())
        return data
    } catch (error) {
        dispatch(fetchActions.error(error))
    }

}

export const agregarItemListoEntrega = values => async (dispatch, getState) => {
    // dispatch(fetchActions.start())
    try {
        let data = await crear('/itemListoEntrega', values)
        // dispatch(editarItem(data))
    } catch (error) {
        console.log(error);
        // dispatch(fetchActions.error(error))
    }

}

export const agregarTrasladoItemRecurso = values => async (dispatch, getState) => {
//   dispatch(editActions.start());
  try {
      const data = await crear('/traslado-item-recurso', values);
    //   dispatch(editActions.success(data));
      return data
  } catch (error) {
      console.log(error);
    //   dispatch(editActions.error(error));
  }
}

// export const fetchItemsTodos = () => async (dispatch, getState) => {
//     dispatch(fetchActions.start())
//     try {
//         let data = await leer(`/item-recurso`)
//         data = data.filter( x => x.estado !== 4 && x.estado !== 5)
//         dispatch(fetchActions.success(data))
//     } catch (error) {
//         dispatch(fetchActions.error(error))
//     }
// }
