import { makeTypes, asyncMac, createReducer, reduceReducers } from 'ducks-maker';
import { createFetch } from './hors.js';
import { leer } from '../services/api';

const t = makeTypes('gerencias');
const FETCH = t('fetch').async();

const fetchActions = asyncMac(FETCH);

const initialState = {
    data: [],
    fetched: false,
    fetching: false
};

const fetchReducer = createReducer(initialState, createFetch(FETCH));

export default reduceReducers(fetchReducer);

export const fetchGerencias = userId => async (dispatch, getState) => {
    dispatch(fetchActions.start());
    try {
        const data = await leer('/gerenciasUsuario', userId);
        dispatch(fetchActions.success(data));
    } catch (error) {
        dispatch(fetchActions.error(error));
    }
};

export const saveGerenciasUsuario = (gerenciasAll, proyectosUsuario) => (dispatch, getState) => {
    dispatch(fetchActions.start());
    try {
        const data = gerenciasAll.filter(x => proyectosUsuario.some(y => y.gerencia === x.id));
        dispatch(fetchActions.success(data));
    } catch (error) {
        dispatch(fetchActions.error(error));
    }
};
