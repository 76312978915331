import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Grid, Box, Container, Typography, Button} from '@material-ui/core'
import Table from '../components/Table'
import Modal from '../components/Modal'
import BotonEditar from '../components/BotonEditar'
import FormClases from '../components/FormClases'
import * as clasesDuck from '../ducks/Clases'
import * as mensajesDuck from '../ducks/Mensajes'
import { leer } from '../services/api';

class Clase extends React.Component{

    state= {
        open: false,
        openForm: false,
        confirmacion: false,
        accion: false,
        clases: [],
        tiposRecursos: [],
        claseSelectedId: null,
    }

    componentDidMount() {
        this.recargarDatos();
    }

    recargarDatos = () => {
        Promise.all([leer('/clase'), leer('/tipo-recurso')]).then(responses => {
            this.setState({ clases: responses[0], tiposRecursos: responses[1] });
        });
    }

    handleClose = () => {
        const { removeError } = this.props
        removeError()
        this.setState({ ...this.state, open: false, confirmacion: false, accion: false})
    }

    handleCloseForm = () => {
        this.setState({ ...this.state, openForm: false, confirmacion: false, accion: false, claseSelectedId: null})
    }

    handleEditarModal = (id, accion) => async e => {
        const { assignMensaje } = this.props
        switch (accion) {
            case "activar":
                assignMensaje(`¿Está seguro desea activar el registro?`)
                this.setState({ ...this.state, open: true, confirmacion: true, accion: this.handleActivar(id)})
                break;
            case "desactivar":
                assignMensaje(`¿Está seguro desea desactivar el registro?`)
                this.setState({ ...this.state, open: true, confirmacion: true, accion: this.handleActivar(id)})
                break;
            case "editar":
                this.setState({ ...this.state, openForm: true, confirmacion: false, accion: false, claseSelectedId: id})
                break;
            default:
                break;
        }
    }

    handleActivar = id => async e => {
        const { editarClases, assignMensaje } = this.props
        let clase = this.state.clases.filter( x => x.id === id)[0]
        await editarClases(id, {...clase, activo: !clase.activo})
        assignMensaje("Registro editado")
        this.recargarDatos();
        this.setState({ ...this.state, open: true, confirmacion: false, accion: false})            
    }

    handleEditar = async values => {
        const { editarClases, assignMensaje } = this.props
        let claseEditado = this.state.clases.filter(x => x.id === this.state.claseSelectedId )
        let clasesRestantes = this.state.clases.filter( x => x.id !== this.state.claseSelectedId )
        if(clasesRestantes.some(x => (x.nombre === values.nombre ))){
          assignMensaje("Esta clase se encuentra en la lista")
          this.setState({ ...this.state, open: true, confirmacion: false, accion: false})            
        }else{
          await editarClases(claseEditado[0].id, {...claseEditado, ...values})
          assignMensaje("Registro editado")
          this.recargarDatos();
          this.setState({ ...this.state, openForm: false, open: true, confirmacion: false, accion: false, claseSelectedId: null})            
        }
    }

    handleAgregarRegistroModal = e => {
        this.setState({ ...this.state, openForm: true, confirmacion: false, accion: false})
    }

    handleAgregarRegistro = async values => {
        const { agregarClase, assignMensaje } = this.props
        if(this.state.clases.some(x => ( x.nombre === values.nombre ))){
            assignMensaje("Esta clase ya existe")
            this.setState({ ...this.state, open: true, confirmacion: false, accion: false})            
        }else{
            await agregarClase(values)
            assignMensaje("Registro agregado")
            this.recargarDatos();
            this.setState({ ...this.state, openForm: false, open: true, confirmacion: false, accion: false})            
        }
    }

    render(){
        const { mensaje } = this.props
        return(
            <Box>
                <Grid container justify="center" alignItems="center">
                    <Typography style={{ marginBottom: '30px' }} variant="h4">Clases</Typography>
                </Grid>
                <Grid container justify="center" alignItems="center">
                    <Button color="primary" variant="outlined" onClick={this.handleAgregarRegistroModal}>Agregar Clase</Button>
                </Grid>
                <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} lg={12}>
                            <Table
                                searchID="mainSearch"
                                agregar={true}
                                agregarRegistro={this.handleAgregarRegistroModal}
                                columnas={[
                                    { title: 'Nombre', field: 'nombre', 
                                      cellStyle: {
                                        textAlign:"center"
                                     },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                    { title: 'Pertenece a Tipo Recurso', field: 'tipo_recurso',cellStyle: {
                                        textAlign:"center"
                                      },headerStyle: {
                                        
                                        textAlign: "center",
                                      }},
                                    { title: 'Descripción', field: 'descripcion',cellStyle: {
                                        textAlign:"center"
                                      },headerStyle: {
                                        
                                        textAlign: "center",
                                      }},
                                    { title: 'Estado', field: 'activo', 
                                      cellStyle: {
                                        textAlign:"center"
                                     },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                    { title: 'Acción', field: 'accion', sorting: false, 
                                    cellStyle: {
                                        textAlign:"center",
                                    },headerStyle: {
                                        textAlign: "center",
                                        
                                    }},
                                ]}
                                data={this.state.clases.map(x => ({
                                    ...x,
                                    tipo_recurso: this.state.tiposRecursos.filter(y => y.id === x.tipo_recurso)[0] ? this.state.tiposRecursos.filter(y => y.id === x.tipo_recurso)[0].nombre : "",
                                    activo: x.activo ? "Activo" : "Inactivo",
                                    accion: <BotonEditar  handleEditar={this.handleEditarModal} handleActivar={this.handleEditarModal} id={x.id} activo={x.activo ? true: false} />                                    
                                }))} 
                            />
                        </Grid>
                </Grid>

                <Modal 
                    open={this.state.open}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}
                >
                    {mensaje.data}
                </Modal>
                
                <Modal 
                    open={this.state.openForm}
                    handleClose={this.handleCloseForm}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}
                    fullWidth={true}
                    maxWidth="md"
                    title="Formulario Clases"
                >
                    <Container>
                        <FormClases
                            editar={this.state.claseSelectedId ? true : false}
                            claseSelected={this.state.claseSelectedId ? this.state.clases.find(x => x.id === this.state.claseSelectedId) : null}
                            tiposRecursos={this.state.tiposRecursos}
                            onSubmit={this.state.claseSelectedId ? this.handleEditar : this.handleAgregarRegistro}
                        />
                    </Container>
                </Modal>

            </Box>
        )
    }
}

const mapStateToProps = state => {
    return {
        mensaje: state.Mensajes,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
      ...clasesDuck,
      ...mensajesDuck,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Clase)