import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles({
  avatar: {
    margin: 10,
  },
});

export default function LetterAvatars(props) {
  const { foto, handleConfirmacion, handlePerfil } = props
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton style={{ padding: '0px'}} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Avatar className={classes.avatar} src={foto}/>
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        >
        <MenuItem onClick={() => {handleClose(); handlePerfil()}}>Mi Perfil</MenuItem>
        <MenuItem onClick={() => {handleClose(); handleConfirmacion()}}>Cerrar Sesión</MenuItem>
      </Menu>
    </div>
  );
}
