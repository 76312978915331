import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Slide, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector } from 'react-redux';
import FieldFiledInput from './FieldFileInput';
import Spinner from './Spinner';
import KeyboardDatePicker from './KeyboardDatePicker';
import FieldTextField from './FieldTextField';
import TextField from './TextField';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

const validate = (values = {}) => {
    const errors = {};
    const requiredFields = ['lugar_entrega', 'fecha', 'nombre_recibe', 'nombre_entrega'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    // if (values.cantidad > values.max) {
    //     errors.cantidad = `No puede ser mayor que ${values.max}`;
    // }
    // if (values.cantidad < 1) {
    //     errors.cantidad = `No puede ser menor que 0`;
    // }
    return errors;
};

const numero = value => (isNaN(value) === false ? (isNaN(parseInt(value)) === true ? '' : parseInt(value)) : value);

// const maxValue = (value, allValues, props) => value && value > allValues.max;

let FormularioEntregarItems = props => {
    const { handleSubmit, handleOnChangeFileComprobante, handleClose, open, fileName, entregados, max, loading, change } =
        props;
    return (
        // <Dialog
        //     open={open}
        //     maxWidth="xl"
        //     TransitionComponent={Transition}
        //     onClose={handleClose}
        //     aria-labelledby="alert-dialog-slide-title"
        //     aria-describedby="alert-dialog-slide-description">
        //     <DialogTitle id="alert-dialog-slide-title">Entrega de Items</DialogTitle>
            <Container>
                <form noValidate onSubmit={handleSubmit}>
                    {/* <Field
                        name="max"
                        component={FieldTextField}
                        normalize={numero}
                        style={{ display: 'none' }}
                        InputProps={{
                            disabled: true
                        }}
                    /> */}
                    {/* <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} lg={6}>
                            <Field
                                label="Items Entregados"
                                name="entregados"
                                component={FieldTextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    disabled: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                type="number"
                                name="cantidad"
                                label="Items a Entregar"
                                component={FieldTextField}
                                normalize={numero}
                                // validate={[maxValue]}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={3}>
                        {/* <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    style={{ width: '90%' }}
                                    type="text"
                                    name="numero"
                                    label="Número Comprobante"
                                    component={TextField}
                                    // InputLabelProps={{
                                    //     shrink: true
                                    // }}
                                />
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '90%' }}
                                    type="text"
                                    name="lugar_entrega"
                                    label="Lugar Entrega"
                                    component={TextField}
                                    // InputLabelProps={{
                                    //     shrink: true
                                    // }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '90%' }}
                                    name="fecha"
                                    label="Fecha Entrega *"
                                    change={change}
                                    // format="DD/MM/YYYY"
                                    component={KeyboardDatePicker}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '90%' }}
                                    type="text"
                                    name="nombre_recibe"
                                    label="Nombre Quién Recibe"
                                    component={TextField}
                                    // InputLabelProps={{
                                    //     shrink: true
                                    // }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    style={{ width: '90%' }}
                                    type="text"
                                    name="nombre_entrega"
                                    label="Nombre Quién Entrega"
                                    component={TextField}
                                    // InputLabelProps={{
                                    //     shrink: true
                                    // }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '20px' }} justify="center" alignItems="center">
                        <Grid item xs={12} lg={12}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    style={{ width: '90%' }}
                                    name="file"
                                    component={FieldFiledInput}
                                    label="Comprobante Entrega (opcional)"
                                    accept=""
                                    type="file"
                                    fileName={fileName}
                                    handleOnChange={handleOnChangeFileComprobante}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Grid container style={{ marginTop: '20px' }} justify="center" alignItems="center">
                                <Button type="submit" variant="outlined" color="primary">
                                    Guardar Entrega
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <DialogActions>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <div>
                                <Button onClick={handleClose} color="secondary">
                                    Cerrar
                                </Button>
                                <Button type="submit" color="primary">
                                    Aceptar
                                </Button>
                            </div>
                        )}
                    </DialogActions> */}
                </form>
            </Container>
    );
};

FormularioEntregarItems = reduxForm({
    form: 'entregarItems'
})(FormularioEntregarItems);

export default connect((state, props) => ({
    initialValues: {
        file: '',
        fecha: null,
        numero: '',
        lugar_entrega: '',
        nombre_recibe: '',
        nombre_entrega: '',
        cantidad: 1,
        max: props.max,
        entregados: props.entregados
    },
    enableReinitialize: true,
    validate
}))(FormularioEntregarItems);
