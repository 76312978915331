import { makeTypes, mac, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, addHOR, selectHOR, editHOR, searchHOR, editFetch } from './hors.js'
import { leer, crear, modificar, solicitud, solicitarAprobacion } from '../services/api'

const t = makeTypes('cotizacion')
const FETCH = t('fetch').async()
const ADD = t('add').single()
const SELECT = t('select').single()
const EDIT = t('edit').single()
const SEARCH = t('search').single()
const EDITFETCH = t('editfetch').async()
 
const fetchActions = asyncMac(FETCH)
const addCotizacion = mac(ADD, 'payload')
const editCotizacion = mac(EDIT, 'payload')
const searchCotizacion = mac(SEARCH, 'payload')
const editarActions = asyncMac(EDITFETCH)
export const selectCotizacion = mac(SELECT, 'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const addReducer = createReducer(initialState, addHOR({ ADD }))
const editReducer = createReducer(initialState, editHOR({ EDIT }))
const selectReducer = createReducer(initialState, selectHOR({ SELECT }))
const editarReducer = createReducer(initialState, editFetch( EDITFETCH ))
const searchReducer = createReducer(initialState, searchHOR({ SEARCH }))

export default reduceReducers(fetchReducer, addReducer, selectReducer, editReducer, searchReducer, editarReducer)

export const fetchCotizaciones = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer('/cotizacion')
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const consultarCotizacion = id => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await leer('/cotizacion', id)
        dispatch(searchCotizacion())
        return data
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const editarCotizaciones = (id, values) => async (dispatch, getState) => {
    dispatch(editarActions.start())
    try {
        const data = await modificar('/cotizacion', id, values)
        dispatch(editarActions.success(data))
    } catch (error) {
        dispatch(editarActions.error(error))
    }
}

export const agregarCotizacion = values => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await crear('/cotizacion', values)
        dispatch(addCotizacion(data))
        return data
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const aprobarCotizacion = (id, values, userId) => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const dataOC = await solicitud('/aprobarCotizacion', id, userId)
        // const data = await modificar('/cotizacion', id, values)
        const data = await leer('/cotizacion', id)
        dispatch(editCotizacion(data))
        return dataOC
    } catch (error) {
        dispatch(fetchActions.error(error))
        throw error
    }
}

export const rechazarCotizacion = (id, values, userId) => async (dispatch, getState) => {
    dispatch(editarActions.start())
    try {
        await crear('/rechazarCotizacion', values)
        // const data = await modificar('/cotizacion', id, values)
        const data = await leer('/cotizacion', id)
        dispatch(editarActions.success(data))
        return data
    } catch (error) {
        dispatch(editarActions.error(error))
    }
}

export const solicitarAprobacionCotizacion = id => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await solicitarAprobacion('/solicitarAprobarCotizacion', id)
        dispatch(searchCotizacion())
        return data
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const agregarEvaluacionCosto = values => async (dispatch, getState) => {
    dispatch(editarActions.start());
    try {
        await crear('/evaluacion-costo-proveedor', values);
        const data = await leer('/cotizacion', values.cotizacion_id);
        data.selected = true;
        dispatch(editarActions.success(data));
    } catch (error) {
        dispatch(editarActions.error(error));
    }
}

