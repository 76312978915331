import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from './TextField';
import Checkbox from './Checkbox';
import SelectField from './SelectField';
import SelectSearch from './SelectSearch';
import FileInput from './FileInput';
import { Grid, Button, Container, TableCell, TableRow, IconButton } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import InputAdornment from '@material-ui/core/InputAdornment';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Tabla from '../components/Tabla';
import Spinner from '../components/Spinner';
import BotonMenuItems from '../components/BotonMenuItems';
import moment from 'moment';
import { addMillsSep, compareObjectString } from '../services/utils';
import 'moment/locale/es';
import { leer } from '../services/api';
import { PAIS_CHILE } from '../utils/constants';
moment.locale('es');

const validate = (values = {}) => {
    const errors = {};
    const requiredFields = [
        'pais',
        'empresa_cyd_id',
        'fecha_orden',
        'proveedor_id',
        'sucursal_id',
        'plazo_entrega',
        'tipo_plazo',
        'ubicacion_logistica_id',
        'condicion_pago',
        'atendido_por',
        'email_atendido_por',
        'tipo_moneda'
    ];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = true;
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    if (values.tipo_moneda && values.tipo_moneda != 'CLP' && values.tipo_moneda != 'PEN' && (!values.cambio_moneda || values.cambio_moneda == '0'))
        errors.cambio_moneda = true;

    if (values.pais !== PAIS_CHILE && (!values.cambioMonedaCLP || values.cambioMonedaCLP == '0'))
        errors.cambioMonedaCLP = true;
    return errors;
};

const currency = value => {    
    if (value) {
        let newValue = value.toString()
            .replace(/[^0-9\,]/g, '')
            .replace(/(\d*\,\d*)(\,)(\d*)/g, '$1$3')
            .replace(/^(\d+,?\d{0,2})\d*$/, '$1');
        let partes = newValue.split(',');
        return addMillsSep(partes[0]);
    } else {
        return '0';
    }
};

const currency2decimal = value => {
    if (value) {
        let newValue = value.toString().replace('.', ',')
            .replace(/[^0-9\,]/g, '')
            .replace(/(\d*\,\d*)(\,)(\d*)/g, '$1$3')
            .replace(/^(\d+,?\d{0,2})\d*$/, '$1');
        let partes = newValue.split(',');
        newValue = addMillsSep(partes[0]);
        if (partes.length > 1) newValue += ',' + partes[1];
        return newValue;
    } else {
        return '0';
    }
};

const currency6decimal = value => {
    if (value) {
        let newValue = value.toString().replace('.', ',')
            .replace(/[^0-9\,]/g, '')
            .replace(/(\d*\,\d*)(\,)(\d*)/g, '$1$3')
            .replace(/^(\d+,?\d{0,6})\d*$/, '$1');
        let partes = newValue.split(',');
        newValue = addMillsSep(partes[0]);
        if (partes.length > 1) newValue += ',' + partes[1];
        return newValue;
    } else {
        return '0';
    }
};

const keepCursor = (event, newValue, previousValue, name) => {
    
};

let FormCotizacion = props => {
    const {
        empresas,
        paises,
        proveedores,
        handleEditarItemsModal,
        handleEliminarItemCotizacion,
        handleNuevoProveedorModal,
        modoEdicion,
        handleSubmit,
        itemsCotizacion,
        formCotizacion,
        centros,
        change,
        touch
    } = props;
    const [tipoMoneda, setTipoMoneda] = useState('');
    const [proveedorSelected, setProveedorSelected] = useState(null);
    const [optcionesCondicionPago, setOptcionesCondicionPago] = useState(['Crédito a 15 días', 'Crédito a 30 días', 'Crédito a 45 días']);
    const [esChile, setEsChile] = useState(null);
    const [variablesPais, setVariablesPais] = useState({
        opcionesTipoMoneda: [
            { id: 'CLP', nombre: 'Peso Chileno (CLP)' },
            { id: 'USD', nombre: 'Dólar Estadounidense (USD)' },
            { id: 'UF', nombre: 'Unidad de Fomento (UF)' },
            { id: 'EUR', nombre: 'Euro (EUR)' }
        ],
        labelComentario: 'Comentario (obligatorio en compras mayores a 5MM CLP)',
        impuesto: { nombre: 'IVA', porcentaje: 19 },
    });
    
    useEffect(() => {        
        if (formCotizacion?.values?.tipo_moneda) setTipoMoneda(formCotizacion?.values?.tipo_moneda);
        if (formCotizacion?.values?.proveedor_id)
            setProveedorSelected(proveedores.find(x => x.id === formCotizacion.values.proveedor_id));
        if (formCotizacion?.values?.ubicacion_logistica_id === 2)
            setOptcionesCondicionPago([
              "Crédito a 15 días",
              "Crédito a 30 días",
              "Crédito a 45 días",
              "Anticipo",
              "Pago Online",
              "Contado",
              "Tarjeta de Crédito Corporativa",
            ]);
    }, [formCotizacion?.values?.tipo_moneda, formCotizacion?.values?.proveedor_id, formCotizacion?.values?.ubicacion_logistica_id]);

    useEffect(() => {
        const paisSeleccionado = paises.find(x => x.id === formCotizacion?.values?.pais);

        if (paisSeleccionado) {
            setEsChile(paisSeleccionado.id === PAIS_CHILE);
            setVariablesPais({
                opcionesTipoMoneda: paisSeleccionado.opcionesTipoMoneda,
                labelComentario: paisSeleccionado.labelComentario,
                impuesto: paisSeleccionado.impuesto,
            });
            if (!modoEdicion) change('cambioMonedaCLP', paisSeleccionado.cambioCLP.toString().replace('.', ','));
        } else {
            setEsChile(null);
            setVariablesPais({
                opcionesTipoMoneda: [
                    { id: 'CLP', nombre: 'Peso Chileno (CLP)' },
                    { id: 'USD', nombre: 'Dólar Estadounidense (USD)' },
                    { id: 'UF', nombre: 'Unidad de Fomento (UF)' },
                    { id: 'EUR', nombre: 'Euro (EUR)' }
                ],
                labelComentario: 'Comentario (obligatorio en compras mayores a 5MM CLP)',
                impuesto: { nombre: 'IVA', porcentaje: 19 },
            });
            if (!modoEdicion) change('cambioMonedaCLP', '1');
        }
    }, [formCotizacion?.values?.pais]);

    //Precarga el nombre y email de la sucursal del proveedor seleccionado
    useEffect(() => {
      if (formCotizacion?.values?.sucursal_id && proveedorSelected && 
            (!formCotizacion.values.email_atendido_por || formCotizacion.values.email_atendido_por.trim() === '')
) {
        const selectedSucursal = proveedorSelected.sucursales.find(
          (sucursal) => sucursal.id === formCotizacion.values.sucursal_id
        );
        if (selectedSucursal) {
          change("email_atendido_por", selectedSucursal?.contacto_email ? selectedSucursal?.contacto_email : '');
          change("atendido_por", selectedSucursal?.contacto_nombre ? selectedSucursal?.contacto_nombre : '');
        }
      }
    }, [formCotizacion?.values?.sucursal_id, proveedorSelected]);

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            disabled={modoEdicion}
                            style={{ width: '80%', padding: '10px 0' }}
                            type="text"
                            change={change}
                            touch={touch}
                            name="pais"
                            label="País"
                            options={paises.sort(compareObjectString('nombre'))}
                            component={SelectSearch}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            required
                            style={{ width: '80%', padding: '10px 0' }}
                            type="text"
                            change={change}
                            touch={touch}
                            name="empresa_cyd_id"
                            label="Empresa CyD"
                            options={
                                formCotizacion?.values?.pais
                                    ? empresas
                                        .filter(x => !x.is_eliminado && paises.find(y => y.id === formCotizacion.values.pais)?.nombre === x.pais)
                                        .sort(compareObjectString('nombre'))
                                        .map(x => ({ ...x, nombre: x.nombre + ' (' + x.rut + ')' }))
                                    : []
                            }
                            component={SelectSearch}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            required
                            type="date"
                            name="fecha_orden"
                            style={{ width: '80%', padding: '10px 0' }}
                            label="Fecha Orden de Compra"
                            component={TextField}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container> {/* Fila 2*/}
                <Grid item xs={12} lg={6}>
                    <Grid container justify="center" alignItems="center" style={{ width: '80%', padding: '10px 0', margin: 'auto' }}>
                        <Grid item xs={11} lg={11}>
                            <Field
                                required                                
                                type="text"
                                change={change}
                                touch={touch}
                                name="proveedor_id"
                                label="Proveedor"
                                options={
                                    formCotizacion?.values?.pais
                                        ? proveedores
                                            .filter(x => x.activo && x.pais === formCotizacion.values.pais)
                                            .sort(compareObjectString('nombre'))
                                        : []
                                }
                                component={SelectSearch}
                            />
                        </Grid>
                        <Grid item xs={1} lg={1}>
                            <Tooltip title="Nuevo">
                                <IconButton
                                    color="primary"
                                    style={{ padding: '6px', float: 'right' }}
                                    onClick={handleNuevoProveedorModal}>
                                    <AddRoundedIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={6} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    type="number"
                                    name="plazo_entrega"
                                    style={{ width: '100%', padding: '10px 0', marginLeft: '20%', marginRight: 10 }}
                                    label="Días de Entrega por el Proveedor (totalidad de productos)"
                                    component={TextField} 
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    name="tipo_plazo"
                                    style={{ width: '100%', padding: '10px 0', marginLeft: 10, marginRight: '20%' }}
                                    label="Tipo de Días"
                                    component={SelectField}>
                                    {[
                                        <MenuItem key="" value="">
                                            Seleccionar
                                        </MenuItem>
                                    ].concat(
                                        [
                                            { id: 'habil', nombre: 'Días Hábiles' },
                                            { id: 'corrido', nombre: 'Días Corridos' }
                                        ].map((x, id) => (
                                            <MenuItem key={id} value={x.id}>
                                                {x.nombre}
                                            </MenuItem>
                                        ))
                                    )}
                                </Field>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>            
            <Grid container> {/* Fila 3 */}
                <Grid item xs={12} lg={6}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            required
                            style={{ width: '80%', padding: '10px 0' }}
                            name="ubicacion_logistica_id"
                            label="Centro"
                            component={SelectField}>
                            {[
                                <MenuItem key="" value="">
                                    Seleccionar
                                </MenuItem>
                            ].concat(
                                centros.map((x, id) => (
                                    <MenuItem key={id} value={x.id}>
                                        {x.nombre}
                                    </MenuItem>
                                ))
                            )}
                        </Field>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={esChile === false ? 3 : 6}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            required
                            style={ 
                                esChile === false 
                                    ? { width: '100%', padding: '10px 0', marginLeft: '20%', marginRight: 10 } 
                                    : {width: '80%', padding: '10px 0'}
                            }
                            type="text"
                            change={change}
                            touch={touch}
                            name="tipo_moneda"
                            label="Tipo de Moneda"
                            options={variablesPais.opcionesTipoMoneda}
                            component={SelectSearch}
                        />
                    </Grid>
                </Grid>
                {esChile === false && (
                    <Grid item xs={12} lg={3}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                disabled
                                name="cambioMonedaCLP"
                                style={{ width: '100%', padding: '10px 0', marginLeft: 10, marginRight: '20%' }}
                                label="Cambio Moneda Local a CLP (Automático)"
                                format={currency6decimal}
                                onChange={keepCursor}
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid container> {/* Fila 4 */}
                <Grid item xs={12} lg={6}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            required
                            style={{ width: '80%', padding: '10px 0' }}
                            type="text"
                            change={change}
                            touch={touch}
                            name="sucursal_id"
                            label="Sucursal"                            
                            options={
                                proveedorSelected
                                    ? proveedorSelected.sucursales.map(x => ({
                                          ...x,
                                          nombre: x.direccion + ', ' + x.comuna.nombre
                                      }))
                                    : []
                            }
                            component={SelectSearch}
                        />
                    </Grid>
                </Grid>
                {tipoMoneda != '' && tipoMoneda != 'CLP' && tipoMoneda != 'PEN' && (
                    <Grid item xs={12} lg={6}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                name="cambio_moneda"
                                style={{ width: '80%', padding: '10px 0' }}
                                label="Cambio a Moneda Local"
                                format={esChile ? currency : currency2decimal}
                                onChange={keepCursor}
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid container> {/*Fila 5 */}
                <Grid item xs={12} lg={6}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            required
                            name="condicion_pago"
                            style={{ width: '80%', padding: '10px 0' }}
                            label="Condiciones de Pago"
                            component={SelectField}>
                            {[
                                <MenuItem key="" value="">
                                    Seleccionar
                                </MenuItem>
                            ].concat(                                
                                optcionesCondicionPago.map((x, id) => (
                                    <MenuItem key={id} value={x}>
                                        {x}
                                    </MenuItem>
                                ))
                            )}
                        </Field>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            multiline
                            type="text"
                            name="comentario"
                            rows="3"
                            characterLimit={500}
                            inputProps={{
                                maxLength: '500'
                            }}
                            style={{ width: '80%', padding: '10px 0' }}
                            label={variablesPais.labelComentario}
                            component={TextField}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container> {/* Fila 6 */}
                <Grid item xs={12} lg={6}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            required
                            type="text"
                            name="atendido_por"
                            style={{ width: '80%', padding: '10px 0' }}
                            label="Nombre Contacto"
                            component={TextField}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container>
                        <Grid item style={{ marginTop: '20px' }} xs={12} lg={12}>
                            <Grid container justify="center" alignItems="center">
                                <Tooltip title="Documento o Correo en formato PDF que respalda la cotización">
                                    <Button disabled={modoEdicion} variant="outlined" component="label" color="primary">
                                        Subir Respaldo Cotización<i className="material-icons">cloud_upload</i>
                                        <Field component={FileInput} name="archivo" accept="application/pdf" />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Grid container justify="center" alignItems="center">
                                <span>
                                    {formCotizacion
                                        ? formCotizacion.values
                                            ? formCotizacion.values.archivo
                                                ? 'Archivo Adjunto: ' + formCotizacion.values.archivo.name
                                                : ''
                                            : ''
                                        : ''}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container> {/* Fila 7 */}
                <Grid item xs={12} lg={6}>
                    <Grid container justify="center" alignItems="center">
                        <Field
                            required
                            type="text"
                            name="email_atendido_por"
                            style={{ width: '80%', padding: '10px 0' }}
                            label="Email Contacto"
                            component={TextField}
                        />
                    </Grid>
                </Grid>                
            </Grid>
            <Grid>
                <Tabla columns={['Cantidad', 'Detalle', 'Descripción', 'Precio Unitario', 'Total', 'Acción']}>
                    {itemsCotizacion.map((x, id) =>
                            <TableRow key={x.id}>
                                <TableCell style={{ width: '80px', textAlign: 'center' }}>{x.cantidad}</TableCell>
                                <TableCell style={{ width: '300px' }}>
                                    {x.detalle}
                                </TableCell>
                                <TableCell>
                                    <Field
                                        required
                                        multiline
                                        type="text"
                                        name={`descripcion{${x.id}}`}
                                        label="Descripción"
                                        style={{ width: '100%', padding: '10px ' }}
                                        component={TextField}
                                    />
                                </TableCell>
                                <TableCell style={{ width: '200px' }}>
                                    <Field
                                        required
                                        name={`${x.id}`}
                                        style={{ width: '100%', padding: '10px ' }}
                                        label="Precio"
                                        format={tipoMoneda == 'CLP' ? currency : currency2decimal}
                                        component={TextField}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">{tipoMoneda}$</InputAdornment>
                                            )
                                        }}
                                    />
                                </TableCell>
                                <TableCell style={{ width: '170px', textAlign: 'right' }}>
                                    {tipoMoneda +
                                        '$ ' +
                                        addMillsSep(
                                            parseFloat(
                                                x.cantidad *
                                                    (x.precio && x.precio.replace(/\./g, '').replace(/\,/g, '.'))
                                            ).toFixed(2),
                                            tipoMoneda
                                        )}
                                </TableCell>

                                <TableCell style={{ width: '110px' }}>
                                    {modoEdicion ? (
                                        'Editando'
                                    ) : (
                                        <BotonMenuItems
                                            id={x.id}
                                            estado={x.estado}
                                            handleEliminarItemCotizacion={handleEliminarItemCotizacion}
                                            handleEditarItemsModal={handleEditarItemsModal}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        
                    )}
                    {itemsCotizacion.length > 0 ? (
                        <TableRow>
                            <TableCell rowSpan={5} />
                            <TableCell colSpan={3}>Subtotal</TableCell>
                            <TableCell align="right">
                                {tipoMoneda +
                                    '$ ' +
                                    addMillsSep(
                                        parseFloat(
                                            itemsCotizacion
                                                .map(x =>
                                                    x.precio
                                                        ? x.precio.replace(/\./g, '').replace(/\,/g, '.') * x.cantidad
                                                        : 0
                                                )
                                                .reduce((acc, y) => {
                                                    return acc + y;
                                                })
                                        ).toFixed(2),
                                        tipoMoneda
                                    )}
                            </TableCell>
                        </TableRow>
                    ) : (
                        <TableRow></TableRow>
                    )}

                    {itemsCotizacion.length > 0 ? (
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Field
                                    name="impuesto_incluido"
                                    style={{ width: '80%', padding: '10px ' }}
                                    component={Checkbox}
                                />
                                Impuesto Incluido
                            </TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    ) : (
                        <TableRow></TableRow>
                    )}

                    {itemsCotizacion.length > 0 &&
                    (formCotizacion
                        ? formCotizacion.values
                            ? formCotizacion.values.impuesto_incluido
                                ? false
                                : true
                            : false
                        : false) ? (
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Field name="iva" style={{ width: '80%', padding: '10px ' }} component={Checkbox} />
                                {`Exento de ${variablesPais.impuesto.nombre}`}
                            </TableCell>
                            <TableCell align="right">
                                {(
                                    formCotizacion
                                        ? formCotizacion.values
                                            ? formCotizacion.values.impuesto_incluido
                                                ? false
                                                : true
                                            : false
                                        : false
                                )
                                    ? formCotizacion
                                        ? formCotizacion.values
                                            ? formCotizacion.values.iva
                                                ? 0
                                                : `${variablesPais.impuesto.porcentaje}%`
                                            : ''
                                        : ''
                                    : ''}
                            </TableCell>
                            <TableCell align="right">
                                {(
                                    formCotizacion
                                        ? formCotizacion.values
                                            ? formCotizacion.values.impuesto_incluido
                                                ? false
                                                : true
                                            : false
                                        : false
                                )
                                    ? tipoMoneda +
                                      '$ ' +
                                      addMillsSep(
                                          parseFloat(
                                              formCotizacion
                                                  ? formCotizacion.values
                                                      ? formCotizacion.values.iva
                                                          ? 0
                                                          : itemsCotizacion
                                                                .map(x =>
                                                                    x.precio
                                                                        ? x.precio
                                                                              .replace(/\./g, '')
                                                                              .replace(/\,/g, '.') * x.cantidad
                                                                        : 0
                                                                )
                                                                .reduce((acc, y) => {
                                                                    return acc + y;
                                                                }) * (variablesPais.impuesto.porcentaje / 100)
                                                      : ''
                                                  : ''
                                          ).toFixed(2),
                                          tipoMoneda
                                      )
                                    : ''}
                            </TableCell>
                        </TableRow>
                    ) : (
                        <TableRow></TableRow>
                    )}
                    {itemsCotizacion.length > 0 ? (
                        <TableRow>
                            <TableCell colSpan={3}>Total</TableCell>
                            <TableCell align="right">
                                {(
                                    formCotizacion
                                        ? formCotizacion.values
                                            ? formCotizacion.values.impuesto_incluido
                                                ? false
                                                : true
                                            : false
                                        : false
                                )
                                    ? formCotizacion
                                        ? formCotizacion.values
                                            ? formCotizacion.values.iva
                                                ? tipoMoneda +
                                                  '$ ' +
                                                  addMillsSep(
                                                      parseFloat(
                                                          itemsCotizacion
                                                              .map(x =>
                                                                  x.precio
                                                                      ? x.precio
                                                                            .replace(/\./g, '')
                                                                            .replace(/\,/g, '.') * x.cantidad
                                                                      : 0
                                                              )
                                                              .reduce((acc, y) => {
                                                                  return acc + y;
                                                              })
                                                      ).toFixed(2),
                                                      tipoMoneda
                                                  )
                                                : tipoMoneda +
                                                  '$ ' +
                                                  addMillsSep(
                                                      parseFloat(
                                                          itemsCotizacion
                                                              .map(x =>
                                                                  x.precio
                                                                      ? x.precio
                                                                            .replace(/\./g, '')
                                                                            .replace(/\,/g, '.') * x.cantidad
                                                                      : 0
                                                              )
                                                              .reduce((acc, y) => {
                                                                  return acc + y;
                                                              }) * (1 + (variablesPais.impuesto.porcentaje / 100))
                                                      ).toFixed(2),
                                                      tipoMoneda
                                                  )
                                            : ''
                                        : ''
                                    : tipoMoneda +
                                      '$ ' +
                                      addMillsSep(
                                          parseFloat(
                                              itemsCotizacion
                                                  .map(x =>
                                                      x.precio
                                                          ? x.precio.replace(/\./g, '').replace(/\,/g, '.') * x.cantidad
                                                          : 0
                                                  )
                                                  .reduce((acc, y) => {
                                                      return acc + y;
                                                  })
                                          ).toFixed(2),
                                          tipoMoneda
                                      )}
                            </TableCell>
                        </TableRow>
                    ) : (
                        <TableRow></TableRow>
                    )}
                </Tabla>
            </Grid>
            <Grid item xs={12} style={{ padding: '20px' }} lg={12}>
                <Grid container justify="center" alignItems="center">
                    <Button type="submit" variant="outlined" color="primary">
                        Guardar Cotización
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

FormCotizacion = reduxForm({
    form: 'cotizacion'    
})(FormCotizacion);

export default connect((state, ownProps) => {
    let itemsCotizacionEditar = state.ItemsCotizacion.data;
    let descripcion = {};
    let precios = {};
    itemsCotizacionEditar.forEach((x, index) => {
        descripcion[`descripcion{${x.id}}`] = state.ModoEdicion.data
            ? ownProps.cotizacionActual.item_cotizacion.filter(y => y.item_recurso_id === x.id)[0]
                ? ownProps.cotizacionActual.item_cotizacion.filter(y => y.item_recurso_id === x.id)[0].descripcion
                : x.descripcion
            : x.descripcion
        precios[x.id] = state.ModoEdicion.data
            ? ownProps.cotizacionActual.item_cotizacion.filter(y => y.item_recurso_id === x.id)[0]
                ? ownProps.cotizacionActual.item_cotizacion
                      .filter(y => y.item_recurso_id === x.id)[0]
                      .precio.toString()
                      .replace('.', ',')
                : ''
            : '';
    });

    return {
        initialValues: {
            pais: state.ModoEdicion.data ? ownProps.cotizacionActual.pais : '',
            empresa_cyd_id: state.ModoEdicion.data
                ? ownProps.cotizacionActual.empresa_cyd_id
                : '',
            proveedor_id: state.ModoEdicion.data
                ? ownProps.cotizacionActual.proveedor_id
                : '',
            sucursal_id: state.ModoEdicion.data
                ? ownProps.cotizacionActual.sucursal_id
                : '',
            ubicacion_logistica_id: state.ModoEdicion.data
                ? ownProps.cotizacionActual.ubicacion_logistica_id
                : '',
            condicion_pago: state.ModoEdicion.data
                ? ownProps.cotizacionActual.condicionPago
                : '',
            atendido_por: state.ModoEdicion.data
                ? ownProps.cotizacionActual.atendidoPor
                : '',
            email_atendido_por: state.ModoEdicion.data
                ? ownProps.cotizacionActual.emailAtendidoPor
                : '',
            fecha_orden: state.ModoEdicion.data
                ? moment(ownProps.cotizacionActual.fecha_orden).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
            iva: state.ModoEdicion.data
                ? ownProps.cotizacionActual?.iva === 0
                    ? true
                    : false
                : false,
            impuesto_incluido: state.ModoEdicion.data
                ?  ownProps.cotizacionActual?.impuesto_incluido
                    ? true
                    : false
                : false,
            tipo_moneda: state.ModoEdicion.data ? ownProps.cotizacionActual.tipo_moneda : '',
            cambio_moneda: state.ModoEdicion.data ? ownProps.cotizacionActual.cambio_moneda.toString().replace('.', ',') : '',
            cambioMonedaCLP: state.ModoEdicion.data ? ownProps.cotizacionActual.cambioMonedaCLP.toString().replace('.', ',') : '',
            comentario: state.ModoEdicion.data ? (ownProps.cotizacionActual.comentario ? ownProps.cotizacionActual.comentario : '') : '',
            plazo_entrega: state.ModoEdicion.data ? ownProps.cotizacionActual.plazo_entrega : '',
            tipo_plazo: state.ModoEdicion.data ? ownProps.cotizacionActual.tipo_plazo : 'habil',
            ...descripcion,
            ...precios
        },
        validate
    };
})(FormCotizacion);
