import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Grid, IconButton } from '@material-ui/core';
import Table from '../components/Table';
import Tooltip from '@material-ui/core/Tooltip';
import TimeLine from '../components/TimeLine';
// import FormularioBusqueda from '../components/FormularioBusqueda';
import Spinner from '../components/Spinner';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
// import FilterListIcon from '@material-ui/icons/FilterList';
import BotonMenuLista from './BotonMenuLista';
import DetalleSolicitud from '../containers/DetalleSolicitud';
// import { Link, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { crear, leer } from '../services/api';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper
    }
}));

export default function ScrollableTabsButtonAuto(props) {
    const {
        selectedTab,
        permisoPruebas,
        permisoEvaluarCosto,
        columnasMisPendientes,
        columnasEnCurso,
        columnasMisSolicitudes,
        columnasVerTodas,
        setColumnConfig,
        handleActualizar,
        handleRowClick,
        handleLoadingSolicitud,
        handleCierreDetalle,
        handleVerDocumento,
        handleCotizacionModal,
        usuarioAutenticado,
        cargarIndicadores,
    } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(selectedTab);
    const [mostrar, setMostrar] = React.useState(false);
    const [detalleVisible, setDetalleVisible] = React.useState(false);

    const lookupCentros1 = {};
    const lookupCentros2 = {};
    const lookupCentros3 = {};
    const lookupCentros4 = {};

    // let textoBuscadoMisPendientes = searchTextMisPendientes;
    // let textoBuscadoEnCurso = searchTextEnCurso;
    // let textoBuscadoMisSolicitudes = searchTextMisSolicitudes;
    // let textoBuscadoVerTodas = searchTextVerTodas;

    useEffect(() => {
        // console.log("primer mount");
        leer('/centro-logistico').then(response => {
            response.filter(x => x.activo && (permisoPruebas || x.id != 5)).forEach(centro => {
                lookupCentros1[centro.nombre] = centro.nombre;
                lookupCentros2[centro.nombre] = centro.nombre;
                lookupCentros3[centro.nombre] = centro.nombre;
                lookupCentros4[centro.nombre] = centro.nombre;
            });
        if (columnasMisPendientes.length < 1 && lookupCentros1 && Object.keys(lookupCentros1).length > 0){
            setColumnConfig([
                {
                    title: 'Código',
                    field: 'codigo',
                    cellStyle: {
                        width: '130px',
                        textAlign: 'center',
                        padding: '18px 0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                },
                {
                    title: 'Tipo',
                    field: 'tipo_recurso',
                    lookup: {
                        'Computación': 'Computación',
                        'Topografía y Laboratorio': 'Topografía y Laboratorio',
                        'Misceláneos': 'Misceláneos',
                        'Vehículos': 'Vehículos'
                    },
                    filterCellStyle: { maxWidth: '110px' },
                    cellStyle: {
                        width: '110px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    }
                },
                {
                    title: 'Clase',
                    field: 'clase',
                    sorting: false,
                    cellStyle: {
                        width: '100px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px'
                    },
                    render: rowData => renderClase(rowData)
                },
                {
                    title: 'Centro Solicitud',
                    field: 'centro',
                    lookup: lookupCentros1,
                    filterCellStyle: { maxWidth: '110px' },
                    cellStyle: {
                        width: '110px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                },
                {
                    title: 'Estado',
                    field: 'estado',
                    lookup: {
                        'Espera aprobación 1': 'Espera aprobación 1',
                        'Espera aprobación 2': 'Espera aprobación 2',
                        'Revisión stock centro seleccionado': 'Revisión stock centro seleccionado',
                        'Revisión stock Santiago': 'Revisión stock Santiago',
                        'Cotización': 'Cotización',
                        'Pendiente entrega proveedor': 'Pendiente entrega proveedor',
                        'Preparar recurso': 'Preparar recurso',
                        'En traslado': 'En traslado',
                        'Listo para entregar': 'Listo para entregar',
                        'Entregado': 'Entregado',
                        // 'Entregado y sin Factura': 'Entregado y sin Factura',
                        'Rechazada': 'Rechazada'
                    },
                    filterCellStyle: { maxWidth: '100px' },
                    grouping: false,
                    cellStyle: {
                        width: '100px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                    render: rowData => renderEstado(rowData)
                },
                {
                    title: 'Proyecto',
                    field: 'proyecto',
                    cellStyle: {
                        width: '120px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                    render: rowData => renderProyecto(rowData)
                },
                {
                    title: 'Solicitante',
                    field: 'solicitante',
                    cellStyle: {
                        width: '120px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        padding: '0px',
                        textAlign: 'center',
                        paddingLeft: '28px'
                    }
                },
                {
                    title: 'Progreso',
                    field: 'progreso',
                    sorting: false,
                    filtering: false,
                    grouping: false,
                    cellStyle: {
                        minWidth: 310,
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px'
                    }
                },
                {
                    title: 'Tiempo Transcurrido',
                    field: 'fecha',
                    sorting: false,
                    filtering: false,
                    grouping: false,
                    cellStyle: {
                        width: '130px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        padding: '0px',
                        textAlign: 'center'
                    }
                },
                {
                    title: 'Cotización\nOrden de Compra\nFactura',
                    field: 'cotizaciones',
                    sorting: false,
                    grouping: false,
                    disableClick: true,
                    cellStyle: {
                        width: '120px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        whiteSpace: 'pre'
                    },
                }
            ], 'MIS_PENDIENTES');
        } else if (columnasMisPendientes.length) {
            let update = [...columnasMisPendientes];
            update[2].render = renderClase;
            update[4].render = renderEstado;
            update[5].render = renderProyecto;
            setColumnConfig(update, 'MIS_PENDIENTES');
        }
        
        if (columnasEnCurso.length < 1 && lookupCentros2 && Object.keys(lookupCentros2).length > 0){
            // console.log("guardando columnas en curso");
            setColumnConfig([
                {
                    title: 'Código',
                    field: 'codigo',
                    cellStyle: {
                        width: '130px',
                        textAlign: 'center',
                        padding: '18px 0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                },
                {
                    title: 'Tipo',
                    field: 'tipo_recurso',
                    lookup: {
                        'Computación': 'Computación',
                        'Topografía y Laboratorio': 'Topografía y Laboratorio',
                        'Misceláneos': 'Misceláneos',
                        'Vehículos': 'Vehículos'
                    },
                    filterCellStyle: { maxWidth: '110px' },
                    cellStyle: {
                        width: '110px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    }
                },
                {
                    title: 'Clase',
                    field: 'clase',
                    sorting: false,
                    cellStyle: {
                        width: '100px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px'
                    },
                    render: rowData => renderClase(rowData)
                },
                {
                    title: 'Centro Solicitud',
                    field: 'centro',
                    lookup: lookupCentros2,
                    filterCellStyle: { maxWidth: '110px' },
                    cellStyle: {
                        width: '110px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                },
                {
                    title: 'Estado',
                    field: 'estado',
                    lookup: {
                        'Espera aprobación 1': 'Espera aprobación 1',
                        'Espera aprobación 2': 'Espera aprobación 2',
                        'Revisión stock centro seleccionado': 'Revisión stock centro seleccionado',
                        'Revisión stock Santiago': 'Revisión stock Santiago',
                        'Cotización': 'Cotización',
                        'Pendiente entrega proveedor': 'Pendiente entrega proveedor',
                        'Preparar recurso': 'Preparar recurso',
                        'En traslado': 'En traslado',
                        'Listo para entregar': 'Listo para entregar',
                        'Entregado': 'Entregado',
                        // 'Entregado y sin Factura': 'Entregado y sin Factura',
                        'Rechazada': 'Rechazada'
                    },
                    filterCellStyle: { maxWidth: '100px' },
                    grouping: false,
                    cellStyle: {
                        width: '100px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                    render: rowData => renderEstado(rowData)
                },
                {
                    title: 'Proyecto',
                    field: 'proyecto',
                    cellStyle: {
                        width: '120px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                    render: rowData => renderProyecto(rowData)
                },
                {
                    title: 'Solicitante',
                    field: 'solicitante',
                    cellStyle: {
                        width: '120px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        padding: '0px',
                        textAlign: 'center',
                        paddingLeft: '28px'
                    }
                },
                {
                    title: 'Progreso',
                    field: 'progreso',
                    sorting: false,
                    filtering: false,
                    grouping: false,
                    cellStyle: {
                        minWidth: 310,
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px'
                    }
                },
                {
                    title: 'Tiempo Transcurrido',
                    field: 'fecha',
                    sorting: false,
                    filtering: false,
                    grouping: false,
                    cellStyle: {
                        width: '130px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        padding: '0px',
                        textAlign: 'center'
                    }
                },
                {
                    title: 'Cotización\nOrden de Compra\nFactura',
                    field: 'cotizaciones',
                    sorting: false,
                    grouping: false,
                    disableClick: true,
                    cellStyle: {
                        width: '120px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        whiteSpace: 'pre'
                    },
                }
            ], 'EN_CURSO');
        } else if (columnasEnCurso.length) {
            // console.log("actualizando funciones columnas en curso");
            let update = [...columnasEnCurso];
            update[2].render = renderClase;
            update[4].render = renderEstado;
            update[5].render = renderProyecto;
            setColumnConfig(update, 'EN_CURSO');
        }
        if (columnasMisSolicitudes.length < 1 && lookupCentros3 && Object.keys(lookupCentros3).length > 0) {
            setColumnConfig([
                {
                    title: 'Código',
                    field: 'codigo',
                    cellStyle: {
                        width: '130px',
                        textAlign: 'center',
                        padding: '18px 0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                },
                {
                    title: 'Tipo',
                    field: 'tipo_recurso',
                    lookup: {
                        'Computación': 'Computación',
                        'Topografía y Laboratorio': 'Topografía y Laboratorio',
                        'Misceláneos': 'Misceláneos',
                        'Vehículos': 'Vehículos'
                    },
                    filterCellStyle: { maxWidth: '110px' },
                    cellStyle: {
                        width: '110px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    }
                },
                {
                    title: 'Clase',
                    field: 'clase',
                    sorting: false,
                    cellStyle: {
                        width: '100px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px'
                    },
                    render: rowData => renderClase(rowData)
                },
                {
                    title: 'Centro Solicitud',
                    field: 'centro',
                    lookup: lookupCentros3,
                    filterCellStyle: { maxWidth: '110px' },
                    cellStyle: {
                        width: '110px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                },
                {
                    title: 'Estado',
                    field: 'estado',
                    lookup: {
                        'Espera aprobación 1': 'Espera aprobación 1',
                        'Espera aprobación 2': 'Espera aprobación 2',
                        'Revisión stock centro seleccionado': 'Revisión stock centro seleccionado',
                        'Revisión stock Santiago': 'Revisión stock Santiago',
                        'Cotización': 'Cotización',
                        'Pendiente entrega proveedor': 'Pendiente entrega proveedor',
                        'Preparar recurso': 'Preparar recurso',
                        'En traslado': 'En traslado',
                        'Listo para entregar': 'Listo para entregar',
                        'Entregado': 'Entregado',
                        // 'Entregado y sin Factura': 'Entregado y sin Factura',
                        'Rechazada': 'Rechazada'
                    },
                    filterCellStyle: { maxWidth: '100px' },
                    grouping: false,
                    cellStyle: {
                        width: '100px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                    render: rowData => renderEstado(rowData)
                },
                {
                    title: 'Proyecto',
                    field: 'proyecto',
                    cellStyle: {
                        width: '120px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                    render: rowData => renderProyecto(rowData)
                },
                {
                    title: 'Solicitante',
                    field: 'solicitante',
                    cellStyle: {
                        width: '120px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        padding: '0px',
                        textAlign: 'center',
                        paddingLeft: '28px'
                    }
                },
                {
                    title: 'Progreso',
                    field: 'progreso',
                    sorting: false,
                    filtering: false,
                    grouping: false,
                    cellStyle: {
                        minWidth: 310,
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px'
                    }
                },
                {
                    title: 'Tiempo Transcurrido',
                    field: 'fecha',
                    sorting: false,
                    filtering: false,
                    grouping: false,
                    cellStyle: {
                        width: '130px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        padding: '0px',
                        textAlign: 'center'
                    }
                },
                {
                    title: 'Cotización\nOrden de Compra\nFactura',
                    field: 'cotizaciones',
                    sorting: false,
                    grouping: false,
                    disableClick: true,
                    cellStyle: {
                        width: '120px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        whiteSpace: 'pre'
                    },
                }
            ], 'MIS_SOLICITUDES');
        } else if (columnasMisSolicitudes.length) {
            let update = [...columnasMisSolicitudes];
            update[2].render = renderClase;
            update[4].render = renderEstado;
            update[5].render = renderProyecto;
            setColumnConfig(update, 'MIS_SOLICITUDES');
        }
        if (columnasVerTodas.length < 1 && lookupCentros4 && Object.keys(lookupCentros4).length > 0) {
            setColumnConfig([
                {
                    title: 'Código',
                    field: 'codigo',
                    cellStyle: {
                        width: '130px',
                        textAlign: 'center',
                        padding: '18px 0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                },
                {
                    title: 'Tipo',
                    field: 'tipo_recurso',
                    lookup: {
                        'Computación': 'Computación',
                        'Topografía y Laboratorio': 'Topografía y Laboratorio',
                        'Misceláneos': 'Misceláneos',
                        'Vehículos': 'Vehículos'
                    },
                    filterCellStyle: { maxWidth: '110px' },
                    cellStyle: {
                        width: '110px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    }
                },
                {
                    title: 'Clase',
                    field: 'clase',
                    sorting: false,
                    cellStyle: {
                        width: '100px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px'
                    },
                    render: rowData => renderClase(rowData)
                },
                {
                    title: 'Centro Solicitud',
                    field: 'centro',
                    lookup: lookupCentros4,
                    filterCellStyle: { maxWidth: '110px' },
                    cellStyle: {
                        width: '110px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                },
                {
                    title: 'Estado',
                    field: 'estado',
                    lookup: {
                        'Espera aprobación 1': 'Espera aprobación 1',
                        'Espera aprobación 2': 'Espera aprobación 2',
                        'Revisión stock centro seleccionado': 'Revisión stock centro seleccionado',
                        'Revisión stock Santiago': 'Revisión stock Santiago',
                        'Cotización': 'Cotización',
                        'Pendiente entrega proveedor': 'Pendiente entrega proveedor',
                        'Preparar recurso': 'Preparar recurso',
                        'En traslado': 'En traslado',
                        'Listo para entregar': 'Listo para entregar',
                        'Entregado': 'Entregado',
                        // 'Entregado y sin Factura': 'Entregado y sin Factura',
                        'Rechazada': 'Rechazada'
                    },
                    filterCellStyle: { maxWidth: '100px' },
                    grouping: false,
                    cellStyle: {
                        width: '100px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                    render: rowData => renderEstado(rowData)
                },
                {
                    title: 'Proyecto',
                    field: 'proyecto',
                    cellStyle: {
                        width: '120px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        paddingLeft: '28px'
                    },
                    render: rowData => renderProyecto(rowData)
                },
                {
                    title: 'Solicitante',
                    field: 'solicitante',
                    cellStyle: {
                        width: '120px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        padding: '0px',
                        textAlign: 'center',
                        paddingLeft: '28px'
                    }
                },
                {
                    title: 'Progreso',
                    field: 'progreso',
                    sorting: false,
                    filtering: false,
                    grouping: false,
                    cellStyle: {
                        minWidth: 310,
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px'
                    }
                },
                {
                    title: 'Tiempo Transcurrido',
                    field: 'fecha',
                    sorting: false,
                    filtering: false,
                    grouping: false,
                    cellStyle: {
                        width: '130px',
                        padding: '0px',
                        textAlign: 'center'
                    },
                    headerStyle: {
                        padding: '0px',
                        textAlign: 'center'
                    }
                },
                {
                    title: 'Cotización\nOrden de Compra\nFactura',
                    field: 'cotizaciones',
                    sorting: false,
                    grouping: false,
                    disableClick: true,
                    cellStyle: {
                        width: '120px',
                        textAlign: 'center',
                        padding: '0px'
                    },
                    headerStyle: {
                        textAlign: 'center',
                        padding: '0px',
                        whiteSpace: 'pre'
                    },
                }
            ], 'VER_TODAS');
        } else if (columnasVerTodas.length) {
            let update = [...columnasVerTodas];
            update[2].render = renderClase;
            update[4].render = renderEstado;
            update[5].render = renderProyecto;
            setColumnConfig(update, 'VER_TODAS');
        }
        });
    }, []);

    function handleChange(event, newValue) {
        if (newValue != value) setValue(newValue);
    }

    function contenidoDetalle(rowData, refreshTable) {
        // if (!detalleVisible) {
        //     setDetalleVisible(true);
        return (
            <div style={{ padding: '8px', backgroundColor: '#f5f5f5' }}>
                {/* {rowData.idSolicitud} */}
                <Paper>
                    <DetalleSolicitud
                        style={{ padding: '8px' }}
                        idPanel={rowData.idSolicitud}
                        idSolicitud={rowData.idSolicitud}
                        solicitudActual={rowData.dataOriginal}
                        handleLoadingSolicitud={handleLoadingSolicitud}
                        handleCierreDetalle={handleCierreDetalle}
                        refreshTable={refreshTable}
                    />
                </Paper>
            </div>
        );
        // } else {
        //     return <Spinner />;
        // }
    }

    function renderEstado(rowData) {
        return (
            <div>
                <div>
                    {rowData.estadoPrincipal}
                </div>
                {rowData.estadoSecundario
                    ? (
                        <div style={{ fontSize: '12px', lineHeight: 1.2, whiteSpace: 'pre' }}>
                            {rowData.estadoSecundario}
                        </div>
                    )
                    : ''}
            </div>
        );
    }

    function renderClase(rowData) {
        return (
            <div style={{ whiteSpace: 'pre' }}>
                {rowData.clase}
            </div>
        );
    }

    function renderProyecto(rowData) {
        return (
            <Tooltip
                enterTouchDelay={0}
                title={
                    <div style={{ whiteSpace: 'pre', textAlign: 'center' }}>
                        {
                            rowData.proyecto.nombre
                            + "\nSociedad: " + rowData.proyecto.sociedad
                        }
                    </div>
                }>
                <div>
                    {rowData.proyecto.codigo}
                </div>
            </Tooltip>
        );
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Toolbar>
                    {/* <Tooltip title="Filtros">
                        <IconButton style={{ outline: 'none' }} onClick={handleBusqueda}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip> */}
                    <Tabs
                        style={{ margin: 'auto' }}
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="auto"
                        centered={true}
                        aria-label="scrollable auto tabs example">
                        <Tab style={{ outline: 'none' }} label="Mis Pendientes" {...a11yProps(0)} />
                        <Tab style={{ outline: 'none' }} label="En Curso" {...a11yProps(1)} />
                        <Tab style={{ outline: 'none' }} label="Mis Solicitudes" {...a11yProps(2)} />
                        <Tab style={{ outline: 'none' }} label="Ver Todas" {...a11yProps(3)} />
                    </Tabs>
                    <Tooltip enterTouchDelay={0} title="Actualizar Indicadores">
                        <IconButton style={{ outline: 'none' }} onClick={cargarIndicadores}>
                            <i className="material-icons">refresh</i>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Grid container>
                    <Grid item xs={12} lg={mostrar ? 9 : 12}>
                            <Table
                                searchID="mainSearch1"
                                // searchText={searchTextMisPendientes}
                                // handleSearchChange={(texto) => { textoBuscadoMisPendientes = texto; }}
                                filtering={true}
                                grouping={false}
                                columnVis={false}
                                handleRowClick={(rowData) => {
                                    // dispatch(generalOptionsDuck.setSearchText([
                                    //     textoBuscadoMisPendientes,
                                    //     textoBuscadoEnCurso,
                                    //     textoBuscadoMisSolicitudes,
                                    //     textoBuscadoVerTodas
                                    // ]));
                                    handleRowClick(rowData, value);
                                }}
                                detalleF={contenidoDetalle}
                                columnas={columnasMisPendientes}
                                remoteData={true}
                                reloadAction={true}
                                data={
                                    query => new Promise((resolve, reject) => {
                                        // console.log('query en pendientes', query);
                                        const filters = query.filters.map(x => ({ field: x.column.field, value: x.value }));
                                        const options = {
                                            page: query.page,
                                            pageSize: query.pageSize,
                                            filters: filters,
                                            search: query.search.trim(),
                                        }
                                        crear(`/solicitudesPendientes/${usuarioAutenticado.data.id}`, options).then(resultado => {
                                            // console.log("solicitudes pendientes", resultado[0]);
                                            resolve({
                                                data: resultado[0].data.map(x => ({
                                                    ...x,
                                                    dataOriginal: x,
                                                    idSolicitud: x.id,
                                                    idProyecto: x.proyecto.id,
                                                    tipo_recurso: x.tipo_recurso.nombre,
                                                    centro: x.centro_logistico.nombre,
                                                    solicitante: x.solicitante.nombre_completo,
                                                    cotizaciones: (
                                                        <div>
                                                            {x.cotizaciones?.filter(y => y.activo && y.estado_id != 24).length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Cotización"
                                                                    tipo="cotizacion"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleCotizacionModal={handleCotizacionModal}
                                                                    listData={x.cotizaciones.filter(
                                                                        y => y.activo && y.estado_id != 24
                                                                    )}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {x.cotizaciones?.filter(y => y.activo && y.ordenCompra && y.estado_id === 23)
                                                                .length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Orden de Compra"
                                                                    tipo="ordenCompra"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleVerDocumento={handleVerDocumento}
                                                                    listData={x.cotizaciones.filter(
                                                                        y => y.activo && y.ordenCompra && y.estado_id === 23
                                                                    ).map(y => ({...y.ordenCompra, archivo_id: y.ordenCompra.archivoId}))}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {x.facturas?.filter(y => y.activo).length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Factura"
                                                                    tipo="factura"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleVerDocumento={handleVerDocumento}
                                                                    listData={x.facturas.filter(y => y.activo)
                                                                        .map(y => ({...y, id: y._id, archivo_id: y.archivoId}))}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    ),
                                                    progreso: (
                                                        <TimeLine
                                                            estado={x.estado}
                                                        />
                                                    ),
                                                    fecha: (
                                                        <Tooltip
                                                            enterTouchDelay={0}
                                                            title={
                                                                <div style={{ whiteSpace: 'pre', textAlign: 'center' }}>
                                                                    {x.estado != 4
                                                                        ? 'Creada: ' +
                                                                        moment(x.fecha).format('DD-MM-YYYY HH:mm:ss') +
                                                                        (x.fecha_termino
                                                                            ? '\nTerminada: ' +
                                                                                moment(x.fecha_termino).format(
                                                                                    'DD-MM-YYYY HH:mm:ss'
                                                                                )
                                                                            : '')
                                                                        : 'Creada: ' +
                                                                        moment(x.fecha).format('DD-MM-YYYY HH:mm:ss') +
                                                                        (x.rechazo
                                                                        ? '\nRechazada: ' +
                                                                        moment(x.rechazo.fecha).format('DD-MM-YYYY HH:mm:ss')
                                                                        : '')}
                                                                </div>
                                                            }>
                                                            <div>
                                                                {x.estado != 4
                                                                    ? x.fecha_termino
                                                                        ? moment(x.fecha_termino).diff(
                                                                            moment(x.fecha),
                                                                            'days'
                                                                        ) + ' días'
                                                                        : moment(x.fecha).fromNow()
                                                                    : x.rechazo
                                                                    ? moment(
                                                                        x.rechazo.fecha
                                                                    ).diff(moment(x.fecha), 'days') + ' días'
                                                                    : ''}
                                                            </div>
                                                        </Tooltip>
                                                    )
                                                    })),
                                                page: query.page,
                                                totalCount: resultado[0].pagination[0]?.total || 0
                                            })
                                        });
                                    })
                                }
                            />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container>
                    <Grid item xs={12} lg={mostrar ? 9 : 12}>
                            <Table
                                searchID="mainSearch2"
                                // searchText={searchTextEnCurso}
                                // handleSearchChange={(texto) => { textoBuscadoEnCurso = texto; }}
                                filtering={true}
                                grouping={false}
                                columnVis={false}
                                handleRowClick={(rowData) => {
                                    // dispatch(generalOptionsDuck.setSearchText([
                                    //     textoBuscadoMisPendientes,
                                    //     textoBuscadoEnCurso,
                                    //     textoBuscadoMisSolicitudes,
                                    //     textoBuscadoVerTodas
                                    // ]));
                                    handleRowClick(rowData, value);
                                }}
                                detalleF={contenidoDetalle}
                                columnas={columnasEnCurso}
                                remoteData={true}
                                reloadAction={true}
                                data={
                                    query => new Promise((resolve, reject) => {
                                        // console.log('query en curso', query);
                                        const filters = query.filters.map(x => ({ field: x.column.field, value: x.value }));
                                        const options = {
                                            estado: 'abiertas',
                                            page: query.page,
                                            pageSize: query.pageSize,
                                            filters: filters,
                                            search: query.search.trim(),
                                        }
                                        crear(`/solicitudesRecursosUsuario/${usuarioAutenticado.data.id}`, options).then(resultado => {
                                            // console.log("solicitudes en curso", resultado[0]);
                                            resolve({
                                                data: resultado[0].data.map(x => ({
                                                    ...x,
                                                    dataOriginal: x,
                                                    idSolicitud: x.id,
                                                    idProyecto: x.proyecto,
                                                    tipo_recurso: x.tipo_recurso.nombre,
                                                    centro: x.centro_logistico.nombre,
                                                    solicitante: x.solicitante.nombre_completo,
                                                    cotizaciones: (
                                                        <div>
                                                            {x.cotizaciones?.filter(y => y.activo && y.estado_id != 24).length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Cotización"
                                                                    tipo="cotizacion"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleCotizacionModal={handleCotizacionModal}
                                                                    listData={x.cotizaciones.filter(
                                                                        y => y.activo && y.estado_id != 24
                                                                    )}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {x.cotizaciones?.filter(y => y.activo && y.ordenCompra && y.estado_id === 23)
                                                                .length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Orden de Compra"
                                                                    tipo="ordenCompra"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleVerDocumento={handleVerDocumento}
                                                                    listData={x.cotizaciones.filter(
                                                                        y => y.activo && y.ordenCompra && y.estado_id === 23
                                                                    ).map(y => ({...y.ordenCompra, archivo_id: y.ordenCompra.archivoId}))}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {x.facturas?.filter(y => y.activo).length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Factura"
                                                                    tipo="factura"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleVerDocumento={handleVerDocumento}
                                                                    listData={x.facturas.filter(y => y.activo)
                                                                        .map(y => ({...y, id: y._id, archivo_id: y.archivoId}))}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    ),
                                                    progreso: (
                                                        <TimeLine
                                                            estado={x.estado}
                                                        />
                                                    ),
                                                    fecha: (
                                                        <Tooltip
                                                            enterTouchDelay={0}
                                                            title={
                                                                <div style={{ whiteSpace: 'pre', textAlign: 'center' }}>
                                                                    {x.estado != 4
                                                                        ? 'Creada: ' +
                                                                        moment(x.fecha).format('DD-MM-YYYY HH:mm:ss') +
                                                                        (x.fecha_termino
                                                                            ? '\nTerminada: ' +
                                                                                moment(x.fecha_termino).format(
                                                                                    'DD-MM-YYYY HH:mm:ss'
                                                                                )
                                                                            : '')
                                                                        : 'Creada: ' +
                                                                        moment(x.fecha).format('DD-MM-YYYY HH:mm:ss') +
                                                                        (x.rechazo
                                                                        ? '\nRechazada: ' +
                                                                        moment(x.rechazo.fecha).format('DD-MM-YYYY HH:mm:ss')
                                                                        : '')}
                                                                </div>
                                                            }>
                                                            <div>
                                                                {x.estado != 4
                                                                    ? x.fecha_termino
                                                                        ? moment(x.fecha_termino).diff(
                                                                            moment(x.fecha),
                                                                            'days'
                                                                        ) + ' días'
                                                                        : moment(x.fecha).fromNow()
                                                                    : x.rechazo
                                                                    ? moment(
                                                                        x.rechazo.fecha
                                                                    ).diff(moment(x.fecha), 'days') + ' días'
                                                                    : ''}
                                                            </div>
                                                        </Tooltip>
                                                    )
                                                    })),
                                                page: query.page,
                                                totalCount: resultado[0].pagination[0]?.total || 0
                                            })
                                        });
                                    })
                                }
                            />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container>
                    <Grid item xs={12} lg={mostrar ? 9 : 12}>
                            <Table
                                searchID="mainSearch3"
                                // searchText={searchTextMisSolicitudes}
                                // handleSearchChange={(texto) => { textoBuscadoMisSolicitudes = texto; }}
                                filtering={true}
                                grouping={false}
                                columnVis={false}
                                handleRowClick={(rowData) => {
                                    // dispatch(generalOptionsDuck.setSearchText([
                                    //     textoBuscadoMisPendientes,
                                    //     textoBuscadoEnCurso,
                                    //     textoBuscadoMisSolicitudes,
                                    //     textoBuscadoVerTodas
                                    // ]));
                                    handleRowClick(rowData, value);
                                }}
                                detalleF={contenidoDetalle}
                                columnas={columnasMisSolicitudes}
                                remoteData={true}
                                reloadAction={true}
                                data={
                                    query => new Promise((resolve, reject) => {
                                        // console.log('query de solicitante', query);
                                        const filters = query.filters.map(x => ({ field: x.column.field, value: x.value }));
                                        const options = {
                                            estado: 'abiertas',
                                            page: query.page,
                                            pageSize: query.pageSize,
                                            filters: filters,
                                            search: query.search.trim(),
                                        }
                                        crear(`/solicitudesSolicitante/${usuarioAutenticado.data.id}`, options).then(resultado => {
                                            // console.log("solicitudes de solicitante", resultado[0]);
                                            resolve({
                                                data: resultado[0].data.map(x => ({
                                                    ...x,
                                                    dataOriginal: x,
                                                    idSolicitud: x.id,
                                                    idProyecto: x.proyecto,
                                                    tipo_recurso: x.tipo_recurso.nombre,
                                                    centro: x.centro_logistico.nombre,
                                                    solicitante: x.solicitante.nombre_completo,
                                                    cotizaciones: (
                                                        <div>
                                                            {x.cotizaciones?.filter(y => y.activo && y.estado_id != 24).length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Cotización"
                                                                    tipo="cotizacion"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleCotizacionModal={handleCotizacionModal}
                                                                    listData={x.cotizaciones.filter(
                                                                        y => y.activo && y.estado_id != 24
                                                                    )}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {x.cotizaciones?.filter(y => y.activo && y.ordenCompra && y.estado_id === 23)
                                                                .length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Orden de Compra"
                                                                    tipo="ordenCompra"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleVerDocumento={handleVerDocumento}
                                                                    listData={x.cotizaciones.filter(
                                                                        y => y.activo && y.ordenCompra && y.estado_id === 23
                                                                    ).map(y => ({...y.ordenCompra, archivo_id: y.ordenCompra.archivoId}))}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {x.facturas?.filter(y => y.activo).length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Factura"
                                                                    tipo="factura"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleVerDocumento={handleVerDocumento}
                                                                    listData={x.facturas.filter(y => y.activo)
                                                                        .map(y => ({...y, id: y._id, archivo_id: y.archivoId}))}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    ),
                                                    progreso: (
                                                        <TimeLine
                                                            estado={x.estado}
                                                        />
                                                    ),
                                                    fecha: (
                                                        <Tooltip
                                                            enterTouchDelay={0}
                                                            title={
                                                                <div style={{ whiteSpace: 'pre', textAlign: 'center' }}>
                                                                    {x.estado != 4
                                                                        ? 'Creada: ' +
                                                                        moment(x.fecha).format('DD-MM-YYYY HH:mm:ss') +
                                                                        (x.fecha_termino
                                                                            ? '\nTerminada: ' +
                                                                                moment(x.fecha_termino).format(
                                                                                    'DD-MM-YYYY HH:mm:ss'
                                                                                )
                                                                            : '')
                                                                        : 'Creada: ' +
                                                                        moment(x.fecha).format('DD-MM-YYYY HH:mm:ss') +
                                                                        (x.rechazo
                                                                        ? '\nRechazada: ' +
                                                                        moment(x.rechazo.fecha).format('DD-MM-YYYY HH:mm:ss')
                                                                        : '')}
                                                                </div>
                                                            }>
                                                            <div>
                                                                {x.estado != 4
                                                                    ? x.fecha_termino
                                                                        ? moment(x.fecha_termino).diff(
                                                                            moment(x.fecha),
                                                                            'days'
                                                                        ) + ' días'
                                                                        : moment(x.fecha).fromNow()
                                                                    : x.rechazo
                                                                    ? moment(
                                                                        x.rechazo.fecha
                                                                    ).diff(moment(x.fecha), 'days') + ' días'
                                                                    : ''}
                                                            </div>
                                                        </Tooltip>
                                                    )
                                                    })),
                                                page: query.page,
                                                totalCount: resultado[0].pagination[0]?.total || 0
                                            })
                                        });
                                    })
                                }
                            />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Grid container>
                    <Grid item xs={12} lg={mostrar ? 9 : 12}>
                            <Table
                                searchID="mainSearch4"
                                // searchText={searchTextVerTodas}
                                // handleSearchChange={(texto) => { textoBuscadoVerTodas = texto; }}
                                filtering={true}
                                grouping={false}
                                columnVis={false}
                                handleRowClick={(rowData) => {
                                    // dispatch(generalOptionsDuck.setSearchText([
                                    //     textoBuscadoMisPendientes,
                                    //     textoBuscadoEnCurso,
                                    //     textoBuscadoMisSolicitudes,
                                    //     textoBuscadoVerTodas
                                    // ]));
                                    handleRowClick(rowData, value);
                                }}
                                detalleF={contenidoDetalle}
                                columnas={columnasVerTodas}
                                remoteData={true}
                                reloadAction={true}
                                data={
                                    query => new Promise((resolve, reject) => {
                                        // console.log('query en todas', query);
                                        const filters = query.filters.map(x => ({ field: x.column.field, value: x.value }));
                                        const options = {
                                            estado: '',
                                            page: query.page,
                                            pageSize: query.pageSize,
                                            filters: filters,
                                            search: query.search.trim(),
                                        }
                                        crear(`/solicitudesRecursosUsuario/${usuarioAutenticado.data.id}`, options).then(resultado => {
                                            // console.log("solicitudes en todas", resultado[0]);
                                            resolve({
                                                data: resultado[0].data.map(x => ({
                                                    ...x,
                                                    idSolicitud: x.id,
                                                    dataOriginal: x,
                                                    idProyecto: x.proyecto,
                                                    tipo_recurso: x.tipo_recurso.nombre,
                                                    centro: x.centro_logistico.nombre,
                                                    solicitante: x.solicitante.nombre_completo,
                                                    cotizaciones: (
                                                        <div>
                                                            {x.cotizaciones?.filter(y => y.activo && y.estado_id != 24).length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Cotización"
                                                                    tipo="cotizacion"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleCotizacionModal={handleCotizacionModal}
                                                                    listData={x.cotizaciones.filter(
                                                                        y => y.activo && y.estado_id != 24
                                                                    )}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {x.cotizaciones?.filter(y => y.activo && y.ordenCompra && y.estado_id === 23)
                                                                .length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Orden de Compra"
                                                                    tipo="ordenCompra"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleVerDocumento={handleVerDocumento}
                                                                    listData={x.cotizaciones.filter(
                                                                        y => y.activo && y.ordenCompra && y.estado_id === 23
                                                                    ).map(y => ({...y.ordenCompra, archivo_id: y.ordenCompra.archivoId}))}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {x.facturas?.filter(y => y.activo).length > 0 ? (
                                                                <BotonMenuLista
                                                                    tooltip="Factura"
                                                                    tipo="factura"
                                                                    permisoEvaluarCosto={permisoEvaluarCosto}
                                                                    handleVerDocumento={handleVerDocumento}
                                                                    listData={x.facturas.filter(y => y.activo)
                                                                        .map(y => ({...y, id: y._id, archivo_id: y.archivoId}))}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    ),
                                                    progreso: (
                                                        <TimeLine
                                                            estado={x.estado}
                                                        />
                                                    ),
                                                    fecha: (
                                                        <Tooltip
                                                            enterTouchDelay={0}
                                                            title={
                                                                <div style={{ whiteSpace: 'pre', textAlign: 'center' }}>
                                                                    {x.estado != 4
                                                                        ? 'Creada: ' +
                                                                        moment(x.fecha).format('DD-MM-YYYY HH:mm:ss') +
                                                                        (x.fecha_termino
                                                                            ? '\nTerminada: ' +
                                                                                moment(x.fecha_termino).format(
                                                                                    'DD-MM-YYYY HH:mm:ss'
                                                                                )
                                                                            : '')
                                                                        : 'Creada: ' +
                                                                        moment(x.fecha).format('DD-MM-YYYY HH:mm:ss') +
                                                                        (x.rechazo
                                                                        ? '\nRechazada: ' +
                                                                        moment(x.rechazo.fecha).format('DD-MM-YYYY HH:mm:ss')
                                                                        : '')}
                                                                </div>
                                                            }>
                                                            <div>
                                                                {x.estado != 4
                                                                    ? x.fecha_termino
                                                                        ? moment(x.fecha_termino).diff(
                                                                            moment(x.fecha),
                                                                            'days'
                                                                        ) + ' días'
                                                                        : moment(x.fecha).fromNow()
                                                                    : x.rechazo
                                                                    ? moment(
                                                                        x.rechazo.fecha
                                                                    ).diff(moment(x.fecha), 'days') + ' días'
                                                                    : ''}
                                                            </div>
                                                        </Tooltip>
                                                    )
                                                    })),
                                                page: query.page,
                                                totalCount: resultado[0].pagination[0]?.total || 0
                                            })
                                        });
                                    })
                                }
                            />
                    </Grid>
                </Grid>
            </TabPanel>
        </div>
    );
}
