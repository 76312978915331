import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Button, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { saveAs } from 'file-saver';
import TextField from '../components/TextField';
import DatePicker from '../components/DatePicker';
import Modal from '../components/Modal';
import Spinner from '../components/Spinner';
import { addMillsSep, downloadUrlAsPromise } from '../services/utils';
import { crear } from '../services/api';
import LogoCyD from '../img/logo_cyd.png';
import ImagenCarrito from '../img/carrito.png';
import ImagenDinero from '../img/money.png';
import ImagenEval from '../img/eval-general.png';

const useStyles = makeStyles(theme => ({
    root: {
        color: 'black'
    },
    titulo1: {
        color: '#980000',
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'right'
    },
    sub1: {
        color: '#980000',
        fontSize: 12,
        textAlign: 'right'
    },
    titulo2: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'right'
    },
    recuadro: {
        border: '1px solid rgb(0, 0, 0)',
        width: 250,
        height: 30
    },
    titulo3: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'left',
        display: 'inline-block'
    },
    texto3: {
        fontSize: 16,
        textAlign: 'center',
        display: 'inline-block'
    },
    kpiValue: {
        textAlign: 'center',
        marginTop: 25,
        fontSize: 40
    },
    kpiTitle: {
        textAlign: 'center',
        fontSize: 20
    },
    titulo4: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'left',
        backgroundColor: '#efefef',
        borderBottom: '2px solid #b7b7b7'
    },
    celdaRangos: {
        padding: '3px 24px 3px 16px',
        borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    celdaRangosBottom: {
        padding: '3px 24px 3px 16px',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: 'none'
    },
    celdaRangosFondo1: {
        padding: '3px 24px 3px 16px',
        backgroundColor: '#ccffcc'
    },
    celdaRangosFondo2: {
        padding: '3px 24px 3px 16px',
        backgroundColor: '#ffff99'
    },
    celdaRangosFondo3: {
        padding: '3px 24px 3px 16px',
        backgroundColor: '#ff6600',
        borderBottom: 'none'
    }
}));

export default function InformeEvalProveedor(props) {
    const {
        dataProveedor,
        defDesde,
        defHasta,
        defCantidadOC,
        defTotalCLP,
        defEvalGeneral,
        cotizacionesProv,
        informesEvalProveedor,
        getInformesEvalProveedor
    } = props;
    const { solicitudesUsuario, clases, itemsCotizacion, usuarioAutenticado } = useSelector(state => ({
        solicitudesUsuario: state.SolicitudesUsuario,
        clases: state.Clases,
        itemsCotizacion: state.ItemsCotizacion,
        usuarioAutenticado: state.Usuario
    }));
    const classes = useStyles();

    const [cantidadOC, setCantidadOC] = useState(defCantidadOC);
    const [totalCLP, setTotalCLP] = useState(defTotalCLP);
    const [evalGeneral, setEvalGeneral] = useState(defEvalGeneral);
    const [configChart1, setConfigChart1] = useState(null);
    const [configChart2, setConfigChart2] = useState(null);
    const [configChart3, setConfigChart3] = useState(null);
    const [configChart4, setConfigChart4] = useState(null);
    const [configChart5, setConfigChart5] = useState(null);
    const [oportunidades, setOportunidades] = useState('');
    const [conclusiones, setConclusiones] = useState('');
    const [filtroDesde, setFiltroDesde] = useState(defDesde);
    const [filtroHasta, setFiltroHasta] = useState(defHasta);

    const [dataInforme, setDataInforme] = useState({});
    const [crearInformeOptions, setCrearInformeOptions] = useState({
        open: false,
        tipoInforme: 0,
        creandoInforme: false,
        mensaje: ''
    });

    useEffect(() => {
        if (solicitudesUsuario.data.length && clases.data.length && itemsCotizacion.data.length) {
            let cotiFiltradas = cotizacionesProv.filter(x => x.estado_id === 23);

            if (filtroDesde)
                cotiFiltradas = cotiFiltradas.filter(x =>
                    moment(x.fecha_orden).isSameOrAfter(moment(filtroDesde, 'MMMM YYYY'))
                );
            if (filtroHasta)
                cotiFiltradas = cotiFiltradas.filter(x =>
                    moment(x.fecha_orden).isSameOrBefore(moment(filtroHasta, 'MMMM YYYY').endOf('month'))
                );

            let clasesCompras = [];
            let cantidadClasesCompras = [];
            let cantTotalClasesCompras = 0;

            let cantOC = cotiFiltradas.length;
            let montoTotal = 0;
            let periodosTabla = [];
            let evaluacionesCalidadTabla = [];
            let evaluacionesCostoTabla = [];
            let evaluacionesEntregaTabla = [];

            let sumaValorCalidad = 0;
            let sumaValorCosto = 0;
            let sumaValorEntrega = 0;
            let sumaTotalCalidad = 0;
            let sumaTotalCosto = 0;
            let sumaTotalEntrega = 0;

            cotiFiltradas.forEach(x => {
                if (x.monto_total_pesos) montoTotal += Math.round(x.monto_total_pesos);

                if (moment(x.fecha_orden).isSameOrAfter('2021-09-01')) {
                    let posPeriodo = 0;
                    for (let y = 0; y < periodosTabla.length; y++) {
                        if (periodosTabla[y].isSame(moment(x.fecha_orden), 'month')) {
                            posPeriodo = y;
                            break;
                        } else {
                            if (periodosTabla[y].isAfter(moment(x.fecha_orden), 'month')) {
                                periodosTabla.splice(y, 0, moment(x.fecha_orden));
                                evaluacionesCalidadTabla.splice(y, 0, { valor: 0, total: 0 });
                                evaluacionesCostoTabla.splice(y, 0, { valor: 0, total: 0 });
                                evaluacionesEntregaTabla.splice(y, 0, { valor: 0, total: 0 });
                                posPeriodo = y;
                                break;
                            } else {
                                if (y === periodosTabla.length - 1) {
                                    periodosTabla.push(moment(x.fecha_orden));
                                    evaluacionesCalidadTabla.push({ valor: 0, total: 0 });
                                    evaluacionesCostoTabla.push({ valor: 0, total: 0 });
                                    evaluacionesEntregaTabla.push({ valor: 0, total: 0 });
                                    posPeriodo = y + 1;
                                    break;
                                } else {
                                    // periodosTabla.splice(y + 1, 0, moment(x.fecha_orden));
                                    // evaluacionesCalidadTabla.splice(y + 1, 0, { valor: 0, total: 0 });
                                    // evaluacionesCostoTabla.splice(y + 1, 0, { valor: 0, total: 0 });
                                    // evaluacionesEntregaTabla.splice(y + 1, 0, { valor: 0, total: 0 });
                                    // posPeriodo = y + 1;
                                    // break;
                                }
                            }
                        }
                    }
                    if (periodosTabla.length === 0) {
                        periodosTabla.push(moment(x.fecha_orden));
                        evaluacionesCalidadTabla.push({ valor: 0, total: 0 });
                        evaluacionesCostoTabla.push({ valor: 0, total: 0 });
                        evaluacionesEntregaTabla.push({ valor: 0, total: 0 });
                    }

                    let calidad = evaluacionesCalidadTabla[posPeriodo].valor;
                    let totalCalidad = evaluacionesCalidadTabla[posPeriodo].total;
                    if (x.evaluaciones_calidad.length > 0) {
                        for (let i = 0; i < x.evaluaciones_calidad.length; i++) {
                            let evalData = x.evaluaciones_calidad[i];
                            let sumaPuntos = 0;
                            let total = 0;
                            if (evalData.calidad1) sumaPuntos++;
                            if (evalData.calidad2) sumaPuntos++;
                            if (evalData.calidad3) sumaPuntos++;
                            if (evalData.calidad4) sumaPuntos++;
                            if (evalData.calidad5) sumaPuntos++;
                            if (evalData.calidad6) sumaPuntos++;
                            if (evalData.calidad7) sumaPuntos++;

                            if (evalData.calidad1 != null) total++;
                            if (evalData.calidad2 != null) total++;
                            if (evalData.calidad3 != null) total++;
                            if (evalData.calidad4 != null) total++;
                            if (evalData.calidad5 != null) total++;
                            if (evalData.calidad6 != null) total++;
                            if (evalData.calidad7 != null) total++;

                            calidad += total > 0 ? sumaPuntos / total : 0;
                            sumaValorCalidad += total > 0 ? sumaPuntos / total : 0;
                        }

                        sumaTotalCalidad += x.evaluaciones_calidad.length;
                        evaluacionesCalidadTabla[posPeriodo].valor = calidad;
                        evaluacionesCalidadTabla[posPeriodo].total = totalCalidad + x.evaluaciones_calidad.length;
                    }

                    let costo = evaluacionesCostoTabla[posPeriodo].valor;
                    let totalCosto = evaluacionesCostoTabla[posPeriodo].total;
                    if (x.evaluaciones_costo.length > 0) {
                        for (let i = 0; i < x.evaluaciones_costo.length; i++) {
                            let evalData = x.evaluaciones_costo[i];

                            costo += evalData.costo / 5;
                            sumaValorCosto += evalData.costo / 5;
                        }

                        sumaTotalCosto += x.evaluaciones_costo.length;
                        evaluacionesCostoTabla[posPeriodo].valor = costo;
                        evaluacionesCostoTabla[posPeriodo].total = totalCosto + x.evaluaciones_costo.length;
                    }

                    let entrega = evaluacionesEntregaTabla[posPeriodo].valor;
                    let totalEntrega = evaluacionesEntregaTabla[posPeriodo].total;
                    if (x.evaluaciones_entrega.length > 0) {
                        for (let i = 0; i < x.evaluaciones_entrega.length; i++) {
                            let evalData = x.evaluaciones_entrega[i];

                            entrega += evalData.entrega ? parseFloat(evalData.entrega) : 0;
                            sumaValorEntrega += evalData.entrega ? parseFloat(evalData.entrega) : 0;
                        }

                        sumaTotalEntrega += x.evaluaciones_entrega.length;
                        evaluacionesEntregaTabla[posPeriodo].valor = entrega;
                        evaluacionesEntregaTabla[posPeriodo].total = totalEntrega + x.evaluaciones_entrega.length;
                    }
                }

                //Datos gráfica de compras por clase
                let clasesCotizacion = [];
                if (solicitudesUsuario.data.filter(sol => sol.id === x.solicitud_recurso_id)[0]) {
                    let itemsCoti = itemsCotizacion.data.filter(item => item.cotizacion_id === x.id);
                    let itemsSol = solicitudesUsuario.data.filter(sol => sol.id === x.solicitud_recurso_id)[0].items;
                    for (let i = 0; i < itemsCoti.length; i++) {
                        let idClase = itemsSol.filter(item => item.id === itemsCoti[i].item_recurso_id)[0].clase;
                        // console.log("id clase", idClase);
                        if (clasesCotizacion.indexOf(idClase) < 0) {
                            clasesCotizacion.push(idClase);
                            let pos = clasesCompras.indexOf(idClase);
                            if (pos < 0) {
                                clasesCompras.push(idClase);
                                cantidadClasesCompras.push(1);
                            } else {
                                cantidadClasesCompras[pos]++;
                            }
                            cantTotalClasesCompras++;
                        }
                    }
                }
            });

            let evalFinalCalidad = sumaTotalCalidad > 0 ? (sumaValorCalidad * 100) / sumaTotalCalidad : 0;
            let evalFinalCosto = sumaTotalCosto > 0 ? (sumaValorCosto * 100) / sumaTotalCosto : 0;
            let evalFinalEntrega = sumaTotalEntrega > 0 ? (sumaValorEntrega * 100) / sumaTotalEntrega : 0;
            let evalFinalFinal = Math.round((evalFinalCalidad + evalFinalCosto + evalFinalEntrega) / 3);

            let meses = [];
            let evalCalidadMeses = [];
            let evalCostoMeses = [];
            let evalEntregaMeses = [];
            for (let i = 0; i < periodosTabla.length; i++) {
                meses.push(periodosTabla[i].format('MMM YYYY'));
                evalCalidadMeses.push(
                    evaluacionesCalidadTabla[i].total > 0
                        ? Math.round((evaluacionesCalidadTabla[i].valor * 100) / evaluacionesCalidadTabla[i].total)
                        : 0
                );
                evalCostoMeses.push(
                    evaluacionesCostoTabla[i].total > 0
                        ? Math.round((evaluacionesCostoTabla[i].valor * 100) / evaluacionesCostoTabla[i].total)
                        : 0
                );
                evalEntregaMeses.push(
                    evaluacionesEntregaTabla[i].total > 0
                        ? Math.round((evaluacionesEntregaTabla[i].valor * 100) / evaluacionesEntregaTabla[i].total)
                        : 0
                );
            }

            let dataClasesCompra = [];
            for (let i = 0; i < clasesCompras.length; i++) {
                dataClasesCompra.push({
                    name: clases.data.filter(clase => clase.id === clasesCompras[i])[0].nombre,
                    y: Math.round((cantidadClasesCompras[i] / cantTotalClasesCompras) * 100)
                });
            }
            dataClasesCompra.sort((a, b) => b.y - a.y);
            let dataClasesCompraFinal = [];
            if (dataClasesCompra.length > 4) {
                dataClasesCompraFinal.push(dataClasesCompra[0]);
                dataClasesCompraFinal.push(dataClasesCompra[1]);
                dataClasesCompraFinal.push(dataClasesCompra[2]);
                let sumaVarios = 0;
                for (let i = 3; i < dataClasesCompra.length; i++) {
                    sumaVarios += dataClasesCompra[i].y;
                }
                dataClasesCompraFinal.push({ name: 'Otros', y: sumaVarios });
            } else {
                dataClasesCompraFinal = [...dataClasesCompra];
            }
            // console.log('clases compra:', dataClasesCompra);
            // console.log('clases compra final:', dataClasesCompraFinal);

            let nuevaConfig1 = {
                chart: {
                    type: 'pie'
                    // width: 300
                    // height: 200
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: 'Compras por Clase'
                },
                series: [
                    {
                        data: dataClasesCompraFinal
                    }
                ],
                plotOptions: {
                    pie: {
                        allowPointSelect: false,
                        cursor: 'pointer',
                        size: '60%',
                        innerSize: '50%',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.y}%'
                        },
                        showInLegend: true
                    }
                },
                tooltip: {
                    enabled: false
                }
            };

            // let nuevaConfig2 = {
            //     chart: {
            //         type: 'gauge',
            //         plotBackgroundColor: null,
            //         plotBackgroundImage: null,
            //         plotBorderWidth: 0,
            //         plotShadow: false,
            //         height: 200
            //     },
            //     credits: {
            //         enabled: false
            //     },
            //     title: {
            //         text: 'Calidad'
            //     },
            //     pane: {
            //         startAngle: -150,
            //         endAngle: 150,
            //         background: [
            //             {
            //                 backgroundColor: {
            //                     linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            //                     stops: [
            //                         [0, '#FFF'],
            //                         [1, '#333']
            //                     ]
            //                 },
            //                 borderWidth: 0,
            //                 outerRadius: '109%'
            //             },
            //             {
            //                 backgroundColor: {
            //                     linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            //                     stops: [
            //                         [0, '#333'],
            //                         [1, '#FFF']
            //                     ]
            //                 },
            //                 borderWidth: 1,
            //                 outerRadius: '107%'
            //             },
            //             {
            //                 // default background
            //             },
            //             {
            //                 backgroundColor: '#DDD',
            //                 borderWidth: 0,
            //                 outerRadius: '105%',
            //                 innerRadius: '103%'
            //             }
            //         ]
            //     },
            //     // the value axis
            //     yAxis: {
            //         min: 0,
            //         max: 100,

            //         minorTickInterval: 'auto',
            //         minorTickWidth: 1,
            //         minorTickLength: 0,
            //         minorTickPosition: 'inside',
            //         minorTickColor: '#666',

            //         tickPixelInterval: 30,
            //         tickWidth: 2,
            //         tickPosition: 'inside',
            //         tickLength: 7,
            //         tickColor: '#666',
            //         labels: {
            //             step: 2,
            //             rotation: 'auto'
            //         },
            //         title: {
            //             text: ''
            //         }
            //         // plotBands: [
            //         //     {
            //         //         from: 80,
            //         //         to: 100,
            //         //         color: '#55BF3B' // green
            //         //     },
            //         //     {
            //         //         from: 40,
            //         //         to: 80,
            //         //         color: '#DDDF0D' // yellow
            //         //     },
            //         //     {
            //         //         from: 0,
            //         //         to: 40,
            //         //         color: '#DF5353' // red
            //         //     }
            //         // ]
            //     },
            //     series: [
            //         {
            //             data: [Math.round(evalFinalCalidad)],
            //             tooltip: {
            //                 enabled: false
            //             }
            //         }
            //     ]
            // };

            // let nuevaConfig3 = {
            //     chart: {
            //         type: 'gauge',
            //         plotBackgroundColor: null,
            //         plotBackgroundImage: null,
            //         plotBorderWidth: 0,
            //         plotShadow: false,
            //         height: 200
            //     },
            //     credits: {
            //         enabled: false
            //     },
            //     title: {
            //         text: 'Costo'
            //     },
            //     pane: {
            //         startAngle: -150,
            //         endAngle: 150,
            //         background: [
            //             {
            //                 backgroundColor: {
            //                     linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            //                     stops: [
            //                         [0, '#FFF'],
            //                         [1, '#333']
            //                     ]
            //                 },
            //                 borderWidth: 0,
            //                 outerRadius: '109%'
            //             },
            //             {
            //                 backgroundColor: {
            //                     linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            //                     stops: [
            //                         [0, '#333'],
            //                         [1, '#FFF']
            //                     ]
            //                 },
            //                 borderWidth: 1,
            //                 outerRadius: '107%'
            //             },
            //             {
            //                 // default background
            //             },
            //             {
            //                 backgroundColor: '#DDD',
            //                 borderWidth: 0,
            //                 outerRadius: '105%',
            //                 innerRadius: '103%'
            //             }
            //         ]
            //     },
            //     // the value axis
            //     yAxis: {
            //         min: 0,
            //         max: 100,

            //         minorTickInterval: 'auto',
            //         minorTickWidth: 1,
            //         minorTickLength: 0,
            //         minorTickPosition: 'inside',
            //         minorTickColor: '#666',

            //         tickPixelInterval: 30,
            //         tickWidth: 2,
            //         tickPosition: 'inside',
            //         tickLength: 7,
            //         tickColor: '#666',
            //         labels: {
            //             step: 2,
            //             rotation: 'auto'
            //         },
            //         title: {
            //             text: ''
            //         }
            //         // plotBands: [
            //         //     {
            //         //         from: 80,
            //         //         to: 100,
            //         //         color: '#55BF3B' // green
            //         //     },
            //         //     {
            //         //         from: 40,
            //         //         to: 80,
            //         //         color: '#DDDF0D' // yellow
            //         //     },
            //         //     {
            //         //         from: 0,
            //         //         to: 40,
            //         //         color: '#DF5353' // red
            //         //     }
            //         // ]
            //     },
            //     series: [
            //         {
            //             data: [Math.round(evalFinalCosto)],
            //             tooltip: {
            //                 enabled: false
            //             }
            //         }
            //     ]
            // };

            // let nuevaConfig4 = {
            //     chart: {
            //         type: 'gauge',
            //         plotBackgroundColor: null,
            //         plotBackgroundImage: null,
            //         plotBorderWidth: 0,
            //         plotShadow: false,
            //         height: 200
            //     },
            //     credits: {
            //         enabled: false
            //     },
            //     title: {
            //         text: 'Entrega'
            //     },
            //     pane: {
            //         startAngle: -150,
            //         endAngle: 150,
            //         background: [
            //             {
            //                 backgroundColor: {
            //                     linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            //                     stops: [
            //                         [0, '#FFF'],
            //                         [1, '#333']
            //                     ]
            //                 },
            //                 borderWidth: 0,
            //                 outerRadius: '109%'
            //             },
            //             {
            //                 backgroundColor: {
            //                     linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            //                     stops: [
            //                         [0, '#333'],
            //                         [1, '#FFF']
            //                     ]
            //                 },
            //                 borderWidth: 1,
            //                 outerRadius: '107%'
            //             },
            //             {
            //                 // default background
            //             },
            //             {
            //                 backgroundColor: '#DDD',
            //                 borderWidth: 0,
            //                 outerRadius: '105%',
            //                 innerRadius: '103%'
            //             }
            //         ]
            //     },
            //     // the value axis
            //     yAxis: {
            //         min: 0,
            //         max: 100,

            //         minorTickInterval: 'auto',
            //         minorTickWidth: 1,
            //         minorTickLength: 0,
            //         minorTickPosition: 'inside',
            //         minorTickColor: '#666',

            //         tickPixelInterval: 30,
            //         tickWidth: 2,
            //         tickPosition: 'inside',
            //         tickLength: 7,
            //         tickColor: '#666',
            //         labels: {
            //             step: 2,
            //             rotation: 'auto'
            //         },
            //         title: {
            //             text: ''
            //         }
            //         // plotBands: [
            //         //     {
            //         //         from: 80,
            //         //         to: 100,
            //         //         color: '#55BF3B' // green
            //         //     },
            //         //     {
            //         //         from: 40,
            //         //         to: 80,
            //         //         color: '#DDDF0D' // yellow
            //         //     },
            //         //     {
            //         //         from: 0,
            //         //         to: 40,
            //         //         color: '#DF5353' // red
            //         //     }
            //         // ]
            //     },
            //     series: [
            //         {
            //             data: [Math.round(evalFinalEntrega)],
            //             tooltip: {
            //                 enabled: false
            //             }
            //         }
            //     ]
            // };

            let nuevaConfig5 = {
                chart: {
                    type: 'line',
                    height: 400
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: 'Desempeño durante el periodo'
                },
                xAxis: {
                    categories: meses
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                series: [
                    {
                        data: evalCalidadMeses,
                        name: 'Calidad',
                        color: '#1f69b1',
                        dataLabels: {
                            color: '#1f69b1'
                        }
                    },
                    {
                        data: evalCostoMeses,
                        name: 'Costo',
                        color: '#d57f1f',
                        dataLabels: {
                            color: '#d57f1f'
                        }
                    },
                    {
                        data: evalEntregaMeses,
                        name: 'Entrega',
                        color: '#d13636',
                        dataLabels: {
                            color: '#d13636'
                        }
                    }
                ],
                tooltip: {
                    enabled: false
                    // headerFormat: '',
                    // pointFormat: '{point.y}%',
                    // split: true
                },
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                },
                plotOptions: {
                    series: {
                        marker: {
                            symbol: 'circle'
                        },
                        label:{
                            enabled: false
                        },
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.y}%</b>',
                            style: {
                                fontSize: 8
                            }
                        }
                    }
                }
            };

            setCantidadOC(addMillsSep(cantOC));
            setTotalCLP(addMillsSep(montoTotal));
            setEvalGeneral(evalFinalFinal);
            setConfigChart1(nuevaConfig1);
            // setConfigChart2(nuevaConfig2);
            // setConfigChart3(nuevaConfig3);
            // setConfigChart4(nuevaConfig4);
            setConfigChart5(nuevaConfig5);
            setDataInforme({
                eval_general: evalFinalFinal,
                data_clases_compra: dataClasesCompraFinal,
                eval_calidad: Math.round(evalFinalCalidad),
                eval_costo: Math.round(evalFinalCosto),
                eval_entrega: Math.round(evalFinalEntrega),
                meses: meses,
                eval_calidad_meses: evalCalidadMeses,
                eval_costo_meses: evalCostoMeses,
                eval_entrega_meses: evalEntregaMeses,
                cantOC: addMillsSep(cantOC),
                totalCLP: '$ ' + addMillsSep(montoTotal)
            });
        }
    }, [defCantidadOC, solicitudesUsuario.data, itemsCotizacion.data, clases.data, filtroDesde, filtroHasta]);

    const handleSoloDescargar = () => {
        setCrearInformeOptions({
            open: true,
            tipoInforme: 0,
            creandoInforme: false,
            mensaje: "Va a crear un informe temporal solo para descarga, no se guardará en el sistema. ¿Desea continuar?",
            confirmacion: true,
            botonNo: 'No'
        })
        
    };

    const handleGuardarYDescargar = () => {
        if (filtroDesde && filtroHasta) {
            let momentDesde = moment(filtroDesde, 'MMMM YYYY');
            let momentHasta = moment(filtroHasta, 'MMMM YYYY');

            if (
                momentDesde.year() == momentHasta.year() &&
                ((momentDesde.month() === 0 && momentHasta.month() === 11) ||
                    (momentDesde.month() === 0 && momentHasta.month() === 5) ||
                    (momentDesde.month() === 6 && momentHasta.month() === 11))
            ) {
                // console.log("si se puede guardar");
                let tipo = 3;
                let mensaje = "Va a crear y guardar el informe Anual del año " + momentDesde.year() + ". \n¿Desea continuar?";
                if (momentDesde.month() === 0 && momentHasta.month() === 5) {
                    tipo = 1;
                    mensaje = "Va a crear y guardar el informe Primer Semestre del año " + momentDesde.year() + ". \n¿Desea continuar?";
                } else if (momentDesde.month() === 6 && momentHasta.month() === 11) {
                    tipo = 2;
                    mensaje = "Va a crear y guardar el informe Segundo Semestre del año " + momentDesde.year() + ". \n¿Desea continuar?";
                }

                if (informesEvalProveedor.data.filter(x => x.annio == momentDesde.year() && x.tipo_informe == tipo)[0]) {
                    setCrearInformeOptions({ 
                        open: true,
                        tipoInforme: tipo,
                        creandoInforme: false,
                        confirmacion: false,
                        botonNo: 'Cerrar',
                        mensaje: 'Ya existe en sistema un informe para el periodo seleccionado.'
                    });
                } else {
                    setCrearInformeOptions({
                        open: true,
                        tipoInforme: tipo,
                        creandoInforme: false,
                        mensaje: mensaje,
                        confirmacion: true,
                        botonNo: 'No'
                    });
                }
            } else {
                // console.log("NO se puede guardar");
                setCrearInformeOptions({
                    open: true,
                    tipoInforme: 0,
                    creandoInforme: false,
                    mensaje: "El intervalo de fechas no coincide para informe Semestral ni Anual, por lo tanto no se guardará. ¿Desea crearlo solo para descarga?",
                    confirmacion: true,
                    botonNo: 'No'
                })
            }
        
        }
    };

    const handleCrearInforme = async () => {
        setCrearInformeOptions({ ... crearInformeOptions, creandoInforme: true });
        let data = {
            ...dataInforme,
            proveedor: {
                id: dataProveedor.id,
                nombre: dataProveedor.nombre,
                antiguedad: moment().diff(moment(dataProveedor.createdAt), 'years') + ' años',
                rut: dataProveedor.rut ? dataProveedor.rut : '',
                criticidad: dataProveedor.criticidad ? dataProveedor.criticidad : '',
                giro: dataProveedor.giro ? dataProveedor.giro : '',
                representante_legal: dataProveedor.representante_legal ? dataProveedor.representante_legal : '',
                representante_legal_rut: dataProveedor.representante_legal_rut
                    ? dataProveedor.representante_legal_rut
                    : '',
                tipo_recurso: dataProveedor.tipo_recurso ? dataProveedor.tipo_recurso : '',
                clase: dataProveedor.clase ? dataProveedor.clase : '',
                banco_nombre: dataProveedor.banco_nombre ? dataProveedor.banco_nombre : '',
                banco_numero_cuenta: dataProveedor.banco_numero_cuenta ? dataProveedor.banco_numero_cuenta : '',
                banco_tipo_cuenta: dataProveedor.banco_tipo_cuenta ? dataProveedor.banco_tipo_cuenta : '',
                banco_titular_cuenta: dataProveedor.banco_titular_cuenta ? dataProveedor.banco_titular_cuenta : '',
                banco_rut_cuenta: dataProveedor.banco_rut_cuenta ? dataProveedor.banco_rut_cuenta : '',
                banco_email: dataProveedor.banco_email ? dataProveedor.banco_email : ''
            },
            num_eval: 'TEMP',
            desde: filtroDesde
                ? 'Desde el 01 de ' + moment(filtroDesde, 'MMMM YYYY').format('MMMM [de] YYYY.')
                : 'Desde el 01 de enero de 2020.',
            hasta: filtroHasta
                ? 'Hasta el ' + moment(filtroHasta, 'MMMM YYYY').endOf('month').format('DD [de] MMMM [de] YYYY.')
                : 'Hasta el ' + moment().format('DD [de] MMMM [de] YYYY.'),
            conclusiones: conclusiones,
            oportunidades: oportunidades,
            carpeta_id: '1N3Zf9YFbTNNcggNJAudOEZb_b7kMwXcw'
        };

        // console.log('nuevo informe: ', data, JSON.stringify(data));

        if (crearInformeOptions.tipoInforme === 0) {
            fetch(
                'https://script.google.com/macros/s/AKfycbx3n1zvsjESibhFjr3YYW1OXY26-nWLHEQ6JR6Q3MKHOJXLoqsGGfrXQjmv5qz3eT9Jdg/exec',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            )
                .then(res => res.json())
                .then(result => {
                    // console.log('pdf creado: ', result);
                    if (result.id) {
                        var request = 'https://www.googleapis.com/drive/v3/files/' + result.id + '?alt=media';

                        var newTab = window.open(window.location.href + '/waiting', '_blank');
                        downloadUrlAsPromise(request, "Evaluación de Proveedor N° TEMP")
                            .then(function(response) {
                                setCrearInformeOptions({ ... crearInformeOptions, open: false });
                                var tipoGrupo = response.blob.type ? response.blob.type.split('/')[0] : '';
                                if (tipoGrupo == 'image' || response.blob.type == 'application/pdf') {
                                    var blobUrl = URL.createObjectURL(response.blob);
                                    newTab.location = blobUrl;
                                } else {
                                    newTab.close();
                                    saveAs(response.blob, "Evaluación de Proveedor N° TEMP");
                                }
                            })
                            .catch(function(error) {
                                console.log(error);
                                setCrearInformeOptions({ ... crearInformeOptions, open: false });
                            });
                    } else {
                        setCrearInformeOptions({ 
                            ... crearInformeOptions,
                            creandoInforme: false,
                            confirmacion: false,
                            botonNo: 'Cerrar',
                            mensaje: 'Ha ocurrido un error, vuelva a intentarlo.'
                        });
                    }
                })
                .catch(error => {
                    console.log('Error: ', error);
                });
        } else {
            data.carpeta_id = "1jRyu2iXZIplMqOHBZSl2WErVPysT_LWS";
            data.tipo_informe = crearInformeOptions.tipoInforme;
            data.annio = moment(filtroDesde, 'MMMM YYYY').year();
            data.usuario_id = usuarioAutenticado.data.id;

            let resultado = await crear('/informe-evaluacion-proveedor', data);
            getInformesEvalProveedor();
            // console.log("resultado", resultado);
            if (resultado.archivo_id) {
                var request = 'https://www.googleapis.com/drive/v3/files/' + resultado.archivo_id + '?alt=media';

                var newTab = window.open(window.location.href + '/waiting', '_blank');
                downloadUrlAsPromise(request, 'Evaluación de Proveedor N° ' + resultado.codigo)
                    .then(function (response) {
                        setCrearInformeOptions({ ...crearInformeOptions, open: false });
                        var tipoGrupo = response.blob.type ? response.blob.type.split('/')[0] : '';
                        if (tipoGrupo == 'image' || response.blob.type == 'application/pdf') {
                            var blobUrl = URL.createObjectURL(response.blob);
                            newTab.location = blobUrl;
                        } else {
                            newTab.close();
                            saveAs(response.blob, 'Evaluación de Proveedor N° ' + resultado.codigo);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        setCrearInformeOptions({ ...crearInformeOptions, open: false });
                    });
            } else {
                setCrearInformeOptions({ 
                    ... crearInformeOptions,
                    creandoInforme: false,
                    confirmacion: false,
                    botonNo: 'Cerrar',
                    mensaje: 'Ha ocurrido un error, vuelva a intentarlo.'
                });
            }
        }
    }

    return (
        <div>
        <form noValidate className={classes.root}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={2}>
                    <img src={LogoCyD} style={{ width: 120 }} />
                </Grid>
                <Grid item xs={2}>
                    <DatePicker
                        input={{ name: 'filtroDesde', value: filtroDesde }}
                        size="small"
                        style={{ width: '100%', fontSize: 12 }}
                        name="desde"
                        label="Desde"
                        change={(name, value) => {
                            setFiltroDesde(value);
                        }}
                        openTo="month"
                        views={['year', 'month']}
                    />
                    <DatePicker
                        input={{ name: 'filtroHasta', value: filtroHasta }}
                        size="small"
                        style={{ width: '100%', fontSize: 12 }}
                        name="hasta"
                        label="Hasta"
                        change={(name, value) => {
                            setFiltroHasta(value);
                        }}
                        openTo="month"
                        views={['year', 'month']}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        style={{ display: 'block', width: 120, margin: 'auto' }}
                        disabled={!filtroDesde || !filtroHasta}
                        onClick={() => handleGuardarYDescargar()}>
                        Guardar y Descargar
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        style={{ display: 'block', width: 120, margin: 'auto', marginTop: 10 }}
                        onClick={() => handleSoloDescargar()}>
                        Sólo Descargar
                    </Button>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="h5" className={classes.titulo1}>
                        EVALUACIÓN DE PROVEEDORES
                    </Typography>
                    <Typography variant="h5" className={classes.sub1}>
                        RPG13-04.03
                    </Typography>
                    <Grid container justify="center" alignItems="center" spacing={3}>
                        <Grid item xs className={classes.titulo2}>
                            Nº
                        </Grid>
                        <Grid item className={classes.recuadro} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                justify="center"
                alignItems="center"
                spacing={3}
                className={classes.titulo3}
                style={{ backgroundColor: '#efefef', marginTop: 10 }}>
                <Grid item xs={12}>
                    <Grid container justify="center" alignItems="center">
                        <Typography variant="h5" className={classes.titulo3}>
                            Proveedor
                        </Typography>
                        <Typography variant="h5" className={classes.texto3} style={{ width: 340 }}>
                            {dataProveedor.nombre}
                        </Typography>
                        <Typography variant="h5" className={classes.titulo3}>
                            Antigüedad
                        </Typography>
                        <Typography variant="h5" className={classes.texto3} style={{ width: 150 }}>
                            {moment().diff(moment(dataProveedor.createdAt), 'years') + ' años'}
                        </Typography>
                        <Typography variant="h5" className={classes.titulo3}>
                            Rut
                        </Typography>
                        <Typography variant="h5" className={classes.texto3} style={{ width: 190 }}>
                            {dataProveedor.rut}
                        </Typography>
                        <Typography variant="h5" className={classes.titulo3}>
                            Criticidad
                        </Typography>
                        <Typography variant="h5" className={classes.texto3} style={{ width: 100 }}>
                            {dataProveedor.criticidad}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 20 }}>
                <Grid item style={{ width: 350 }}>
                    <Typography variant="h5" gutterBottom>
                        Antecedentes Generales
                    </Typography>
                    <Typography component="p">
                        <strong>Giro de la Empresa: </strong>
                        {dataProveedor.giro}
                        <br />
                        <strong>Representante Legal: </strong>
                        {dataProveedor.representante_legal}
                        <br />
                        <strong>RUT Representante Legal: </strong>
                        {dataProveedor.representante_legal_rut}
                        <br />
                        <strong>Tipo de Recurso: </strong>
                        {dataProveedor.tipo_recurso}
                        <br />
                        <strong>Clase: </strong>
                        {dataProveedor.clase.replace(/,/g, ', ')}
                    </Typography>
                </Grid>
                <Grid item style={{ width: 350 }}>
                    <Typography variant="h5" gutterBottom>
                        Antecedentes Bancarios
                    </Typography>
                    <Typography component="p">
                        <strong>Nombre Banco: </strong>
                        {dataProveedor.banco_nombre}
                        <br />
                        <strong>Nº de Cuenta: </strong>
                        {dataProveedor.banco_numero_cuenta}
                        <br />
                        <strong>Tipo de Cuenta: </strong>
                        {dataProveedor.banco_tipo_cuenta}
                        <br />
                        <strong>Titular Cuenta: </strong>
                        {dataProveedor.banco_titular_cuenta}
                        <br />
                        <strong>RUT Cuenta Bancaria: </strong>
                        {dataProveedor.banco_rut_cuenta}
                        <br />
                        <strong>Correo electrónico: </strong>
                        {dataProveedor.banco_email}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                        <Paper variant="outlined" style={{ width: 150, height: 110, marginTop: 10 }}>
                            <Grid container justify="center" alignItems="center" style={{ position: 'relative' }}>
                                <div style={{ width: 105, position: 'absolute', backgroundColor: 'white' }}>
                                    <img src={ImagenEval} style={{ width: 90, margin: 'auto', display: 'block' }} />
                                </div>
                            </Grid>
                            <Typography variant="h5" component="p" className={classes.kpiValue}>
                                {evalGeneral + '%'}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                className={classes.kpiTitle}>
                                Evaluación
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 30 }}>
                <Grid item style={{ width: 320 }}>
                    <Typography className={classes.titulo4}>Objetivo</Typography>
                    <Typography component="p" style={{ marginBottom: 20 }}>
                        Aplicar criterios de evaluación, selección y seguimiento del desempeño de los proveedores,
                        establecidos en el SGI.
                    </Typography>
                    <Typography className={classes.titulo4}>Alcance</Typography>
                    <Typography component="p">
                        Comprende todas las adquisiciones de bienes y/o servicios en el período:
                    </Typography>
                    <Typography component="p">
                        {filtroDesde
                            ? 'Desde el 01 de ' + moment(filtroDesde, 'MMMM YYYY').format('MMMM [de] YYYY.')
                            : 'Desde el 01 de enero de 2020.'}
                    </Typography>
                    <Typography component="p" style={{ marginBottom: 20 }}>
                        {filtroHasta
                            ? 'Hasta el ' +
                              moment(filtroHasta, 'MMMM YYYY').endOf('month').format('DD [de] MMMM [de] YYYY.')
                            : 'Hasta el ' + moment().format('DD [de] MMMM [de] YYYY.')}
                    </Typography>
                    <Typography className={classes.titulo4}>Metodología</Typography>
                    <Typography component="p">
                        Calidad y Costo: Aplicación de cuestionario de preguntas cerradas para cada evento registrado en
                        SDA(1).
                    </Typography>
                    <Typography component="p">
                        Entrega: % de Cumplimiento del plazo establecido en cada adquisición.
                    </Typography>
                    <Typography component="p" style={{ marginBottom: 20 }}>
                        Evaluación General: % Promedio de cumplimiento durante el período.
                    </Typography>
                    <HighchartsReact highcharts={Highcharts} options={configChart1} />
                </Grid>
                <Grid item xs>
                    <Typography className={classes.titulo4}>Ámbitos evaluados</Typography>
                    <HighchartsReact highcharts={Highcharts} options={configChart5} />
                    <Grid container spacing={3} style={{ marginTop: 10, height: 200, padding: '0 10px' }}>
                        <TableContainer component={Paper} variant="outlined" style={{ height: 'fit-content' }}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.celdaRangos}>Proveedor Excelente - Sobresaliente</TableCell>
                                        <TableCell className={classes.celdaRangos}>Mayor o igual a 90%</TableCell>
                                        <TableCell className={classes.celdaRangosFondo1} rowSpan={5} align="center">Proveedores Homologados</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.celdaRangos}>Proveedor Muy Bueno</TableCell>
                                        <TableCell className={classes.celdaRangos}>Mayor que 85%</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.celdaRangos}>Proveedor Bueno</TableCell>
                                        <TableCell className={classes.celdaRangos}>Mayor que 80%</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.celdaRangos}>Proveedor Adecuado</TableCell>
                                        <TableCell className={classes.celdaRangos}>Mayor que 70%</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.celdaRangos}>Proveedor Aceptable</TableCell>
                                        <TableCell className={classes.celdaRangos}>Mayor que 60%</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.celdaRangos}>Proveedor Mejorable</TableCell>
                                        <TableCell className={classes.celdaRangos}>Mayor que 50%</TableCell>
                                        <TableCell className={classes.celdaRangosFondo2} align="center">Proveedor debe presentar Plan de Mejora</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.celdaRangosBottom}>Proveedor Inaceptable</TableCell>
                                        <TableCell className={classes.celdaRangosBottom}>Menor o igual que 50%</TableCell>
                                        <TableCell className={classes.celdaRangosFondo3} align="center">Eliminar como Proveedor</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <Grid item xs>
                            <HighchartsReact highcharts={Highcharts} options={configChart2} />
                        </Grid>
                        <Grid item xs>
                            <HighchartsReact highcharts={Highcharts} options={configChart3} />
                        </Grid>
                        <Grid item xs>
                            <HighchartsReact highcharts={Highcharts} options={configChart4} />
                        </Grid> */}
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: 40, height: 150 }}>
                        <Grid item xs={4}>
                            <Grid container justify="center" alignItems="center" spacing={3}>
                                <Paper variant="outlined" style={{ width: 150, height: 110 }}>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        style={{ position: 'relative' }}>
                                        <div style={{ position: 'absolute' }}>
                                            <img
                                                src={ImagenCarrito}
                                                style={{ width: 60, margin: 'auto', display: 'block' }}
                                            />
                                        </div>
                                    </Grid>
                                    <Typography variant="h5" component="p" className={classes.kpiValue}>
                                        {cantidadOC}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                        className={classes.kpiTitle}>
                                        Cantidad OC
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container justify="center" alignItems="center" spacing={3}>
                                <Paper variant="outlined" style={{ width: 350, height: 110 }}>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        style={{ position: 'relative' }}>
                                        <div style={{ width: 65, position: 'absolute', backgroundColor: 'white' }}>
                                            <img
                                                src={ImagenDinero}
                                                style={{ width: 60, margin: 'auto', display: 'block' }}
                                            />
                                        </div>
                                    </Grid>
                                    <Typography variant="h5" component="p" className={classes.kpiValue}>
                                        {'$ ' + totalCLP}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                        className={classes.kpiTitle}>
                                        Total $CLP
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 0 }}>
                <Grid item xs>
                    <Typography className={classes.titulo4}>Conclusiones</Typography>
                    {/* <Field
                        multiline
                        type="text"
                        name="conclusiones"
                        rows="6"
                        characterLimit={500}
                        inputProps={{
                            maxLength: '500'
                        }}
                        style={{ width: '100%' }}
                        label=""
                        component={TextField}
                    /> */}
                    <TextField
                        multiline
                        meta={{}}
                        input={{ value: conclusiones }}
                        onChange={event => {
                            setConclusiones(event.target.value);
                        }}
                        type="text"
                        name="conclusiones"
                        rows="6"
                        characterLimit={500}
                        inputProps={{
                            maxLength: '500'
                        }}
                        style={{ width: '100%' }}
                        label=""
                    />
                </Grid>
                <Grid item xs>
                    <Typography className={classes.titulo4}>Oportunidades de Mejora</Typography>
                    {/* <Field
                        multiline
                        type="text"
                        name="oportunidades"
                        rows="6"
                        characterLimit={500}
                        inputProps={{
                            maxLength: '500'
                        }}
                        style={{ width: '100%' }}
                        label=""
                        component={TextField}
                    /> */}
                    <TextField
                        multiline
                        meta={{}}
                        input={{ value: oportunidades }}
                        onChange={event => {
                            setOportunidades(event.target.value);
                        }}
                        type="text"
                        name="oportunidades"
                        rows="6"
                        characterLimit={500}
                        inputProps={{
                            maxLength: '500'
                        }}
                        style={{ width: '100%' }}
                        label=""
                    />
                </Grid>
            </Grid>
        </form>
            <Modal
                disableBackClick={true}
                disableEscapeKey={true}
                open={crearInformeOptions.open}
                handleClose={() => setCrearInformeOptions({ ...crearInformeOptions, open: false })}
                confirmacion={crearInformeOptions.confirmacion}
                accion={() => handleCrearInforme()}
                title="Nuevo Informe"
                botonSi="Sí"
                botonNo={crearInformeOptions.botonNo}
                showActions={!crearInformeOptions.creandoInforme}
                maxWidth="xs"
                fullWidth={true}>
                {crearInformeOptions.creandoInforme ? (
                    <Spinner />
                ) : (
                    <p style={{ whiteSpace: 'break-spaces' }}>{crearInformeOptions.mensaje}</p>
                )}
            </Modal>
        </div>
    );
}
