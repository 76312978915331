import React, { Component } from 'react';
import {
  ListItem,
  Typography
} from '@material-ui/core';

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}



export default class TableOfContentsItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { link, selected, text, style, handleClick, ...custom } = this.props;
    return (
      <ListItemLink button href={link} selected={selected} onClick={handleClick}>
        {/* <ListItemText
          inset
          primary={text}
          style={{
            
          }}
        /> */}
        <Typography
          color={selected ? "textPrimary" : "textSecondary"}
          {...custom}
          style={{
            ...style
          }}
        >
            {text}
        </Typography>
      </ListItemLink>
    )
  }
}