import React from 'react'
import { Field, reduxForm } from 'redux-form'
import TextField from './TextField';
import { Grid, Button, Container } from '@material-ui/core';
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'nombre',
    'descripcion',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }
  return errors
}

let FormMemoriaRam = props => {
    const { handleSubmit, pristine, editar, reset, submitting } = props
      return(
            <Container>
                <form noValidate onSubmit={handleSubmit} >

                    <Grid container justify="center" alignItems="center"  >
                        <Grid item xs={12} lg={6}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                type="text"
                                name="nombre"
                                label="Nombre"
                                component={TextField}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                type="text"
                                name="descripcion"
                                label="Descripción"
                                component={TextField}
                            />
                        </Grid>                                                           
                    </Grid>

                    
                    <Grid container style={{ marginTop: '20px'}} justify="center" alignItems="center">
                        <Button type='submit' variant='outlined' color="primary">
                            {editar ? "Editar" : "Agregar"}
                        </Button>
                    </Grid>
                </form>
            </Container>
        )
    }


FormMemoriaRam = reduxForm({
  form: 'memorias',
})(FormMemoriaRam);

export default connect( state => ({
    initialValues: {
      nombre: state.MemoriaRam.data.filter(x => x.selected)[0] ? state.MemoriaRam.data.filter(x => x.selected)[0].nombre : "" ,
      descripcion: state.MemoriaRam.data.filter(x => x.selected)[0] ? state.MemoriaRam.data.filter(x => x.selected)[0].descripcion : "",
    },
    enableReinitialize : true,
    validate,
}))(FormMemoriaRam)