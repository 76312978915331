import React from 'react'
import { Field, reduxForm } from 'redux-form'
import TextField from './TextField';
import SelectSearch from './SelectSearch';
import { Grid, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'comentario',
    'etapa'
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }
  return errors
}

let FormComentario = props => {
    const { handleSubmit, etapas, submitSucceeded, pristine, reset, change, submitting } = props
    console.log(props)
      return(
            <Box>
                <form noValidate onSubmit={handleSubmit} >
                    <Grid container >
                        <Grid item xs={12} lg={12} xl={12}>
                          <Grid container justify="center" align="center">
                            <Field
                                style={{ width: '100%'}}
                                required
                                multiline
                                type="text"
                                name="comentario"
                                label="Comentarios:"
                                component={TextField}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} xl={12}>
                          <Grid container justify="center" align="center">
                            <Field
                                style={{ width: '100%'}}
                                required={true}
                                clear={submitSucceeded}
                                change={change}
                                options={etapas.data}
                                type="text"
                                name="etapa"
                                label="Dirigido a:"
                                component={SelectSearch}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} lg={12} xl={12}>
                          <Grid container justify="center" align="center">
                            <Button type='submit' variant='outlined' color="primary" disabled={submitting}>
                                <SendIcon />
                            </Button>
                          </Grid>
                        </Grid>                                                          
                    </Grid>
                </form>
            </Box>
        )
    }


FormComentario = reduxForm({
  form: 'comentario',
})(FormComentario);

export default connect( state => ({
    initialValues: {
      comentario: '',
    },
    enableReinitialize : true,
    validate,
}))(FormComentario)
