import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  pos: {

    marginBottom: 0,
  },
});

export default function SimpleCard(props) {
  const { info } = props
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        {props.children}
        {info && <Typography variant="caption" display="block" gutterBottom className={classes.pos} color="textSecondary">
          {info}
        </Typography>}
      </CardContent>
    </Card>
  );
}