import { makeTypes, asyncMac, createReducer, reduceReducers } from 'ducks-maker';
import { createFetch } from './hors.js';
import { crear } from '../services/api';

const t = makeTypes('comprobante-recepcion');
const FETCH = t('fetch').async();
 
const fetchActions = asyncMac(FETCH)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
};

const fetchReducer = createReducer(initialState, createFetch( FETCH ));

export default reduceReducers(fetchReducer);

export const agregarComprobante = values => async (dispatch, getState) => {
    dispatch(fetchActions.start());
    try {
        const data = await crear('/documento-entrega', values)
        dispatch(fetchActions.success(data));
        return data;
    } catch (error) {
        dispatch(fetchActions.error(error));
    }
}

export const agregarGuiaDespacho = values => async (dispatch, getState) => {
    dispatch(fetchActions.start());
    try {
        const data = await crear('/guia-despacho', values)
        dispatch(fetchActions.success(data));
        return data;
    } catch (error) {
        dispatch(fetchActions.error(error));
    }
}

export const agregarFactura = values => async (dispatch, getState) => {
    dispatch(fetchActions.start());
    try {
        const data = await crear('/factura', values)
        dispatch(fetchActions.success(data));
        return data;
    } catch (error) {
        dispatch(fetchActions.error(error));
    }
}