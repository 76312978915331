import { makeTypes, asyncMac, createReducer, reduceReducers } from 'ducks-maker';
import { createFetch } from './hors.js';
import { crear } from '../services/api';

const t = makeTypes('entrega-item-recurso')
const FETCH = t('fetch').async()
 
const fetchActions = asyncMac(FETCH)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))

export default reduceReducers(fetchReducer);

export const agregarEntregaItemRecurso = values => async (dispatch, getState) => {
  dispatch(fetchActions.start());
  try {
      const data = await crear('/entrega-item-recurso', values);
      dispatch(fetchActions.success(data));
      return data
  } catch (error) {
      dispatch(fetchActions.error(error));
  }
}