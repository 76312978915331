import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Highcharts from 'highcharts';
// import highchartsMore from 'highcharts/highcharts-more.js';
import HighchartsReact from 'highcharts-react-official';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormFiltrosDashProv from '../containers/FormFiltrosDashProv';
import { addMillsSep, compareObjectString } from '../services/utils';
import moment from 'moment';

solidGauge(Highcharts);

const useStyles = makeStyles({
    root: {
        typography: {
            fontSize: '12px'
        }
    },
    kpiLogo: {
        width: '50px',
        position: 'absolute',
        marginTop: '-30px'
    },
    kpiLogoColorOrange: {
        background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 152 0 / 40%)'
    },
    kpiLogoColorGreen: {
        background: 'linear-gradient(60deg, #66bb6a, #43a047)',
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)'
    },
    kpiLogoColorRed: {
        background: 'linear-gradient(60deg, #ef5350, #e53935)',
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(244 67 54 / 40%)'
    },
    kpiLogoGrid: {
        height: '50px'
    },
    kpiLogoIcon: {
        color: 'white',
        fontSize: '22px'
    },
    kpiCard: {
        width: '140px'
    },
    kpiCardContent: {
        paddingBottom: '10px !important'
    },
    kpiValue: {
        textAlign: 'center',
        marginBottom: '0'
    },
    kpiTitle: {
        textAlign: 'center',
        marginBottom: 20,
        fontSize: 16
    }
});

const DashboardProveedor = props => {
    const { proveedor, cotizacionesProv } = props;
    const { gerencias, proyectos, formFiltros, solicitudesUsuario, clases, itemsCotizacion } = useSelector(state => ({
        gerencias: state.Gerencias,
        proyectos: state.Proyectos,
        formFiltros: state.form['formProv' + proveedor.id],
        solicitudesUsuario: state.SolicitudesUsuario,
        clases: state.Clases,
        itemsCotizacion: state.ItemsCotizacion
    }));
    // const { gerencias } = props;
    const classes = useStyles();
    const [cantidadOC, setCantidadOC] = useState('0');
    const [totalCLP, setTotalCLP] = useState('0');
    const [dataEvaluaciones, setDataEvaluaciones] = useState([]);
    const [configChart3, setConfigChart3] = useState(null);
    const [configChart4, setConfigChart4] = useState(null);

    useEffect(() => {
        // console.log('filtros: ', formFiltros?.values);
        // console.log('solicitudes: ', solicitudesUsuario.data);
        // console.log('clases: ', clases.data);
        // console.log('items Coti: ', itemsCotizacion.data);
        if (
            formFiltros?.values &&
            solicitudesUsuario.data.length &&
            clases.data.length &&
            itemsCotizacion.data.length
        ) {
            // console.log('recalculando este prov: ', proveedor.nombre);
            // console.log('coti del prov: ', cotizacionesProv);
            let filtros = formFiltros.values;
            let cotiFiltradas = cotizacionesProv.filter(x => x.estado_id === 23);
            // console.log(moment(filtros.desde, 'MMMM YYYY'));
            // console.log(moment(filtros.hasta, 'MMMM YYYY').endOf('month'));
            if (filtros.desde)
                cotiFiltradas = cotiFiltradas.filter(x =>
                    moment(x.fecha_orden).isSameOrAfter(moment(filtros.desde, 'MMMM YYYY'))
                );
            if (filtros.hasta)
                cotiFiltradas = cotiFiltradas.filter(x =>
                    moment(x.fecha_orden).isSameOrBefore(moment(filtros.hasta, 'MMMM YYYY').endOf('month'))
                );

            if (filtros?.proyecto?.length) {
                cotiFiltradas = cotiFiltradas.filter(
                    x =>
                        filtros.proyecto.indexOf(
                            solicitudesUsuario.data.find(sol => sol.id === x.solicitud_recurso_id)?.proyecto
                        ) > -1
                );
            } else {
                if (filtros?.gerencia?.length) {
                    cotiFiltradas = cotiFiltradas.filter(
                        x =>
                            filtros.gerencia.indexOf(
                                proyectos.data.find(
                                    z =>
                                        z.id ===
                                        solicitudesUsuario.data.find(sol => sol.id === x.solicitud_recurso_id)?.proyecto
                                )?.gerencia
                            ) > -1
                    );
                }
            }

            let cantOC = cotiFiltradas.length;
            let montoTotal = 0;
            let periodosTabla = [];
            let evaluacionesCalidadTabla = [];
            let evaluacionesCostoTabla = [];
            let evaluacionesEntregaTabla = [];

            let sumaValorCalidad = 0;
            let sumaValorCosto = 0;
            let sumaValorEntrega = 0;
            let sumaTotalCalidad = 0;
            let sumaTotalCosto = 0;
            let sumaTotalEntrega = 0;

            let clasesCompras = [];
            let cantidadClasesCompras = [];
            let cantTotalClasesCompras = 0;
            cotiFiltradas.forEach(x => {
                if (x.monto_total_pesos) montoTotal += Math.round(x.monto_total_pesos);

                if (moment(x.fecha_orden).isSameOrAfter('2021-09-01')) {
                    let posPeriodo = 0;
                    for (let y = 0; y < periodosTabla.length; y++) {
                        if (periodosTabla[y].isSame(moment(x.fecha_orden), 'month')) {
                            posPeriodo = y;
                            break;
                        } else {
                            if (periodosTabla[y].isAfter(moment(x.fecha_orden), 'month')) {
                                periodosTabla.splice(y, 0, moment(x.fecha_orden));
                                evaluacionesCalidadTabla.splice(y, 0, { valor: 0, total: 0 });
                                evaluacionesCostoTabla.splice(y, 0, { valor: 0, total: 0 });
                                evaluacionesEntregaTabla.splice(y, 0, { valor: 0, total: 0 });
                                posPeriodo = y;
                                break;
                            } else {
                                if (y === periodosTabla.length - 1) {
                                    periodosTabla.push(moment(x.fecha_orden));
                                    evaluacionesCalidadTabla.push({ valor: 0, total: 0 });
                                    evaluacionesCostoTabla.push({ valor: 0, total: 0 });
                                    evaluacionesEntregaTabla.push({ valor: 0, total: 0 });
                                    posPeriodo = y + 1;
                                    break;
                                } else {
                                    // periodosTabla.splice(y + 1, 0, moment(x.fecha_orden));
                                    // evaluacionesCalidadTabla.splice(y + 1, 0, { valor: 0, total: 0 });
                                    // evaluacionesCostoTabla.splice(y + 1, 0, { valor: 0, total: 0 });
                                    // evaluacionesEntregaTabla.splice(y + 1, 0, { valor: 0, total: 0 });
                                    // posPeriodo = y + 1;
                                    // break;
                                }
                            }
                        }
                    }
                    if (periodosTabla.length === 0) {
                        periodosTabla.push(moment(x.fecha_orden));
                        evaluacionesCalidadTabla.push({ valor: 0, total: 0 });
                        evaluacionesCostoTabla.push({ valor: 0, total: 0 });
                        evaluacionesEntregaTabla.push({ valor: 0, total: 0 });
                    }

                    let calidad = evaluacionesCalidadTabla[posPeriodo].valor;
                    let totalCalidad = evaluacionesCalidadTabla[posPeriodo].total;
                    if (x.evaluaciones_calidad.length > 0) {
                        for (let i = 0; i < x.evaluaciones_calidad.length; i++) {
                            let evalData = x.evaluaciones_calidad[i];
                            let sumaPuntos = 0;
                            let total = 0;
                            if (evalData.calidad1) sumaPuntos++;
                            if (evalData.calidad2) sumaPuntos++;
                            if (evalData.calidad3) sumaPuntos++;
                            if (evalData.calidad4) sumaPuntos++;
                            if (evalData.calidad5) sumaPuntos++;
                            if (evalData.calidad6) sumaPuntos++;
                            if (evalData.calidad7) sumaPuntos++;

                            if (evalData.calidad1 != null) total++;
                            if (evalData.calidad2 != null) total++;
                            if (evalData.calidad3 != null) total++;
                            if (evalData.calidad4 != null) total++;
                            if (evalData.calidad5 != null) total++;
                            if (evalData.calidad6 != null) total++;
                            if (evalData.calidad7 != null) total++;

                            calidad += total > 0 ? sumaPuntos / total : 0;
                            sumaValorCalidad += total > 0 ? sumaPuntos / total : 0;
                        }

                        sumaTotalCalidad += x.evaluaciones_calidad.length;
                        evaluacionesCalidadTabla[posPeriodo].valor = calidad;
                        evaluacionesCalidadTabla[posPeriodo].total = totalCalidad + x.evaluaciones_calidad.length;
                    }

                    let costo = evaluacionesCostoTabla[posPeriodo].valor;
                    let totalCosto = evaluacionesCostoTabla[posPeriodo].total;
                    if (x.evaluaciones_costo.length > 0) {
                        for (let i = 0; i < x.evaluaciones_costo.length; i++) {
                            let evalData = x.evaluaciones_costo[i];

                            costo += evalData.costo / 5;
                            sumaValorCosto += evalData.costo / 5;
                        }

                        sumaTotalCosto += x.evaluaciones_costo.length;
                        evaluacionesCostoTabla[posPeriodo].valor = costo;
                        evaluacionesCostoTabla[posPeriodo].total = totalCosto + x.evaluaciones_costo.length;
                    }

                    let entrega = evaluacionesEntregaTabla[posPeriodo].valor;
                    let totalEntrega = evaluacionesEntregaTabla[posPeriodo].total;
                    if (x.evaluaciones_entrega.length > 0) {
                        for (let i = 0; i < x.evaluaciones_entrega.length; i++) {
                            let evalData = x.evaluaciones_entrega[i];

                            entrega += evalData.entrega ? parseFloat(evalData.entrega) : 0;
                            sumaValorEntrega += evalData.entrega ? parseFloat(evalData.entrega) : 0;
                        }

                        sumaTotalEntrega += x.evaluaciones_entrega.length;
                        evaluacionesEntregaTabla[posPeriodo].valor = entrega;
                        evaluacionesEntregaTabla[posPeriodo].total = totalEntrega + x.evaluaciones_entrega.length;
                    }
                }

                //Datos gráfica de compras por clase
                let clasesCotizacion = [];
                if (solicitudesUsuario.data.filter(sol => sol.id === x.solicitud_recurso_id)[0]) {
                    let itemsCoti = itemsCotizacion.data.filter(item => item.cotizacion_id === x.id);
                    let itemsSol = solicitudesUsuario.data.filter(sol => sol.id === x.solicitud_recurso_id)[0].items;
                    for (let i = 0; i < itemsCoti.length; i++) {
                        let idClase = itemsSol.filter(item => item.id === itemsCoti[i].item_recurso_id)[0].clase;
                        // console.log("id clase", idClase);
                        if (clasesCotizacion.indexOf(idClase) < 0) {
                            clasesCotizacion.push(idClase);
                            let pos = clasesCompras.indexOf(idClase);
                            if (pos < 0) {
                                clasesCompras.push(idClase);
                                cantidadClasesCompras.push(1);
                            } else {
                                cantidadClasesCompras[pos]++;
                            }
                            cantTotalClasesCompras++;
                        }
                    }
                }
            });

            // console.log("clases ", clasesCompras, cantidadClasesCompras);
            // console.log(cantTotalClasesCompras);

            let dataClasesCompra = [];
            for (let i = 0; i < clasesCompras.length; i++) {
                dataClasesCompra.push({
                    name: clases.data.filter(clase => clase.id === clasesCompras[i])[0].nombre,
                    y: Math.round((cantidadClasesCompras[i] / cantTotalClasesCompras) * 10000) / 100
                });
            }

            let evalFinalCalidad = sumaTotalCalidad > 0 ? (sumaValorCalidad * 100) / sumaTotalCalidad : 0;
            let evalFinalCosto = sumaTotalCosto > 0 ? (sumaValorCosto * 100) / sumaTotalCosto : 0;
            let evalFinalEntrega = sumaTotalEntrega > 0 ? (sumaValorEntrega * 100) / sumaTotalEntrega : 0;
            let evalFinalFinal = Math.round((evalFinalCalidad + evalFinalCosto + evalFinalEntrega) / 3);
            // console.log(" otro", sumaValorCalidad, sumaTotalCalidad);
            // console.log(evalFinalCalidad, evalFinalCosto, evalFinalEntrega);

            let dataEvaluacionesTabla = [];
            for (let i = 0; i < periodosTabla.length; i++) {
                dataEvaluacionesTabla.push({
                    periodo: periodosTabla[i].format('MMMM YYYY'),
                    calidad:
                        evaluacionesCalidadTabla[i].total > 0
                            ? Math.round((evaluacionesCalidadTabla[i].valor * 100) / evaluacionesCalidadTabla[i].total)
                            : 0,
                    costo:
                        evaluacionesCostoTabla[i].total > 0
                            ? Math.round((evaluacionesCostoTabla[i].valor * 100) / evaluacionesCostoTabla[i].total)
                            : 0,
                    entrega:
                        evaluacionesEntregaTabla[i].total > 0
                            ? Math.round((evaluacionesEntregaTabla[i].valor * 100) / evaluacionesEntregaTabla[i].total)
                            : 0
                });
            }

            // console.log(cantOC, montoTotal, evalFinalFinal);
            // console.log(periodosTabla);
            // console.log(evaluacionesCalidadTabla);
            // console.log(evaluacionesCostoTabla);
            // console.log(evaluacionesEntregaTabla);
            // console.log(dataEvaluacionesTabla);

            setCantidadOC(addMillsSep(cantOC));
            setTotalCLP(addMillsSep(montoTotal));
            setDataEvaluaciones(dataEvaluacionesTabla);

            let nuevaConfig3 = {
                chart: {
                    type: 'pie',
                    width: 550
                    // height: 200
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: 'Compras por Clase'
                },
                // xAxis: {
                //     categories: nomGerencias
                // },
                // yAxis: {
                //   title: {
                //     text: 'Solicitudes'
                //   }
                // },
                series: [
                    {
                        data: dataClasesCompra
                    }
                ],
                plotOptions: {
                    pie: {
                        allowPointSelect: false,
                        cursor: 'pointer',
                        size: '100%',
                        innerSize: '50%',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.y}%'
                            // style: {
                            //     fontSize: '10px',
                            //     // width: '100px'
                            // }
                        },
                        showInLegend: true
                    }
                },
                tooltip: {
                    enabled: false
                    // headerFormat: '',
                    // pointFormat: '{point.y}%'
                }
                // legend: {
                //     enabled: true
                // }
            };

            let nuevaConfig4 = {
                chart: {
                    type: 'solidgauge',
                    height: 78
                },
                credits: {
                    enabled: false
                },
                title: null,
                pane: {
                    center: ['50%', '85%'],
                    size: '170%',
                    startAngle: -90,
                    endAngle: 90,
                    background: {
                        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                        innerRadius: '60%',
                        outerRadius: '100%',
                        shape: 'arc'
                    }
                },
                tooltip: {
                    enabled: false
                },
                yAxis: {
                    stops: [
                        [0.3, '#DF5353'], // red
                        [0.5, '#DDDF0D'], // yellow
                        [0.7, '#55BF3B'] // green
                    ],
                    lineWidth: 0,
                    tickWidth: 0,
                    minorTickInterval: null,
                    tickAmount: 2,
                    title: {
                        y: -23,
                        text: ''
                    },
                    labels: {
                        distance: -8,
                        y: 12,
                        style: {
                            fontSize: '10px'
                        }
                    },
                    min: 0,
                    max: 100
                },
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            y: 1,
                            borderWidth: 0,
                            useHTML: true
                        }
                    }
                },
                series: [
                    {
                        name: 'Terminadas',
                        data: [evalFinalFinal],
                        dataLabels: {
                            format:
                                '<div style="text-align:center; margin-bottom: 5px">' +
                                '<span style="font-size:14px">{y}%</span><br/>' +
                                '</div>'
                        }
                    }
                ]
            };

            setConfigChart3(nuevaConfig3);
            setConfigChart4(nuevaConfig4);
        }
    }, [proveedor, solicitudesUsuario.data, clases.data, formFiltros?.values]);

    return (
        <Paper variant="outlined" style={{ padding: 10 }}>
            <Grid container spacing={5} justify="center" alignItems="center">
                <Grid item>
                    <Typography variant="h5">{proveedor.nombre}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} justify="center" alignItems="center">
                <Grid item style={{ width: 250 }}>
                    <Paper style={{ padding: 20 }}>
                        <Typography gutterBottom variant="h3" component="p" className={classes.kpiValue}>
                            {cantidadOC}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.kpiTitle}>
                            Cantidad OC
                        </Typography>
                        <Typography gutterBottom variant="h4" component="p" className={classes.kpiValue}>
                            {'$ ' + totalCLP}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.kpiTitle}>
                            Total Compras (CLP)
                        </Typography>
                        <FormFiltrosDashProv
                            form={'formProv' + proveedor.id}
                            gerencias={gerencias}
                            proyectos={proyectos}
                        />
                        <Grid container justify="center" alignItems="center" style={{ marginTop: 20 }}>
                            <Grid item xs={5}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.kpiTitle}
                                    style={{ marginBottom: 0 }}>
                                    Evaluación General
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <HighchartsReact highcharts={Highcharts} options={configChart4} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Grid container spacing={5} justify="center" alignItems="center">
                        <Grid item xs>
                            <TableContainer component={Paper} style={{ minHeight: 502, maxHeight: 502 }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Período</TableCell>
                                            <TableCell>Calidad</TableCell>
                                            <TableCell>Costo</TableCell>
                                            <TableCell>Entrega</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataEvaluaciones.map((x, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{x.periodo}</TableCell>
                                                <TableCell>{x.calidad + '%'}</TableCell>
                                                <TableCell>{x.costo + '%'}</TableCell>
                                                <TableCell>{x.entrega + '%'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item style={{ width: 557 }}>
                            <Paper
                                style={{
                                    height: 502,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <HighchartsReact highcharts={Highcharts} options={configChart3} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DashboardProveedor;
