import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'

export default function SimpleMenu(props) {
  const { handleEditarItemsModal, handleEliminarItemCotizacion, ubicacion, id, estado } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <Button variant="outlined" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Acción 
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(estado === 3 || estado === 7 || estado === 8) && <MenuItem onClick={() => {handleClose();handleEditarItemsModal(id,'separar')()}}>Separar</MenuItem>}
        {(estado === 3 || estado === 7) && <MenuItem onClick={() => {handleClose();handleEditarItemsModal(id,6)()}}>Existe</MenuItem>}
        {(estado === 3 || estado === 7) && <MenuItem onClick={() => {handleClose();handleEditarItemsModal(id,8)()}}>Comprar</MenuItem>}
        {estado === 3 && (ubicacion !== 2 ? true : false) && <MenuItem onClick={() => {handleClose();handleEditarItemsModal(id,7)()}}>Solicitar</MenuItem>}
        {(estado === 8 || estado== 9) && <MenuItem onClick={() => {handleClose();handleEliminarItemCotizacion(id)()}}>Eliminar</MenuItem>}
        {estado === 12 && <MenuItem onClick={() => {handleClose();handleEditarItemsModal(id,6)()}}>Recibido</MenuItem>}
        {estado === 6 && <MenuItem onClick={() => {handleClose();handleEditarItemsModal(id,17)()}}>En traslado</MenuItem>}
        {(estado === 6 || estado === 17) && <MenuItem onClick={() => {handleClose();handleEditarItemsModal(id,18)()}}>Listo para entrega</MenuItem>}
        {estado === 18 && <MenuItem onClick={() => {handleClose();handleEditarItemsModal(id,20)()}}>Entregado</MenuItem>}
      </Menu>
    </div>
  );
}
