import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Proveedores from './Proveedores';
import DashboardProveedor from './DashboardProveedor';
import Spinner from '../components/Spinner';
import * as cotizacionesDuck from '../ducks/Cotizaciones';
import * as itemsCotizacionDuck from '../ducks/ItemsCotizacion';

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const a11yProps = index => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
}));

const GestionProveedores = props => {
    const { proveedores, cotizaciones, solicitudesUsuario, itemsCotizacion, permisos } = useSelector(state => ({
        proveedores: state.Proveedores,
        cotizaciones: state.Cotizaciones,
        solicitudesUsuario: state.SolicitudesUsuario,
        itemsCotizacion: state.ItemsCotizacion,
        permisos: state.PermisosUsuario
    }));

    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [textoProvBuscado, setTextoProvBuscado] = useState('');
    const [proveedoresCrit, setProveedoresCrit] = useState([]);

    // useEffect(() => {
    //     // console.log('fetching cotizaciones');
    //     // dispatch(cotizacionesDuck.fetchCotizaciones());
    //     // dispatch(itemsCotizacionDuck.fetchItemsCot());
    // }, []);

    useEffect(() => {
        if (permisos.data.length > 0){
            if (!permisos.data.some(x => x.nombre === 'administrarProveedores')) props.history.push('/');
        }
    }, [permisos.data])

    // useEffect(() => {
    //     let provCrit = proveedores.data.filter(x => x.criticidad == 'Alta');
    //     // console.log('prov:', provCrit);
    //     // console.log('coti:', cotizaciones);
    //     // console.log('items cotizacion: ', itemsCotizacion);
    //     setProveedoresCrit(provCrit);
    // }, [proveedores.data, cotizaciones.data, itemsCotizacion.data]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const handleChangeTextoProvBuscado = e => {
    //     // console.log("prov buscado", e.target.value);
    //     let textoBuscado = '';
    //     if (e?.target?.value) {
    //         textoBuscado = e.target.value;
    //         // console.log("texo buscado", textoBuscado);
    //         // setTextoProvBuscado(e.target.value);
    //         let provCrit = proveedores.data.filter(
    //             x => x.criticidad == 'Alta' && x.nombre.toLowerCase().includes(textoBuscado.toLowerCase())
    //         );
    //         setTextoProvBuscado(textoBuscado);
    //         setProveedoresCrit(provCrit);
    //     } else {
    //         // console.log("en el else:", e?.target?.value);
    //         let provCrit = proveedores.data.filter(x => x.criticidad == 'Alta');
    //         setTextoProvBuscado('');
    //         setProveedoresCrit(provCrit);
    //     }
    // };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                    <Tab label="Proveedores" {...a11yProps(0)} />
                    {/* <Tab label="Dashboard" {...a11yProps(1)} /> */}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Proveedores />
            </TabPanel>
            <TabPanel value={value} index={1} style={{ backgroundColor: '#fafafa' }}>
                
                {(proveedoresCrit.length || textoProvBuscado) &&
                cotizaciones.data.length &&
                solicitudesUsuario.data.length &&
                itemsCotizacion.data.length ? (
                    // <div>
                    //     <Paper variant="outlined" style={{ padding: 10 }}>
                    //         <form autoComplete="off">
                    //             <TextField
                    //                 label="Buscar proveedor"
                    //                 type="search"
                    //                 onChange={handleChangeTextoProvBuscado}
                    //                 value={textoProvBuscado}
                    //             />
                    //         </form>
                    //     </Paper>
                    //     {proveedoresCrit.map((prov, index) => (
                    //         <DashboardProveedor
                    //             key={index}
                    //             proveedor={prov}
                    //             cotizacionesProv={cotizaciones.data.filter(x => x.proveedor_id === prov.id)}
                    //         />
                    //     ))}
                    // </div>
                    <DashboardProveedor
                        // key={index}
                        proveedor={proveedoresCrit[1]}
                        cotizacionesProv={cotizaciones.data.filter(x => x.proveedor_id === proveedoresCrit[1].id)}
                    />
                ) : (
                    <Spinner />
                )}
            </TabPanel>
        </div>
    );
};

export default GestionProveedores;
