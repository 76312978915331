import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch,  editHOR, addHOR, delHOR, searchHOR, resetHOR, editFetch, removeErrorHOR, addFetch } from './hors.js'
import { leer, modificar, solicitud, crear } from '../services/api'

const t = makeTypes('ItemsAll')
const FETCH = t('fetch').async()

const fetchActions = asyncMac(FETCH)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))

export default reduceReducers(fetchReducer )

export const fetchItemsTodos = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data = await leer(`/item-recurso`)
        data = data.filter( x => x.estado !== 4 && x.estado !== 5)
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}
