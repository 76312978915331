import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// import Stepper from '@material-ui/core/Stepper';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
// import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
// import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import StepConnector from '@material-ui/core/StepConnector';
import Tooltip from '@material-ui/core/Tooltip';
import ImagenLabelRed from '../img/label_icon_red.png';
import ImagenLabelTrans from '../img/label_icon_trans.png';

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    active: {
        '& $line': {
            borderColor: '#731f1f'
        }
    },
    completed: {
        '& $line': {
            borderColor: '#731f1f'
        }
    },
    line: {
        borderColor: '#b8b8b9',
        borderTopWidth: 3,
        borderRadius: 1
    }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#b8b8b9',
        display: 'flex',
        height: 22,
        alignItems: 'center'
    },
    active: {
        color: '#731f1f'
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
        marginRight: '5px',
        marginLeft: '5px'
    },
    backRed: {
        backgroundImage: 'url(' + ImagenLabelRed + ')',
        backgroundSize: 'contain',
        marginLeft: -15,
        height: 45,
        width: 86,
        display: 'inline-block'
    },
    backWhite: {
        backgroundImage: 'url(' + ImagenLabelTrans + ')',
        backgroundSize: 'contain',
        marginLeft: -15,
        height: 45,
        width: 86,
        display: 'inline-block'
    },
    completed: {
        color: '#ffffff',
        // zIndex: 1,
        fontSize: 24,
        display: 'block',
        margin: '10px auto'
    }
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}>
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool
};

export default function HorizontalLabelPositionBelowStepper(props) {
    const classes = useQontoStepIconStyles();
    const { estado } = props;
    return (
        <div style={{ paddingLeft: 15 }}>
            <Tooltip title="Aprobación de Solicitud">
                <div className={estado === 4 ? classes.backWhite : classes.backRed}>
                    {(estado > 4 || estado === 3) && <DoneRoundedIcon className={classes.completed} />}
                </div>
            </Tooltip>
            <Tooltip title="Revisión de Stock">
                <div className={estado === 4 || estado <= 2 ? classes.backWhite : classes.backRed}>
                    {(estado > 7 || estado === 6) && <DoneRoundedIcon className={classes.completed} />}
                </div>
            </Tooltip>
            <Tooltip title="Proceso de Adquisición">
                <div className={estado <= 7 && estado != 6 ? classes.backWhite : classes.backRed}>
                    {(estado >= 17 || estado === 6) && <DoneRoundedIcon className={classes.completed} />}
                </div>
            </Tooltip>
            <Tooltip title="Preparación y Traslado">
                <div className={estado < 17 && estado != 6 ? classes.backWhite : classes.backRed}>
                    {estado >= 19 && <DoneRoundedIcon className={classes.completed} />}
                </div>
            </Tooltip>
            {/* <Stepper
        style={{ padding: '0px', backgroundColor: 'transparent' }}
        activeStep={
          estado === 1
            ? 0
            : estado === 2
            ? 0
            : estado === 3
            ? 1
            : estado === 4
            ? 1
            : estado === 5
            ? 1
            : estado === 6
            ? 3
            : estado === 7
            ? 1
            : estado === 8
            ? 2
            : estado === 12
            ? 2
            : estado === 17
            ? 3
            : estado === 18
            ? 3
            : estado === 19
            ? 3
            : estado === 20
            ? 4
            : estado
        }
        connector={<QontoConnector />}>
        {[1, 2, 3, 4].map(x => {
          return (
            <Tooltip
              key={x}
              title={
                x === 1
                  ? 'Pendiente de Aprobación'
                  : x === 2
                  ? 'Revisión de Stock'
                  : x === 3
                  ? 'Proceso de Adquisición'
                  : x === 4
                  ? 'Recepción y Entrega'
                  : ''
              }>
              <Step>
                <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
              </Step>
            </Tooltip>
          );
        })}
      </Stepper> */}
        </div>
    );
}
