import { makeTypes, asyncMac, mac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, delHOR, searchHOR, editFetch } from './hors.js'
import { leer, modificar, solicitud, crear } from '../services/api'

const t = makeTypes('itemsCotizacion')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
const DEL = t('del').single()
const SEARCH = t('search').single()
 
const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)
export const delItemsCotizacion = mac(DEL, 'payload')
export const searchItem = mac(SEARCH,'payload')

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const delReducer = createReducer(initialState, delHOR({ DEL }))
const searchReducer = createReducer(initialState, searchHOR({ SEARCH }))
const editReducer = createReducer(initialState, editFetch( EDIT ))


export default reduceReducers(fetchReducer, delReducer, searchReducer, editReducer)

export const fetchItemsCotizacion = solicitudActual => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        // let data = await leer(`/detallesSolicitudRecursos/${id}`)
        let data = solicitudActual.items.filter(x => (x.estado === 8 || x.estado === 9) );
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const fetchItemsCot = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data = await leer(`/item-cotizacion/`)
        dispatch(fetchActions.success(data))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const consultarItemCotizacion = id => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        let data = await leer(`/item-cotizacion/${id}`)
        dispatch(searchItem())
        return data
    } catch (error) {
        dispatch(fetchActions.error(error))
    }

}
