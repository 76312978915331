import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import TextField from './TextField';
import SelectField from './SelectField';
import { Grid, Button, Container, Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import SelectSearch from './SelectSearch';
import { compareObjectString } from '../services/utils';
import moment from 'moment';
import 'moment/locale/es';
import { GERENCIA_PERU } from '../utils/constants';
moment.locale('es');

const validate = values => {
    const errors = {};
    const requiredFields = ['gerencia', 'centro_logistico', 'proyecto', 'requerido_en', 'fechaSolicitud'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = true;
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
};

const numero = value => (isNaN(value) === false ? (isNaN(parseInt(value)) === true ? '' : parseInt(value)) : value);

let FormSolicitudHeaders = props => {
    const {
        handleSubmit,
        pristine,
        reset,
        submitting,
        gerencias,
        proyectos,
        centros,
        formSolicitudHeaders,
        permisoPruebas,
        change,
        touch
    } = props;
    return (
        <Box>
            <form id="formSolicitudHeaders" noValidate onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12} lg={6} style={{ marginTop: '10px' }}>
                        <Grid container justify="center" alignItems="center">
                            {/* <Field
                                required
                                style={{ width: '80%', padding: '10px ' }}
                                name="gerencia"
                                label="Gerencia"
                                component={SelectField}>
                                {[
                                    <MenuItem key="" value="">
                                        Seleccionar
                                    </MenuItem>
                                ].concat(
                                    gerencias.data
                                        ? gerencias.data.map((x, id) => (
                                              <MenuItem key={id} value={x.id}>
                                                  {x.nombre}
                                              </MenuItem>
                                          ))
                                        : []
                                )}
                            </Field> */}
                            <Field
                                required
                                style={{ width: '80%' }}
                                type="text"
                                change={change}
                                touch={touch}
                                name="gerencia"
                                label="Gerencia"
                                options={gerencias.filter(x => permisoPruebas || x.id != "DEV").sort(compareObjectString('nombre'))}
                                component={SelectSearch}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ marginTop: '10px' }}>
                        <Grid container justify="center" alignItems="center">
                            {/* <Field
                                required
                                name="centro_logistico"
                                style={{ width: '80%', padding: '10px ' }}
                                label="Solicitar a:"
                                component={SelectField}>
                                {[
                                    <MenuItem key="" value="">
                                        Seleccionar
                                    </MenuItem>
                                ].concat(
                                    centros.data.map((x, id) => (
                                        <MenuItem key={id} value={x.id}>
                                            {x.nombre}
                                        </MenuItem>
                                    ))
                                )}
                            </Field> */}
                            <Field
                                required
                                style={{ width: '80%' }}
                                type="text"
                                change={change}
                                touch={touch}
                                name="centro_logistico"
                                label="Centro Logístico (procesará la Solicitud)"
                                options={centros
                                    .filter(
                                        x =>
                                            x.activo &&
                                            (permisoPruebas || x.id != 5) &&
                                            (formSolicitudHeaders.gerencia === GERENCIA_PERU || x.id != 7)
                                    )
                                    .sort(compareObjectString('nombre'))}
                                component={SelectSearch}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} lg={6} style={{ marginTop: '10px' }}>
                        <Grid container justify="center" alignItems="center">
                            {/* <Field
                                required
                                name="proyecto"
                                style={{ width: '80%', padding: '10px ' }}
                                label="Proyecto"
                                component={SelectField}>
                                {[
                                    <MenuItem key="" value="">
                                        Seleccionar
                                    </MenuItem>
                                ].concat(
                                    proyectos.data
                                        ? proyectos.data.map((x, id) => (
                                              <MenuItem key={id} value={x.id}>
                                                  {x.nombre}
                                              </MenuItem>
                                          ))
                                        : []
                                )}
                            </Field> */}
                            <Field
                                required
                                style={{ width: '80%' }}
                                type="text"
                                change={change}
                                touch={touch}
                                name="proyecto"
                                label="Proyecto"
                                options={proyectos
                                    .filter(x => x.gerencia === formSolicitudHeaders.gerencia && x.activo)
                                    .sort(compareObjectString('codigo'))}
                                component={SelectSearch}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ marginTop: '10px' }}>
                        <Grid container justify="center" alignItems="center">
                            <Field
                                required
                                name="requerido_en"
                                style={{ width: '80%' }}
                                label="Lugar de entrega (indicar dirección y quién recibe)"
                                component={TextField}
                            />
                        </Grid>
                    </Grid>
                    {false && (
                        <Grid item xs={12} lg={6}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    disabled
                                    type="date"
                                    name="fecha_solicitud"
                                    style={{ width: '80%', padding: '10px ' }}
                                    label="Fecha de Solicitud"
                                    component={TextField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </form>
        </Box>
    );
};

FormSolicitudHeaders = reduxForm({
    form: 'solicitudHeaders'
})(FormSolicitudHeaders);

export default connect(state => ({
    initialValues: {
        fecha_solicitud: moment().format('YYYY-MM-DD')
    },
    enableReinitialize: true,
    validate
}))(FormSolicitudHeaders);
