import React, { Fragment } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

// const logo = require('../img/logo.png');
// const logo = require('../img/logo-cydocs-color.png');
const logo = "https://storage.googleapis.com/mi-cyd/PRUEBA%20BPM%20GMI/Cydocs.svg";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        zIndex: 2
    },
    z2: {
        zIndex: 2
    }
});

function Page(props) {
    const classes = useStyles();

    return (
        <Fragment>
            <Container fixed>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh'
                    }}>
                    <div className={classes.root}>
                        <img
                            style={{
                                width: 222.6,
                                height: 100,
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: '100px'
                            }}
                            src={logo}
                            alt="logo"
                        />
                        <LinearProgress color="primary" style={{ marginLeft: '60vh', marginRight: '60vh' }} />
                    </div>
                </div>
            </Container>
        </Fragment>
    );
}

export default Page;
