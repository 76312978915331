import React from 'react';
import { Field, reduxForm } from 'redux-form';
import FieldTextField from './FieldTextField';
import TextField from './TextField';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Slide, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from './Spinner';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validate = (values = {}) => {
    const errors = {};
    const requiredFields = ['info_traslado'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }
    });
    // if (values.cantidad > values.max) {
    //     errors.cantidad = `No puede ser mayor que ${values.max}`;
    // }
    // if (values.cantidad < 1) {
    //     errors.cantidad = `No puede ser menor que 1`;
    // }
    return errors;
};

const numero = value => (isNaN(value) === false ? (isNaN(parseInt(value)) === true ? '' : parseInt(value)) : value);

let FormularioItemTraslado = props => {
    const { handleSubmit, handleClose, open, max, enTraslado, loading } = props;
    return (
        // <Dialog
        //     open={open}
        //     maxWidth="xl"
        //     TransitionComponent={Transition}
        //     onClose={handleClose}
        //     aria-labelledby="alert-dialog-slide-title"
        //     aria-describedby="alert-dialog-slide-description">
        //     <DialogTitle id="alert-dialog-slide-title">Transporte de Items</DialogTitle>
            <Container>
                <form noValidate onSubmit={handleSubmit}>
                    {/* <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} lg={6}>
                            <Field
                                label="Items en traslado"
                                name="enTraslado"
                                component={FieldTextField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    disabled: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                required
                                type="number"
                                name="cantidad"
                                label="Cantidad"
                                component={FieldTextField}
                                normalize={numero}
                            />
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    style={{ width: '90%' }}
                                    type="text"
                                    name="numero_traslado"
                                    label="Número de seguimiento"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    multiline
                                    style={{ width: '90%' }}
                                    rows="3"
                                    characterLimit={250}
                                    inputProps={{
                                        maxLength: '250'
                                    }}
                                    type="text"
                                    name="info_traslado"
                                    label="Detalles"
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <Grid container style={{ marginTop: '20px' }} justify="center" alignItems="center">
                                {/* <Button type="submit" variant="outlined" color="primary">
                                    Recepcionar
                                </Button> */}
                                <Button type="submit" variant="outlined" color="primary">
                                    Guardar Traslado
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <DialogActions>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <div>
                                <Button onClick={handleClose} color="secondary">
                                    Cerrar
                                </Button>
                                <Button type="submit" color="primary">
                                    Aceptar
                                </Button>
                            </div>
                        )}
                    </DialogActions> */}
                </form>
            </Container>
    );
};

FormularioItemTraslado = reduxForm({
    form: 'traslado'
})(FormularioItemTraslado);

export default connect((state, props) => ({
    initialValues: {
        numero_traslado: ""
        // cantidad: 1,
        // enTraslado: props.enTraslado,
        // max: props.max
    },
    enableReinitialize: true,
    validate
}))(FormularioItemTraslado);
