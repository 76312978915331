import { makeTypes, asyncMac, createReducer, reduceReducers } from 'ducks-maker';
import { createFetch } from './hors.js';
import { leer } from '../services/api';

const t = makeTypes('SolicitudesAprobar');
const FETCH = t('fetch').async();

const fetchActions = asyncMac(FETCH);

const initialState = {
    data: [],
    fetched: false,
    fetching: false
};

const fetchReducer = createReducer(initialState, createFetch(FETCH));

export default reduceReducers(fetchReducer);

export const fetchSolicitudesAprobar = (userId) => async (dispatch, getState) => {
    // console.log('obteniendo mis pendientes ');
    dispatch(fetchActions.start());
    try {
        // const data = await leer(
        //     `/solicitudesRecursosAprobador/${userId}?estado=&fecha_inicio=&fecha_fin=&gerencia=&proyecto=`
        // );
        
        const respuestas = await Promise.all([
            leer(`/solicitudesPendientes/${userId}?tipo_pendiente=1`),
            leer(`/solicitudesPendientes/${userId}?tipo_pendiente=2`),
            leer(`/solicitudesPendientes/${userId}?tipo_pendiente=3`),
            leer(`/solicitudesPendientes/${userId}?tipo_pendiente=4`),
            leer(`/solicitudesPendientes/${userId}?tipo_pendiente=5`),
            leer(`/solicitudesPendientes/${userId}?tipo_pendiente=6`),
            leer(`/solicitudesPendientes/${userId}?tipo_pendiente=7`),
            leer(`/solicitudesPendientes/${userId}?tipo_pendiente=8`)
        ]);
            // console.log('listo pendientes');
            // console.log('respuestas: ', respuestas);
            const allData = [
                ...respuestas[0],
                ...respuestas[1],
                ...respuestas[2],
                ...respuestas[3],
                ...respuestas[4],
                ...respuestas[5],
                ...respuestas[6],
                ...respuestas[7]
            ];
            // console.log("all data: ", allData);
            let idArr = allData.map(x => x.id);
            // console.log(" array de id: ", idArr);
            const allDataFiltered = allData.filter((x, i) => x.activo && idArr.indexOf(x.id) === i);
            // console.log("all data filtrada: ", allDataFiltered);
            // allDataFiltered.forEach(x => {
            //     if (!data.find(y => y.id === x.id)) console.log("no encontrado en data old", x, data.length);
            // });
            // data.forEach(x => {
            //     if (!allDataFiltered.find(y => y.id === x.id)) console.log("no encontrado en data nueva", x, allDataFiltered.length);
            // });
            dispatch(fetchActions.success(allDataFiltered));
            // dispatch(fetchActions.success(data));
        
        // dispatch(fetchActions.success(data));
    } catch (error) {
        dispatch(fetchActions.error(error));
    }
};
