import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { IconButton, Grid, Button, Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Table from './Table';
import TextField from './TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const validate = values => {
  const errors = {};
  const requiredFields = ['cantidad', 'descripcion', 'fecha_requerida'];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true;
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const numero = value => (isNaN(value) === false ? (isNaN(parseInt(value)) === true ? '' : parseInt(value)) : value);

let TablaDetalleItems = props => {
  const {
    solicitudActual,
    items,
    idItem,
    handleSubmit,
    aprobador1,
    aprobador2,
    handleEdit,
    handleNoEdit,
    editar,
    handleRechazarItemModal,
    handleVerDocumento,
    permisosUsuario,
    handleEditarItemModal
  } = props;

  function renderMonitor(rowData) {
    if (rowData.estado_id) {
      let listadoNombres = '';
      if (rowData.responsables) {
        rowData.responsables.forEach(x => {
          if (listadoNombres.length > 0) listadoNombres += '\n';
          listadoNombres += x.nombres + ' ' + x.apellidos;
        });
      }
      if (rowData.estado_id == 22) listadoNombres += 'Ver Cotizaciones';
      if (listadoNombres.length == 0) {
        return '';
      } else {
        return (
          <Tooltip enterTouchDelay={0} title={<div style={{ whiteSpace: 'pre', textAlign: 'left' }}>{listadoNombres}</div>}>
            <PersonIcon />
          </Tooltip>
        );
      }
    } else {
      return '';
    }
  }

  return (
    <Grid>
      <form noValidate onSubmit={handleSubmit}>
        <Table
          paging={false}
          search={false}
          toolbar={false}
          columnas={[
            {
              title: 'Adjunto',
              field: 'verAdjunto',
              sorting: false,
              cellStyle: {
                width: '50px',
                textAlign: 'center',
                padding: '18px 0px'
              },
              headerStyle: {
                textAlign: 'center',
                padding: '0px'
              }
            },
            {
              title: 'Cantidad',
              field: 'cantidad',
              sorting: false,
              cellStyle: {
                width: '80px',
                textAlign: 'center',
                padding: '18px 0px'
              },
              headerStyle: {
                textAlign: 'center',
                padding: '0px'
              }
            },
            {
              title: 'Estado',
              field: 'estado',
              sorting: false,
              cellStyle: {
                width: '100px',
                textAlign: 'center',
                padding: '0px'
              },
              headerStyle: {
                textAlign: 'center',
                padding: '0px'
              }
            },
            {
              title: '¿Quién Gestiona?',
              field: 'monitor',
              sorting: false,
              cellStyle: {
                width: '50px',
                textAlign: 'center',
                padding: '0px'
              },
              headerStyle: {
                textAlign: 'center',
                padding: '0px'
              },
              render: rowData => renderMonitor(rowData)
            },
            {
              title: 'Centro',
              field: 'centro',
              sorting: false,
              cellStyle: {
                width: '100px',
                padding: '0px',
                textAlign: 'center'
              },
              headerStyle: {
                textAlign: 'center',
                padding: '0px'
              }
            },
            {
              title: 'Gasto Reembolsable',
              field: 'es_gasto_reemb',
              sorting: false,
              cellStyle: {
                width: '60px',
                textAlign: 'center',
                padding: '0px'
              },
              headerStyle: {
                textAlign: 'center',
                padding: '0px'
              }
            },
            {
              title: 'Detalle',
              field: 'detalle',
              sorting: false,
              cellStyle: {
                width: '300px',
                padding: '0px',
                textAlign: 'center'
              },
              headerStyle: {
                textAlign: 'center',
                padding: '0px'
              }
            },
            {
              title: 'Descripción',
              field: 'descripcion',
              sorting: false,
              cellStyle: {
                padding: '0px',
                textAlign: 'center'
              },
              headerStyle: {
                padding: '0px',
                textAlign: 'center'
              }
            },
            {
              title: 'Fecha Requerida',
              field: 'fecha_requerida',
              sorting: false,
              cellStyle: {
                width: '130px',
                textAlign: 'center',
                padding: '0px'
              },
              headerStyle: {
                textAlign: 'center',
                padding: '0px'
              }
            },
            {
              title: 'Editar',
              field: 'editar',
              sorting: false,
              hidden: !(
                ((aprobador1 || aprobador2) && solicitudActual.estado === 1) ||
                (aprobador2 && solicitudActual.estado === 2) ||
                permisosUsuario.some(x => x.nombre === 'modificarSolicitudLogistica')
              ),
              cellStyle: {
                width: '70px',
                textAlign: 'center',
                padding: '0px'
              },
              headerStyle: {
                textAlign: 'center',
                padding: '0px'
              }
            },
            {
              title: 'Rechazar',
              field: 'rechazar',
              sorting: false,
              hidden: !(
                ((aprobador1 || aprobador2) && solicitudActual.estado === 1) ||
                (aprobador2 && solicitudActual.estado === 2)
              ),
              cellStyle: {
                width: '70px',
                textAlign: 'center',
                padding: '0px'
              },
              headerStyle: {
                textAlign: 'center',
                padding: '0px'
              }
            }
          ]}
          data={items.map((x, id) => ({
            ...x,
            verAdjunto: x.adjunto ? (
              <Button
                color="primary"
                onClick={() => {
                  handleVerDocumento(x.adjunto, 'adjunto');
                }}>
                Ver
              </Button>
            ) : (
              ''
            ),
            cantidad: editar ? (
              x.id === idItem ? (
                <Field
                  required
                  type="number"
                  name="cantidad"
                  style={{ width: '80%', padding: '10px ' }}
                  label="Cantidad"
                  component={TextField}
                  normalize={numero}
                />
              ) : (
                x.cantidad
              )
            ) : (
              x.cantidad
            ),
            estado_id: x.estado,
            responsables: x.responsables,
            estado: x.estadoRef.nombre,
            centro: x.ubicacionLogistica.nombre,
            es_gasto_reemb: x.es_gasto_reemb ? "Sí" : "No",
            detalle: x.detalle,
            descripcion: editar ? (
              x.id === idItem ? (
                <Field
                  required
                  multiline
                  type="text"
                  name="descripcion"
                  style={{ width: '80%', padding: '10px ' }}
                  label="Descripción"
                  component={TextField}
                />
              ) : (
                x.descripcion
              )
            ) : (
              x.descripcion
            ),
            fecha_requerida: editar ? (
              x.id === idItem ? (
                <Field
                  required
                  type="date"
                  name="fecha_requerida"
                  style={{ width: '100%', padding: '10px ' }}
                  label="Fecha Requerida"
                  component={TextField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              ) : (
                moment(x.fecha_requerida).format('DD-MM-YYYY')
              )
            ) : (
              moment(x.fecha_requerida).format('DD-MM-YYYY')
            ),
            accion: 'estado',
            editar: editar ? (
              x.id === idItem ? (
                <IconButton onClick={handleSubmit}>
                  <i className="material-icons">check</i>
                </IconButton>
              ) : (
                'Editando'
              )
            ) : (
              <IconButton onClick={() => {
                if (permisosUsuario.some(y => y.nombre === 'modificarSolicitudLogistica')){
                  handleEditarItemModal(x);
                } else {
                  handleEdit(x.id);
                }
              }}>
                <i className="material-icons">edit</i>
              </IconButton>
            ),

            rechazar: editar ? (
              x.id === idItem ? (
                <IconButton onClick={handleNoEdit}>
                  <i className="material-icons">cancel</i>
                </IconButton>
              ) : (
                'Editando'
              )
            ) : (
              <IconButton onClick={handleRechazarItemModal(x.id)} aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            )
          }))}
        />
      </form>
    </Grid>
  );
};

TablaDetalleItems = reduxForm({
  form: 'detalle'
})(TablaDetalleItems);

export default connect(state => ({
  initialValues: {
    cantidad: state.Items.data.filter(x => x.id === state.Iditem.data)[0]
      ? state.Items.data.filter(x => x.id === state.Iditem.data)[0].cantidad
      : 0,
    descripcion: state.Items.data.filter(x => x.id === state.Iditem.data)[0]
      ? state.Items.data.filter(x => x.id === state.Iditem.data)[0].descripcion
      : 0,
    fecha_requerida: state.Items.data.filter(x => x.id === state.Iditem.data)[0]
      ? moment(state.Items.data.filter(x => x.id === state.Iditem.data)[0].fecha_requerida).format('YYYY-MM-DD')
      : 0
  },
  enableReinitialize: true,
  validate
}))(TablaDetalleItems);
