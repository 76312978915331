import Solicitudes from './Solicitudes';
import SolicitudesUsuario from './SolicitudesUsuario';
import SolicitudesAprobar from './SolicitudesAprobar';
import Iditem from './Iditem';
import Idsolicitud from './Idsolicitud';
import Items from './Items';
import ItemsAll from './ItemsAll';
import Aprobador1 from './Aprobador1';
import Aprobador2 from './Aprobador2';
import Usuario from './Usuario';
import Usuarios from './Usuarios';
import Gerencias from './Gerencias';
import GerenciasGeneral from './GerenciasGeneral';
import Proyectos from './Proyectos';
import ProyectosGeneral from './ProyectosGeneral';
import Vehiculos from './Vehiculos';
import ModoEdicion from './ModoEdicion';
import TopografiaLaboratorios from './TopografiaLaboratorio';
import Miscelaneos from './Miscelaneos';
import Estados from './Estados';
import Clases from './Clases';
import Subclases from './Subclases';
import Mensajes from './Mensajes';
import Computacionales from './Computacionales';
import GruposCotizacion from './GruposCotizacion';
import Cotizaciones from './Cotizaciones';
import Empresas from './Empresas';
import Proveedores from './Proveedores';
import Centros from './Centros';
import CentroLogistico from './CentroLogistico';
import IdGrupoCotizacion from './IdGrupoCotizacion';
import ItemsCotizacion from './ItemsCotizacion';
import PermisosUsuario from './PermisosUsuario';
import Archivo from './Archivo';
import Facturas from './Factura';
import ItemsCotizados from './ItemsCotizados';
import MarcasVehiculos from './MarcasVehiculos';
import Perfiles from './Perfiles';
import RangosPrecios from './RangosPrecios';
import AprobadoresCotizacion from './AprobadoresCotizacion';
import TiposRecursos from './TiposRecursos';
import Equipamientos from './Equipamientos';
import Colores from './Colores';
import Procesadores from './Procesadores';
import DiscosDuros from './DiscosDuros';
import MemoriaRam from './MemoriaRam';
import Comunas from './Comunas';
import Ciudades from './Ciudades';
import AprobadoresProyecto from './AprobadoresProyecto';
import AprobadoresGerencia from './AprobadoresGerencia';
import ConfiguracionProyecto from './ConfiguracionProyecto';
import ComprobanteRecepcion from './ComprobanteRecepcion';
import ColoresProyecto from './ColoresProyecto';
import Traccion from './Traccion';
import TipoMotor from './TipoMotor';
import Cilindrada from './Cilindrada';
import Drawer from './Drawer';
import DrawerComentarios from './DrawerComentarios';
import Comentarios from './Comentarios';
import Modales from './Modales';
import IdProyecto from './IdProyecto';
import Etapas from './Etapas';
import GeneralOptions from './GeneralOptions';
import OrdenesCompra from './OrdenesCompra';
import TipoDocumentoProveedor from './TipoDocumentoProveedor';
import { reducer as formReducer } from 'redux-form';

export default {
    GeneralOptions,
    Items,
    ItemsAll,
    Solicitudes,
    SolicitudesAprobar,
    SolicitudesUsuario,
    Aprobador1,
    Aprobador2,
    Iditem,
    Idsolicitud,
    Usuario,
    Usuarios,
    Gerencias,
    GerenciasGeneral,
    ProyectosGeneral,
    Proyectos,
    Vehiculos,
    TopografiaLaboratorios,
    Miscelaneos,
    Computacionales,
    Estados,
    Clases,
    Subclases,
    Mensajes,
    ModoEdicion,
    GruposCotizacion,
    Cotizaciones,
    OrdenesCompra,
    Empresas,
    Proveedores,
    Centros,
    IdGrupoCotizacion,
    ItemsCotizacion,
    PermisosUsuario,
    Archivo,
    Facturas,
    ItemsCotizados,
    Perfiles,
    CentroLogistico,
    MarcasVehiculos,
    Proyectos,
    RangosPrecios,
    AprobadoresCotizacion,
    TiposRecursos,
    Equipamientos,
    Colores,
    Procesadores,
    DiscosDuros,
    MemoriaRam,
    Comunas,
    Ciudades,
    AprobadoresProyecto,
    AprobadoresGerencia,
    ConfiguracionProyecto,
    ComprobanteRecepcion,
    ColoresProyecto,
    Traccion,
    TipoMotor,
    Cilindrada,
    Drawer,
    DrawerComentarios,
    Comentarios,
    Modales,
    IdProyecto,
    Etapas,
    TipoDocumentoProveedor,
    form: formReducer
};
