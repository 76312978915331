import { makeTypes, mac, createReducer, reduceReducers } from 'ducks-maker'
import { assignHOR } from './hors.js'

const t = makeTypes('iditem')
const ASSIGN_IDITEM = t('assign').single()

export const assignIditem = mac(ASSIGN_IDITEM, 'payload')

const initialState = {
    data: "",
}

const assignReducer =  createReducer(initialState, assignHOR({ ASSIGN: ASSIGN_IDITEM }))

export default reduceReducers(assignReducer)