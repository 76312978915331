import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Tooltip from "@material-ui/core/Tooltip";
import TextField from './TextField';
import SelectField from './SelectField';
import Checkbox from './Checkbox';
import { Grid, Button, Container, Box, InputAdornment, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Link } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import SelectSearch from './SelectSearch';
import ModRadioGroup from './ModRadioGroup';
import FieldFiledInput from './FieldFileInput';
import { addMillsSep, compareObjectString } from '../services/utils';
import moment from 'moment';
import 'moment/locale/es';
import { leer } from '../services/api';
moment.locale('es');

const validate = (values, props) => {
    const { tiposRecursos, itemEditado } = props;
    const errors = {};
    const requiredFields = [
        'cantidad',
        'tipo_recurso',
        'clase',
        'sub_clase',
        'marca_vehiculo',
        'traccion',
        'tipo_motor',
        'color',
        'cilindrada',
        'anio',
        'procesador',
        'hdd',
        'ram',
        'descripcion',
        // 'fecha_requerida',
        'centro',
        'estado_item',
        'requiereChip'
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = true;
        }
    });

    if (values.cantidad && values.cantidad < 1) errors.cantidad = "La cantidad debe ser mayor a 0";

    // if (!itemEditado && values.fecha_requerida) {
    //     const nombreTipoRecurso = tiposRecursos.data.filter(x => x.id === values.tipo_recurso)[0]
    //         ? tiposRecursos.data.filter(x => x.id === values.tipo_recurso)[0].nombre
    //         : '';
    //     switch (nombreTipoRecurso) {
    //         case 'Vehículos':
    //             if (moment(values.fecha_requerida) < moment().add(14, 'days')) {
    //                 errors.fecha_requerida = 'El plazo mínimo requerido para este item es de 14 días';
    //             }
    //             break;
    //         case 'Misceláneos':
    //             if (moment(values.fecha_requerida) < moment().add(5, 'days')) {
    //                 errors.fecha_requerida = 'El plazo mínimo requerido para este item es de 5 días';
    //             }
    //             break;
    //         case 'Computación':
    //             if (moment(values.fecha_requerida) < moment().add(10, 'days')) {
    //                 errors.fecha_requerida = 'El plazo mínimo requerido para este item es de 10 días';
    //             }
    //             break;
    //         case 'Topografía y Laboratorio':
    //             if (moment(values.fecha_requerida) < moment().add(10, 'days')) {
    //                 errors.fecha_requerida = 'El plazo mínimo requerido para este item es de 10 días';
    //             }
    //             break;
    //         default:
    //             break;
    //     }
    // }

    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    return errors;
};

const numero = num => {
    let value = num && num.toLocaleString().replace(/\./g, '');
    let valor = isNaN(value) === false ? (isNaN(parseInt(value)) === true ? '' : parseInt(value)) : value;
    return valor;
};

// const currency = value => {
//     let valor = value && parseFloat(value);
//     valor = valor && valor.toLocaleString('en-US');
//     valor = valor && valor.replace(/\,/g, '.');
//     return valor ? valor : '';
// };

const currency = value => {
    if (value) {
        let newValue = value
            .replace(/[^0-9\,]/g, '')
            .replace(/(\d*\,\d*)(\,)(\d*)/g, '$1$3')
            .replace(/^(\d+,?\d{0,2})\d*$/, '$1');
        let partes = newValue.split(',');
        return addMillsSep(partes[0]);
    } else {
        return '0';
    }
};

const currency2decimal = value => {
    if (value) {
        let newValue = value
            .replace(/[^0-9\,]/g, '')
            .replace(/(\d*\,\d*)(\,)(\d*)/g, '$1$3')
            .replace(/^(\d+,?\d{0,2})\d*$/, '$1');
        let partes = newValue.split(',');
        newValue = addMillsSep(partes[0]);
        if (partes.length > 1) newValue += ',' + partes[1];
        return newValue;
    } else {
        return '0';
    }
};

const radioGroupChip = ({ input, meta: { touched, error }, children, ...custom }) => (
    <FormControl component="fieldset" style={{ width: '80%' }}>
        <FormLabel component="legend">¿Requiere chip?</FormLabel>
        <RadioGroup
            row
            value={input.value}
            onChange={input.onChange}
            style={{ color: touched && error ? '#ff0000' : '#000000de' }}>
            <FormControlLabel
                style={{ marginBottom: 0 }}
                value="1"
                control={<Radio color="primary" />}
                label="Sí"
            />
            <FormControlLabel
                style={{ marginBottom: 0 }}
                value="2"
                control={<Radio color="primary" />}
                label="No"
            />
        </RadioGroup>
    </FormControl>
);

let FormSolicitudItems = props => {
    const {
        handleSubmit,
        formSolicitudItems,
        anios,
        pristine,
        reset,
        submitting,
        change,
        touch,
        adjuntoName,
        handleOnChangeFileAdjunto,
        itemEditado,
        nuevoItem,
    } = props;
    const [listasFormulario, setListasFormulario] = React.useState({
        tiposRecursos: [],
        clases: [],
        subClases: [],
        equipamientos: [],
        marcas: [],
        colores: [],
        procesadores: [],
        discoDuro: [],
        memoriaRam: [],
        tracciones: [],
        cilindros: [],
        motores: [],
        centros: [],
        estados: [],
    });

    useEffect(() => {
        leer('/getListasFormItem').then(response => {
            setListasFormulario(response);
        });
    }, []);
    // console.log('formItems: ', formSolicitudItems);
    return (
      <Box>
        <form id="formularioSolicitud" noValidate onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} lg={6} style={{ marginTop: "10px" }}>
              <Grid container justify="center" alignItems="center">
                {/* <Field
                                required
                                name="tipo_recurso"
                                style={{ width: '80%'}}
                                label="Tipo de Recurso"
                                component={SelectField}>
                                {[
                                    <MenuItem key="" value="">
                                        Seleccionar
                                    </MenuItem>
                                ].concat(
                                    tiposRecursos.data
                                        .filter(x => x.activo)
                                        .map((x, id) => (
                                            <MenuItem key={id} value={x.id}>
                                                {x.nombre}
                                            </MenuItem>
                                        ))
                                )}
                            </Field> */}
                <Field
                  required
                  // disabled={itemEditado ? true: false }
                  style={{ width: "80%" }}
                  type="text"
                  change={change}
                  touch={touch}
                  name="tipo_recurso"
                  label="Tipo de Recurso"
                  options={listasFormulario.tiposRecursos.filter(
                    (x) => x.activo
                  )}
                  component={SelectSearch}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} style={{ marginTop: "10px" }}>
              <Grid container justify="center" alignItems="center">
                <Field
                  required
                  type="number"
                  name="cantidad"
                  // normalize={numero}
                  style={{ width: "80%" }}
                  label="Cantidad"
                  component={TextField}
                />
              </Grid>
            </Grid>
          </Grid>

          {formSolicitudItems.tipo_recurso && (
            <Grid container>
              <Grid item xs={12} lg={6} style={{ marginTop: "10px" }}>
                <Grid container justify="center" alignItems="center">
                  {/* <Field
                                    required
                                    name="clase"
                                    style={{ width: '80%', padding: '10px ' }}
                                    label="Clase"
                                    component={SelectField}>
                                    {[
                                        <MenuItem key="" value="">
                                            Seleccionar
                                        </MenuItem>
                                    ].concat(
                                        clases.data
                                            .filter(x => x.activo)
                                            .filter(x => x.tipo_recurso === formSolicitudItems.tipo_recurso)
                                            .map((x, id) => (
                                                <MenuItem key={id} value={x.id}>
                                                    {x.nombre}
                                                </MenuItem>
                                            ))
                                    )}
                                </Field> */}
                  <Field
                    required
                    style={{ width: "80%" }}
                    type="text"
                    change={change}
                    touch={touch}
                    name="clase"
                    label="Clase"
                    options={listasFormulario.clases
                      .filter((x) => x.activo)
                      .filter(
                        (x) =>
                          x.tipo_recurso === formSolicitudItems.tipo_recurso
                      )
                      .sort(compareObjectString("nombre"))}
                    component={SelectSearch}
                  />
                </Grid>
              </Grid>
              {/* {!itemEditado && <Grid item xs={12} lg={6} style={{ marginTop: '10px' }}>
                            <Grid container justify="center" alignItems="baseline">
                                <Field
                                    name="precio_referencial"
                                    style={{ width: '35%' }}
                                    label="Precio Referencial / Unidad"
                                    // normalize={numero}
                                    format={formSolicitudItems.tipo_moneda == 'CLP' ? currency : currency}
                                    component={TextField}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                />
                                <Field
                                    name="tipo_moneda"
                                    style={{ width: '40%', marginLeft: '5%' }}
                                    label="Tipo de Moneda"
                                    component={SelectField}>
                                    {[
                                        { id: 'CLP', nombre: 'Peso Chileno (CLP)' },
                                        { id: 'USD', nombre: 'Dólar Estadounidense (USD)' },
                                        { id: 'UF', nombre: 'Unidad de Fomento (UF)' },
                                        { id: 'EUR', nombre: 'Euro (EUR)' }
                                    ].map((x, id) => (
                                        <MenuItem key={id} value={x.id}>
                                            {x.nombre}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                        </Grid>} */}
              {itemEditado && (
                <Grid item xs={12} lg={6} style={{ marginTop: "10px" }}>
                  <Grid container justify="center" alignItems="center">
                    <Field
                      required
                      style={{ width: "35%" }}
                      type="text"
                      change={change}
                      touch={touch}
                      name="estado_item"
                      label="Estado"
                      options={listasFormulario.estados.filter(
                        (x) =>
                          [1, 2, 3, 4, 6, 7, 8, 12, 17, 18, 20, 22].indexOf(
                            x.id
                          ) > -1
                      )}
                      component={SelectSearch}
                    />
                    <Field
                      required
                      style={{ width: "40%", marginLeft: "5%" }}
                      type="text"
                      change={change}
                      touch={touch}
                      name="centro"
                      label="Centro Logístico"
                      options={listasFormulario.centros
                        .filter((x) => x.activo && x.id != 5)
                        .sort(compareObjectString("nombre"))}
                      // options={centros.data.filter(x => x.activo).sort(compareObjectString('nombre'))}
                      component={SelectSearch}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}

          {formSolicitudItems.tipo_recurso &&
            [1, 2].indexOf(formSolicitudItems.tipo_recurso) > -1 && (
              <Grid container>
                <Grid item xs={12} lg={6} style={{ marginTop: "10px" }}>
                  <Grid container justify="center" alignItems="center">
                    {/* <Field
                                        required
                                        name="sub_clase"
                                        style={{ width: '80%', padding: '10px ' }}
                                        label="Sub-clase"
                                        component={SelectField}>
                                        {[
                                            <MenuItem key="" value="">
                                                Seleccionar
                                            </MenuItem>
                                        ].concat(
                                            subClases.data
                                                .filter(x => x.activo)
                                                .filter(x => x.clase === formSolicitudItems.clase)
                                                .map((x, id) => (
                                                    <MenuItem key={id} value={x.id}>
                                                        {x.nombre ? x.nombre : ''}
                                                    </MenuItem>
                                                ))
                                        )}
                                    </Field> */}
                    <Field
                      required
                      style={{ width: "80%" }}
                      type="text"
                      change={change}
                      touch={touch}
                      name="sub_clase"
                      label="Sub-clase"
                      options={listasFormulario.subClases
                        .filter((x) => x.activo)
                        .filter((x) => x.clase === formSolicitudItems.clase)
                        .sort(compareObjectString("nombre"))}
                      component={SelectSearch}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

          {listasFormulario.clases.filter(
            (x) => x.id === formSolicitudItems.clase
          )[0] &&
            ["Vehículos"].indexOf(
              listasFormulario.clases.filter(
                (x) => x.id === formSolicitudItems.clase
              )[0].nombre
            ) !== -1 && (
              <Grid container>
                <Grid container>
                  <Grid item xs={12} lg={4} style={{ marginTop: "10px" }}>
                    <Grid container justify="center" alignItems="center">
                      {/* <Field
                                            required
                                            type="text"
                                            name="marca_vehiculo"
                                            style={{ width: '80%', padding: '10px ' }}
                                            label="Marca"
                                            component={SelectField}>
                                            {[
                                                <MenuItem key="" value="">
                                                    Seleccionar
                                                </MenuItem>
                                            ].concat(
                                                marcas.data
                                                    .filter(x => x.activo)
                                                    .map((x, id) => (
                                                        <MenuItem key={id} value={x.id}>
                                                            {x.nombre ? x.nombre : ''}
                                                        </MenuItem>
                                                    ))
                                            )}
                                        </Field> */}
                      <Field
                        required
                        style={{ width: "80%" }}
                        type="text"
                        change={change}
                        touch={touch}
                        name="marca_vehiculo"
                        label="Marca"
                        options={listasFormulario.marcas
                          .filter((x) => x.activo)
                          .sort(compareObjectString("nombre"))}
                        component={SelectSearch}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={4} style={{ marginTop: "10px" }}>
                    <Grid container justify="center" alignItems="center">
                      {/* <Field
                                            required
                                            type="text"
                                            name="traccion"
                                            style={{ width: '80%', padding: '10px ' }}
                                            label="Traccion"
                                            component={SelectField}>
                                            {[
                                                <MenuItem key="" value="">
                                                    Seleccionar
                                                </MenuItem>
                                            ].concat(
                                                tracciones.data
                                                    .filter(x => x.activo)
                                                    .map((x, id) => (
                                                        <MenuItem key={x.id} value={x.id}>
                                                            {x.nombre}
                                                        </MenuItem>
                                                    ))
                                            )}
                                        </Field> */}
                      <Field
                        required
                        style={{ width: "80%" }}
                        type="text"
                        change={change}
                        touch={touch}
                        name="traccion"
                        label="Traccion"
                        options={listasFormulario.tracciones
                          .filter((x) => x.activo)
                          .sort(compareObjectString("nombre"))}
                        component={SelectSearch}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={4} style={{ marginTop: "10px" }}>
                    <Grid container justify="center" alignItems="center">
                      {/* <Field
                                            required
                                            type="date"
                                            name="tipo_motor"
                                            style={{ width: '80%', padding: '10px ' }}
                                            label="Tipo Motor"
                                            component={SelectField}>
                                            {[
                                                <MenuItem key="" value="">
                                                    Seleccionar
                                                </MenuItem>
                                            ].concat(
                                                motores.data
                                                    .filter(x => x.activo)
                                                    .map((x, id) => (
                                                        <MenuItem key={x.id} value={x.id}>
                                                            {x.nombre}
                                                        </MenuItem>
                                                    ))
                                            )}
                                        </Field> */}
                      <Field
                        required
                        style={{ width: "80%" }}
                        type="text"
                        change={change}
                        touch={touch}
                        name="tipo_motor"
                        label="Tipo Motor"
                        options={listasFormulario.motores
                          .filter((x) => x.activo)
                          .sort(compareObjectString("nombre"))}
                        component={SelectSearch}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} lg={4} style={{ marginTop: "10px" }}>
                    <Grid container justify="center" alignItems="center">
                      {/* <Field
                                            required
                                            name="color"
                                            style={{ width: '80%', padding: '10px ' }}
                                            label="Color"
                                            component={SelectField}>
                                            {[
                                                <MenuItem key="" value="">
                                                    Seleccionar
                                                </MenuItem>
                                            ].concat(
                                                colores.data
                                                    .filter(x => x.activo)
                                                    .map((x, id) => (
                                                        <MenuItem key={id} value={x.id}>
                                                            {x.nombre ? x.nombre : ''}
                                                        </MenuItem>
                                                    ))
                                            )}
                                        </Field> */}
                      <Field
                        required
                        style={{ width: "80%" }}
                        type="text"
                        change={change}
                        touch={touch}
                        name="color"
                        label="Color"
                        options={listasFormulario.colores
                          .filter((x) => x.activo)
                          .sort(compareObjectString("nombre"))}
                        component={SelectSearch}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={4} style={{ marginTop: "10px" }}>
                    <Grid container justify="center" alignItems="center">
                      {/* <Field
                                            required
                                            name="cilindrada"
                                            style={{ width: '80%', padding: '10px ' }}
                                            label="Cilindrada"
                                            component={SelectField}>
                                            {[
                                                <MenuItem key="" value="">
                                                    Seleccionar
                                                </MenuItem>
                                            ].concat(
                                                cilindros.data
                                                    .filter(x => x.activo)
                                                    .map((x, id) => (
                                                        <MenuItem key={x.id} value={x.id}>
                                                            {x.nombre}
                                                        </MenuItem>
                                                    ))
                                            )}
                                        </Field> */}
                      <Field
                        required
                        style={{ width: "80%" }}
                        type="text"
                        change={change}
                        touch={touch}
                        name="cilindrada"
                        label="Cilindrada"
                        options={listasFormulario.cilindros.filter(
                          (x) => x.activo
                        )}
                        component={SelectSearch}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={4} style={{ marginTop: "10px" }}>
                    <Grid container justify="center" alignItems="center">
                      {/* <Field
                                            required
                                            name="anio"
                                            style={{ width: '80%', padding: '10px ' }}
                                            label="Año"
                                            component={SelectField}>
                                            {[
                                                <MenuItem key="" value="">
                                                    Seleccionar
                                                </MenuItem>
                                            ].concat(
                                                anios.map((x, id) => (
                                                    <MenuItem key={id} value={x}>
                                                        {x}
                                                    </MenuItem>
                                                ))
                                            )}
                                        </Field> */}
                      <Field
                        required
                        style={{ width: "80%" }}
                        type="text"
                        change={change}
                        touch={touch}
                        name="anio"
                        label="Año"
                        options={anios}
                        component={SelectSearch}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justify="center" alignItems="center">
                  {listasFormulario.equipamientos
                    .filter((x) => x.activo === 1)
                    .map((x, id) => (
                      <Grid
                        key={id}
                        item
                        xs={12}
                        lg={2}
                        style={{ marginTop: "10px" }}
                      >
                        <Field
                          name={`equipamiento[${x.id}]`}
                          label={x.nombre}
                          style={{ width: "80%", padding: "10px " }}
                          component={Checkbox}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            )}

          {listasFormulario.clases.filter(
            (x) => x.id === formSolicitudItems.clase
          )[0] &&
            [
              "Computador Notebook",
              "Computador Escritorio",
              "Servidor",
            ].indexOf(
              listasFormulario.clases.filter(
                (x) => x.id === formSolicitudItems.clase
              )[0].nombre
            ) !== -1 && (
              <Grid container>
                <Grid item xs={12} lg={12} style={{ marginTop: "10px" }}>
                  <Grid container justify="center" alignItems="center">
                    <Field
                      required
                      style={{ width: "80%" }}
                      change={change}
                      touch={touch}
                      name="propositoEquipo"
                      label="Uso que tendrá el equipo"
                      claseEquipo={
                        listasFormulario.clases.filter(
                          (x) => x.id === formSolicitudItems.clase
                        )[0].nombre
                      }
                      // options={anios}
                      component={ModRadioGroup}
                    />

                    {formSolicitudItems.propositoEquipo === "personalizado" && (
                      <Typography
                        variant="body1"
                        style={{ width: "90%" }}
                        gutterBottom
                      >
                        ¡Uso exclusivo de licitaciones que requieren equipos
                        específicos!
                        <br />
                        <br />
                      </Typography>
                    )}
                    {formSolicitudItems.propositoEquipo === "oficina" &&
                      listasFormulario.clases.filter(
                        (x) => x.id === formSolicitudItems.clase
                      )[0].nombre == "Computador Notebook" && (
                        <Typography
                          variant="body1"
                          style={{ width: "90%" }}
                          gutterBottom
                        >
                          Se recomienda para usuarios que necesitan desempeñar
                          las siguientes funciones:
                          <br />
                          <br />
                          - Navegación web
                          <br />
                          - Correo electrónico
                          <br />
                          - Microsoft office, visio, project
                          <br />
                          - Adobe acrobat pro
                          <br />
                          - Informes
                          <br />
                          - Presentaciones
                          <br />
                          - Multitarea
                          <br />
                          <br />
                          Procesador Intel i5, 16GB Ram, 500GB SSD, pantalla
                          14", Windows 10 Pro, 3 años de garantía
                          <br />
                          Precio Referencia: CLP$ 890.000
                          <br />
                        </Typography>
                      )}
                    {formSolicitudItems.propositoEquipo === "oficina" &&
                      listasFormulario.clases.filter(
                        (x) => x.id === formSolicitudItems.clase
                      )[0].nombre != "Computador Notebook" && (
                        <Typography
                          variant="body1"
                          style={{ width: "90%" }}
                          gutterBottom
                        >
                          Se recomienda para usuarios que necesitan desempeñar
                          las siguientes funciones:
                          <br />
                          <br />
                          - Navegación web
                          <br />
                          - Correo electrónico
                          <br />
                          - Microsoft office, visio, project
                          <br />
                          - Adobe acrobat pro
                          <br />
                          - Informes
                          <br />
                          - Presentaciones
                          <br />
                          - Multitarea
                          <br />
                          <br />
                          Procesador Intel i5, 16GB Ram, 500GB SSD, pantalla
                          14", Windows 10 Pro, 3 años de garantía
                          <br />
                          Precio Referencia: CLP$ 790.000
                          <br />
                        </Typography>
                      )}
                    {formSolicitudItems.propositoEquipo === "gerencia" && (
                      <Typography
                        variant="body1"
                        style={{ width: "90%" }}
                        gutterBottom
                      >
                        Se recomienda para usuarios que necesitan desempeñar las
                        siguientes funciones:
                        <br />
                        <br />
                        - Navegación web
                        <br />
                        - Correo electrónico
                        <br />
                        - Microsoft office, visio, project
                        <br />
                        - Adobe acrobat pro
                        <br />
                        - Informes
                        <br />
                        - Presentaciones
                        <br />
                        - Multitarea
                        <br />
                        - Primavera
                        <br />
                        <br />
                        Procesador Intel i7, 16GB Ram, 500GB SSD, pantalla 14",
                        Windows 10 Pro, 3 años de garantía
                        <br />
                        Precio Referencia: CLP$ 1.380.000
                        <br />
                      </Typography>
                    )}
                    {formSolicitudItems.propositoEquipo === "autocad" && (
                      <Typography
                        variant="body1"
                        style={{ width: "90%" }}
                        gutterBottom
                      >
                        Se recomienda para usuarios que necesitan desempeñar las
                        siguientes funciones:
                        <br />
                        <br />
                        - Navegación web
                        <br />
                        - Correo electrónico
                        <br />
                        - Microsoft office, visio, project
                        <br />
                        - Adobe acrobat pro
                        <br />
                        - Informes
                        <br />
                        - Presentaciones
                        <br />
                        - Multitarea
                        <br />
                        - Primavera
                        <br />
                        - AutoCAD
                        <br />
                        <br />
                        Procesador Intel i7, 16GB Ram, 500GB SSD, tarjeta de
                        video 4GB, pantalla 15", Windows 10 Pro, 3 años de
                        garantía
                        <br />
                        Precio Referencia: CLP$ 1.430.000
                        <br />
                      </Typography>
                    )}
                    {formSolicitudItems.propositoEquipo === "bim" &&
                      listasFormulario.clases.filter(
                        (x) => x.id === formSolicitudItems.clase
                      )[0].nombre == "Computador Notebook" && (
                        <Typography
                          variant="body1"
                          style={{ width: "90%" }}
                          gutterBottom
                        >
                          Se recomienda para usuarios que necesitan desempeñar
                          las siguientes funciones:
                          <br />
                          <br />
                          - Navegación web
                          <br />
                          - Correo electrónico
                          <br />
                          - Microsoft office, visio, project
                          <br />
                          - Adobe acrobat pro
                          <br />
                          - Informes
                          <br />
                          - Presentaciones
                          <br />
                          - Multitarea
                          <br />
                          - Primavera
                          <br />
                          - AutoCAD, civil 3D, naviswork manage, revit, recap
                          pro, etc. (autodesk collection).
                          <br />
                          <br />
                          Procesador Intel i9, 32GB Ram, 1TB SSD, tarjeta de
                          video 4GB o más, pantalla 15", Windows 10 Pro, 3 años
                          de garantía
                          <br />
                          Precio Referencia: CLP$ 1.980.000
                          <br />
                        </Typography>
                      )}
                    {formSolicitudItems.propositoEquipo === "bim" &&
                      listasFormulario.clases.filter(
                        (x) => x.id === formSolicitudItems.clase
                      )[0].nombre != "Computador Notebook" && (
                        <Typography
                          variant="body1"
                          style={{ width: "90%" }}
                          gutterBottom
                        >
                          Se recomienda para usuarios que necesitan desempeñar
                          las siguientes funciones:
                          <br />
                          <br />
                          - Navegación web
                          <br />
                          - Correo electrónico
                          <br />
                          - Microsoft office, visio, project
                          <br />
                          - Adobe acrobat pro
                          <br />
                          - Informes
                          <br />
                          - Presentaciones
                          <br />
                          - Multitarea
                          <br />
                          - Primavera
                          <br />
                          - AutoCAD, civil 3D, naviswork manage, revit, recap
                          pro, etc. (autodesk collection).
                          <br />
                          <br />
                          Procesador Intel i9, 32GB Ram, 1TB SSD, tarjeta de
                          video 4GB o más, pantalla 15", Windows 10 Pro, 3 años
                          de garantía
                          <br />
                          Precio Referencia: CLP$ 2.800.000
                          <br />
                        </Typography>
                      )}
                  </Grid>
                </Grid>
                {/* </Grid> */}

                {/* <Grid container> */}
                {formSolicitudItems.propositoEquipo === "personalizado" && (
                  <Grid container>
                    <Grid item xs={12} lg={4} style={{ marginTop: "10px" }}>
                      <Grid container justify="center" alignItems="center">
                        {/* <Field
                                                required
                                                type="text"
                                                name="procesador"
                                                style={{ width: '80%', padding: '10px ' }}
                                                label="Procesador"
                                                component={SelectField}>
                                                {[
                                                    <MenuItem key="" value="">
                                                        Seleccionar
                                                    </MenuItem>
                                                ].concat(
                                                    procesadores.data
                                                        .filter(x => x.activo)
                                                        .map((x, id) => (
                                                            <MenuItem key={x.id} value={x.id}>
                                                                {x.nombre}
                                                            </MenuItem>
                                                        ))
                                                )}
                                            </Field> */}
                        <Field
                          required
                          style={{ width: "80%" }}
                          type="text"
                          change={change}
                          touch={touch}
                          name="procesador"
                          label="Procesador"
                          options={listasFormulario.procesadores
                            .filter((x) => x.activo)
                            .sort(compareObjectString("nombre"))}
                          component={SelectSearch}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4} style={{ marginTop: "10px" }}>
                      <Grid container justify="center" alignItems="center">
                        {/* <Field
                                                required
                                                type="text"
                                                name="hdd"
                                                style={{ width: '80%', padding: '10px ' }}
                                                label="Disco Duro"
                                                component={SelectField}>
                                                {[
                                                    <MenuItem key="" value="">
                                                        Seleccionar
                                                    </MenuItem>
                                                ].concat(
                                                    discoDuro.data
                                                        .filter(x => x.activo)
                                                        .map((x, id) => (
                                                            <MenuItem key={x.id} value={x.id}>
                                                                {x.nombre}
                                                            </MenuItem>
                                                        ))
                                                )}
                                            </Field> */}
                        <Field
                          required
                          style={{ width: "80%" }}
                          type="text"
                          change={change}
                          touch={touch}
                          name="hdd"
                          label="Disco Duro"
                          options={listasFormulario.discoDuro
                            .filter((x) => x.activo)
                            .sort(compareObjectString("nombre"))}
                          component={SelectSearch}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4} style={{ marginTop: "10px" }}>
                      <Grid container justify="center" alignItems="center">
                        {/* <Field
                                                required
                                                type="date"
                                                name="ram"
                                                style={{ width: '80%', padding: '10px ' }}
                                                label="Memoria Ram"
                                                component={SelectField}>
                                                {[
                                                    <MenuItem key="" value="">
                                                        Seleccionar
                                                    </MenuItem>
                                                ].concat(
                                                    memoriaRam.data
                                                        .filter(x => x.activo)
                                                        .map((x, id) => (
                                                            <MenuItem key={x.id} value={x.id}>
                                                                {x.nombre}
                                                            </MenuItem>
                                                        ))
                                                )}
                                            </Field> */}
                        <Field
                          required
                          style={{ width: "80%" }}
                          type="text"
                          change={change}
                          touch={touch}
                          name="ram"
                          label="Memoria Ram"
                          options={listasFormulario.memoriaRam
                            .filter((x) => x.activo)
                            .sort(compareObjectString("nombre"))}
                          component={SelectSearch}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {!itemEditado && (
                  <Grid container justify="center" alignItems="center">
                    <Typography
                      variant="body1"
                      style={{ width: "90%" }}
                      gutterBottom
                    >
                      <br />
                      Al agregar cualquier equipo Computador de Escritorio o
                      Notebook, se agregará automáticamente un ítem Software
                      Office con la misma cantidad.
                      <br />
                      Equipo no incluye AutoCAD u otro software especializado.
                      Si lo requiere debe solicitarlo como otro ítem en esta
                      misma solicitud.
                      <br />
                      Equipo no incluye monitor externo. Si lo requiere debe
                      solicitarlo como otro ítem en esta misma solicitud.
                      <br />
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          {listasFormulario.subClases.filter(
            (x) => x.id === formSolicitudItems.sub_clase
          )[0] &&
            ["Estaciones totales", "Niveles"].indexOf(
              listasFormulario.subClases.filter(
                (x) => x.id === formSolicitudItems.sub_clase
              )[0].nombre
            ) !== -1 &&
            !itemEditado && (
              <Grid container>
                <Grid container justify="center" alignItems="center">
                  <Typography
                    variant="body1"
                    style={{ width: "90%" }}
                    gutterBottom
                  >
                    <br />
                    Al agregar cualquier equipo Estaciones Totales o Niveles, se
                    agregará automáticamente un ítem Calibración Equipos con la
                    misma cantidad.
                    <br />
                  </Typography>
                </Grid>
              </Grid>
            )}
          {listasFormulario.clases.filter(
            (x) => x.id === formSolicitudItems.clase
          )[0] &&
            ["Telefonía y/o BAM"].indexOf(
              listasFormulario.clases.filter(
                (x) => x.id === formSolicitudItems.clase
              )[0].nombre
            ) !== -1 && (
              <Grid item xs={12} lg={6} style={{ marginTop: "10px" }}>
                <Grid container justify="center" alignItems="center">
                  <Field
                    required
                    name="requiereChip"
                    component={radioGroupChip}
                  />
                </Grid>
              </Grid>
            )}
          {listasFormulario.clases.filter(
            (x) => x.id === formSolicitudItems.clase
          )[0] &&
            ["Seguros"].indexOf(
              listasFormulario.clases.filter(
                (x) => x.id === formSolicitudItems.clase
              )[0].nombre
            ) !== -1 && (
              <Grid container>
                <Grid container justify="center" alignItems="center">
                  <Typography
                    variant="body1"
                    style={{ width: "90%" }}
                    gutterBottom
                  >
                    <br />
                    Para las solicitudes de Seguros, descargue este documento,
                    complételo y luego adjúntelo:{" "}
                    <Link
                      href="https://storage.googleapis.com/sda-v2/documentos/FORMULARIO%20SOLICITUD%20DE%20SEGURO%20RC.docx"
                      download
                    >
                      Documento Seguro
                    </Link>
                    <br />
                  </Typography>
                </Grid>
              </Grid>
            )}
          {formSolicitudItems.tipo_recurso && (
            <Grid container>
              <Grid item xs={12} lg={6} style={{ marginTop: "10px" }}>
                <Grid container justify="center" alignItems="center">
                  <Field
                    required
                    multiline
                    type="text"
                    name="descripcion"
                    rows="3"
                    characterLimit={500}
                    inputProps={{
                      maxLength: "500",
                    }}
                    style={{ width: "80%" }}
                    label="Descripcion"
                    component={TextField}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} lg={6} style={{ marginTop: '10px' }}>
                            <Grid container justify="center" alignItems="center">
                                <Field
                                    required
                                    type="date"
                                    name="fecha_requerida"
                                    style={{ width: '80%' }}
                                    label="Fecha Requerida"
                                    component={TextField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid> */}
              {!itemEditado && (
                <Grid item xs={12} lg={6} style={{ marginTop: "10px" }}>
                  <Grid container justify="center" alignItems="center">
                    <Field
                      name="adjunto"
                      style={{ width: "80%" }}
                      component={FieldFiledInput}
                      label="Archivo adjunto (opcional)"
                      accept=""
                      type="file"
                      fileName={adjuntoName}
                      handleOnChange={handleOnChangeFileAdjunto}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} lg={6} style={{ marginTop: "10px" }}>
                <Tooltip
                  title={
                    <Typography style={{ fontSize: "11px" }}>
                      Gasto sujeto a reembolso directo por parte del cliente
                    </Typography>
                  }
                  //   title="Gasto sujeto a reembolso directo por parte del cliente"
                  placement="top-start"
                  arrow
                  style={{ marginTop: "15px" }}
                >
                  <Grid container justify="center" alignItems="left">
                    <Field
                      name="es_gasto_reemb"
                      label="Gasto Reembolsable"
                      style={{
                        width: "80%",
                        padding: "10px ",
                        marginTop: "-20px",
                      }}
                      component={Checkbox}
                    />
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            style={{ marginTop: "20px" }}
            justify="center"
            alignItems="center"
          >
            <Button type="submit" variant="outlined" color="primary">
              {nuevoItem || !itemEditado ? "Agregar" : "Guardar"}
            </Button>
            {!itemEditado && (
              <Button
                onClick={reset}
                disabled={pristine || submitting}
                variant="outlined"
                color="primary"
              >
                Limpiar
              </Button>
            )}
          </Grid>
        </form>
      </Box>
    );
};

FormSolicitudItems = reduxForm({
    form: 'solicitudItems'
})(FormSolicitudItems);

export default connect((state, ownProps) => ({
    initialValues: {
        tipo_recurso: ownProps.itemEditado?.tipo_recurso ? ownProps.itemEditado.tipo_recurso : null,
        estado_item: ownProps.itemEditado?.estado && !ownProps.nuevoItem ? ownProps.itemEditado.estado : null,
        clase: ownProps.itemEditado?.clase && !ownProps.nuevoItem ? ownProps.itemEditado.clase : null,
        sub_clase: ownProps.itemEditado?.sub_clase && !ownProps.nuevoItem ? ownProps.itemEditado.sub_clase : null,
        descripcion: ownProps.itemEditado?.descripcion && !ownProps.nuevoItem ? ownProps.itemEditado.descripcion : null,
        centro: ownProps.itemEditado?.ubicacion_logistica && !ownProps.nuevoItem ? ownProps.itemEditado.ubicacion_logistica : null,
        fecha_requerida: ownProps.itemEditado?.fecha_requerida
        ? moment(ownProps.itemEditado.fecha_requerida).format('YYYY-MM-DD')
        : moment().add(10, 'days').format('YYYY-MM-DD'),
        cantidad: ownProps.itemEditado?.cantidad && !ownProps.nuevoItem ? ownProps.itemEditado.cantidad : '',
        propositoEquipo:
            ownProps.itemEditado?.equipo_estandar && !ownProps.nuevoItem
            ? ownProps.itemEditado?.equipo_estandar.indexOf('Administrativo') > -1
            ? 'oficina'
            : ownProps.itemEditado?.equipo_estandar.indexOf('Ejecutivo') > -1
            ? 'gerencia'
            : ownProps.itemEditado?.equipo_estandar.indexOf('AutoCAD') > -1
            ? 'autocad'
            : ownProps.itemEditado?.equipo_estandar.indexOf('Especialista BIM') > -1
            ? 'bim'
            : 'personalizado'
            : 'oficina',
        tipo_moneda: 'CLP'
    },
    enableReinitialize: true,
    validate
}))(FormSolicitudItems);
