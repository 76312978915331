import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Grid, Container, Box, Typography, Paper } from '@material-ui/core';
import TableOfContents from '../components/TableOfContents';
import image_sda from '../img/SDA_0.png';
class Ayuda extends React.Component {

    state = {
        showSnack: false,
        snackMessage: '',
        selected: 0
    }

    componentDidMount() {
    }

    handleClick = (index) => {
        this.setState({ ...this.state, selected: index });
    }

    render() {
        const { selected } = this.state;
        return (
            <Box>
                <Typography align="center" variant="h4" gutterBottom>
                    Ayuda
                </Typography>
                <Paper>
                    <Grid container>
                        <Grid item xs={9} style={{ paddingLeft: "15px", paddingRight: "15px" }}>

                            <br />
                            {selected === 0 &&
                                <div>
                                    <div id="crearSolicitud" style={{ paddingBottom: "56.25%", position: "relative", height: 0, overflow: "hidden" }}>
                                        <iframe title="crearSolicitud" src='https://drive.google.com/file/d/1aeV3Vri_v_He-BhNzxr-neRpJdGeg4Iz/preview' style={{ top: 0, left: 0, position: "absolute", width: "100%", height: "100%" }} frameBorder="0" allowFullScreen />
                                    </div>
                                    <br />
                                    <Typography variant="h5" gutterBottom style={{ paddingLeft: "15px" }}>
                                        Pasos para crear una Solicitud de recursos:
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "30px" }}>
                                        1.      Seleccionar “Crear Nueva Solicitud” desde el menú lateral.<br />
                                        2.      Seleccionar Gerencia.<br />
                                        3.      Seleccione en qué centro logístico requiere el recurso.<br />
                                        4.      Seleccione el proyecto asociado.<br />
                                        5.      Selecciones el tipo de recurso.<br />
                                        6.      Seleccione la cantidad a solicitar.<br />
                                        7.      Complete el formulario según el tipo de recurso a solicitar.<br />
                                    </Typography>
                                </div>
                            }
                            {selected === 1 &&
                                <div>
                                    <div id="aprobacion" style={{ paddingBottom: "56.25%", position: "relative", height: 0, overflow: "hidden" }}>
                                        <iframe title="aprobacion" src='https://drive.google.com/file/d/1ak-1tlozKo6tcoehoXmJCApYSbkMLxzt/preview' style={{ top: 0, left: 0, position: "absolute", width: "100%", height: "100%" }} frameBorder="0" allowFullScreen />
                                    </div>
                                    <Typography variant="h5" gutterBottom style={{ paddingLeft: "15px" }}>
                                        Pasos para la Aprobación de una Solicitud de recursos:
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "30px" }}>

                                        1.      Seleccionar “Mis solicitudes” desde el menú lateral.<br />
                                        2.      Seleccionar "Mis pendientes" en la barra horizontal.<br />
                                        3.      Seleccionar una solicitud en estado "En espera de aprobación". <br />
                                        4.      Seleccionar el cuadro de "Detalle de la solicitud".<br />
                                        5.      Seleccionar una de las dos opciones disponibles para la solicitud: "Aprobar" o "Rechazar".<br />
                                        <br />
                                        *Nota: Es posible editar o eliminar ítems de la solictud antes de la aprobación.
                                    </Typography>
                                </div>
                            }
                            {selected === 2 &&
                                <div>
                                    <div id="revisionStock" style={{ paddingBottom: "56.25%", position: "relative", height: 0, overflow: "hidden" }}>
                                        <iframe title="revisionStock" src='https://drive.google.com/file/d/1ag1bvqlMJtAoOoVQ9KCFkHKToJemVK9Y/preview' style={{ top: 0, left: 0, position: "absolute", width: "100%", height: "100%" }} frameBorder="0" allowFullScreen />
                                    </div>
                                    <Typography variant="h5" gutterBottom style={{ paddingLeft: "15px" }}>
                                        Pasos para la Revisión de Stock:
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "30px" }}>

                                        1.      Seleccionar “Mis solicitudes” desde el menú lateral.<br />
                                        2.      Seleccionar "Mis pendientes" en la barra horizontal.<br />
                                        3.      Seleccionar una solicitud en estado "Revisión stock centro seleccionado". <br />
                                        4.      Seleccionar el cuadro de "Revisión de Stock".<br />
                                        5.      Seleccionar una de las opciones disponibles para cada item de la solicitud de recursos:<br />
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "45px" }}>

                                        a.      "Separar": En caso de requerir acciones distintas para el mismo item, se puede separar en más ítems.<br />
                                        b.      "Existe": Si hay stock del item seleccionado.<br />
                                        c.      "Comprar": Si no hay stock del item seleccionado y se comprará en el mismo centro logístico.<br />
                                        d.      "Solicitar": Si no hay stock del item seleccionado pero se solicitará la compra en el centro logístico de Santiago.<br />
                                    </Typography>
                                </div>

                            }
                            {selected === 3 &&
                                <div>
                                    <div id="cotizacion" style={{ paddingBottom: "56.25%", position: "relative", height: 0, overflow: "hidden" }}>
                                        <iframe title="cotizacion" src='https://drive.google.com/file/d/1b5dsZa1gQ_O0xwGxobtD-TGXzqE02110/preview' style={{ top: 0, left: 0, position: "absolute", width: "100%", height: "100%" }} frameBorder="0" allowFullScreen />
                                    </div>
                                    <Typography variant="h5" gutterBottom style={{ paddingLeft: "15px" }}>
                                        Pasos para crear una Cotización:
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "30px" }}>

                                        1.      Seleccionar “Mis solicitudes” desde el menú lateral.<br />
                                        2.      Seleccionar "Mis pendientes" en la barra horizontal.<br />
                                        3.      Seleccionar una solicitud en estado "Preparar cotización". <br />
                                        4.      Seleccionar el cuadro de "Adquisión y Cotización".<br />
                                        5.      Seleccionar "Crear nuevo grupo de cotización."<br />
                                        6.      Seleccionar el grupo de cotización creado.<br />
                                        7.      Seleccionar "Crear nueva Cotización"<br />
                                        8.      Ingresar los datos para la cotización: Empresa, Fecha Orden de Compra, Proveedor, Centro Solicitante, Condiciones de Pago, Nombre Contacto del Proveedor.<br />
                                        9.      Seleccionar el archivo digital con la cotización del proveedor.<br />
                                        10.     Ingresar los precios de los items cotizados.<br />
                                        <br />
                                        11.     Presionar "Enviar Cotización".<br/>
                                        12.     Si la cotización está lista "Solicitar aprobación de cotizaciones".<br/>
                                        *Nota: Es posible separar un ítem si así se require. Para excluir un item de la cotización solo se debe eliminar, y este se cargará al crear una nueva cotización.
                                    </Typography>
                                </div>

                            }
                            {selected === 4 &&
                                <div>
                                    {/* <div id="recepcion" style={{ paddingBottom: "56.25%", position: "relative", height: 0, overflow: "hidden" }}> */}
                                        <img style={{width: "100%", height:"auto"}} src={image_sda}/>
                                        <br/>
                                        {/* <iframe title="recepcion" src='https://drive.google.com/file/d/119iUpvYw0Y8LW-tEn3UPtb_iNzxgsevC/preview' style={{ top: 0, left: 0, position: "absolute", width: "100%", height: "100%" }} frameBorder="0" allowFullScreen /> */}
                                    {/* </div> */}
                                    <Typography variant="h5" gutterBottom style={{ paddingLeft: "15px" }}>
                                        Pasos para Recepción de ítems:
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "30px" }}>

                                        1.      Seleccionar “Mis solicitudes” desde el menú lateral.<br />
                                        2.      Seleccionar "Mis pendientes" en la barra horizontal.<br />
                                        3.      Seleccionar una solicitud en estado "Preparar cotización". <br />
                                        4.      Seleccionar el cuadro de "Adquisión y Cotización".<br />
                                        5.      Presionar botón de "Acción" de uno de los ítems de la solicitud de recursos y seleccionar "Recepción de Recursos".<br />
                                        6.      Presionar botón de "Acción" y seleccionar "Recibido".<br />
                                        6.      Ingresar la cantidad de ítems recepcionados, el tipo y el número del documento, y la fecha de recepción.<br />
                                        7.      Seleccionar el archivo digital del documento.<br/>
                                        8.      Presionar "Recibir ítems".<br/>
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "30px" }}>
                                        *Nota: Si no todos los items han sido recepcionados, estos pueden marcarse de forma parcial indicando un número menor a la cantidad solicitada.
                                    </Typography>
                                </div>

                            }
                            {selected === 5 &&
                                <div>
                                    {/* <div id="preparacion" style={{ paddingBottom: "56.25%", position: "relative", height: 0, overflow: "hidden" }}>
                                        <iframe title="preparacion" src='https://drive.google.com/file/d/119iUpvYw0Y8LW-tEn3UPtb_iNzxgsevC/preview' style={{ top: 0, left: 0, position: "absolute", width: "100%", height: "100%" }} frameBorder="0" allowFullScreen />
                                    </div> */}
                                    <img style={{width: "100%", height:"auto"}} src={image_sda}/>
                                        <br/>
                                    <Typography variant="h5" gutterBottom style={{ paddingLeft: "15px" }}>
                                        Pasos para Entrega de los ítem de una solicitud de recursos:
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "30px" }}>

                                        1.      Seleccionar “Mis solicitudes” desde el menú lateral.<br />
                                        2.      Seleccionar "Mis pendientes" en la barra horizontal.<br />
                                        3.      Seleccionar una solicitud en estado "Preparar recurso". <br />
                                        4.      Seleccionar el cuadro de "Entrega y Recepción".<br />
                                        5.      Presionar botón de "Acción" ítem de la solicitud de recursos y seleccionar una de las acciones diponibles.<br />
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "45px" }}>
                                        a.      "En traslado": Para indicar que el recurso se esta trasladando al centro logístico en que se solicitud.<br />
                                        b.      "Listo para Entrega": Para indicar que el item listo para su entrega.<br />
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "30px" }}>
                                        6.      Ingresar la cantidad de ítems listos para entrega o que se van a trasladar.<br />
                                        7.      Presionar Aceptar.<br/>
                                        *Nota: Si no todos los items están listos, o en traslado, estos pueden marcarse de forma parcial indicando un número menor a la cantidad solicitada.
                                    </Typography>
                                </div>

                            }
                            {selected === 6 &&
                                <div>
                                    {/* <div id="entrega" style={{ paddingBottom: "56.25%", position: "relative", height: 0, overflow: "hidden" }}>
                                        <iframe title="entrega" src='https://drive.google.com/file/d/119iUpvYw0Y8LW-tEn3UPtb_iNzxgsevC/preview' style={{ top: 0, left: 0, position: "absolute", width: "100%", height: "100%" }} frameBorder="0" allowFullScreen />
                                    </div> */}
                                    <img style={{width: "100%", height:"auto"}} src={image_sda}/>
                                        <br/>
                                    <Typography variant="h5" gutterBottom style={{ paddingLeft: "15px" }}>
                                        Pasos para Entrega de los ítem de una solicitud de recursos:
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "30px" }}>

                                        1.      Seleccionar “Mis solicitudes” desde el menú lateral.<br />
                                        2.      Seleccionar "Mis pendientes" en la barra horizontal.<br />
                                        3.      Seleccionar una solicitud en estado "Listo para entregar". <br />
                                        4.      Seleccionar el cuadro de "Entrega y Recepción".<br />
                                        5.      Presionar el botón de "Acción" de un ítem de la solicitud de recursos y seleccionar "Entregar".<br />
                                        6.      Ingresar la cantidad de ítems a entregar, el número del comprobante y la fecha de entrega.<br />
                                        7.      Seleccionar el archivo digital del Comprobante de entrega. <br/>
                                        8.      Presionar Aceptar.<br/>
                                    </Typography>
                                    <Typography gutterBottom style={{ paddingLeft: "30px" }}>
                                        *Nota: Si no todos los items han sido entregados, estos pueden marcarse de forma parcial indicando un número menor a la cantidad solicitada.
                                    </Typography>
                                </div>

                            }

                        </Grid>
                        <Grid item xs={3}>

                            <TableOfContents
                                title={"Contenido"}
                                selected={selected}
                                handleClick={this.handleClick}
                                items={[
                                    { text: "Crear una Solicitud de Recursos", link: "#crearSolicitud" },
                                    { text: "Aprobación de Solicitud de Recursos", link: "#aprobacion" },
                                    { text: "Revisión Stock", link: "#revisionStock" },
                                    { text: "Cotización de Recursos", link: "#cotizacion" },
                                    { text: "Recepción de Recursos", link: "#recepcion" },
                                    { text: "Preparación de Recursos", link: "#preparacion" },
                                    { text: "Entrega de Recursos", link: "#entrega" }

                                ]}
                            />

                        </Grid>
                    </Grid>
                    <br />
                </Paper>
            </Box>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Ayuda)