import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Paper, IconButton } from '@material-ui/core';
import Table from './Table';
import BotonMenuCotizaciones from './BotonMenuCotizaciones';
import DetalleSolicitud from '../containers/DetalleSolicitud';
import RevisionStock from '../containers/RevisionStock';
import AdquisicionCotizacion from '../containers/AdquisicionCotizacion';
import EntregaRecepcion from '../containers/EntregaRecepcion';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        margin: 'auto',
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    estado: {
        margin: 'auto',
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    button: {
        margin: theme.spacing(1)
    },
    input: {
        display: 'none'
    }
}));

const style = {
    etapa1: estado => ({
        color: estado === 1 || estado === 2 ? 'blue' : ''
    })
};

export default function ControlledExpansionPanels(props) {
    const {
        solicitudActual,
        items,
        estados,
        aprobador1,
        aprobador2,
        procesadores,
        discoDuro,
        memoriaRam,
        recargarDatos,
        proyectos,
        comentarios,
        // clases,
        // subClases,
        // handleEditarItemsModal,
        // gruposCotizacion,
        // cotizaciones,
        // handleCrearGrupoCotizacionModal,
        // handleSolicitarAprobacionModal,
        // handleCrearCotizacionModal,
        // handleIdGrupoCotizacion,
        // idGrupoCotizacion,
        // centros,
        proveedores,
        // revisarStock,
        // revisarCotizacion,
        // handleCotizacionModal,
        // centroLogistico,
        // marcasVehiculos,
        // tiposRecursos,
        // aprobadoresCotizacion,
        // rangosPrecios,
        // usuarioAutenticado
    } = props;

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box className={classes.root}>
            <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <ExpansionPanelSummary
                    style={
                        solicitudActual.items.some(x => x.estado === 1 || x.estado === 2)
                            ? { borderLeft: '10px solid #D3FFB8' }
                            : {}
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <Typography className={classes.heading}>Detalle de la solicitud</Typography>
                    <Typography className={classes.secondaryHeading}>
                        Solicitud N°: {solicitudActual ? solicitudActual.codigo : ''}
                    </Typography>
                    {/* {console.log(proyectos)} */}
                    {solicitudActual.contrato && (
                        <div style={{ display: 'contents' }}>
                            <Typography className={classes.secondaryHeading}>
                                Proyecto:{' '}
                                <Tooltip enterTouchDelay={0} title={solicitudActual.contrato.nombre}>
                                    <span>{solicitudActual.contrato.codigo}</span>
                                </Tooltip>
                            </Typography>
                            <Typography className={classes.secondaryHeading}>
                                Sociedad: {' ' + solicitudActual.contrato?.sociedad_ref?.nombre}
                            </Typography>
                        </div>
                    )}
                    <Typography className={classes.estado}>
                        Estado:{' '}
                        {solicitudActual.items.filter(y => y.estado === 8 || y.estado === 22).length === 0 &&
                        solicitudActual.estado === 8
                            ? estados.find(y => y.id === 12).nombre
                            : solicitudActual.estadoRef.nombre}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <DetalleSolicitud
                        idSolicitud={solicitudActual.oldId}
                        solicitudActual={solicitudActual}
                        aprobador1={aprobador1}
                        aprobador2={aprobador2}
                        procesadores={procesadores}
                        discoDuro={discoDuro}
                        memoriaRam={memoriaRam}
                        recargarDatos={recargarDatos}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <ExpansionPanelSummary
                    style={
                        solicitudActual.items.some(x => x.estado === 3 || x.estado === 7)
                            ? { borderLeft: '10px solid #D3FFB8' }
                            : {}
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header">
                    <Typography className={classes.heading}>Revisión de Stock</Typography>
                    <Typography className={classes.secondaryHeading}></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <RevisionStock
                        idSolicitud={solicitudActual.oldId}
                        solicitudActual={solicitudActual}
                        recargarDatos={recargarDatos}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <ExpansionPanelSummary
                    style={
                        solicitudActual.items.some(x => x.estado === 8 || x.estado === 9 || x.estado === 12 || x.estado === 22)
                            ? { borderLeft: '10px solid #D3FFB8' }
                            : {}
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header">
                    <Typography className={classes.heading}>Adquisición y Cotización</Typography>
                    {false && <Typography className={classes.secondaryHeading}>Estado:</Typography>}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <AdquisicionCotizacion
                        idSolicitud={solicitudActual.oldId}
                        solicitudActual={solicitudActual}
                        aprobador2={aprobador2}
                        proveedores={proveedores}
                        recargarDatos={recargarDatos}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <ExpansionPanelSummary
                    style={
                        solicitudActual.items.some(x => x.estado === 6 || x.estado === 17 || x.estado === 18 || x.estado === 19)
                            ? { borderLeft: '10px solid #D3FFB8' }
                            : {}
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header">
                    <Typography className={classes.heading}>Entrega y Recepción</Typography>
                    {false && <Typography className={classes.secondaryHeading}>Entrega:</Typography>}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <EntregaRecepcion
                        idSolicitud={solicitudActual.oldId}
                        solicitudActual={solicitudActual}
                        recargarDatos={recargarDatos}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Box>
    );
}
