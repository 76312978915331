import React from 'react'
import { Field, reduxForm } from 'redux-form'
import TextField from './TextField';
import { Grid, Button, Container } from '@material-ui/core';
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')

const validate = (values = {}) => {
  const errors = {}
  const requiredFields = [
    'nombre',
    'direccion',
    'fax',
    'giro',
    'rut',
    'telefono',
    'descripcion',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  if ( !checkRut(values.rut) ) {
    errors.rut = "Rut Inválido"
  }

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }
  return errors
}

const checkRut = (rut = "") =>  {
    
  // Despejar Puntos
  var valor = rut.replace(/\./g,'');
  // Despejar Guión
  valor = valor.replace(/\-/g,'');
  // Aislar Cuerpo y Dígito Verificador
  var cuerpo = valor.slice(0,-1);
  var dv = valor.slice(-1).toUpperCase();
  // Formatear RUN
  rut = cuerpo + '-'+ dv
  
  if(cuerpo.length < 7) { 
      return false;  
  }

  var suma = 0;
  var multiplo = 2;
  
  // Para cada dígito del Cuerpo
  for( var i=1;i<=cuerpo.length;i++) {    
      // Obtener su Producto con el Múltiplo Correspondiente
      var index = multiplo * valor.charAt(cuerpo.length - i);
      // Sumar al Contador General
      suma = suma + index;
      // Consolidar Múltiplo dentro del rango [2,7]
      if(multiplo < 7) { 
          multiplo = multiplo + 1; 
      } else { 
          multiplo = 2; 
      }
  }
  
  // Calcular Dígito Verificador en base al Módulo 11
  var dvEsperado = 11 - (suma % 11);
  
  // Casos Especiales (0 y K)
  dv = (dv == 'K') ? 10 : dv;
  dv = (dv == 0) ? 11 : dv;
  
  // Validar que el Cuerpo coincide con su Dígito Verificador
  if(dvEsperado != dv) { 
      return false; 
  }

  return true
}

const formatoRut = rut => {
  var textoRut = rut.replace(/[\W\s\._\-a-jA-Jl-zL-Z]+/g, '');
  var fullRut = "";
  
  if (textoRut.length <= 1) {
      if (textoRut == "k") {
          fullRut = "K";
      } else {
          fullRut = textoRut;
      }
  } else {
      var cont = 0;
      for (var i = (textoRut.length - 1); i >= 0; i--) {
          if (i == (textoRut.length - 1)) {
              if (textoRut.charAt(i) == "k") {
                  fullRut = "-K";
              } else {
                  fullRut = "-" + textoRut.charAt(i);
              }
          } else {
          if (cont == 3) {
              fullRut = "." + fullRut;
              cont = 0;
          }
          fullRut = textoRut.charAt(i) + fullRut;
          cont++;
          }
      }
  }
  return fullRut
}


let FormEmpresas = props => {
    const { handleSubmit, pristine, editar, reset, submitting } = props
      return(
            <Container>
                <form noValidate onSubmit={handleSubmit} >

                    <Grid container justify="center" alignItems="center"  >
                        <Grid item xs={12} lg={6}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                type="text"
                                name="nombre"
                                label="Nombre"
                                component={TextField}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                type="text"
                                name="rut"
                                label="RUT"
                                component={TextField}
                                normalize={formatoRut}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                type="text"
                                name="direccion"
                                label="Dirección"
                                component={TextField}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                type="text"
                                name="fax"
                                label="Fax"
                                component={TextField}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                type="text"
                                name="telefono"
                                label="Telefono"
                                component={TextField}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                type="text"
                                name="giro"
                                label="Giro"
                                component={TextField}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Field
                                required
                                style={{ width: '80%', padding: '10px '}}
                                type="text"
                                name="descripcion"
                                label="Descripción"
                                component={TextField}
                            />
                        </Grid>                                                           
                    </Grid>

                    
                    <Grid container style={{ marginTop: '20px'}} justify="center" alignItems="center">
                        <Button type='submit' variant='outlined' color="primary">
                            {editar ? "Editar" : "Agregar"}
                        </Button>
                    </Grid>
                </form>
            </Container>
        )
    }


FormEmpresas = reduxForm({
  form: 'empresas',
})(FormEmpresas);

export default connect( state => ({
    initialValues: {
      nombre: state.Empresas.data.filter(x => x.selected)[0] ? state.Empresas.data.filter(x => x.selected)[0].nombre : "" ,
      descripcion: state.Empresas.data.filter(x => x.selected)[0] ? state.Empresas.data.filter(x => x.selected)[0].descripcion : "",
    },
    enableReinitialize : true,
    validate,
}))(FormEmpresas)