import React, { Component } from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

export default class MyDatePicker extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        // this.state = {
        //     selectedDate: props.input.value ? moment(props.input.value, 'DD/MM/YYYY') : moment()
        // };
    }

    onChange(e) {
        const { input, change } = this.props;
        // this.setState({ ...this.state, selectedDate: e });
        if (change) {
            if (e) {
                change(input.name, e.format('MMMM YYYY'));
            } else {
                change(input.name, null);
            }
        }
    }

    render() {
        const {
            input,
            label,
            input: { value },
            ...custom
        } = this.props;
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    {...input}
                    clearable
                    autoOk
                    clearLabel="Borrar"
                    cancelLabel="Cancelar"
                    views={custom.views ? custom.views : ['year', 'month', 'date']}
                    openTo={custom.openTo ? custom.openTo : 'year'}
                    style={custom.style ? custom.style : {}}
                    label={label}
                    size={custom.size ? custom.size : 'medium'}
                    value={value ? moment(value, 'MMMM YYYY') : null}
                    onChange={this.onChange}
                />
            </MuiPickersUtilsProvider>
        );
    }
}
