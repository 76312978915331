import React, { Component } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { TablePagination } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const useStylesTable = theme => ({
    pagination: {
        border: 'none'
    }
});
class TablaPrueba extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagProps: null,
            focusedElement: null,
            openedPanels: {}
        };
        this.tableRef = props.tableRef ? props.tableRef : React.createRef();
        // this.openedPanels = React.createRef();
        this.oldFunction = React.createRef();
        // this.openedPanels.current = {};
    }

    componentDidUpdate() {
        const { searchID } = this.props;
        const { focusedElement, openedPanels } = this.state;

        let searchBar = document.querySelector(`#${searchID} input`);
        // console.log('elemento focuseado: ', focusedElement);
        if (focusedElement && (focusedElement.classList.contains('MuiInput-inputTypeSearch') || focusedElement.classList.contains('MuiAutocomplete-input'))) {
            // console.log('focuseando el focused anterior');
            // focusedElement.focus();
        } else {
            if (focusedElement && focusedElement.classList.contains('MuiInputBase-input') && searchBar) {
                // console.log('focuseando el search general');
                searchBar.focus();
            } 
        }

        if (!this.oldFunction.current) {
            // console.log("seteando oldFunction");
            this.oldFunction.current = this.tableRef.current?.onToggleDetailPanel;
        }
        if (this.oldFunction.current === this.tableRef.current?.onToggleDetailPanel) {
            // console.log("seteando onToggleDetailPanel");
            this.tableRef.current.onToggleDetailPanel = (path, render) => {
                // console.log("aprete el ojo ", openedPanels);
                // console.log("datas ", this.tableRef.current.dataManager);
                this.tableRef.current.state.data = this.tableRef.current.state.data.map(data => {
                    if (this.tableRef.current.dataManager.sortedData[path[0]].id !== data.id)
                        data.tableData.showDetailPanel = null;
                    return data;
                });
                if (this.tableRef.current.dataManager.sortedData[path[0]].tableData.showDetailPanel) {
                    // console.log("quitando panel, ninguno abierto");
                    // delete openedPanels[path[0]];
                    this.setState({ openedPanels: {}, focusedElement: document.activeElement });
                    // this.forceUpdate();
                } else {
                    // console.log("agregando panel, abierto:", this.tableRef.current.dataManager.sortedData[path[0]].id);
                    // console.log(path, render, this.tableRef.current.dataManager.sortedData[path[0]]);
                    // console.log(this.tableRef.current);
                    this.setState({openedPanels: {
                        // ...this.openedPanels.current,
                        // [path[0]]: true,
                        [this.tableRef.current.dataManager.sortedData[path[0]].id]: true,
                    }, focusedElement: document.activeElement});
                }
                // console.log("paneles abiertos: ", this.state.openedPanels);
                this.oldFunction.current(path, render);
            }
        }
        // console.log("paneles abiertos: ", this.openedPanels.current);
    }

    render() {
        const {
            columnas,
            data,
            agregar,
            search,
            detalle,
            detalleF,
            toolbar,
            paging,
            handleRowClick,
            columnVis,
            filtering,
            grouping,
            sorting,
            classes,
            searchID,
            // searchText,
            // handleSearchChange,
            selection,
            handleSelectionChange,
            handleOrderChange,
            detailMultiple,
            remoteData,
            reloadAction,
            actions
        } = this.props;

        const refreshTable = () => {
            this.tableRef.current && this.tableRef.current.onQueryChange();
        }

        return (
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    tableRef={this.tableRef}
                    columns={columnas}
                    actions={actions 
                        ? actions
                        : reloadAction 
                        ? [{
                            icon: 'refresh',
                            tooltip: 'Actualizar Tabla',
                            isFreeAction: true,
                            onClick: refreshTable
                        }]
                        : []}
                    data={remoteData
                        ? query => new Promise((resolve, reject) => {
                            data(query).then(resultado => {
                                // console.log("resultado: ", resultado)
                                resolve({
                                    data: resultado.data?.map((d, i) => ({
                                        ...d,
                                        tableData: {
                                        ...d.tableData,
                                        showDetailPanel: this.state.openedPanels[d.id]
                                            ? detalleF
                                            ? rowData => detalleF(rowData, refreshTable)
                                            : detalle[0]
                                            ? detalle[0].render
                                            : null
                                            : null
                                        }
                                    })),
                                    page: resultado.page,
                                    totalCount: resultado.totalCount
                                });
                            });
                        })
                        : data?.map((d, i) => ({
                            ...d,
                            tableData: {
                            ...d.tableData,
                            showDetailPanel: this.state.openedPanels[d.id]
                                ? detalleF
                                ? detalleF
                                : detalle[0]
                                ? detalle[0].render
                                : null
                                : null
                            }
                        })) || []
                    }
                    title=""
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'No hay datos para mostrar',
                            filterRow: {
                                filterTooltip: 'Filtro'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Buscar',
                            searchPlaceholder: 'Buscar',
                            showColumnsTitle: 'Columnas Visibles',
                            showColumnsAriaLabel: 'Columnas Visibles',
                            addRemoveColumns: 'Agregar o quitar columnas'
                        },
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas',
                            firstTooltip: 'Primera Página',
                            previousTooltip: 'Página Anterior',
                            nextTooltip: 'Página Siguiente',
                            lastTooltip: 'Última Página'
                        },
                        grouping: {
                            placeholder: 'Arrastre los encabezados aquí para agrupar'
                        }
                    }}
                    options={{
                        debounceInterval: 800,
                        draggable: false,
                        pageSizeOptions: [10, 50, 100],
                        pageSize: this.state.rowsPerPage || 50,
                        emptyRowsWhenPaging: false,
                        search: search === false ? false : true,
                        // searchText: searchText ? searchText : '',
                        detailPanelType: detailMultiple === true ? 'multiple' : 'single',
                        toolbar: toolbar === false ? false : true,
                        paging: paging === false ? false : true,
                        showTitle: false,
                        columnsButton: columnVis === true ? true : false,
                        filtering: filtering === true ? true : false,
                        grouping: grouping === true ? true : false,
                        sorting: sorting === false ? false : true,
                        selection: selection === true ? true : false,
                        selectionProps: rowData =>
                            !rowData.seleccionable
                                ? {
                                      disabled: true,
                                      checked: false,
                                      color: 'primary'
                                  }
                                : {
                                      color: 'primary'
                                  },
                        filterCellStyle: {
                            textAlign: 'center',
                            paddingLeft: '5px',
                            paddingRight: '5px'
                        },
                        headerStyle: {
                            backgroundColor: '#731f1f',
                            color: 'white',
                            fontWeight: 'bold'
                        },
                        rowStyle: rowData => {
                            let estilo = { backgroundColor: 'white' };
                            if (Object.keys(this.state.openedPanels).length > 0 && !this.state.openedPanels[rowData.id])
                                estilo.filter = 'brightness(50%)';
                            return estilo;
                        }
                    }}
                    detailPanel={
                        detalleF
                            ? [
                                  {
                                      icon: 'remove_red_eye',
                                      openIcon: 'remove_red_eye',
                                      tooltip: 'Ver Detalle',
                                      render: rowData => detalleF(rowData, refreshTable)
                                  }
                              ]
                            : detalle
                    }
                    // onSearchChange={handleSearchChange ? (text) => handleSearchChange(text) : null}
                    onRowClick={handleRowClick ? (event, rowData) => handleRowClick(rowData) : null}
                    onSelectionChange={handleSelectionChange ? rows => handleSelectionChange(rows) : null}
                    onChangeRowsPerPage={data => { 
                        this.setState({rowsPerPage: data});
                    }}
                    onOrderChange={handleOrderChange ? (columnId, direction) => handleOrderChange(columnId, direction) : null}
                    components={{
                        Toolbar: props => {
                            if (searchID) {
                                return (
                                    <div
                                        id={searchID}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <MTableToolbar {...props} />
                                        {this.state.pagProps && (
                                            <div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <TablePagination
                                                                className={classes.pagination}
                                                                {...this.state.pagProps}
                                                            />
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                );
                            } else {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <MTableToolbar {...props} />
                                        {this.state.pagProps && (
                                            <div>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <TablePagination
                                                                className={classes.pagination}
                                                                {...this.state.pagProps}
                                                            />
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                        },
                        Pagination: props => {
                            // console.log("pagination: ", props);
                            // pagProps = {...props};
                            if (
                                !this.state.pagProps ||
                                this.state.pagProps.count != props.count ||
                                this.state.pagProps.page != props.page ||
                                this.state.pagProps.rowsPerPage != props.rowsPerPage
                            ) {
                                // console.log("cambiando pagination");
                                this.setState({
                                    ...this.state,
                                    pagProps: { ...props },
                                    focusedElement: document.activeElement
                                });
                            }
                            return <TablePagination {...props} />;
                        }
                    }}
                />
            </div>
        );
    }
}

export default withStyles(useStylesTable)(TablaPrueba);
