import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Container, Typography, Box, Tooltip, InputAdornment } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import BotonMenuItems from '../components/BotonMenuItems';
import Table from '../components/Table';
import Modal from '../components/Modal';
import Spinner from '../components/Spinner';
import FormSepararItems from '../components/FormSepararItems';
import TextField from '../components/TextField';
import { currency } from '../services/utils';
import * as solicitudesDuck from '../ducks/Solicitudes';
import * as idsolicitudDuck from '../ducks/Idsolicitud';
import * as iditemDuck from '../ducks/Iditem';
import * as itemsDuck from '../ducks/Items';
import * as vehiculoDuck from '../ducks/Vehiculos';
import * as mensajesDuck from '../ducks/Mensajes';
import * as proyectosGeneralDuck from '../ducks/ProyectosGeneral';
import * as gerenciasGeneralDuck from '../ducks/GerenciasGeneral';
import * as comprobanteRecepcionDuck from '../ducks/ComprobanteRecepcion';
import * as trasladoItemRecursoDuck from '../ducks/TrasladoItemRecurso';
import * as entregaItemRecurso from '../ducks/EntregaItemRecurso';
import * as itemsCotizacionDuck from '../ducks/ItemsCotizacion';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

class RevisionStock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openModalRechazo: false,
            openModalSeparar: false,
            openModalEntregar: false,
            openCrearCotizacion: false,
            openVerCotizacion: false,
            openItemTraslado: false,
            openListoEntrega: false,
            openConStock: false,
            confirmacion: false,
            accion: null,
            loading: false,
            mostrarFormulario: true,
            mostrarBusqueda: true,
            mostrarDetalle: false,
            loadingDetalle: true,
            comprobante: '',
            cantidadItemsEntregados: 0,
            textoItemsEntregados: '',
            maxItemsEntrega: 0,
            cantidadItemsRecibidos: 0,
            textoItemsRecibidos: '',
            maxItemsRecibidos: 0,
            textoItemsEnTraslado: '',
            maxItemsEnTraslado: 0,
            montoAhorrado: '0'
        };
    }

    handleClose = () => {
        this.setState({
            ...this.state,
            openConStock: false,
            openItemTraslado: false,
            openVerCotizacion: false,
            accion: false,
            confirmacion: false,
            open: false,
            openModalRechazo: false,
            openModalSeparar: false,
            openModalEntregar: false,
            openListoEntrega: false
        });
    };
    //Verificar estado de la solicitud
    verificarEstadoSolicitud = async itemsEditados => {
        const { idSolicitud, editarSolicitudes } = this.props;
        if (itemsEditados.some(x => x.estado === 3)) {
            await editarSolicitudes(idSolicitud, { estado: 3 });
        } else if (itemsEditados.some(x => x.estado === 7)) {
            await editarSolicitudes(idSolicitud, { estado: 7 });
        } else if (itemsEditados.some(x => x.estado === 8 || x.estado === 22)) {
            await editarSolicitudes(idSolicitud, { estado: 8 });
        } else if (itemsEditados.some(x => x.estado === 9)) {
            await editarSolicitudes(idSolicitud, { estado: 9 });
        } else if (itemsEditados.some(x => x.estado === 12)) {
            await editarSolicitudes(idSolicitud, { estado: 12 });
        } else if (itemsEditados.some(x => x.estado === 6)) {
            await editarSolicitudes(idSolicitud, { estado: 6 });
        } else if (itemsEditados.some(x => x.estado === 17)) {
            await editarSolicitudes(idSolicitud, { estado: 17 });
        } else if (itemsEditados.some(x => x.estado === 18)) {
            await editarSolicitudes(idSolicitud, { estado: 18 });
        } else if (itemsEditados.some(x => x.estado === 20)) {
            await editarSolicitudes(idSolicitud, { estado: 20 });
        }
    };

    handleEditarItemsModal = (id, accion) => async e => {
        const {
            assignMensaje,
            fetchItems,
            itemsCotizacionActual,
            consultarItemCotizacion,
            assignIditem,
            solicitudActual,
            idSolicitud,
            consultarItemCompleto
        } = this.props;
        let cantidadAnterior = solicitudActual.items.find(x => x.id === id).cantidad;
        switch (accion) {
            case 6:
                let itemActual = solicitudActual.items.find(x => x.id === id);
                if ((itemActual.estado === 3 || itemActual.estado === 7) && itemActual.cantidad === cantidadAnterior) {
                    // assignMensaje('¿Desea aplicar acción EXISTE?');
                    this.setState({
                        // ...this.state,
                        montoAhorrado: '0',
                        openConStock: true,
                        confirmacion: true,
                        // open: true,
                        accion: this.handleEditarItems(id, accion)
                    });
                } else if (itemActual.estado === 12 && itemActual.cantidad === cantidadAnterior) {
                    await assignIditem(id);
                    let itemCotizacion = itemsCotizacionActual.data.filter(x => x.item_recurso_id === id)[0];
                    let itemActualCotizacion = await consultarItemCotizacion(itemCotizacion.id);
                    var totalRecibidos = 0;
                    if (itemActualCotizacion.recepciones) {
                        for (var e = 0; e < itemActualCotizacion.recepciones.length; e++) {
                            totalRecibidos += itemActualCotizacion.recepciones[e].cantidad;
                        }
                    }
                    let recibidos = totalRecibidos + ' de ' + itemActualCotizacion.cantidad;
                    let maxRecibidos = itemActualCotizacion.cantidad - totalRecibidos;
                    this.setState({
                        ...this.state,
                        confirmacion: false,
                        accion: false,
                        cantidadItemsRecibidos: totalRecibidos,
                        totalRecibir: itemActual.cantidad,
                        textoItemsRecibidos: recibidos,
                        maxItemsRecibidos: maxRecibidos
                    });
                } else {
                    assignMensaje('Lista de Items actualizada');
                    this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
                }

                break;
            case 7:
                assignMensaje('¿Desea aplicar acción SOLICITAR a Santiago?');
                this.setState({
                    ...this.state,
                    confirmacion: true,
                    open: true,
                    accion: this.handleEditarItems(id, accion)
                });
                break;
            case 8:
                assignMensaje('¿Desea aplicar acción COMPRAR?');
                this.setState({
                    ...this.state,
                    confirmacion: true,
                    open: true,
                    accion: this.handleEditarItems(id, accion)
                });
                break;
            case 18: {
                // assignMensaje('¿Desea aplicar acción LISTO PARA ENTREGA?')
                let itemActual = await consultarItemCompleto(id);
                await assignIditem(id);
                let listos = itemActual.cantidad_listos_entrega + ' de ' + itemActual.cantidad;
                let maxListos = itemActual.cantidad - itemActual.cantidad_listos_entrega;
                this.setState({
                    ...this.state,
                    confirmacion: true,
                    openListoEntrega: true,
                    accion: accion,
                    textoItemsListoEntrega: listos,
                    maxItemsListoEntrega: maxListos
                });
                break;
            }
            case 17: {
                await assignIditem(id);
                let itemActual = await consultarItemCompleto(id);
                let max = itemActual.cantidad;
                let enTraslado = 0;
                if (itemActual.traslados) {
                    for (let i = 0; i < itemActual.traslados.length; i++) {
                        enTraslado += itemActual.traslados[i].cantidad;
                    }
                }
                if (itemActual.cotizaciones) {
                    max = 0;
                    for (let i = 0; i < itemActual.cotizaciones.length; i++) {
                        let recepciones = itemActual.cotizaciones[i].recepciones;
                        if (recepciones) {
                            for (let j = 0; j < recepciones.lenght; j++) {
                                max = recepciones[j].cantidad;
                            }
                        }
                    }
                }
                let textoEnTraslado = enTraslado + ' de ' + max;
                this.setState({
                    ...this.state,
                    confirmacion: false,
                    openItemTraslado: true,
                    accion: false,
                    textoItemsEnTraslado: textoEnTraslado,
                    maxItemsEnTraslado: max
                });
                break;
            }
            case 20: {
                // assignMensaje('¿Desea aplicar acción ENTREGADO?')
                // this.setState({...this.state, confirmacion: true, open: true, accion: this.handleItemEntregado(id) })
                assignMensaje('Cargando');
                let itemActual = await consultarItemCompleto(id);
                await assignIditem(id);
                var totalEntregados = 0;
                if (itemActual.entregas) {
                    for (var e = 0; e < itemActual.entregas.length; e++) {
                        totalEntregados += itemActual.entregas[e].cantidad;
                    }
                }
                let entregados = totalEntregados + ' de ' + itemActual.cantidad;
                let maxEntrega = itemActual.cantidad - totalEntregados;
                this.setState({
                    ...this.state,
                    confirmacion: false,
                    openModalEntregar: true,
                    accion: false,
                    cantidadItemsEntregados: totalEntregados,
                    totalEntregar: itemActual.cantidad,
                    textoItemsEntregados: entregados,
                    maxItemsEntrega: maxEntrega
                });
                break;
            }
            case 'separar': {
                let itemActual = solicitudActual.items.find(x => x.id === id);
                if (itemActual.cantidad === cantidadAnterior) {
                    await assignIditem(id);
                    this.setState({ ...this.state, confirmacion: false, openModalSeparar: true, accion: false });
                } else {
                    await fetchItems(idSolicitud);
                    assignMensaje('Lista de Items actualizada');
                    this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
                }
                break;
            }
            default:
                break;
        }
    };

    handleEditarItems = (id, accion) => async e => {
        const { editarItems, verificarStockItems, assignMensaje, solicitudActual, usuarioAutenticado, recargarDatos } = this.props;
        let itemOrig = solicitudActual.items.filter(x => x.id === id)[0];
        let itemsEditados;
        let item = {
            ...itemOrig,
            estado: accion,
            fecha_requerida: moment(itemOrig.fecha_requerida).toISOString(),
            ubicacion_logistica: accion === 7 ? 2 : itemOrig.ubicacion_logistica
        };
        if (accion === 6 && (itemOrig.estado === 3 || itemOrig.estado === 7))
            item.monto_ahorro = parseInt(this.state.montoAhorrado.replace(/\./g, ''));
        console.log('data item editado', item);
        const itemEditado = await editarItems(id, item);
        let dataStock = {
            ...item,
            usuarioAccion: usuarioAutenticado.data.id
        };
        switch(accion) {
            case 7:
            case 8:
                await verificarStockItems(id, dataStock);
                break;
        }
        if (itemEditado) {
            itemsEditados = solicitudActual.items.map(x => {
                if (x.id === id) {
                    return itemEditado;
                } else {
                    return x;
                }
            });
            await this.verificarEstadoSolicitud(itemsEditados);
            assignMensaje('Item Editado con éxito');
            this.setState({ ...this.state, confirmacion: false, open: true, accion: false, openConStock:false });
            if (recargarDatos) recargarDatos(false);
        }
    };

    handleSepararItems = async values => {
        const { solicitudActual, idItem, addItems, editarItems, assignMensaje, recargarDatos } = this.props;
        let item = solicitudActual.items.filter(x => x.id === idItem)[0];
        if (item.cantidad > 1 && values.cantidad > 0) {
            if (values.cantidad < item.cantidad) {
                item = {
                    ...item,
                    fecha_requerida: moment(item.fecha_requerida).toISOString(),
                    cantidad: item.cantidad - values.cantidad
                };
                let newItem = {
                    ...item,
                    fecha_requerida: moment(item.fecha_requerida).toISOString(),
                    cantidad: values.cantidad
                };
                const itemEditado = await editarItems(idItem, item);
                if (itemEditado) {
                    await addItems(newItem);
                    assignMensaje('Items separados con exito');
                    this.setState({
                        ...this.state,
                        openModalSeparar: false,
                        confirmacion: false,
                        open: true,
                        accion: false
                    });
                    if (recargarDatos) recargarDatos(false);
                }
            } else {
                assignMensaje('La cantidad a separar no puede ser mayor que la cantidad de items');
                this.setState({
                    ...this.state,
                    openModalSeparar: false,
                    confirmacion: false,
                    open: true,
                    accion: false
                });
            }
        } else {
            assignMensaje('Para separar el item la cantidad debe ser mayor que 1');
            this.setState({ ...this.state, openModalSeparar: false, confirmacion: false, open: true, accion: false });
        }
    };

    renderMonitor = rowData => {
        if (rowData.estado_id) {
            let listadoNombres = '';
            if (rowData.responsables) {
                rowData.responsables.forEach(x => {
                    if (listadoNombres.length > 0) listadoNombres += '\n';
                    listadoNombres += x.nombres + ' ' + x.apellidos;
                });
            }
            if (rowData.estado_id == 22) listadoNombres += 'Ver Cotizaciones';
            if (listadoNombres.length == 0) {
                return '';
            } else {
                return (
                    <Tooltip
                        title={
                            <div style={{ whiteSpace: 'pre', textAlign: 'left' }}>
                                {listadoNombres}
                            </div>
                        }>
                        <PersonIcon />
                    </Tooltip>
                );
            }
        } else {
            return '';
        }
    }

    render() {
        const {
            revisarStockVehiculos,
            revisarStockTopografia,
            revisarStockComputacionales,
            revisarStockMiscelaneos,
            solicitudActual,
            mensaje,
        } = this.props;

        return (
            <Grid item xs={12}>
                <Box>
                    <Typography variant="h5" gutterBottom>
                        Solicitud de Recurso N° {solicitudActual ? solicitudActual.codigo : ''}
                    </Typography>
                </Box>
                <Box>
                    <Table
                        search={false}
                        toolbar={false}
                        paging={false}
                        sorting={false}
                        columnas={[
                            {
                                title: 'Cantidad',
                                field: 'cantidad',
                                cellStyle: {
                                    width: '80px',
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                }
                            },
                            {
                                title: 'Estado',
                                field: 'estado',
                                cellStyle: {
                                    width: '100px',
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                }
                            },
                            {
                                title: '¿Quién Gestiona?',
                                field: 'monitor',
                                sorting: false,
                                cellStyle: {
                                    width: '50px',
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                render: rowData => this.renderMonitor(rowData)
                            },
                            {
                                title: 'Centro',
                                field: 'centro',
                                cellStyle: {
                                    width: '100px',
                                    padding: '0px',
                                    textAlign: 'center'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                }
                            },
                            {
                                title: 'Detalle',
                                field: 'detalle',
                                cellStyle: {
                                    width: '300px',
                                    padding: '0px',
                                    textAlign: 'center'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                }
                            },
                            {
                                title: 'Descripción',
                                field: 'descripcion',
                                cellStyle: {
                                    padding: '0px',
                                    textAlign: 'center'
                                },
                                headerStyle: {
                                    padding: '0px',
                                    textAlign: 'center'
                                }
                            },
                            {
                                title: 'Fecha Requerida',
                                field: 'fecha_requerida',
                                cellStyle: {
                                    width: '130px',
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                }
                            },
                            {
                                title: 'Acción',
                                field: 'accion',
                                sorting: false,
                                cellStyle: {
                                    padding: '0px',
                                    textAlign: 'center'
                                },
                                headerStyle: {
                                    padding: '0px',
                                    textAlign: 'center'
                                }
                            }
                        ]}
                        data={solicitudActual.items.map((x, id) => ({
                            ...x,
                            estado_id: x.estado,
                            responsables: x.responsables,
                            estado: x.estadoRef.nombre,
                            centro: x.ubicacionLogistica.nombre,
                            detalle: x.detalle,
                            fecha_requerida: moment(x.fecha_requerida).format('DD-MM-YYYY'),
                            accion:
                                ((x.tipo_recurso === 1 && revisarStockVehiculos) ||
                                    (x.tipo_recurso === 2 && revisarStockTopografia) ||
                                    (x.tipo_recurso === 3 && revisarStockComputacionales) ||
                                    (x.tipo_recurso === 4 && revisarStockMiscelaneos)) &&
                                (x.estado === 3 || x.estado === 7) && x.deMisCentros ? (
                                    <BotonMenuItems
                                        ubicacion={x.ubicacion_logistica}
                                        id={x.id}
                                        estado={x.estado}
                                        handleEditarItemsModal={this.handleEditarItemsModal}
                                    />
                                ) : (
                                    x.estadoRef.nombre
                                )
                        }))}
                    />
                </Box>

                <Modal
                    open={this.state.open}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}>
                    {mensaje.data}
                </Modal>

                <Modal
                    open={this.state.openModalSeparar}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}>
                    <FormSepararItems onSubmit={this.handleSepararItems} />
                </Modal>
                <Modal
                    open={this.state.openConStock}
                    handleClose={this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    showTitle={false}
                    maxWidth='xs'
                    fullWidth={true}
                    botonSi='Guardar'
                    botonNo='Cancelar'>
                    <Grid container justify="center" alignItems="center">
                        <Typography style={{ fontSize: 14 }}>
                            Se moverá el recurso al estado "Preparar recurso" y se guardará con el siguiente monto ahorrado:
                        </Typography>
                        <TextField
                            meta={{}}
                            input={{ value: this.state.montoAhorrado }}
                            onChange={event => {
                                let newValue = currency(event.target.value);
                                this.setState({ montoAhorrado: newValue });
                            }}
                            name="montoAhorrado"
                            style={{ width: 200, marginTop: 10 }}
                            label="Monto Ahorrado"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                        />
                    </Grid>
                </Modal>
                
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        usuarioAutenticado: state.Usuario,
        idItem: state.Iditem.data,
        mensaje: state.Mensajes,
        revisarStockTopografia: state.PermisosUsuario.data.some(x => x.nombre === 'revisarStockTopografia'),
        revisarStockVehiculos: state.PermisosUsuario.data.some(x => x.nombre === 'revisarStockVehiculos'),
        revisarStockComputacionales: state.PermisosUsuario.data.some(x => x.nombre === 'revisarStockComputacionales'),
        revisarStockMiscelaneos: state.PermisosUsuario.data.some(x => x.nombre === 'revisarStockMiscelaneos'),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...solicitudesDuck,
            ...idsolicitudDuck,
            ...iditemDuck,
            ...itemsDuck,
            ...vehiculoDuck,
            ...mensajesDuck,
            ...proyectosGeneralDuck,
            ...gerenciasGeneralDuck,
            ...comprobanteRecepcionDuck,
            ...entregaItemRecurso,
            ...trasladoItemRecursoDuck,
            ...itemsCotizacionDuck
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RevisionStock);
