import { makeTypes, mac, createReducer, reduceReducers } from 'ducks-maker'
import { assignHOR } from './hors.js'

const t = makeTypes('idsolicitud')
const ASSIGN_IDSOLICITUD = t('assign').single()

export const assignIdsolicitud = mac(ASSIGN_IDSOLICITUD, 'payload')

const initialState = {
    data: "",
}

const assignReducer = createReducer(initialState, assignHOR({ ASSIGN: ASSIGN_IDSOLICITUD }))

export default reduceReducers(assignReducer)