import { makeTypes, asyncMac, createReducer, reduceReducers } from 'ducks-maker'
import { createFetch, editFetch } from './hors.js'
import { leer, modificar } from '../services/api'

const t = makeTypes('proyectosGeneral')
const FETCH = t('fetch').async()
const EDIT = t('edit').async()
 
const fetchActions = asyncMac(FETCH)
const editActions = asyncMac(EDIT)

const initialState = {
    data: [],
    fetched: false,
    fetching: false,
}

const fetchReducer = createReducer(initialState, createFetch( FETCH ))
const editReducer = createReducer(initialState, editFetch( EDIT ))

export default reduceReducers(fetchReducer, editReducer)

export const fetchProyectosGeneral = () => async (dispatch, getState) => {
    dispatch(fetchActions.start())
    try {
        const data = await Promise.all([
            leer('/proyecto'),
            fetch('https://api-portafolio.cydocs.cl/contratos')
        ]);
        let proyPortafolio = await data[1].json();
        let proyectosSoc = data[0].map(x => {
            let proyEncontrado = proyPortafolio.find(y => y._id === x.portafolio_id);
            return { ...x, sociedad: proyEncontrado ? proyEncontrado.sociedad_ref.nombre : ""  };
            });
        dispatch(fetchActions.success(proyectosSoc))
    } catch (error) {
        dispatch(fetchActions.error(error))
    }
}

export const editarProyecto = (id, values) => async (dispatch, getState) => {
    dispatch(editActions.start())
    try {
        const data = await modificar('/proyecto', id, values)
        dispatch(editActions.success(data))
    } catch (error) {
        console.log(error)
        dispatch(editActions.error(error))
    }
}